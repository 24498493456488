import { useEffect, useState } from 'react'
import { Formik, Form} from 'formik'
import SicondirSelect from "../components/SicondirSelect";
import SicondirInput from "../components/SicondirInput";
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import formatter from '../utils/formatters/_';
import tipiPagamento from "../../_data/tipi_pagamento.json";
import _ from 'lodash'

const PagamentoForm = (props) => {
    let pagamentoId = props.pagamento.id
    const [pagamento, setPagamento] = useState(props.pagamento);
    

    const checkIdDuplicato = async (valore, pagamentoId) => {
        const url = process.env.REACT_APP_API_URL + 'pagamenti/codice/'+pagamentoId+"/"+encodeURI(valore);
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });
          const json = await response.json()
          return !_.isEmpty(json.risposta.pagamenti)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
      };

    return(
        <>
          <br />
          {props.importoUtilizzato > 0 && 
            <div className="alert alert-info" role="alert">
              In questo momento non è possibile modificare il pagamento in quanto risulta associato a riferimenti di debiti.
            </div>
            }
           <Formik 
           id="pagamento"
           
           disabled={true}
           initialValues={{
                tipologia_pagamento: pagamento.tipologia_pagamento || '',
                identificativo_pagamento: pagamento.identificativo_pagamento || '',
                data_pagamento: pagamento.data_pagamento ? pagamento.data_pagamento.substring(0, 10) : '',
                importo: pagamento.importo ? formatter.formatNumber(pagamento.importo).replace(".", "") : '',
            }}
        
            validationSchema={Yup.object({
                tipologia_pagamento: Yup.string().required("Il campo è obbligatorio"),
                identificativo_pagamento: Yup.string().required("Il campo è obbligatorio").test('checkIdDuplicato', 
                'Identificativo già presente a sistema', 
                async function(value) {
                    if (value != null && value.trim()!='') {
                      const idDuplicato = await checkIdDuplicato(value, pagamentoId);
                      return !idDuplicato;
                    }
                    return true;
                }
                ),
                data_pagamento: Yup.string().required("Il campo è obbligatorio"),
                importo: Yup.string().required("Il campo è obbligatorio")
                .matches(/^((\d+(\,\d{0,2})?))$/, 'Inserire un valore numerico, i decimali(massimo 2) devono essere separati da ","')
                .test("checkImporto", "Il valore dell'importo non può essere inferiore a " + formatter.formatNumber(props.importoUtilizzato) + "€",
                function(value){
                  if(value != null && value.trim()!=''){
                    let i = parseFloat(value.replace(",", "."));
                    if(i < props.importoUtilizzato) return false
                  }
                  return true
                }
                ),
            })}  

          onSubmit = { async (values, { setSubmitting, resetForm }) => {
            let campi = ""
            Object.entries(values).map(([key,value],i) => {
                if(key == 'importo'){
                    if(value != ""){
                      value = value.replace(",", ".");
                    }
                } 
                campi += key + "='" + formatter.escapeSql(value) + "', ";
            })
              
            if(campi == ""){
            alertify.warning("Inserire almeno un campo");
            return;
            }

            try{
                  
                campi += "utente_modifica_id=" + localStorage.id;
                const payload = {
                  nome_tabella: "pagamenti",
                  lista_campi_valore: campi,
                  lista_clausole_where: "id="+ pagamentoId + " and flag_deleted=0"
                }
                
                const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' }, 
                  mode: 'cors', 
                  body: JSON.stringify({Entry:payload})
                })
                const data_response = await response.json();
                if(data_response.hasOwnProperty('Fault')){
                  if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                    console.log("ErrorCode: " + data_response.Fault.faultcode)
                    throw new Error(data_response.Fault.faultstring)
                  } else{
                    throw new Error("errore durante il salvataggio.")
                  }
                }
                alertify.success("Dati pagamento salvati con successo.");
                resetForm({ values });
                props.setAggiornamento(true)
              } catch(error) {
                alertify.error("Errore durante l'inserimento dei dati: " + error);
                console.log("errore: ", error);
              }


          }} 
          
          >
            {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
            }) => (
            <Form>
                <fieldset disabled={props.importoUtilizzato > 0}>
                <br /><br />
                <div className="row">
                    <div className="col-12">
                        <SicondirSelect
                        label='Tipologia Pagamento'
                        id='tipologia_pagamento'                
                        name='tipologia_pagamento'
                        mandatory='true'    
                        options={tipiPagamento} 
                        isClearable={true}
                        placeholder = 'Tipologia Pagamento'
                        defaultValue={ tipiPagamento.filter(c => c.value == pagamento.tipologia_pagamento) }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SicondirInput 
                            label='Identificativo Pagamento'
                            id='identificativo_pagamento'
                            type='text'
                            mandatory='true'
                            name='identificativo_pagamento'
                            className={`form-control`}
                            size="large" 
                            placeholder = 'Inserisci Identificativo Pagamento'   
                            maxLength = '100'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    <SicondirInput 
                      label='Data Pagamento'
                      id='data_pagamento'
                      type='date'
                      mandatory='true'
                      name='data_pagamento'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Data Pagamento'   
                    />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SicondirInput 
                        label='Importo Bollettino Pagamento(€)'
                        id='importo'
                        type='text'
                        mandatory='true'
                        name='importo'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Importo Pagamento'
                        maxLength = '10'  
                        />
                    </div>
                </div>
                <div className="col-12 mt-5 row justify-content-center">
                    <button type="submit" className="btn btn-primary col-5" disabled={!(isValid && dirty && !isSubmitting)}>
                        Salva
                    </button>
                </div>
                </fieldset>
            </Form>
            ) }
           </Formik>
        </>
    )
} 

export default PagamentoForm;