import ConcessioneIntestatario from "../components/ConcessioneIntestatario";
import ConcessioneForm from "../../../common/forms/ConcessioneForm";
import BeniDemanialiConcessioniForm from "../../../common/forms/BeniDemanialiConcessioniForm";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import DeleteButton from "../../../common/components/DeleteButton";
import { useParams, useLocation } from "react-router";
import { useEffect, useState } from 'react'
import alertify from "alertifyjs";  
import formatter from "../../../common/utils/formatters/_";
import OperaForm from "../../../common/forms/OperaForm";
import DocumentoForm from "../../../common/forms/DocumentoForm";
import ConcessioneCanone from "../components/ConcessioneCanone";

export default function Concessioni(props) {
    // Get ID from URL
    const params = useParams();
    const concessioneId = params.id;
    // Per recuperare l'url da cui provengo.
    const location = useLocation()
    // const {fromUrl} = location.state.fromUrl;
    const [fromUrl, setFromUrl] = useState('');
    const [concessione, setConcessione] = useState({});
    const [loading, setLoading] = useState(true);
    const [macroambito, setMacroambito] = useState('');

    useEffect(() => {
      const url = process.env.REACT_APP_API_URL + 'concessioni/id/'+concessioneId;
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          
          setConcessione(formatter.cleanObject(json.risposta.concessioni));
          json.risposta.concessioni.macroarea_ambito == 'D' ? setFromUrl("/concessioni/derivazioni") : setFromUrl("/concessioni/fluviali-lacuali")
          setMacroambito(json.risposta.concessioni.macroarea_ambito)
          setLoading(false);

        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
      };
  
      fetchData();
    }, []); 

    return (
      <main>
        <h3>Gestione concessione</h3>
        { !loading?
          <>
          <div className="card ">
          <div className="card-body">
            
            <Tabs defaultActiveKey="tab1">
              <Tab eventKey="tab1" title="Dati Concessione">
                <ConcessioneForm loading={loading} concessione={concessione} macroambito={macroambito}></ConcessioneForm>
              </Tab>
              <Tab eventKey="tab2" title="Intestatario">
                <ConcessioneIntestatario></ConcessioneIntestatario>
              </Tab>
              <Tab eventKey="tab3" title="Bene Demaniale/Derivazione">
                <BeniDemanialiConcessioniForm macroambito={macroambito}></BeniDemanialiConcessioniForm>
              </Tab>
              <Tab eventKey="tab4" title="Opere">
                <OperaForm macroambito={macroambito}></OperaForm>
              </Tab>
              <Tab eventKey="tab5" title="Documenti">
                <DocumentoForm></DocumentoForm>
              </Tab>
              <Tab eventKey="tab6" title="Dati Contabili">
                <ConcessioneCanone></ConcessioneCanone>
              </Tab>
            </Tabs>
            
          </div>
        </div>
        <br />
        <div className="card ">
              <div className="card-body">
                <div className="float-right">
                  <DeleteButton 
                    table="concessioni" 
                    resourceId={params.id} 
                    redirectPath={fromUrl}
                    buttonLabel="Elimina concessione"
                    confirmLabel="Procedere con l'eliminazione della concessione?"
                    successMessage="Concessione eliminata con successo"
                    errorMessage="Errore durante la cancellazione della concessione">  
                  </DeleteButton>
                </div>
              </div>
            </div>
            </>
          :
          
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>

        }
        
      </main>
    );
  }