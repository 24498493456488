import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Formik, isObject, Field } from 'formik'
import { Table } from 'react-bootstrap';
import alertify from "alertifyjs";
import formatter from '../../../common/utils/formatters/_';
import RicercaPersona from "../../../common/forms/RicercaPersona";
import Modal from 'react-bootstrap/Modal'
import SicondirTable from "../../../common/components/SicondirTable";
import IntestatarioForm from '../../../common/forms/IntestatarioForm';

import AnagraficaFormEdit from '../../../common/forms/AnagraficaFormEdit'
import { values } from 'lodash';
import { faRulerHorizontal } from '@fortawesome/free-solid-svg-icons';
import { propTypes } from 'react-bootstrap/esm/Image';
function RichiesteConcessioneIntestatario(props) {
    const [editShow, setEditShow] = React.useState(false);
    const [list, setList] = useState([])
    const params = useParams();
    const[modificaIntestatario,setModificaIntestatario]= useState(false)
    const richiestaId = params.id;
    const [ruoliIntestatario, setRuoliIntestatario] = useState([])
    const [richiestaIntestatario, setRichiestaIntestatario] = useState(null)
    const [show, setShow] = useState(false);
    const [associazione, setAssociazione] = useState(false);
    const [cancellaAssociazione, setCancellaAssociazione] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingEdit, setLoadingEdit] = useState(true)
    const[indirizzi, setIndirizzi] = useState({data: []})
    const [indirizzoCambiato, setIndirizzoCambiato] = useState(true);
    const[contatti, setContatti] = useState({data: []})
    const [contattoCambiato, setContattoCambiato] = useState(true);

    const handleModal = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }
    const handleModalEdit = async (idPersona) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL +
                'persone/id/' + idPersona

                ,
                {
                    method: 'GET',
                    headers: { accept: 'application/json' },
                    mode: 'cors',
                })

            const json = await response.json()
            let persona = null
            let ruoli = []
            if (json.risposta.persone) {
                persona = json.risposta.persone
            }
            const response2 = await fetch(process.env.REACT_APP_API_URL +
                'ruoli/persona_id/' + idPersona
                ,
                {
                    method: 'GET',
                    headers: { accept: 'application/json' },
                    mode: 'cors',
                })

            const json2 = await response2.json()
            if (json2.risposta.ruoli) {
                ruoli = json2.risposta.ruoli
            }


            setRichiestaIntestatario(formatter.cleanObject(persona))
            let array = []
            if(Array.isArray(ruoli)) {
                ruoli.map(r => {
                    array.push(formatter.cleanObject(r))
                })
                setRuoliIntestatario(array)
            }

            setLoadingEdit(false)

        }
        catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
        }

        setEditShow(true);

    }
    const handleCloseEdit = () => {
        setEditShow(false);
    }
    const handleDelete = async (idAssociazione) => {
        setLoading(true);
        const payload = {
            nome_tabella: "richieste_concessione_persone",
            id: idAssociazione,
            utente_modifica_id: localStorage.id
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
                method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                mode: 'cors',
                body: JSON.stringify({ Entry: payload })
            })
            const data = await response.json();
            alertify.success("Associazione intestatario/domanda eliminata con successo");

            setLoading(false)
            setCancellaAssociazione(true)
        } catch (error) {
            alertify.error("Errore durante la cancellazione dell'associazione bene intestatario/domanda");
            setLoading(false)
        }
    }


    const fetchData = async (richiestaId) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `richieste_concessione_persone/richiesta_id/${richiestaId}`,
                {
                    method: 'GET',
                    headers: { accept: 'application/json' },
                    mode: 'cors',
                })
            const json = await response.json()
            if (json.risposta.richieste_persone) {
                let response = json.risposta.richieste_persone
                setRichiestaIntestatario(response)
                const lista = []
                if (Array.isArray(response)) {
                    const entries = Object.entries(response);
                    let i = 0;
                    entries.map(([key, value]) => {
                        formatter.cleanObject(value)
                        let actions = buildActions(value.associazione_id, value.id)
                        lista[i] =
                        {
                            id: value.id,
                            nome: value.nome,
                            cognome: value.cognome,
                            codice_fiscale: value.codice_fiscale,
                            ragione_sociale: value.ragione_sociale,
                            piva: value.piva,
                            tipo_persona: value.tipo_persona,
                            ruolo: 'Intestatario',
                            app_actions: actions
                        }

                        i++
                    })
                } else {
                    // solo un caso
                    let value = formatter.cleanObject(response)
                    let actions = buildActions(value.associazione_id, value.id)
                    lista[0] =
                    {
                        id: value.id,
                        nome: value.nome,
                        cognome: value.cognome,
                        codice_fiscale: value.codice_fiscale,
                        ragione_sociale: value.ragione_sociale,
                        piva: value.piva,
                        tipo_persona: value.tipo_persona,
                        ruolo: 'Intestatario',
                        app_actions: actions

                    }

                }
                setList(lista)
                setCancellaAssociazione(false)
                setLoading(false);
            } else {
                setRichiestaIntestatario(null)
            }
            setAssociazione(false)
            setModificaIntestatario(false)
            setLoading(false);
        } catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
        }
    }
    const buildActions = (idAssociazione, idPersona) => {
        let result = {};

        result = {
            associa: {
                kind: "function",
                function: handleDelete,
                parameters:
                {
                    idAssociazione: idAssociazione
                },
                label: "Elimina associazione",
                buttonClass: "btn-danger",
                linkClass: "text-danger",
                confirmLabel: "Procedere con l'eliminazione dell'intestatario?"
            },
            modifica: {
                kind: "function",
                function: handleModalEdit,
                parameters:
                {
                    idPersona: idPersona
                },
                label: "Modifica Intestatario"
            }
        }

        return result;
    }
    const fetchDataIndirizzi = async () => {
        if(richiestaIntestatario) {
            try {
            setLoading(true)

            // INDIRIZZI
            // const whereIndirizzi = "AND persona_id="+richiestaIntestatario.id
            
            let urlApiIndirizzi = `persone/indirizzi/${encodeURI(richiestaIntestatario.id)}`
            
            const responseIndirizzi = await fetch(process.env.REACT_APP_API_URL + urlApiIndirizzi, 
            {
                    method: 'GET',
                    headers: { accept:'application/json' }, 
                    mode: 'cors',  
            })
    
            const jsonindirizzi = await responseIndirizzi.json()
            if(jsonindirizzi.risposta) {
                setIndirizzi(formatter.cleanObject(jsonindirizzi.risposta.persone_indirizzi))
            } else
                setIndirizzi([])
            } catch (error) {
            alertify.error("Errore durante il recupero degli indirizzi.");
            console.log("errore: ", error);
            }
            setLoading(false)
            setIndirizzoCambiato(false)
        }
    }
    const fetchDataContatti = async () => {
        if(richiestaIntestatario) {
            try {
            setLoading(true)
            
            // CONTATTI
            let urlApiContatti = `persone/contatti/` + richiestaIntestatario.id;
            const responseContatti = await fetch(process.env.REACT_APP_API_URL + urlApiContatti, 
            {
                    method: 'GET',
                    headers: { accept:'application/json' }, 
                    mode: 'cors',  
            })
            const jsoncontatti = await responseContatti.json()
            if(jsoncontatti.risposta) {
                setContatti(formatter.cleanObject(jsoncontatti.risposta.persone_contatti))
                } else
                setContatti([]);
            } catch (error) {
            alertify.error("Errore durante il recupero dei contatti.");
            console.log("errore: ", error);
            }
            setLoading(false)
            setContattoCambiato(false)
        }
    }
    useEffect(() => {
        fetchData(richiestaId)
    }, [associazione, cancellaAssociazione, modificaIntestatario]
    )
    useEffect(() => {
        if(indirizzoCambiato) {
          setLoading(true)
          setIndirizzoCambiato(false)
          fetchDataIndirizzi();
        }
        if(contattoCambiato) {
          setLoading(true)
          setContattoCambiato(false)
          fetchDataContatti()
        }
    },[indirizzoCambiato, contattoCambiato, fetchDataIndirizzi])

    return (
        <>
            {!loading
                ?
                <>
                    {!richiestaIntestatario
                        ?
                        <div className="row">
                            <div className="col-12 mt-5 row justify-content-center">
                                Nessun Intestatario è associato a questa domanda.
                            </div>
                        </div>
                        :
                        <div>
                            <br />
                            <SicondirTable
                                headers={[
                                    "Nome",
                                    "Cognome",
                                    "Codice fiscale",
                                    "Ragione sociale",
                                    "Partita Iva",
                                    "Tipo",
                                    "Ruolo",
                                    ""
                                ]}
                                data={list}
                                dropdown={true}
                                readOnly={props.readOnly}
                                />
                        </div>
                    }
                    <div className="col-12 mt-5 row justify-content-center">
                        <button className="btn btn-primary col-5" onClick={handleModal} disabled={props.readOnly}>
                            Associa Intestatario
                        </button>
                    </div>
                </>
                :
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <div className="progress-spinner progress-spinner-active">
                        <span className="sr-only">Caricamento...</span>
                    </div>
                </div>

            }


            <Modal
                dialogClassName={'modal-fullpage'}
                show={editShow}
                onHide={handleCloseEdit}
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        Modifica Intestatario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loadingEdit ?

                        <AnagraficaFormEdit
                            ruoli={ruoliIntestatario}
                            persona={richiestaIntestatario}
                            indirizzi={indirizzi}
                            contatti={contatti} 
                           // setIndirizzoCambiato={setIndirizzoCambiato} 
                          //  setContattoCambiato={setContattoCambiato} 
                            setPersonaAggiornata={setAssociazione}
                            setModificaIntestatario={setModificaIntestatario}
                            contesto="richiestaConcessioni"
                            handleClose={handleCloseEdit}
                        />
                        

                        :
                        <div className="h-100 d-flex align-items-center justify-content-center">
                            <div className="progress-spinner progress-spinner-active">
                                <span className="sr-only">Caricamento...</span>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>

            <Modal
                dialogClassName={'modal-fullpage'}
                show={show}
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        Associa Intestatario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RicercaPersona richiestaId={richiestaId} contesto="richiesta" handleClose={handleClose} richiestaIntestatario={richiestaIntestatario} setAssociazione={setAssociazione} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RichiesteConcessioneIntestatario
