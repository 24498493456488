import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirSelect from "../../components/SicondirSelect";
import tipiAmbito from '../../../_data/tipi_ambito.json'
import SicondirInput from "../../components/SicondirInput";
import tipiDurata from '../../../_data/tipi_durata.json';
import tipiRichiesta from '../../../_data/tipi_richiesta.json';
import formatter from '../../utils/formatters/_';
import { isNull, stubString } from "lodash";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import ConcessioniRicerca from '../../../modules/concessioni/components/ConcessioniRicerca';
import Modal from 'react-bootstrap/Modal'
import SicondirTable from "../../components/SicondirTable";
import { onConfirm } from "../../components/DeleteButton";
import provinceLazio from "../../../_data/province_lazio.json"
import SicondirTextarea from "../../components/SicondirTextarea";
import tipiStatoConcessione from "../../../_data/tipi_stato_concessioni.json";
import tipiOccupazione from "../../../_data/tipi_occupazione.json";
import SicondirSingleCheckbox from "../../components/SicondirSingleCheckbox";
import RichiesteConcessioniRicerca from "../../../modules/concessioni/components/RichiesteConcessioniRicerca";

const OccupazioneForm = (props) => {
    // Get ID from URL
    const params = useParams();
    const occupazioneId = params.id;
    let navigate = useNavigate();
    const [oldValue, setOldValue] = useState(props.data);
    const [loading, setLoading] = useState(props.loading);
    const [occupazione, setOccupazione] = useState(props.occupazione);
    const [tipiAmbitoFiltrati, setTipiAmbitoFiltrati] = useState([])
    const [valueUploaded, setValueUploaded] = useState(false);
    const [associazione, setAssociazione] = useState(false)
    const [show, setShow] = useState(false);
    const [cancellaAssociazione, setCancellaAssociazione] = useState(false);
    const [concessione, setConcessione] = useState(null)
    const [list, setList] = useState([])

    // valori per richiesta
    const [showRichiesta, setShowRichiesta] = useState(false);
    const [richiesta, setRichiesta] = useState(null)
    const [listRichieste, setListRichieste] = useState([])
    const [associazioneRichiesta, setAssociazioneRichiesta] = useState(false)
    const [cancellaAssociazioneRichiesta, setCancellaAssociazioneRichiesta] = useState(false)

    const [provinciaSelezionata,setProvinciaSelezionata] = useState(null)
    const [provincianascita,setProvincianascita] = useState()
    const [comunenascita, setComunenascita] = useState();
    const [nazionenascita, setNazionenascita] = useState('');
    const [codicefiscale, setCodicefiscale] = useState(props.cod_fisc);
    const [labelComuneNascita, setLabelComuneNascita] = useState('Comune nascita');
    const [ragione_sociale,setRagione_sociale] = useState(props.ragione_sociale);
    const [genere, setGenere] = useState('');
    const [datanascita, setDatanascita] = useState('');
    const [estero,setEstero] = useState('');
    const [valueNaturaGiuridica, setValueNaturaGiuridica] = useState(props.natura_giuridica);
    const Mesi = { A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' }
    const [stringaMacroambito, setStringaMacroambito] = useState(props.macroambito == "F" ? "fluviali-lacuali" : "derivazioni")
    
    const collegamento=async(id) => {
      navigate('/concessioni/' + stringaMacroambito + '/inserisci/' + id);
    }

    const collegamentoRichiesta=async(id) => {
      navigate('/richieste-concessioni/' + stringaMacroambito + '/inserisci/' + id);
    }


    const handleModal = () => {
      setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }
   
    const handleDelete = async (associazioneId) => {
      setLoading(true);
      const payload = {
          nome_tabella: "occupazioni_concessione",
          id: associazioneId,
          utente_modifica_id: localStorage.id
      }
      console.log("payload", payload)
      try{
          const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
              method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
              headers: { 'Content-Type': 'application/json',
                          'Accept': 'application/json' }, 
              mode: 'cors', 
              body: JSON.stringify({Entry:payload})
          })
          const data = await response.json();
          alertify.success("Associazione occupazione/concessione eliminata con successo");
          setLoading(false)
          setCancellaAssociazione(true)
          setList([]);
      } catch(error) {
          alertify.error("Errore durante la cancellazione dell'associazione bene occupazione/concessione");
          setLoading(false)
      }
  }

  /* */
  const handleModalRichiesta = () => {
    setShowRichiesta(true);
  }

  const handleCloseRichiesta = () => {
      setShowRichiesta(false);
  }

  const handleDeleteRichiesta = async (associazioneId) => {
    setLoading(true);
    const payload = {
        nome_tabella: "occupazioni_richieste_concessione",
        id: associazioneId,
        utente_modifica_id: localStorage.id
    }
  
    try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
            method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
            headers: { 'Content-Type': 'application/json',
                        'Accept': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
        })
        const data = await response.json();
        alertify.success("Associazione occupazione/domanda eliminata con successo");
        setLoading(false)
        setCancellaAssociazioneRichiesta(true)
        setListRichieste([]);
    } catch(error) {
        alertify.error("Errore durante la cancellazione dell'associazione bene occupazione/domanda");
        setLoading(false)
    }
}
  /* */

  useEffect(() => {
    if(oldValue && !valueUploaded){
      setValueUploaded(true)
      if(oldValue.tipo_provincia_id){
    const provinciaSelezionataRecuperata = provinceLazio.filter(c => c.label == oldValue.tipo_provincia_id).map(c => {
      return {
        value: c.value,
        label: c.label,
      }
    })
    setProvinciaSelezionata(provinciaSelezionataRecuperata[0])
  }}} ,[oldValue])

  useEffect(() => { 
    const fetchData = async () => {
        // Per la tendina degli ambiti:
        const res = tipiAmbito.filter(c => c.macroarea_ambito == props.occupazione.macroarea_ambito)
        .map(    
        c => { return { 
            value: c.value,
            label: c.label,
        };}
        )
        setTipiAmbitoFiltrati(res)

        // Recupero l'associazione con la concessione se presente: 
        try {
          const url = process.env.REACT_APP_API_URL + 'occupazioni_concessione/occupazione_id/' + occupazioneId
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          if(json.risposta.concessione){
              let element = formatter.cleanObject(json.risposta.concessione)
              setConcessione(element); 
                 // gestione intestatario
                 let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
                 let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
                 let ragioneSociale =  datiIntestatario[2]
                 let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
                 let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
                 let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]

                 let statoArray = tipiStatoConcessione.filter(s => (s.value == element.stato))
                 let stato
                 if(statoArray[0]) stato = statoArray[0].label
                 let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
                 let tipoAmbito
                 if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice

              let listConcessioni = []
              listConcessioni[0] = {
                id: element.associazione_id,
                tipo_ambito: tipoAmbito || "-",
                codice_sicondir: element.codice_sicondir  || "-",
                codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                intestatario: intestatario ,
                codice_fiscale: codiceFiscale,
                piva: piva,
                stato: stato || "-",
                app_actions:{
                  elimina: {
                    kind: "function", 
                    function: handleDelete,
                    label: "Elimina associazione",
                    parameters: {
                        associazioneId: element.associazione_id
                    },
                    confirmLabel: "Procedere con l'eliminazione dell'associazione alla concessione?",
                    buttonClass:"btn-danger",
                    linkClass: "text-danger",
                  },
                  collegamento: { 
                    kind:"function",
                    function: collegamento,
                    parameters:
                    {
                      id: element.id
                    },
                    label: "Dettaglio Concessione"     
                  },
              }
              }
              setList(listConcessioni) // c'è una lista, ma dovremmo avere sempre e solo una concessione

          } else{
              setConcessione(null);
          }
          setLoading(false);
          setAssociazione(false)
          setCancellaAssociazione(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }

        setLoading(false);
    };
    fetchData();
  }, [associazione, cancellaAssociazione]);
  
  /* */
  useEffect(() => {
    if(oldValue && !valueUploaded){
      setValueUploaded(true)
      if(oldValue.tipo_provincia_id){
    const provinciaSelezionataRecuperata = provinceLazio.filter(c => c.label == oldValue.tipo_provincia_id).map(c => {
      return {
        value: c.value,
        label: c.label,
      }
    })
    setProvinciaSelezionata(provinciaSelezionataRecuperata[0])
  }}} ,[oldValue])

  useEffect(() => { 
    const fetchData = async () => {
        // Per la tendina degli ambiti:
        const res = tipiAmbito.filter(c => c.macroarea_ambito == props.occupazione.macroarea_ambito)
        .map(    
        c => { return { 
            value: c.value,
            label: c.label,
        };}
        )
        setTipiAmbitoFiltrati(res)

        // Recupero l'associazione con la concessione se presente: 
        try {
          const url = process.env.REACT_APP_API_URL + 'occupazioni_richieste_concessione/occupazione_id/' + occupazioneId
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          if(json.risposta.richiesta){
              let element = formatter.cleanObject(json.risposta.richiesta)
              setRichiesta(element); 
                 // gestione intestatario
                 let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
                 let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
                 let ragioneSociale =  datiIntestatario[2]
                 let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
                 let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
                 let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]

                 let statoArray = tipiStatoConcessione.filter(s => (s.value == element.stato))
                 let stato
                 if(statoArray[0]) stato = statoArray[0].label
                 let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
                 let tipoAmbito
                 if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice

              let listRichieste = []
              listRichieste[0] = {
                id: element.associazione_id,
                tipo_ambito: tipoAmbito || "-",
                codice_sicondir: element.codice_sicondir  || "-",
                codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                intestatario: intestatario ,
                codice_fiscale: codiceFiscale,
                piva: piva,
                stato: stato || "-",
                app_actions:{
                  elimina: {
                    kind: "function", 
                    function: handleDeleteRichiesta,
                    label: "Elimina associazione",
                    parameters: {
                        associazioneId: element.associazione_id
                    },
                    confirmLabel: "Procedere con l'eliminazione dell'associazione alla domanda?",
                    buttonClass:"btn-danger",
                    linkClass: "text-danger",
                  },
                  collegamento: { 
                    kind:"function",
                    function: collegamentoRichiesta,
                    parameters:
                    {
                      id: element.id
                    },
                    label: "Dettaglio Richiesta"       
                  },
                }
              }
              setListRichieste(listRichieste) // c'è una lista, ma dovremmo avere sempre e solo richiesta

          } else{
              setRichiesta(null);
          }
          setLoading(false);
          setAssociazioneRichiesta(false)
          setCancellaAssociazioneRichiesta(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
        setLoading(false);
    };
    fetchData();
  }, [associazioneRichiesta, cancellaAssociazioneRichiesta]);
  /* */

  useEffect(() => {
    setOldValue(props.data);
  });

  useEffect(() => {
    if(oldValue && !valueUploaded){
      setValueUploaded(true)
          if(oldValue.tipo_ambito_id){ const tipoAmbitoSelezionato = tipiAmbito.filter( // ...lo popolo
          (c) => c.value == oldValue.tipo_ambito_id
        ).map((c) => {
          return {
            value: c.value,
            label: c.label,
          };
        });
        setTipiAmbitoFiltrati(tipoAmbitoSelezionato[0])
      }
      if(oldValue.tipo_provincia_id){
    const provinciaSelezionataRecuperata = provinceLazio.filter(c => c.label == oldValue.tipo_provincia_id).map(c => {
      return {
        value: c.value,
        label: c.label,
      }
    })
    setProvinciaSelezionata(provinciaSelezionataRecuperata[0])
  }}} ,[oldValue])

  useEffect(() => {
    setLoading(false);
  });

  const options = [
    { value: 'BOZZA', label: 'Bozza' },
    { value: 'CONSOLIDATA', label: 'Consolidata' }
  ]

    return (
        <>
        {!loading ?
        <>
        <Formik
        id='occupazione' className="p-4"
        initialValues={{
          tipo_ambito_id: occupazione.tipo_ambito_id || '',
          tipo_occupazione_id: occupazione.tipo_occupazione_id || '',
          tipo_richiesta_id: occupazione.tipo_richiesta_id || '', 
          descrizione_occupazione: occupazione.descrizione_occupazione || '',
          data_accertamento_occupazione: occupazione.data_accertamento_occupazione ? occupazione.data_accertamento_occupazione.substring(0, 10) : '',
          data_termine_occupazione: occupazione.data_termine_occupazione ? occupazione.data_termine_occupazione.substring(0, 10) : '',
          codice_sistema_precedente: occupazione.codice_sistema_precedente || '',
          codice_sicondir: occupazione.codice_sicondir || '', // è "nuovo codice univoco occupazione"
          archivio_provenienza: occupazione.archivio_provenienza || '',
          archivio_codice: occupazione.archivio_codice || '',
          archivio_posizione: occupazione.archivio_posizione || '',
          tipo_provincia_id: occupazione.tipo_provincia_id || '',
          presenza_abusi: occupazione.presenza_abusi || '',
          note: occupazione.note || '',
          stato_import: occupazione.stato_import || 'BOZZA',
          data_consolidazione: occupazione.data_consolidazione ? occupazione.data_consolidazione.substring(0, 10) : '',  
        }}
          onSubmit = { async (values, { setSubmitting }) => {
                let campi = ""
                Object.entries(values).map(([key,value],i) => {
                  if(value != '' && value != null && key!='data_consolidazione'){
                    campi += key + "='" + formatter.escapeSql(value) + "', ";
                    if(key=="stato_import" && value=="CONSOLIDATA"){
                      
                      var today = new Date();
                      
                      var date = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');
                      campi+="data_consolidazione='"+date +"', "
                    }
                  } 
                  if(value != ''&& value != null){
                    campi += key + "='" + formatter.escapeSql(value) + "', ";
                  } else {
                    if(key != 'data_consolidazione')
                      campi += key + "=NULL , ";
                  }
                  if(key == 'tipo_ambito_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_occupazione_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_richiesta_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'descrizione_occupazione' && value == null) { campi += key + "=null, "; }
                  if(key == 'data_accertamento_occupazione' && value == null) { campi += key + "=null, "; }
                  if(key == 'data_termine_occupazione' && value == null) { campi += key + "=null, "; }
                  if(key == 'codice_sistema_precedente' && value == null) { campi += key + "=null, "; }
                  if(key == 'codice_sicondir' && value == null) { campi += key + "=null, "; }
                  if(key == 'archivio_provenienza' && value == null) { campi += key + "=null, "; }
                  if(key == 'archivio_codice' && value == null) { campi += key + "=null, "; }
                  if(key == 'archivio_posizione' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_provincia_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'presenza_abusi' && value == null) { campi += key + "=null, "; }
                  if(key == 'note' && value == null) { campi += key + "=null, "; }

                })
                
                if(campi == ""){
                  alertify.warning("Inserire almeno un campo");
                  return;
                }
              
                try{
                  campi += "utente_modifica_id=" + localStorage.id;
                  const payload = {
                    nome_tabella: "occupazioni",
                    lista_campi_valore: campi,
                    lista_clausole_where: "id="+ occupazioneId + " and flag_deleted=0"
                  }
                  
                  
                  const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:payload})
                  })
                  const data_response = await response.json();
                  if(data_response.hasOwnProperty('Fault')){
                    if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + data_response.Fault.faultcode)
                      throw new Error(data_response.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }
                  // navigate('/beni-demaniali');
                  alertify.success("Dati occupazione salvati con successo.");
                } catch(error) {
                  alertify.error("Errore durante l'inserimento dei dati: " + error);
                  console.log("errore: ", error);
                }
                
            }}

        >
            
                  
        <Form>
                <br /><br />
                <div className="row">
                  <div className="col-4 md-12 sm-12 ">
                    <SicondirSelect 
                      label="Tipo Ambito" 
                      id="tipo_ambito_id" 
                      name="tipo_ambito_id" 
                      placeholder="Seleziona Tipo Ambito"
                      options={tipiAmbitoFiltrati} 
                      isClearable={true}
                      isDisabled={true}
                      defaultValue={ tipiAmbito.filter(c => c.value == occupazione.tipo_ambito_id) }
                    />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect 
                      label="Tipo Occupazione" 
                      id="tipo_occupazione_id" 
                      name="tipo_occupazione_id" 
                      placeholder="Seleziona Tipo Occupazione"
                      options={tipiOccupazione} 
                      isClearable={true}
                      defaultValue={ tipiOccupazione.filter(c => c.value == occupazione.tipo_occupazione_id) } 
                    />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect 
                      label="Tipo Domanda" 
                      id="tipo_richiesta_id" 
                      name="tipo_richiesta_id" 
                      placeholder="Seleziona Tipo Domanda"
                      options={tipiRichiesta} 
                      isClearable={true}
                      defaultValue={ tipiRichiesta.filter(c => c.value == occupazione.tipo_richiesta_id) }    
                    />    
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                        label='Descrizione Occupazione'
                        id='descrizione_occupazione'
                        type='text'
                        name='descrizione_occupazione'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Descrizione Occupazione'   
                        maxLength = '255'
                      />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                        label='Data Inizio Occupazione'
                        id='data_accertamento_occupazione'
                        type='date'
                        name='data_accertamento_occupazione'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Data Inizio Occupazione'
                        maxLength = '255'
                      />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                        label='Data Termine Occupazione'
                        id='data_termine_occupazione'
                        type='date'
                        name='data_termine_occupazione'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Data Termine Occupazione'
                        maxLength = '255'
                      />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Codice Univoco Del Sistema Di Provenienza'
                      id='codice_sistema_precedente'
                      type='text'
                      name='codice_sistema_precedente'
                      className={`form-control mb-2`}
                      size="large" 
                      disabled={true}
                      placeholder = '-'   
                      maxLength = '255'
                      />
                  </div>
                  <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Nuovo Codice Univoco Occupazione'
                        id='codice_sicondir'
                        type='text'
                        name='codice_sicondir'
                        className={`form-control mb-2`}
                        size="large" 
                        disabled={true}
                        placeholder = 'Inserisci Nuovo Codice Univoco Occupazione'   
                        maxLength = '255'
                      />
                  </div>
                </div>
                <div className="row">
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Archivio Provenienza'
                        id='archivio_provenienza'
                        type='text'
                        name='archivio_provenienza'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Archivio Provenienza'   
                        maxLength = '255'
                      />
                    </div>
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Codice Archivio Provenienza'
                        id='archivio_codice'
                        type='text'
                        name='archivio_codice'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Codice Archivio Provenienza'   
                        maxLength = '255'
                      />
                    </div>
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Posizione Archivio'
                        id='archivio_posizione'
                        type='text'
                        name='archivio_posizione'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Posizione Archivio'   
                        maxLength = '255'
                      />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect
                      label='Provincia'
                      id='tipo_provincia_id'                
                      name='tipo_provincia_id'
                      options={provinceLazio} 
                      isClearable={true}
                      defaultValue={  provinceLazio.filter(c => c.value == occupazione.tipo_provincia_id) }  
                      isDisabled={true}
                      placeholder = 'Provincia '
                      maxLength = '2'
                    />           
                    </div>
                    {/* Eventualmente qua mettere la tendina dei regolamenti. */}
                </div>
                
                <div className="row">
                  <SicondirTextarea
                      label='Presenza Abusi'
                      id='presenza_abusi'
                      type='textarea'
                      style={{height: "100px", width: "100%"}}
                      name='presenza_abusi'
                      maxLength = '255'
                      size="large" 
                    />
                </div>
                <div className="row">
                  <div className="col-12 md-12 sm-12">
                    <SicondirTextarea
                      label='Note'
                      id='note'
                      type='textarea'
                      style={{height: "150px", width: "100%"}}
                      name='note'
                      size="large" 
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect
                      label='Stato Import'
                      id='stato_import'
                      type='text'
                      name='stato_import'                   
                      size="large" 
                      placeholder = 'Stato Import' 
                      options={options} 
                      isClearable={false}
                      isNullable={false}
                      defaultValue={ options.filter(c => c.value == occupazione.stato_import) }                       
                     // onChange={enableDataConsolidazione}

                    />
                  </div>

                  <div className="col-4 md-12 sm-12">
                    <SicondirInput readOnly
                      label='Consolidato in data'
                      id='data_consolidazione'
                      type='date'
                      name='data_consolidazione'                   
                      size="large" 
                      placeholder = 'Data Consolidazione'                
                    />
                  </div>
                </div>


            <div className="col-12 mt-5 row justify-content-center">
                <button type="submit" className="btn btn-primary col-5">
                Salva
                </button>
            </div>
            
        </Form>
             
        </Formik>
        <>
        <br /> <br />
        <hr></hr>
        <h5>Concessione associata</h5>
        { !concessione
            ?
            <div className="row">
              <div className="col-12 mt-5 row justify-content-center">
                  Nessuna concessione associata all'occupazione
              </div>
              <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModal}>
                  Associa Concessione
                </button>
              </div>
              </div>
            : 
            <>
              <SicondirTable 
                headers={[
                  "Tipo ambito",
                  "Codice sicondir",
                  "Codice sistema precedente",
                  "Richiedente/ Intestatario",
                  "Codice fiscale",
                  "Partita IVA", 
                  "Stato",
                  ""
                  ]} 
                data={list} 
                dropdown={true}
              />         
            </>
        }
        <hr></hr>
        <h5>Domanda associata</h5>
        { !richiesta
            ?
            <div className="row">
              <div className="col-12 mt-5 row justify-content-center">
                  Nessuna domanda associata all'occupazione
              </div>
              <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModalRichiesta}>
                  Associa Domanda
                </button>
              </div>
              </div>
            : 
            <>
              <SicondirTable 
                headers={[
                  "Tipo ambito",
                  "Codice sicondir",
                  "Codice sistema precedente",
                  "Richiedente/ Intestatario",
                  "Codice fiscale",
                  "Partita IVA", 
                  "Stato",
                  ""
                  ]} 
                data={listRichieste}
                dropdown={true}
              />         
            </>
        }
        </>
        </>
        :
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>
        }

        <Modal 
                dialogClassName={'modal-fullpage'}
                show={show} 
                onHide={handleClose}>
				  <Modal.Header closeButton>
					  <Modal.Title >
              Ricerca Concessione
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <ConcessioniRicerca contesto='occupazioni' occupazioneId={occupazioneId}
                handleClose={handleClose}
                setAssociazione={setAssociazione}
                macroambito={props.occupazione.macroarea_ambito}/>
          </Modal.Body>
        </Modal>          

        <Modal 
                dialogClassName={'modal-fullpage'}
                show={showRichiesta} 
                onHide={handleCloseRichiesta}>
				  <Modal.Header closeButton>
					  <Modal.Title >
              Ricerca Domanda
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <RichiesteConcessioniRicerca contesto='occupazioni' occupazioneId={occupazioneId}
                handleClose={handleCloseRichiesta}
                setAssociazione={setAssociazioneRichiesta}
                macroambito={props.occupazione.macroarea_ambito}/>
          </Modal.Body>
        </Modal>

      </>
    )
}

export default OccupazioneForm;