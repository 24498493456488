import { Field, useField } from "formik";

export default function SicondirRadio(props){
   let count = 0;
   const [field, meta] = useField(props);
   const mandatory = props.mandatory ? "*" : ""
   return (
    <>
    <label className="sicondir-label" htmlFor={props.name}>{props.label}<sup>{mandatory}</sup></label>
        <div>
            { 
                props.options.map((option) => {
                count++
                return(
                <div className="form-check" key={props.name + "_div_" + count}>
                  <input {...field} {...props} type="radio" value={option.value} checked={option.checked} id={props.name + "_" + count}/>
                  <label htmlFor={props.name + "_" + count}>{option.label}</label>
                </div>
                )
                })
            }
        </div>
        {
        meta.touched && meta.error ? 
        (<small className="sicondir-error">{meta.error}</small>) 
        : (<div style={{height: "8px"}}></div>)
        } 
    </>
   )
}