import { useState,useEffect } from 'react'
import { Formik, isObject, Field } from 'formik'
import * as Yup from 'yup' 
import IntestatarioForm from './IntestatarioForm' 
import formatter from '../utils/formatters/_'
import _ from 'lodash'
import SicondirRadio from '../components/SicondirRadio'
import IntestatarioRichiestaForm from './richiesteConcessioni/IntestatarioRichiestaForm'
import alertify from "alertifyjs";

const initialValue = {
    cognome: "",
    naturagiuridica: "",
    codice_fiscale: "",
    ragione_sociale: "",
    piva: "",
    codice_sicer: ""
}

const RicercaPersona = (props) => {
    const [ricerca, setRicerca] = useState(true);
    const [personaTrovata, setPersonaTrovata] = useState({})
    const [codicefiscale, setCodicefiscale] = useState('')
    const [cognome, setCognome] = useState('')
    const [partitaiva, setPartitaiva] = useState('')
    const [ragione_sociale, setRagione_sociale] = useState('')
    const [codiceSicer, setCodiceSicer] = useState('')
    const [valueNaturaGiuridica, setValueNaturaGiuridica] = useState('PF');
    const [loading, setLoading] = useState(false)

    const validationSchemaRicercaPf = Yup.object().shape(
        {
            cognome: Yup.string().min(3, "Specificare almeno 3 caratteri"),
            codice_fiscale: Yup.string().min(16, "Il codice fiscale non può avere meno di 16 caratteri"),
            codice_sicer: Yup.string().min(2, "Specificare almeno 2 caratteri")
        },
        [["cognome", "codice_fiscale", "codice_sicer"]]
    );
    const validationSchemaRicercaPg = Yup.object().shape(
        {
          ragione_sociale: Yup.string().min(3, "Specificare almeno 3 caratteri"),
          piva: Yup.string().min(11, "La partita Iva non può avere meno di 11 caratteri"),
          codice_sicer: Yup.string().min(2, "Specificare almeno 2 caratteri")
        },
        [["ragione_sociale", "piva", "codice_sicer"]]
    );

    const onChange = (e, errors) => {
        for (const key in errors) {
          delete errors[key];
        }
        setValueNaturaGiuridica(e.target.value)
    };
    const fetchData = async (natura_giuridica, cod_fisc, cognome, partita_iva, ragione_sociale, codice_sicer) => {
        try {
            
            let arrayParametri = new Array();
            if(natura_giuridica=='PF') {
                arrayParametri.push("PF");
                let cfParam = cod_fisc ? encodeURI(cod_fisc) : " ";
                let cognomeParam = cognome ? encodeURI(cognome.replace(/^\|+|\|+$/g, '')) : " ";
                arrayParametri.push(cognomeParam);
                arrayParametri.push(cfParam);
            }
            
            if(natura_giuridica=='PG') {
                arrayParametri.push("PG");
                let pivaParam = partita_iva ? encodeURI(partita_iva) : " ";
                let ragioneSocialeParam = ragione_sociale ? encodeURI(ragione_sociale.replace(/^\|+|\|+$/g, '')) : " ";
                arrayParametri.push(ragioneSocialeParam);
                arrayParametri.push(pivaParam);
            }
            let codicesicerParam = codice_sicer ? encodeURI(codice_sicer) : "-";
            arrayParametri.push(encodeURI(codicesicerParam));
            let urlPhp = "";

            setLoading(true)
            if(props.contesto=='Concessione') {
                urlPhp += 'persone/intestatario/' + props.concessioneId + "/" + arrayParametri[0] + "/" +   arrayParametri[1] + "/" + arrayParametri[2] + "/" + arrayParametri[3];
            } else if(props.contesto=='richiesta') {
                urlPhp += 'persone/intestatario/richiesta/' + props.richiestaId + "/" + arrayParametri[0] + "/" +   arrayParametri[1] + "/" + arrayParametri[2] + "/" + arrayParametri[3];
            } else if(props.contesto=='occupazione') {
                urlPhp += 'persone/intestatario/occupazione/' + props.occupazioneId + "/" + arrayParametri[0] + "/" +   arrayParametri[1] + "/" + arrayParametri[2] + "/" + arrayParametri[3];
            } else if(props.contesto=='denuncia_pozzo') {
                urlPhp += 'persone/intestatario/denuncia_pozzo/' + props.denunciaPozzoId + "/" + arrayParametri[0] + "/" +   arrayParametri[1] + "/" + arrayParametri[2] + "/" + arrayParametri[3];
            } else {
                urlPhp += 'persone/ricerca/' + arrayParametri[0] + "/" +   arrayParametri[1] + "/" + arrayParametri[2] + "/" + arrayParametri[3];
            }
            
            const response = await fetch(process.env.REACT_APP_API_URL + urlPhp, 
            {
                method: 'GET',
                headers: { accept:'application/json' }, 
                mode: 'cors',  
            })
            const json = await response.json()
            setRicerca(false)
            setLoading(false)
            
            if(!isObject(json.risposta)){
                // non è un oggetto, dunque non c'è nel db e quindi deve apparire il form intestatario
                setCodicefiscale(cod_fisc)
                setPartitaiva(partita_iva)
                setCognome(cognome)
                setRagione_sociale(ragione_sociale)
                setCodiceSicer(codice_sicer)
            } else {
                setPersonaTrovata(formatter.cleanObject(json.risposta.persone))
            }
        } catch (error) {
            console.log("errore: ", error);
        }
    }
    return (
        <>
            {   
               // !codicefiscale.length && !Object.keys(personaTrovata).length 
               ricerca
            ? 
                <>
                <h3>Ricerca persona</h3>
                <div className="card ">
                <Formik 
                    initialValues={initialValue} 
                    validationSchema={valueNaturaGiuridica=='PF'?validationSchemaRicercaPf:validationSchemaRicercaPg} 
                    onSubmit = {(values, { setSubmitting }) => {
                        let valoriInseriti = "";
                        Object.keys(values).map(function(key) {
                            valoriInseriti += values[key]
                        });
                        if (valoriInseriti.trim() == "") {
                            alertify.warning("Specificare almeno un campo per la ricerca");
                            return;
                        }
                        
                        setSubmitting(true);
                        //log(valueNaturaGiuridica)
                        fetchData(valueNaturaGiuridica, values.codice_fiscale, values.cognome, values.piva, values.ragione_sociale, values.codice_sicer);
                    }}
                >
                {({
                    values, 
                    errors, 
                    handleChange, 
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    touched,
                    dirty,
                }) => (
                    <form onSubmit={handleSubmit} id='ricercaPersona' className="p-4">
                        <div className="col-12 col-md-6">
                            <SicondirRadio 
                                label="Tipo persona:"
                                name="naturagiuridica"
                                id="naturagiuridica"
                                onChange={handleChange && ((e) => onChange(e, errors))} 
                                options={[
                                {
                                    value:"PF", 
                                    label:"Persona fisica (PF)",
                                    checked:(valueNaturaGiuridica === "PF" ? true : false)
                                },
                                {
                                    value:"PG",
                                    label:"Persona giuridica (PG)",
                                    checked:(valueNaturaGiuridica === "PG" ? true : false)
                                }
                                ]} 
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="codice_fiscale" className='sicondir-label'>
                                Cerca per
                            </label><br /><br />
                            <input 
                                id = 'cognome'
                                type = 'text'
                                name = 'cognome'
                                value = {values.cognome}
                                onChange = {handleChange}
                                className={`form-control col-9 text-capitalize ${valueNaturaGiuridica==='PG'?'d-none':'d-block'}`}
                                size = "large" 
                                placeholder = 'Cognome'   
                                maxLength = '40'
                            />
                            {
                                touched.cognome && errors.cognome 
                                ? 
                                (<small className="sicondir-error col-9">{errors.cognome}</small>)
                                :
                                (<div style = {{ height: "8px" }}></div>)
                            }
                            <input 
                                id = 'codice_fiscale'
                                type = 'text'
                                name = 'codice_fiscale'
                                value = {values.codice_fiscale}
                                onChange = {handleChange}
                                className={`form-control col-9 ${valueNaturaGiuridica==='PG'?'d-none':'d-block'}`}
                                size = "large" 
                                placeholder = 'Codice fiscale'   
                                maxLength = '16'
                            />
                            {
                                touched.codice_fiscale && errors.codice_fiscale 
                                ? 
                                (<small className="sicondir-error col-9">{errors.codice_fiscale}</small>)
                                : 
                                (<div style = {{ height: "8px" }}></div>)
                            }
                            <input
                                id = 'ragione_sociale'
                                type = 'text'
                                name = 'ragione_sociale'
                                value = { values.ragione_sociale }
                                onChange = { handleChange }
                                className={`form-control col-9 ${valueNaturaGiuridica==='PF'?'d-none':'d-block'}`}
                                size = "large"
                                placeholder = 'Ragione sociale'
                                maxLength = '50'
                            />
                            {
                                touched.ragione_sociale && errors.ragione_sociale 
                                ? 
                                (<small className="sicondir-error col-9">{errors.ragione_sociale}</small>)
                                : 
                                (<div style = {{ height: "8px" }}></div>)
                            }
                            <input
                                id = 'piva'
                                type = 'text'
                                name = 'piva'
                                value = { values.piva }
                                onChange = { handleChange }
                                className={`form-control col-9 ${valueNaturaGiuridica==='PF'?'d-none':'d-block'}`}
                                size = "large"
                                placeholder = 'Partita IVA'
                                maxLength = '11'
                            />
                            {
                                touched.piva && errors.piva 
                                ?
                                (<small className="sicondir-error col-9">{errors.piva}</small>)
                                : 
                                (<div style = {{ height: "8px" }}></div>)
                            }
                            <input
                                id = 'codice_sicer'
                                type = 'text'
                                name = 'codice_sicer'
                                value = { values.codice_sicer }
                                onChange = { handleChange }
                                className={`form-control col-9`}
                                size = "large"
                                placeholder = 'Codice Sicer'
                                maxLength = '50'
                            />
                            {
                                touched.codice_sicer && errors.codice_sicer 
                                ?
                                (<small className="sicondir-error col-9">{errors.codice_sicer}</small>)
                                : 
                                (<div style = {{ height: "8px" }}></div>)
                            }
                        </div>
                        <div className="form-group col-12 text-center">
                            <button type="submit" className='btn btn-primary col-4'>
                            Cerca
                            </button>
                        </div>                    
                    </form>
                )}
                </Formik>
                </div>
                </>
            :
                <>
                {
                    !loading ?
                        <>
                        <h3>{Object.keys(personaTrovata).length === 0 ? <>Inserimento persona</> : <>Ricerca persona</>}</h3>
                        <div className="card  p-3">      
                            <IntestatarioForm 
                                cod_fisc={codicefiscale} 
                                cognome={cognome} 
                                natura_giuridica={valueNaturaGiuridica} 
                                partita_iva={partitaiva} 
                                codiceSicer={codiceSicer} 
                                ragione_sociale={ragione_sociale} 
                                persona_trovata={personaTrovata} 
                                setRicerca={setRicerca} 
                                concessioneId={props.concessioneId} 
                                contesto={props.contesto} 
                                handleClose={props.handleClose} 
                                setAssociazione={props.setAssociazione} 
                            />
                        </div>           
                        </>
                    :
                        <div className="h-100 d-flex align-items-center justify-content-center">
                            <div className="progress-spinner progress-spinner-active">
                                <span className="sr-only">Caricamento...</span>
                            </div>
                        </div>
                }
                </>
            }
        </>
    )
}
export default RicercaPersona
