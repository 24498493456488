import alertify from "alertifyjs";  
import formatter from "../../../common/utils/formatters/_";
import {useLocation} from 'react-router-dom';
import _ from "lodash";
import BeniDemanialiFormEdit from "../../../common/forms/BeniDemanialiFormEdit";
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";




export default function BeniDemanialiEdit(props) {
    const params = useParams();

    const[concessioneId, setConcessioneId] = useState(params.id)
    const location = useLocation();
    const[beneDemaniale, setBeneDemaniale] = useState(null)
    const[associazione, setAssociazione] = useState(false)
    const[associazioneId, setAssociazioneId] = useState(null)
    const [loading, setLoading] = useState(true);
    const macroambito = _.get(location, 'state.macroambito', null)
    let titolo = 'Modifica Bene Demaniale - '
    if (props.macroambito=='D'){
      titolo+='Derivazione'
    }
    if (props.macroambito=='F'){
      titolo+='Fluviali/Lacuali'
    }

    useEffect(() => {
      const url = process.env.REACT_APP_API_URL + 'catasto_demaniale_derivazioni/id/'+concessioneId;
    
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });
    
          const json = await response.json();
          if(json.risposta.catasto_demaniale_derivazioni){
              let response = formatter.cleanObject(json.risposta.catasto_demaniale_derivazioni)
              setBeneDemaniale(response); // Se c'è un oggetto lo setto come bene demaniale.
              setAssociazioneId(response.id_concessione_catasto);
          } else{
              setBeneDemaniale(null)
          }
    
          setLoading(false);
          setAssociazione(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
      };
    
      fetchData();
    },[]);

  
    return (
      <main>
        <h3>{titolo}</h3>
          <div className="card ">
            <div className="card-body">
            <br></br>
            {!loading ?
            <BeniDemanialiFormEdit macroambito={props.macroambito} data={beneDemaniale} contesto="anagrafica"></BeniDemanialiFormEdit>
            :
            <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="progress-spinner progress-spinner-active">
                    <span className="sr-only">Caricamento...</span>
                </div>
            </div>
            } 
            </div>
            
          </div>
      </main>
    );

  }