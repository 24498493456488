import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import alertify from "alertifyjs";
import formatter from "../../../common/utils/formatters/_";
import RichiesteConcessioniRicerca from "../components/RichiesteConcessioniRicerca";

export default function RichiesteConcessioniList(props) {

    let navigate = useNavigate();
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [currentMacroambito, setCurrentMacroambito] = useState(props.macroambito);
    const [stringaMacroambito, setStringaMacroambito] = useState(props.macroambito == "F" ? "fluviali-lacuali" : "derivazioni")
    const [sent, setSent] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);

    let titolo = "Ricerca domande"
    let fromUrl = "/richieste-concessioni/"
    if(props.macroambito == 'F'){
      //titolo += " fluviali/lacuali"
      fromUrl += "fluviali-lacuali"
    } else if(props.macroambito == 'D'){
      //titolo += " derivazioni"
      fromUrl += "derivazioni"
    }

    // "TENDINA AMBITI": necessario questo useEffect per quando si cambia routing da concessioni fluviali e derivazioni(e viceversa), per aggiornare il menu a tendina(vedere anche ConcessioniRicercaForm)
    useEffect(()=>{
      setCurrentMacroambito(props.macroambito)
    }, [props.macroambito])
    

    const vaiDettaglio = (idRichiesta) =>{
      navigate('/richieste-concessioni/' + stringaMacroambito + '/inserisci/' + idRichiesta) ;
    }

    

    useEffect(() => {
      const fetchData = async (filter) => {
        setLoading(true);
        let whereCondition = "";
        if(props.macroambito){
          whereCondition += " AND richieste_concessione.macroarea_ambito='"+ props.macroambito +"'"
        } 

        if(filter.tipo_ambito_id){
          whereCondition += " AND tipo_ambito_id=" + filter.tipo_ambito_id
        }
        
        if(filter.codice_sicondir){
          whereCondition += " AND codice_sicondir=\"" + filter.codice_sicondir +"\""
        } 
        if(filter.codice_sistema_precedente){
          whereCondition += " AND codice_sistema_precedente=\"" + filter.codice_sistema_precedente +"\""
        } 
        if(filter.codice_fiscale){
          whereCondition += " AND persone.codice_fiscale=\"" + filter.codice_fiscale +"\""
        } 
        if(filter.piva){
          whereCondition += " AND persone.piva=\"" + filter.piva +"\""
        } 
       
        whereCondition = encodeURI(whereCondition);
        let pagination = (currentPage - 1)*process.env.REACT_APP_PAGINATION+","+process.env.REACT_APP_PAGINATION
        const url = process.env.REACT_APP_API_URL + 'richieste_concessione/ricerca/' + whereCondition + "/" + pagination;
      
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: { 
                      // 'Content-Type': 'application/json',
                      accept: "application/json"
                    },      
            mode: 'cors'
           });

          const json = await response.json();

          // Da rivedere questa parte: dovremmo avere una api ad hoc che ritorni solo i dati che ci servono, non dobbiamo scremarli qua...
          let list = [];
          let i = 1;
          setTotalElements(0);
          if(json.risposta.richieste_concessione){
            if(Array.isArray(json.risposta.richieste_concessione)){
              setTotalElements(json.risposta.richieste_concessione[0].conteggio);
              json.risposta.richieste_concessione.map(element => {
                element = formatter.cleanObject(element)

                list[i] = {
                  id: element.id,
                 
                  tipo_ambito: element.ambito_demaniale || "-",
                  codice_sicondir: element.codice_sicondir  || "-",
                  codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                  intestatario: element.nome==null && element.cognome==null ? "-" : element.nome || "" + " "+ element.cognome  || "" || "-" ,
                  codice_fiscale: element.codice_fiscale  || "-",
                  piva: element.piva  || "-",
                  app_actions:{
                    //update: "/concessioni/inserisci/" + element.id,
                    updateConcessione: {
                      kind: "function", 
                          function: vaiDettaglio,
                          parameters:
                          {
                            idRichiesta: element.id
                          },
                          label: "Gestisci" 
                    }, 
                    delete: {
                      table: "richieste_concessione",
                      id: element.id
                    },
                  }
                }
                i++
              });
            }
          } 
          
          setList(list); 
          setLoading(false);
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
      
        
      
    }
     
    
    if (sent) { 
      fetchData(filter);
     }
    }, [filter,sent, currentPage]); 

    const creaRichiestaConcessione = async () => {
      const concessione = {
        nome_tabella: "richieste_concessione",
        lista_campi: "macroarea_ambito,utente_modifica_id",
        lista_valori: "'" + formatter.escapeSql(currentMacroambito) + "'," + localStorage.id // regolamento è sempre 1? Ci vuole una select?
      }
      try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }, 
          mode: 'cors', 
          body: JSON.stringify({Entry:concessione})
         })
         const data = await response.json();
         navigate('/richieste-concessioni/' +  stringaMacroambito + '/inserisci/' + data.risposta.elementi_aggiunti.id, 
                  {state: {
                    fromUrl: {fromUrl}
                    }
                  }) ;
         alertify.success("Nuova richiesta di concessione creata con successo.");
      } catch(error) {
        alertify.error("Errore durante l'inserimento.");
        console.log("errore: ", error);
      }
      
    }
    useEffect(() => {
      if((localStorage.sezione=='Derivazioni' && props.macroambito == 'F') 
      || (localStorage.sezione=='Fluviali' && props.macroambito == 'D')) {
        navigate('/');
      }
    },[])
    return (
      <main>
        <h3>{titolo}</h3>
        <div className="card ">
          <div className="card-body">
            <br></br>
            <RichiesteConcessioniRicerca insert={true} contesto='anagrafica' macroambito={currentMacroambito}/>
          </div>
        </div>
      </main>
    );
  }
