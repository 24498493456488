import React from 'react'
import alertify from "alertifyjs";

const DeleteIndirizzoContatto = (props) => {
    const EliminaData = async () => {
        let nome_tabella='';
        props.contesto=='indirizzo'
            ?
            nome_tabella = "persone_indirizzi"
            :
            nome_tabella = "persone_contatti"
        const payload = {
            nome_tabella: nome_tabella,
            lista_campi_valore: "flag_deleted=1",
            lista_clausole_where: "id="+ props.id + ""
        }
        const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
        })
    }

    const eliminaIndirizzo = () => {
        try {
            EliminaData()
            props.contesto=='indirizzo'
            ?
                props.setIndirizzoCambiato(true)
            :
                props.setContattoCambiato(true)
        } 
        catch(error) {
            alertify.error("Errore durante la modifica dei dati: " + error);
            console.log("errore: ", error);
        }
    }
    const alertElimina = (contesto) => {
        const label = (contesto=='indirizzo')?'dell\'indirizzo':'del contatto'
        alertify.confirm(`Procedere con l\'eliminazione ${label}?`, eliminaIndirizzo)
        .set('labels', {
            cancel: "ANNULLA",
            ok: "OK",
        });
    }
  return (
    <button 
        className="btn btn-danger col-2 mr-2" 
        type="button" 
        onClick={() => alertElimina(props.contesto)}>
        Elimina {props.contesto=='indirizzo'?'indirizzo':'contatto'}
    </button>
  )
}
export default DeleteIndirizzoContatto
