import { useEffect, useState } from 'react'
import { Formik, Form} from 'formik'
import SicondirSelect from "../components/SicondirSelect";
import SicondirInput from "../components/SicondirInput";

const PagamentiRicercaForm = (props) => {
    const [provinciaSelezionata, setProvinciaSelezionata] = useState(null);
    const [tipiAmbitoFiltrati, setTipiAmbitoFiltrati] = useState([])
    const [ambitoSelezionato, setAmbitoSelezionato] = useState(props.macroambito);
    
    // TODO: gestire fluviali/lacuali vs derivazioni.
    return(
        <>
           <Formik 
           id="filtroPagamenti"
           initialValues={{
            identificativo_pagamento: '',
            identificativo_debito: '',
            codice: '',
          }}
         
          onSubmit = { async (values, { setSubmitting }) => {
            props.setSent(true);  
            props.setFilter(values);
            props.setCurrentPage(1)
          }} 
          
          onReset ={() => {
            setAmbitoSelezionato(null);
            props.setFilter({})
            props.setCurrentPage(1)
          }}

          >
            {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
            }) => (
            <Form>
            <div className="">
                <div className="row">
                    <div className="col-4">
                        <SicondirInput 
                        label = 'Identificativo pagamento'   
                        id='identificativo_pagamento'
                        type='text'
                        name='identificativo_pagamento'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Identificativo Pagamento'   
                        maxLength = '255'
                        />
                    </div>
                    <div className="col-4">
                        <SicondirInput 
                        label = 'Identificativo dovuto'   
                        id='identificativo_debito'
                        type='text'
                        name='identificativo_debito'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Identificativo Dovuto'   
                        maxLength = '255'
                        />
                    </div>
                    <div className="col-4">
                        <SicondirInput 
                        label = 'Codice concessione/occupazione'   
                        id='codice'
                        type='text'
                        name='codice'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Codice Concessione/Occupazione'   
                        maxLength = '100'
                        />
                    </div>
                </div>
                     
               <div className="col-12 mt-5 row justify-content-end">
                  <button type="reset" className="btn btn-primary col-2" disabled={props.disabled}>Pulisci Campi</button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary col-2" disabled={props.disabled}>
                    Ricerca
                  </button>
                </div>
            </div>
            
            </Form>
            ) }
           </Formik>
        </>
    )
} 

export default PagamentiRicercaForm;
