import React from 'react'
import { useEffect, useState } from 'react'
import { Formik, Field, Form, useFormik } from "formik";
import SicondirSelect from "./SicondirSelect";
import SicondirInput from "./SicondirInput";
import tipiIndirizziPG from '../../_data/tipi_indirizziPG.json'
import tipiIndirizziPF from '../../_data/tipi_indirizziPF.json'
import Comuni from '../../_data/comuni.json'
import ProvinceSigla from '../../_data/provinceSigla.json'
import Nazioni from '../../_data/nazioni.json'
import formatter from '../utils/formatters/_' 
import alertify from "alertifyjs";
import * as Yup from 'yup'

const Indirizzo = (props) => {
  const [tipiIndirizziElab, setTipiIndirizziElab] = useState(
    props.valueNaturaGiuridica=='PF' 
    ?
      tipiIndirizziPF
    :
      tipiIndirizziPG
  );
  const [tipiIndirizzi, setTipiIndirizzi] = useState({});
  const [nazioneDeafult, setNazioneDeafult] = useState({
    "value": '109', 
    "label": 'Italia'
  })
  const [nazione, setNazione] = useState();
  const [comuniDisabled, setComuniDisabled] = useState(true);
  const [comuniFiltrati, setComuniFiltrati] = useState([]);
  const [capDisabled, setCapDisabled] = useState(true);
  const [capFiltrati, setCapFiltrati] = useState([]);
  const [loading, setLoading] = useState(false);
  const [provincia, setProvincia] = useState({});
  const[persona, setPersona] = useState({data: []})
  const initialValue = {
    tipo_indirizzo_id: "",
    nazioneIndirizzo: 109,
    provinciaIndirizzo: "",
    comuneIndirizzo: "",
    codiceCap: "",
    localita: "",
    indirizzo: "",
    numeroCivico: "",
  }
  const validationSchema = Yup.object().shape({
    tipo_indirizzo_id: Yup.string()
     .required("Il tipo indirizzo è obbligatorio"),
  });

  const Nazioniarr = (nazioniobj) => {
    const nazionearr = [];
    for(let key in nazioniobj) {
      nazionearr.push({'value': nazioniobj[key].minint, 'label':nazioniobj[key].italian_country_name_1})
    }
    return nazionearr;
  }
  const nazionearr = Nazioniarr(Nazioni)
  

  const enableComuni = (event) => {
    if(event){
      const res = Comuni
      .filter((c) => c.sigla == event.value)
      .map((c) => {
        return {
          value: c.nome,
          label: c.nome,
        };
      });
      setComuniDisabled(false);
      setComuniFiltrati(res);
    } 
  }  

  const enableCap = (event) => {
    if(event){
      const res = Comuni.filter((c) => c.nome == event.value);
      const capArray = res[0].cap;
      let caps = [];
      capArray.map((e) => {
        caps.push({
          value: e,
          label: e,
        });
      });
  
      setCapDisabled(false);
      setCapFiltrati(caps);

    } else { // se ho cancellato il comune dalla combobox...
      setCapDisabled(true);
    }
  };

  const fetchData = async (listaCampi, lista_valori) => {
    let payloadIns = {
      nome_tabella: "persone_indirizzi",
      lista_campi: listaCampi,
      lista_valori: lista_valori
    }
    const responseIns = 
    await fetch(
      process.env.REACT_APP_API_URL + 'inserisci_elemento', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }, 
      mode: 'cors', 
      body: JSON.stringify({Entry:payloadIns})
      }
    )
  }
  const stampa_persona = async () => {
    let urlApi = `persone/id/${props.persona_id}`
    const response = await fetch(process.env.REACT_APP_API_URL + urlApi, 
    {
      method: 'GET',
      headers: { accept:'application/json' }, 
      mode: 'cors',  
    })
    const json = await response.json()
    setPersona(formatter.cleanObject(json.risposta.persone))
  }
  useEffect(() => {
    stampa_persona();
  },[]);
 
   //   console.log('tipiIndirizziElab', tipiIndirizziElab)
      useEffect(() => {
      },[tipiIndirizzi])
    return (
      
      <>
        <h3 className="text-capitalize">
          { (props.valueNaturaGiuridica=='PF') 
            ? 
               persona.nome + ' ' + persona.cognome 
            :
               persona.ragione_sociale 
          }
        </h3>
      {!loading ?
        <>
       
        <Formik 
          initialValues={initialValue} 
          validationSchema={validationSchema}
          id='indirizzo' 
          className="p-4"
          enableReinitialize={true}
          onSubmit = { async (values, { setSubmitting }) => {
            setSubmitting(true);
            // FACCIO L'INSERIMENTO DELLA PERSONA
            let campiIndirizzo = "utente_modifica_id, persona_id,"
            let valoriIndirizzo = localStorage.id+','+props.persona_id+','
            const lengthV = Object.entries(values).length-1
            let i = 1;
            //console.log('values',values)
            Object.entries(values).map(([key, value], i) => {
              
              if(key == 'nazioneIndirizzo')
                key = 'nazione';
              if(key == 'provinciaIndirizzo')
                key = 'provincia';
              if(key == 'comuneIndirizzo')
                key = 'comune';
              if(key == 'codiceCap')
                key = 'cap';
              if(key == 'numeroCivico')
                key = 'civico';
              campiIndirizzo += key + (i<lengthV?',':'')
              valoriIndirizzo += "'" + formatter.escapeSql(value) + "'" + (i<lengthV?',':'')
              i++;
            });
            try {
              fetchData(campiIndirizzo, valoriIndirizzo);
              alertify.success("Indirizzo inserito con successo.");
              
              props.setIndirizzoCambiato(true);
              props.handleClose()
            } 
            catch(error) {
              alertify.error("Errore durante la modifica dei dati: " + error);
              console.log("errore: ", error);
            }
          }}
        >
          {({
            values, 
            errors, 
            handleChange, 
            handleSubmit,
            isSubmitting,
            isValid,
            touched,
            dirty,
          }) => (
            
            <form onSubmit={handleSubmit} id='ricercaPersona' className="p-4">    
              <div className="col-4 md-12 sm-12 sicondir-label">
              
                <SicondirSelect 
                  label="Tipo indirizzo"
                  id='tipo_indirizzo_id'
                  name='tipo_indirizzo_id'
                  mandatory= 'true'
                //  value={props.tipo_indirizzo_id || ''}
                  options={tipiIndirizziElab}
                 // onChange={handleChange}
                  key="tipoIndirizzi"
                  placeholder = 'Tipo indirizzo'
                />  

              </div>
              <div className="col-4 md-12 sm-12 sicondir-label">
              
                <SicondirSelect 
                  label='Nazione'
                  id='nazioneIndirizzo'
                  name='nazioneIndirizzo'
                  options={nazionearr} 
                  placeholder = 'Nazione'
                  value={nazione}
                  defaultValue={nazioneDeafult}
                  key='Nazione'
                  onChange={(e) => setNazione(e)} 
              //   onChange={props.handleChange}
                />  
              </div>
              <div className="col-4 md-12 sm-12">
                <SicondirSelect 
                  label="Provincia"
                  id='provinciaIndirizzo'
                  name='provinciaIndirizzo'
                  //value={values.provinciaIndirizzo || ''}
                  options={ProvinceSigla} 
                  key="Provincia"
                  placeholder = 'Provincia'
                  onChange={(e) => enableComuni(e)} 
                />  
              </div>
              <div className="col-4 md-12 sm-12">
                <SicondirSelect 
                  label="Comuni"
                  id='comuneIndirizzo'
                  name='comuneIndirizzo'
                  isDisabled={comuniDisabled} 
                  options={comuniFiltrati} 
                  kay = "comuneIndirizzo"
                  placeholder = 'Comuni' 
                  onChange={e => {
                  enableCap(e);
                  }} 
                />  
              </div>
              <div className="col-4 md-12 sm-12">
                <SicondirSelect 
                  label="C.A.P." 
                  id='codiceCap'
                  name='codiceCap'
                  options={capFiltrati} 
                  isDisabled={capDisabled} 
                  key='codiceCap'
                  placeholder="Seleziona C.A.P."
                //  onChange={handleChange}
                />
              </div>
              <div className="col-4 md-12 sm-12">
                <SicondirInput
                  label="Località" 
                  id='localita'
                  name='localita'
                  type='text'
                  onChange={handleChange}
                  key="localita"
                  className='form-control mb-2'
                  size="large" 
                  placeholder = 'Località'
                  maxLength = '50'
                />
              </div>
              <div className="col-4 md-12 sm-12">
              <SicondirInput
                  label="Indirizzo"
                  id='indirizzo'
                  name='indirizzo'
                  type='text'
                  key="indirizzo"
                  onChange={handleChange}
                  className='form-control mb-2'
                  size="large" 
                  placeholder = 'Inserisci indirizzo'  
                  maxLength = '100'

              />
              </div>  
              <div className="col-4 md-12 sm-12">
                <SicondirInput
                  label="Numero civico" 
                  id='numeroCivico'
                  name='numeroCivico'
                  type='text'
                  key="numerocivico"
                  onChange={handleChange}
                  className='form-control mb-2'
                  size="large" 
                  placeholder = 'Numero civico'
                  maxLength = '20'
                />
              </div>
              <button type="button" onClick={props.handleClose} className="btn btn-primary col-2 mr-2">
                Chiudi
              </button>
              <button type="submit" className="btn btn-primary col-2">
                Inserisci
              </button>
            </form>
          )}
        </Formik>
  
        </>
         :
         <>
         <br />
         <div className="h-100 d-flex align-items-center justify-content-center">
           <div className="progress-spinner progress-spinner-active">
             <span className="sr-only">Caricamento...</span>
           </div>
         </div>
         </>
        } 
      </>     
    )
}
export default Indirizzo