import React from 'react'
import { useEffect, useState } from 'react'
import { Formik, Field, Form, useFormik } from "formik";
import SicondirSelect from "./SicondirSelect";
import SicondirInput from "./SicondirInput";
import tipiIndirizzi from '../../_data/tipi_indirizzi.json'
//import tipiIndirizziPF from '../../_data/tipi_indirizziPF.json'
import Comuni from '../../_data/comuni.json'
import ProvinceSigla from '../../_data/provinceSigla.json'
import Nazioni from '../../_data/nazioni.json'
import formatter from '../utils/formatters/_' 
import alertify from "alertifyjs";
import { isArrayLike } from 'lodash';

const IndirizzoEdit = (props) => {
  const [tipiIndirizziElab, setTipiIndirizziElab] = useState(
    tipiIndirizzi
    // props.valueNaturaGiuridica=='PF' 
    // ?
    //   tipiIndirizziPF
    // :
    //   tipiIndirizziPG
  );
  //const [tipiIndirizzi, setTipiIndirizzi] = useState({});

  const [oldValue, setOldValue] = useState(props.indirizzo);

  const [provinciaSelezionata, setProvinciaSelezionata] = useState(null);
  const [nazioneSelezionata, setNazioneSelezionata] = useState(null);
  const [comuniDisabled, setComuniDisabled] = useState(true);
  const [comuniFiltrati, setComuniFiltrati] = useState([]);
  const [comuneSelezionato, setComuneSelezionato] = useState(null);

  const [capDisabled, setCapDisabled] = useState(false);
  const [capFiltrati, setCapFiltrati] = useState([]);
  const [capSelezionato, setCapSelezionato] = useState(null);

  useEffect(() => {
    setOldValue(props.indirizzo);
  });
  useEffect(() => {
    const nazioneSelezionataRecuperata = Nazioni.filter(c => c.minint == oldValue.nazione)
    .map((c) => {
      return {
        value: c.minint,
        label: c.italian_country_name_1
      }
    })
   
 
    setNazioneSelezionata(nazioneSelezionataRecuperata[0])
    const provinciaSelezionataRecuperata = 
      ProvinceSigla.filter(p => p.value == oldValue.provincia)
    setProvinciaSelezionata(provinciaSelezionataRecuperata[0])
    setComuniDisabled(false)

    let comuniFiltr = Comuni
      .filter((c) => c.sigla == provinciaSelezionataRecuperata[0].value)
      .map((c) => {
        return {
          value: c.nome,
          label: c.nome
        };
      });
      setComuniFiltrati(comuniFiltr)
      const comuneSelezionatoRecuperato = 
      comuniFiltr.filter(c => c.label == oldValue.comune)
      setComuneSelezionato(comuneSelezionatoRecuperato)
      
      //let capFiltrati = 
      
      //const res = Comuni.filter((c) => c.id == comuneSelezionatoRecuperato[0].value);
      let capFiltrati = Comuni
      .filter(c => c.nome == oldValue.comune)
      // .map((c) => {
      //   return {
      //     value: c.cap,
      //   };
      // });
      let caps = [];
      if(capFiltrati[0]){
        const capArray = capFiltrati[0].cap;
        capArray.map((e) => {
          caps.push({
            value: e,
            label: e,
          });
        });
      }
      setCapFiltrati(caps)
     

      if(oldValue.cap){
        setCapSelezionato({
          value: oldValue.cap,
          label: oldValue.cap,
        })
      }
   
  }, [oldValue])
  useEffect(() => {
    setLoading(false);
  });
  const [loading, setLoading] = useState(true);
  const [provincia, setProvincia] = useState({});

 

  const Nazioniarr = (nazioniobj) => {
    const nazionearr = [];
    for(let key in nazioniobj) {
      nazionearr.push({'value': nazioniobj[key].minint, 'label':nazioniobj[key].italian_country_name_1})
    }
    return nazionearr;
  }
  const nazionearr = Nazioniarr(Nazioni)



  const enableComuni = (event) => {
    
    if(event){
      const res = Comuni
      .filter((c) => c.provincia.nome == event.label)
      .map((c) => {
        return {
          value: c.id,
          label: c.nome,
        };
      });
      setComuniDisabled(false);
      setComuniFiltrati(res);
      setComuneSelezionato(null);
      setCapSelezionato(null);
    } 
    
  }  
 // 
  const enableCap = (event) => {

    if(event){
      const res = Comuni.filter((c) => c.nome == event.value);
      const capArray = res[0].cap;
      let caps = [];
      capArray.map((e) => {
        caps.push({
          value: e,
          label: e,
        });
      });
  
      setCapDisabled(false);
      setCapFiltrati(caps);
      setCapSelezionato(null);
      setComuneSelezionato(event);
    } else { // se ho cancellato il comune dalla combobox...
      setComuneSelezionato(null);
      setCapDisabled(true);
      setCapSelezionato(null);
    }
  };


  const fetchData = async (listaCampi) => {
    const payload = {
      nome_tabella: "persone_indirizzi",
      lista_campi_valore: listaCampi,
      lista_clausole_where: "id="+ props.indirizzo.id + " and flag_deleted=0"
    }
    const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }, 
      mode: 'cors', 
      body: JSON.stringify({Entry:payload})
    })
  }

    return (
      
      <>
      {!loading ? (
        <>
        <Formik 
          initialValues={oldValue} 
          id='modifica_indirizzo' 
          className="p-4"
          enableReinitialize={true}
          onSubmit = { async (values, { setSubmitting }) => {
            setSubmitting(true);
            // FACCIO LA MODIFICA DELL'INDIRIZZO
            let campiIndirizzo = ""
            Object.entries(values).map(([key, value], i) => {
              if(key == 'nazioneIndirizzo')
                key = 'nazione';
              if(key == 'provinciaIndirizzo')
                key = 'provincia';
              if(key == 'comuneIndirizzo')
                key = 'comune';
              if(key == 'codiceCap')
                key = 'cap';
              if(key == 'numeroCivico')
                key = 'civico';
                campiIndirizzo += key + "='" + formatter.escapeSql(value) + "', ";
            });
            try {
              campiIndirizzo += "utente_modifica_id=" + localStorage.id;
              fetchData(campiIndirizzo);
              alertify.success("Indirizzo Modificato con successo.");
              props.setIndirizzoCambiato(true)
              props.handleClose()
            } 
            catch(error) {
              alertify.error("Errore durante la modifica dei dati: " + error);
              console.log("errore: ", error);
            }
          }}
        >
          {({
            values, 
            errors, 
            handleChange, 
            handleSubmit,
            isSubmitting,
            isValid,
            touched,
            dirty,
          }) => (
            
            <form onSubmit={handleSubmit} id='ricercaPersona' className="p-4">    
              <div className="col-4 md-12 sm-12"> 
                <SicondirSelect 
                  label="Tipo indirizzo"
                  id='tipo_indirizzo_id'
                  name='tipo_indirizzo_id'
                  options={tipiIndirizziElab}
                  isClearable={true}
                  key="tipoIndirizzi"
                  placeholder = 'Tipo indirizzo'
                  defaultValue={ tipiIndirizziElab.filter(c => c.value == props.indirizzo.tipo_indirizzo_id) }
                />  
              </div>
              <div className="col-4 md-12 sm-12">
                <SicondirSelect 
                  label='Nazione'
                  id='nazioneIndirizzo'
                  name='nazioneIndirizzo'
                  options={nazionearr} 
                  placeholder = 'Nazione'
                  //value={nazioneDeafult}
                  value={nazioneSelezionata}
                  key='Nazione'
                  onChange={(e)=>setNazioneSelezionata(e)}
                />  
              </div>
              <div className="col-4 md-12 sm-12">
                <SicondirSelect 
                  label="Provincia"
                  id='provinciaIndirizzo'
                  name='provinciaIndirizzo'
                  //value={{"value": "84", "label":"Agrigento"}}
                  //value={props.indirizzo.provincia || ''}
                  options={ProvinceSigla} 
                  key="Provincia"
                  isClearable={true}
                  placeholder = 'Provincia'
                  onChange={ (e) => enableComuni(e) }
                  defaultValue = { provinciaSelezionata }       
                /> 
              </div>
              <div className="col-4 md-12 sm-12">
                <SicondirSelect 
                  label="Comuni"
                  id='comuneIndirizzo'
                  name='comuneIndirizzo'
                  isDisabled={comuniDisabled} 
                  options={comuniFiltrati} 
                  key = "comuneIndirizzo"
                  placeholder = 'Comuni' 
                  onChange={ (e) => enableCap(e) }
                 value = { comuneSelezionato }
                />  
               
              </div>
          
              <div className="col-4 md-12 sm-12">
                <SicondirSelect 
                  label="C.A.P." 
                  id='codiceCap'
                  name='codiceCap'
                  options={capFiltrati} 
                  isDisabled={capDisabled} 
                  value={capSelezionato}
                  key='codiceCap'
                  placeholder="Seleziona C.A.P."
                  onChange={(e) => {
                    if(e){
                      setCapSelezionato({
                        label: e.label,
                        value: e.value,
                      });
                    } else {setCapSelezionato(null)}
                  }}
                  
                />
              </div>
              <div className="col-4 md-12 sm-12">
                <SicondirInput
                  label="Località" 
                  id='localita'
                  name='localita'
                  type='text'
                  key="localita"
                  className='form-control mb-2'
                  size="large" 
                  placeholder = 'Località'
                  maxLength = '50'
                />
              </div>
              <div className="col-4 md-12 sm-12">
              <SicondirInput
                  label="Indirizzo"
                  id='indirizzo'
                  name='indirizzo'
                  type='text'
                  key="indirizzo"
                  className='form-control mb-2'
                  size="large" 
                  placeholder = 'Inserisci indirizzo'  
                  maxLength = '100'

              />
              </div>  
              <div className="col-4 md-12 sm-12">
                <SicondirInput
                  label="Numero civico" 
                  id='civico'
                  name='civico'
                  type='text'
                  key="numerocivico"
                  className='form-control mb-2'
                  size="large" 
                  placeholder = 'Numero civico'
                  maxLength = '20'
                />
              </div>
              <button type="button" onClick={props.handleClose} className="btn btn-primary col-2 mr-2">
                Chiudi
              </button>
              <button type="submit" className="btn btn-primary col-2">
            Modifica
          </button>
            </form>
          )}
        </Formik>
        </>
      )
         :
         <>
         <br />
         <div className="h-100 d-flex align-items-center justify-content-center">
           <div className="progress-spinner progress-spinner-active">
             <span className="sr-only">Caricamento...</span>
           </div>
         </div>
         </>
        } 
      </>     
    )
}
export default IndirizzoEdit