import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import DeleteButton from "../../../common/components/DeleteButton";
import { useParams, useLocation } from "react-router";
import { useEffect, useState } from 'react'
import alertify from "alertifyjs";  
import formatter from "../../../common/utils/formatters/_";
import PagamentoForm from '../../../common/forms/PagamentoForm';
import PagamentoRiferimento from '../components/PagamentoRiferimento';
import PagamentoImporti from '../components/PagamentoImporti';

export default function Pagamenti(props) {
    // Get ID from URL
    const params = useParams();
    const pagamentoId = params.id;
    // Per recuperare l'url da cui provengo.
    const location = useLocation()
    const [pagamento, setPagamento] = useState(null);
    const [fromUrl, setFromUrl] = useState('/pagamenti');
    const [loading, setLoading] = useState(true);
    const [aggiornamento, setAggiornamento] = useState(false);

    const [importoUtilizzato, setImportoUtilizzato] = useState(0);
    const [importoUtilizzabile, setImportoUtilizzabile] = useState(0);

    

    // Con questa useEffect recupero il pagamento per popolare il form e inizializzo la cifra coperta dal pagamento nei vari pagamento_oggetto.
    useEffect(() => {
      const url = process.env.REACT_APP_API_URL + 'pagamenti/id/'+pagamentoId;
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          
          setPagamento(formatter.cleanObject(json.risposta.pagamenti));
          setAggiornamento(false)
          setLoading(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
      };
      fetchData();
    }, [aggiornamento]); 

    return (
      <main>
        <h3>Gestione pagamento</h3>
        { !loading?
          <>
          <div className="card ">
          <div className="card-body">
            <PagamentoImporti 
              pagamento={pagamento}
              aggiornamento={aggiornamento}
              setImportoUtilizzabile={setImportoUtilizzabile}
              setImportoUtilizzato={setImportoUtilizzato}
            />
            <Tabs defaultActiveKey="tab1">
              <Tab eventKey="tab1" title="Dati Pagamento">
                <PagamentoForm 
                  loading={loading} 
                  pagamento={pagamento} 
                  setAggiornamento={setAggiornamento}
                  importoUtilizzato={importoUtilizzato}
                  />
              </Tab>
              <Tab eventKey="tab2" title="Riferimenti Pagamento">
                <PagamentoRiferimento 
                  pagamento={pagamento}
                  importoUtilizzabile={importoUtilizzabile}
                  setAggiornamento={setAggiornamento}
                />
              </Tab>
            </Tabs>
            
          </div>
        </div>
        <br />
        <div className="card ">
              <div className="card-body">
                <div className="float-right">
                  <DeleteButton 
                    table="pagamenti" 
                    resourceId={params.id} 
                    redirectPath={fromUrl}
                    buttonLabel="Elimina pagamento"
                    confirmLabel="Procedere con l'eliminazione del pagamento?"
                    successMessage="Pagamento eliminato con successo"
                    errorMessage="Errore durante la cancellazione del pagamento">  
                  </DeleteButton>
                </div>
              </div>
            </div>
            </>
          :
          
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>

        }
        
      </main>
    );
  }