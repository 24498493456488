import {Dropdown } from 'react-bootstrap';
import alertify from "alertifyjs";  
import sprite from 'bootstrap-italia/dist/svg/sprite.svg';


export default function SicondirDropDownButtons(props) {

let mainLabel = props.mainLabel ? props.mainLabel : "Azioni - ";
return (
    <div >
    <Dropdown>
      <Dropdown.Toggle className="text-nowrap" disabled={props.disabled} >
        {mainLabel}
        <svg className="icon-expand icon icon-sm icon-light">
        <use href={sprite + "#it-expand"}></use></svg>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        { Object.entries(props.data).map(([key,value],i) => {

            let linkClass="dropdown-item list-item"
            if(value.linkClass){ 
                linkClass+=" "+ value.linkClass
            }

            const handleClick = (e, params) => {
                // eseguo la funzione che ho passato come parametro con i suoi parametri.
                if(params){ // Se ho i parametri
                    if(value.confirmLabel){
                        e.preventDefault();
                        alertify.confirm(value.confirmLabel, function(){value.function(...Object.values(params))})
                        .set('labels', {
                            cancel: "ANNULLA",
                            ok: "OK",
                        });
                    } else { 
                        value.function(...Object.values(params))
                    }
                } else{ // Se non ho i parametri
                    if(value.confirmLabel){
                        e.preventDefault();
                        alertify.confirm(value.confirmLabel, function(){value.function()})
                        .set('labels', {
                            cancel: "ANNULLA",
                            ok: "OK",
                        });
                    } else { 
                        value.function()
                    }
                }
                
            }

            return <Dropdown.Item key={key} onClick={(e) => handleClick(e, value.parameters)} className={linkClass} >{value.label}</Dropdown.Item>
        })
        } 
      </Dropdown.Menu>
    </Dropdown>
    </div>
)
}