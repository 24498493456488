import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import alertify from "alertifyjs";  
import SicondirRadio from "../components/SicondirRadio";
import  { confirmLabel }  from "../components/DeleteButton";
import tipiAmbito from '../../_data/tipi_ambito.json'
import SicondirInput from "../components/SicondirInput";
import tipiDurata from '../../_data/tipi_durata.json';
import tipiRichiesta from '../../_data/tipi_richiesta.json';
import formatter from '../utils/formatters/_';
import { stubString } from "lodash";
import SicondirTable from "../components/SicondirTable";
import SicondirSelect from "../components/SicondirSelect";
import tipiOpera from '../../_data/tipi_opera.json'
const OperaForm = (props) => {
    // Get ID from URL
    const params = useParams();
    const concessioneId = params.id;
    const [loading, setLoading] = useState(true);
    const [opereArray, setOpereArray] = useState([])
    const [list, setList] = useState([])
    const [clearRadio, setClearRadio] = useState(false)
    const [operaSelezionata, setOperaSelezionata] = useState(null);



 let tipiOperaFiltrati  = tipiOpera.filter(
  c => (c.macroarea_ambito == props.macroambito) 
)
let labels = tipiOpera.filter(
  c => (c.macroarea_ambito == props.macroambito) 
).map(tipiOpera =>(
  
    tipiOpera.label
  
))


    /* const state = {
      list: tipiOpera
      
      
    };
    let tipiOperaFiltrati  = list.filter(
      list => list.macroarea_ambito.includes(state.list.macroarea_ambito==props.macroambito) 
  ); */
    
    
   // var tipiOperaFiltrati =tipiOpera.filter( element => element.macroarea_ambito ==props.macroambito)
   
     /* if(props.macroambito =='D'){ 
      resultD = tipiOpera.filter(checkOpereD);
      
     function checkOpereD(tipiOpera) {
      if (tipiOpera.macroarea_ambito=='D') return tipiOpera.label;
     }}
     else if (props.macroambito=='F'){ 
      const resultF = tipiOpera.filter(checkOpereF);
       
      function checkOpereF(tipiOpera) {
       if (tipiOpera.macroarea_ambito=='F') return tipiOpera.label;
      }}*/




    const deleteOpera = async(operaId) => {
      setLoading(true);

      const payload = {
          nome_tabella: "concessioni_opere_accettate",
          id: operaId,
          utente_modifica_id: localStorage.id
      }
    
      try{
          const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
              method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
              headers: { 'Content-Type': 'application/json',
                          'Accept': 'application/json' }, 
              mode: 'cors', 
              body: JSON.stringify({Entry:payload})
          })
          const data = await response.json();
          alertify.success("Opera eliminata con successo");
          
          setLoading(false)
          setOpereArray([]);
      } catch(error) {
          console.log("ERRORE: ", error);
          alertify.error("Errore durante la cancellazione dell'opera");
          setLoading(false)
      }
      

    }
    const handleOpera = (e) => {
      if(e){
          setOperaSelezionata({
              label: e.label,
              value: e.value
          })
      } else{
          setOperaSelezionata(null);
      }}
    useEffect(() => {
      /* const res = tipiOpera.filter(c => c.macroarea_ambito == props.macroambito)
      .map(c => { return { 
          value: c.value,
          label: c.label,
      };} 
      )
  setTipiOperaFiltrati(res)*/

  
   
     
      const getFlagStabile = (flag_stabile) => {
        if(flag_stabile == null)return "-"; // non è stato settato
        if(flag_stabile == "0") return "Sì"; // E' stabile? No. Verrà rimosso? Sì
        if(flag_stabile == "1") return "No"; // E' stabile? Sì. Verrà rimosso? No
        
      }
        
        const fetchData = async() => {
          setLoading(true)
          try{
            const url = process.env.REACT_APP_API_URL + 'concessioni_opere_accettate/concessione_id/' + concessioneId;
            const response = await fetch(url, {
              method: 'GET',
              headers: { 
                        accept: "application/json"
                      },      
              mode: 'cors'
             });
             const json = await response.json();
             // Da rivedere questa parte: dovremmo avere una api ad hoc che ritorni solo i dati che ci servono, non dobbiamo scremarli qua...
            let list = [];
            let i = 1;
            
            if(json.risposta.concessioni_opere_accettate){
              if(Array.isArray(json.risposta.concessioni_opere_accettate)){
                json.risposta.concessioni_opere_accettate.map(element => {
                  element = formatter.cleanObject(element)
                  
                  let tipo = tipiOpera.filter(c => c.value == element.tipo_opera)[0]
                 let labelOpera = tipo ? tipo.label : "-" 
                  console.log("element",element)
                 
                  list[i] = {
                    id: element.id,
                    tipo_opera : labelOpera ,
                    descrizione: element.descrizione || " - ",
                    flag_stabile: getFlagStabile(element.flag_stabile),
                    app_actions:{
                      deleteOpera: {
                        kind: "function", 
                            function: deleteOpera,
                            parameters:
                            {
                              idOpera: element.id,
                            },
                            label: "Elimina",
                            confirmLabel: "Procedere con l'eliminazione dell'opera?",
                            buttonClass: 'btn-danger'

                      },
                         
                    }
                  }
                  
                  i++
                });
              } 
            } 
            
            setList(list); 
            setLoading(false);
          
          } catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
          }
          
          setLoading(false);
      };
      fetchData();
      
    }, [opereArray]);
  

    return (
        <>
        <Formik
        id='opera' className="p-4"
        initialValues={{
            tipo_opera: '', 
            descrizione: '',
            flag_stabile: ''
          }}
          onSubmit = { async (values, { setSubmitting,resetForm }) => {
            setLoading(true)
            let campi = ""
            let valori = ""

            Object.entries(values).map(([key,value],i) => {
             
              if(key == 'flag_stabile' && value != ''){
                campi += key + ", "
                let valoreFlag = value == 'true' ? 1 :0
                valori += ""+ valoreFlag + ", " // stabile_flag non ha bisogno dell'apice perché è un boolean
              } else if(value != ''){
                campi += key + ", "
                valori += "'" + formatter.escapeSql(value) + "', "
              } 
            })

            if(valori == ""){
              alertify.warning("Inserire almeno un campo");
              setLoading(false)
              return;
            }

            try{
              campi += "concessione_id,utente_modifica_id";
              valori += concessioneId+","+localStorage.id
              const opera = {
                nome_tabella: "concessioni_opere_accettate",
                lista_campi: campi,
                lista_valori: valori
              }

              const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:opera})
              })
              const data_response = await response.json();
              if(data_response.hasOwnProperty('Fault')){
                if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                  console.log("ErrorCode: " + data_response.Fault.faultcode)
                  throw new Error(data_response.Fault.faultstring)
                } else{
                  throw new Error("errore durante il salvataggio.")
                }
              }
              setOpereArray(opereArray => [opereArray, data_response.risposta.elementi_aggiunti.id] );
              alertify.success("Opera associata alla concessione con successo.");
              resetForm()
            } catch(error) {
              alertify.error("Errore durante l'associazione dell'opera.");
              console.log("errore: ", error);
            }
            setLoading(false)
          }}
         
      
        >
        <Form>
                <br /><br />

                <SicondirSelect
                  label='Tipo Opera'
                  id='tipo_opera'                
                  name='tipo_opera'              
                  value={operaSelezionata}
                  options={tipiOperaFiltrati} 
                  isClearable={true}
                                
                  placeholder = 'Tipo Opera '
                 
                   onChange={handleOpera}
                />           
                     

                      <SicondirInput 
                        label='Descrizione'
                        id='descrizione'
                        type='text'
                        name='descrizione'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Descrizione Opera'   
                        maxLength = '255'
                      />

                      <SicondirRadio 
                        label="Rimozione al termine della concessione"
                        name="flag_stabile"
                        id="flag_stabile"
                        // clearRadio={!clearRadio}
                        options={[
                          {
                            value:false, // false significa che stabile = false, quindi verrà rimossa alla fine della concessione...
                            label:"Sì",
                          },
                          {
                            value:true, // true significa che stabile = true, quindi NON verrà rimossa alla fine della concessione...
                            label:"No",
                          }
                        ]} 
                      />

            <div className="col-12 mt-5 row justify-content-center">
                <button type="submit" className="btn btn-primary col-5" disabled={loading}>
                Salva
                </button>
            </div>
            
        </Form>
        </Formik>
        <br /><br />
        {!loading ?
          <SicondirTable 
          headers={[           
            "Tipo opera",
            "Descrizione", 
            "Rimozione al termine della concessione", 
            ""]} 
          data={list} 
                
          />
      
        
        :
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>
        }
      </>
    )
}

export default OperaForm;