import './App.css';
import AppRoutes from './AppRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
