import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Formik, Field, Form, useFormik } from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirSelect from "../components/SicondirSelect";
import tipiAmbito from '../../_data/tipi_ambito.json'
import tipiCatastoDemanialeDerivazioni from '../../_data/tipi_catasto_demaniale_derivazioni.json'
import provinceLazio from '../../_data/province_lazio.json'
import comuni from '../../_data/comuni.json'
import statusAreaDemaniale from '../../_data/status_area_demaniale.json'
import SicondirInput from "../components/SicondirInput";
import SicondirTextarea from "../components/SicondirTextarea";
import SicondirNumber from "../components/SicondirNumber";
import tipiModalitaScarico from '../../_data/tipi_modalita_scarico.json'
import formatter from "../utils/formatters/_"

const BeniDemanialiForm = (props) => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    
    const [provinciaSelezionata, setProvinciaSelezionata] = useState(null);

    const [tipiCatastoDisabled, setTipiCatastoDisabled] = useState(true);
    const [tipiCatastoFiltrati, setTipiCatastoFiltrati] = useState([]);
    const [tipoCatastoSelezionato, setTipoCatastoSelezionato] = useState(null);

    const [comuniDisabled, setComuniDisabled] = useState(true);
    const [comuniFiltrati, setComuniFiltrati] = useState([]);
    const [comuneSelezionato, setComuneSelezionato] = useState(null);

    const [capDisabled, setCapDisabled] = useState(true);
    const [capFiltrati, setCapFiltrati] = useState([]);
    const [capSelezionato, setCapSelezionato] = useState(null);
    const [tipiAmbitoFiltrati, setTipiAmbitoFiltrati] = useState(tipiAmbito)

    useEffect(() => {
      const fetchData = () => {
          // Per la tendina degli ambiti:
          if(props.macroambito){
            const res = tipiAmbito.filter(c => c.macroarea_ambito == props.macroambito)
            .map(c => { return { 
                value: c.value,
                label: c.label,
            };}
            )
            setTipiAmbitoFiltrati(res)
          }
          
          setLoading(false);
      };
      fetchData();
    }, []);

    const enableTipiCatasto = (event) => {
        const ambito = tipiAmbito.filter(c => c.value == event.value)
        const res = tipiCatastoDemanialeDerivazioni.filter(c => c.macroarea_ambito == ambito[0].macroarea_ambito)
        setTipiCatastoDisabled(false);
        setTipiCatastoFiltrati(res);
        setTipoCatastoSelezionato(null);
    }  


    const enableComuni = (event) => {
      if(event){
        const res = comuni
        .filter((c) => c.provincia.nome == event.label)
        .map((c) => {
          return {
            value: c.id,
            label: c.nome,
          };
        });
        setProvinciaSelezionata(event)
        setComuniDisabled(false);
        setComuniFiltrati(res);
        setComuneSelezionato(null);
        setCapSelezionato(null);
      } else{ // se ho cancellato la provincia dalla combobox...
        setProvinciaSelezionata(null)
        setComuniDisabled(true);
        setComuneSelezionato(null);
        setCapDisabled(true);
        setCapSelezionato(null);
      }
    }  

    const enableCap = (event) => {
      if(event){
        const res = comuni.filter((c) => c.id == event.value);
        const capArray = res[0].cap;
        let caps = [];
        capArray.map((e) => {
          caps.push({
            value: e,
            label: e,
          });
        });
    
        setCapDisabled(false);
        setCapFiltrati(caps);
        setCapSelezionato(null);
        setComuneSelezionato(event);
      } else { // se ho cancellato il comune dalla combobox...
        setComuneSelezionato(null);
        setCapDisabled(true);
        setCapSelezionato(null);
      }
      
    };

    return(
        <>
            <Formik 
              id='beneDemaniale' className="p-4"

              initialValues={{
                tipo_ambito_id: '',
                tipo_catasto_demaniale_derivazione_id: '',
                provincia: '',
                tipo_comune_id: '',
                indirizzo: '',
                civico: '',
                localita: '',
                codice_cap: '',
                codice_area: '',
                catasto_sezione: '',
                catasto_foglio: '',
                catasto_particella: '',
                catasto_sub: '',
                superficie_totale: '',
                identificativo_pozzo: '',
                unita_misura_nominale: '',
                produzione_nominale: '',
                tipo_stato_catasto_demaniale_derivazione_id: '',
                tipo_modalita_scarico_id: '',
                //opere_richieste: '',
                //opere_esistenti: '',
                //stato_patrimoniale: ''
              }}
              validationSchema={Yup.object({
                tipo_ambito_id: Yup.string().required("Il campo è obbligatorio"),
                produzione_nominale: Yup.string().matches(/^((\d+(\,\d{0,2})?))$/, 'Inserire un valore numerico, i decimali(massimo 2) devono essere separati da ","')
              })}
              /* Al momento non ci sono campi obbligatori
              validationSchema={Yup.object({
                tipo_ambito_id: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                tipo_catasto_demaniale_derivazione_id: Yup.string().required("Il campo è obbligatorio"),
                provincia: Yup.string().required("Il campo è obbligatorio"),
                tipo_comune_id: Yup.string().required("Il campo è obbligatorio"),
                indirizzo: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                civico: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                cap: Yup.string().required("Il campo è obbligatorio"),
                codice_area: Yup.string().max(100, "Consentiti al massimo 100 caratteri").required("Il campo è obbligatorio"),
                catasto_sezione: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                catasto_foglio: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                catasto_particella: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                catasto_sub: Yup.string().required("Il campo è obbligatorio"),
                superficie_totale: Yup.string().min(2, "Il campo è obbligatorio").max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                identificativo_pozzo: Yup.string().max(100, "Consentiti al massimo 100 caratteri").required("Il campo è obbligatorio"),
                unita_misura_nominale: Yup.string().max(100, "Consentiti al massimo 100 caratteri").required("Il campo è obbligatorio"),
                produzione_nominale: Yup.number().required("Il campo è obbligatorio e deve essere un numero"),
                tipo_stato_catasto_demaniale_derivazione_id: Yup.string().required("Il campo è obbligatorio"),
                //opere_richieste: Yup.string().required("Il campo è obbligatorio"),
                //opere_esistenti: Yup.string().required("Il campo è obbligatorio"),
                //stato_patrimoniale: Yup.string().required("Il campo è obbligatorio")
                
              })}
              */
              onSubmit = { async (values, { setSubmitting }) => {
                delete(values["provincia"]); // la provincia non mi serve...
                setSubmitting(true);
                let campi = ""
                let valori = ""
              

                Object.entries(values).map(([key,value],i) => {
                  
                  if(key == 'tipo_ambito_id'){
                    let ambito = tipiAmbito.filter(c => c.value == value)
                    // Calcolo il macroambito_demaniale che deve valere D o F
                    if(typeof ambito[0] !== 'undefined'){
                      let macroambito_demaniale = ambito[0].macroarea_ambito;
                      campi += "macroambito_demaniale, "
                      valori += "'" + macroambito_demaniale + "', "
                    }
                  }

                  if(key == 'produzione_nominale' && value != ''){
                    value = parseFloat(value.replace(",", "."));
                  }

                  if(value != ''){
                    campi += key + ", "
                    valori += "'" + formatter.escapeSql(value) + "', "
                  } 
                })
                
                if(valori == ""){
                  alertify.warning("Inserire almeno un campo");
                  return;
                }
              
                try{
                  campi += "utente_modifica_id";
                  valori += localStorage.id
                  const bene = {
                    nome_tabella: "catasto_demaniale_derivazioni",
                    lista_campi: campi,
                    lista_valori: valori
                  }
                  
                  const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:bene})
                  })
                  const data_response = await response.json();
                  if(data_response.hasOwnProperty('Fault')){
                    if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + data_response.Fault.faultcode)
                      throw new Error(data_response.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }
                  if(props.fromAssociazione){
                    props.setBeneDemanialeInserito(data_response.risposta.elementi_aggiunti.id);
                  } else{
                    if (props.macroambito=='D'){ 
                      navigate('/beni-demaniali/derivazioni');
                      }
                     else if (props.macroambito=='F'){
                        navigate('/beni-demaniali/fluviali-lacuali');
                      }
                      alertify.success("Nuovo bene demaniale/derivazione creato con successo.");
                  }
                  
                } catch(error) {
                  alertify.error("Errore durante l'inserimento: " + error);
                  console.log("errore: ", error);
                }
                
            }}
            >
            {
              ({
                values, 
                errors, 
                handleChange, 
                handleSubmit,
                isSubmitting,
                isValid,
                touched,
                dirty,
              }) => (
              <Form>
                <SicondirSelect 
                  label="Tipo Ambito" 
                  id="tipo_ambito_id" 
                  name="tipo_ambito_id" 
                  options={tipiAmbitoFiltrati} 
                  mandatory={true}
                  placeholder="Seleziona Tipo Ambito"
                  onChange={enableTipiCatasto} />
                <br />

                <SicondirSelect 
                  label="Tipo Catasto Demaniale/Derivazioni" 
                  id="tipo_catasto_demaniale_derivazione_id" 
                  name="tipo_catasto_demaniale_derivazione_id" 
                  options={tipiCatastoFiltrati} 
                  isDisabled={tipiCatastoDisabled} 
                  value={tipoCatastoSelezionato}
                  isClearable={true}
                  placeholder="Seleziona Tipo Catasto Demaniale/Derivazioni"
                  onChange={(e) => {
                    if(e){
                      setTipoCatastoSelezionato({
                        label: e.label,
                        value: e.value,
                      });
                    } else {setTipoCatastoSelezionato(null)}
                  }}/>

                <br />
                <SicondirSelect 
                  label="Provincia" 
                  id="provincia" 
                  name="provincia" 
                  isClearable={true}
                  options={provinceLazio} 
                  placeholder="Seleziona Provincia"
                  onChange={enableComuni}/>
                <br />

                <SicondirSelect 
                  label="Comune" 
                  id="tipo_comune_id" 
                  options={comuniFiltrati} 
                  name="tipo_comune_id" 
                  isDisabled={comuniDisabled} 
                  value={comuneSelezionato} 
                  isClearable={true}
                  placeholder="Seleziona Comune"
                  onChange={e => {
                    enableCap(e);
                  }}/>
                <br />
                
                <SicondirSelect 
                  label="C.A.P." 
                  id="codice_cap" 
                  options={capFiltrati} 
                  name="codice_cap" 
                  isDisabled={capDisabled} 
                  value={capSelezionato} 
                  isClearable={true}
                  placeholder="Seleziona C.A.P."
                  onChange={(e) => {
                    if(e){
                      setCapSelezionato({
                        label: e.label,
                        value: e.value,
                      });
                    } else {setCapSelezionato(null)}
                  }}
                />

                <SicondirInput 
                  label='Indirizzo'
                  id='indirizzo'
                  type='text'
                  name='indirizzo'
                  className={`form-control mb-2`}
                  size="large" 
                  placeholder = 'Inserisci Indirizzo'   
                  maxLength = '255'
                />
              
                <SicondirInput 
                  label="Numero Civico"
                  id='civico'
                  type='text'
                  name='civico'
                  className={`form-control mb-2`}
                  size="large" 
                  placeholder = 'Inserisci Numero Civico'   
                  maxLength = '255'
                />

                <SicondirInput 
                  label="Località"
                  id='localita'
                  type='text'
                  name='localita'
                  className={`form-control mb-2`}
                  size="large" 
                  placeholder = 'Inserisci Località'   
                  maxLength = '255'
                />
                
                <SicondirInput 
                label = 'Codice Area'  
                id='codice_area'
                type='text'
                name='codice_area'
                className={`form-control mb-2`}
                size="large" 
                placeholder = 'Inserisci Codice Area'   
                maxLength = '255'
                />
                {/*
                  <SicondirInput 
                  label = 'Sezione'   
                  id='catasto_sezione'
                  type='text'
                  name='catasto_sezione'
                  className={`form-control mb-2`}
                  size="large" 
                  placeholder = 'Inserisci Sezione'   
                  maxLength = '255'
                  />
                */}
                <SicondirInput 
                label = 'Foglio'
                id='catasto_foglio'
                type='text'
                name='catasto_foglio'
                className={`form-control mb-2`}
                size="large" 
                placeholder = 'Inserisci Foglio'   
                maxLength = '255'
                />
                <SicondirInput 
                label = 'Particella'
                id='catasto_particella'
                type='text'
                name='catasto_particella'
                className={`form-control mb-2`}
                size="large" 
                placeholder = 'Inserisci Particella'   
                maxLength = '255'
                />

                <SicondirInput 
                label = 'Subalterno'
                id='catasto_sub'
                type='text'
                name='catasto_sub'
                className={`form-control mb-2`}
                size="large" 
                placeholder = 'Inserisci Subalterno'   
                maxLength = '255'
                /> 
                <SicondirInput 
                label = 'Superficie Totale'   
                id='superficie_totale'
                type='text'
                name='superficie_totale'
                className={`form-control mb-2`}
                size="large" 
                placeholder = 'Inserisci Superficie Totale'   
                maxLength = '255'
                />
                {props.macroambito != 'F' &&
                  <>
                  <SicondirInput 
                  label = 'Identificativo Pozzo'  
                  id='identificativo_pozzo'
                  type='text'
                  name='identificativo_pozzo'
                  className={`form-control mb-2`}
                  size="large" 
                  placeholder = 'Inserisci Identificativo Pozzo'   
                  maxLength = '255'
                  />
                
                  <SicondirInput 
                  label = 'Unità Misura Nominale'   
                  id='unita_misura_nominale'
                  type='text'
                  name='unita_misura_nominale'
                  className={`form-control mb-2`}
                  size="large" 
                  placeholder = 'Inserisci Unità Misura Nominale'   
                  maxLength = '255'
                  />
                  </>
                }

                <SicondirInput 
                label = 'Produzione Nominale'  
                id='produzione_nominale'
                type='text'
                name='produzione_nominale'
                className={`form-control mb-2`}
                size="large" 
                placeholder = 'Inserisci Produzione Nominale'   
                maxLength = '10'
                />
              
                <br /><br />
                <SicondirSelect 
                  label="Status Area Demaniale" 
                  id="tipo_stato_catasto_demaniale_derivazione_id" 
                  name="tipo_stato_catasto_demaniale_derivazione_id" 
                  placeholder="Seleziona Status Area Demaniale"
                  isClearable={true}
                  options={statusAreaDemaniale} />
                <br /><br />
                {props.macroambito != 'F' &&
                  <>
                  <SicondirSelect 
                  label="Modalità Di Scarico" 
                  id="tipo_modalita_scarico_id" 
                  name="tipo_modalita_scarico_id" 
                  placeholder="Seleziona Status Modalità Di Scarico"
                  isClearable={true}
                  options={tipiModalitaScarico} />
                  <br /><br />
                  </>
                }
                <SicondirTextarea
                  label='Note'
                  id='note'
                  type='textarea'
                  style={{height: "150px", width: "100%"}}
                  name='note'
                  size="large" 
                />
                <br /><br />
                {/*
                <SicondirRadio 
                  label="Opere Richieste"
                  name="opere_richieste"
                  id="opere_richieste"
                  options={[
                    {
                      value:"true",
                      label:"Sì",
                    },
                    {
                      value:"false",
                      label:"No",
                    }
                ]}

                />
                */}

                {/*
                <SicondirRadio 
                  label="Opere Esistenti"
                  name="opere_esistenti"
                  id="opere_esistenti"
                  options={[
                    {
                      value:"true",
                      label:"Sì",
                    },
                    {
                      value:"false",
                      label:"No",
                    }
                ]} 
                /> */}

                {/* <SicondirSelect label="Stato del bene demaniale al momento della richiesta" options={tipiStatoCatastoAttuale} /> */}

                {/*
                <SicondirRadio 
                  label="Stato Patrimoniale"
                  name="stato_patrimoniale"
                  id="stato_patrimoniale"
                  options={[
                    {
                      value:"DISPONIBILE",
                      label:"Disponibile",
                    },
                    {
                      value:"INDISPONIBILE",
                      label:"Indisponibile",
                    }
                ]} 
                />*/}
               {
                 props.fromAssociazione 
                 ?
                  <div className="col-12 mt-5 row justify-content-center">
                    <button type="submit" className="btn btn-primary col-5" disabled={!(isValid && dirty && !isSubmitting)}>
                    Inserisci e associa
                    </button>
                  </div>
                :
                  <div className="col-12 mt-5 row justify-content-center">
                    <button type="submit" className="btn btn-primary col-5" disabled={!(isValid && dirty && !isSubmitting)}>
                    Inserisci
                    </button>
                  </div>
               }
               <br/><br/>
            </Form>)}
            </Formik>
            
            
        </>
        
    )
   
}
export default BeniDemanialiForm;