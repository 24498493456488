import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from "react-router";
import AnagraficaFormEdit from "../../../common/forms/AnagraficaFormEdit";
import alertify from "alertifyjs";
import formatter from '../../../common/utils/formatters/_' 
import SicondirTable from '../../../common/components/SicondirTable';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'  

function AnagraficaEdit() {
  const params=useParams()
  const[persona, setPersona] = useState([])
  const[ruoli, setRuoli] = useState([])
  const[loading, setLoading] = useState(true)
  const [listConcessioni,setListConcessioni] = useState([])
  const [listDenuncePozzo,setListDenuncePozzo] = useState([])
  const [listRichiesteConcessioni,setListRichiesteConcessioni] = useState([])
  const [listOccupazioni,setListOccupazioni]  = useState([])
  let navigate = useNavigate();

  const redirect = (id, macroambito, entita) => {
    let url = "/" + entita + "/"
    if(entita == 'concessioni' || entita == 'richieste-concessioni'){
      let macroarea=''
      if(macroambito=='F'){macroarea= 'fluviali-lacuali'}
      else {macroarea = 'derivazioni'}
      url += macroarea + "/inserisci/" + id
    } else{
      url += "inserisci/" + id
    }
    navigate(url)
  }

  const fetchData = async () => {
    try {
      let urlApi = `persone/id/${params.id}`
      const response = await fetch(process.env.REACT_APP_API_URL + urlApi, 
      {
        method: 'GET',
        headers: { accept:'application/json' }, 
        mode: 'cors',  
      })
      const json = await response.json()
      setPersona(formatter.cleanObject(json.risposta.persone))
     // RUOLI
      const urlApiRuoli = 'ruoli/persona_id/' + params.id
      const responseRuoli = await fetch(process.env.REACT_APP_API_URL + urlApiRuoli, 
      {
        method: 'GET',
        headers: { accept:'application/json' }, 
        mode: 'cors',  
      })
      const jsonRuoli = await responseRuoli.json()
      if(jsonRuoli.risposta) {
        let array = []
        if(Array.isArray(jsonRuoli.risposta.ruoli)) {
          jsonRuoli.risposta.ruoli.map(r => {
            array.push(formatter.cleanObject(r))
          })
          setRuoli(array)
        } 
      }
      
    } catch (error) {
      alertify.error("Errore durante il recupero dei dati.");
      console.log("errore: ", error);
    }
    setLoading(false)
  }

  const fetchDataConcessioni = async() => {
    try {
        const urlApiConcessioni = "concessioni/persona_id/" + params.id
        const responseConcessioni = await fetch(
          process.env.REACT_APP_API_URL + urlApiConcessioni, {
            method: 'GET',
            headers: { accept:'application/json' }, 
            mode: 'cors',  
          }
        )
        const jsonConcessioni = await responseConcessioni.json()
        
        let list = [];
        let i = 1;
        if(jsonConcessioni.risposta.concessioni){
        
          if(Array.isArray(jsonConcessioni.risposta.concessioni)){
            jsonConcessioni.risposta.concessioni.map(element => {
              element = formatter.cleanObject(element)
              
              list[i] = {
                id: element.id,
                codice_sicondir: element.codice_sicondir || " - ",
                codice_sistema_precedente: element.codice_sistema_precedente || " - ",
                tipo_ambito: element.tipo_ambito || " - ",
                provincia: element.provincia || " - ",
                stato: element.stato || " - ",            
                app_actions:{
                  redirect: {
                    kind: "function", 
                        function: redirect,
                        parameters:
                        {
                          id: element.id,
                          macroambito: element.macroarea_ambito,
                          entita: "concessioni"
                        },
                        label: "Dettaglio Concessione"
                      
  
                  },  
                }
              }
              i++
            });
          }
    }
      setListConcessioni(list); 
      //setLoading(false);
    } 
    catch (error) {
      alertify.error("Errore durante il recupero dei dati.");
      console.log("errore: ", error);
    }
   
    
    }
 
  const fetchDataRichiesteConcessioni = async() => {
      try {
        const urlApiRichiesteConcessioni = "richieste_concessione/persona_id/" + params.id
        const responseRichiesteConcessioni = await fetch(
          process.env.REACT_APP_API_URL + urlApiRichiesteConcessioni, {
            method: 'GET',
            headers: { accept:'application/json' }, 
            mode: 'cors',  
          }
        )
        const jsonRichiesteConcessioni = await responseRichiesteConcessioni.json()
        
        let listRichiesteConcessioni = [];
        let i = 1;
      
        if(jsonRichiesteConcessioni.risposta.richieste_concessione){
        
          if(Array.isArray(jsonRichiesteConcessioni.risposta.richieste_concessione)){
           
            jsonRichiesteConcessioni.risposta.richieste_concessione.map(element => {
              element = formatter.cleanObject(element)
              
              listRichiesteConcessioni[i] = {
                id: element.id,
                codice_sicondir: element.codice_sicondir || " - ",
                codice_sistema_precedente: element.codice_sistema_precedente || " - ",
                tipo_ambito: element.tipo_ambito || " - ",
                provincia: element.provincia || " - ",
                stato: element.stato || " - ",    
                app_actions:{
                  redirectRichiesteConcessioni: {
                    kind: "function", 
                        function: redirect,
                        parameters:
                        {
                          id: element.id,
                          macroambito: element.macroarea_ambito,
                          entita: "richieste-concessioni"
                        },
                        label: "Dettaglio Domanda",
                  },  
                }
              }
              i++
            });
          }
        }
     
      setListRichiesteConcessioni(listRichiesteConcessioni); 
      //setLoading(false);
    } 
    catch (error) {
      alertify.error("Errore durante il recupero dei dati.");
      console.log("errore: ", error);
    }
    
    
  } 
  
  const fetchDataOccupazioni = async() => {
        try {
          const urlApiOccupazioni = "occupazioni/persona_id/" + params.id
          const responseOccupazioni = await fetch(
            process.env.REACT_APP_API_URL + urlApiOccupazioni, {
              method: 'GET',
              headers: { accept:'application/json' }, 
              mode: 'cors',  
            }
          )
          const jsonOccupazioni = await responseOccupazioni.json()
          
          let listOccupazioni = [];
          let i = 1;
        
          if(jsonOccupazioni.risposta.occupazioni){
          
            if(Array.isArray(jsonOccupazioni.risposta.occupazioni)){
             
              jsonOccupazioni.risposta.occupazioni.map(element => {
                element = formatter.cleanObject(element)
                
                listOccupazioni[i] = {
                  id: element.id,
                  codice_sicondir: element.codice_sicondir || " - ",
                  codice_sistema_precedente: element.codice_sistema_precedente || " - ",
                  tipo_ambito: element.tipo_ambito || " - ",
                  provincia: element.provincia || " - ",
                  stato: element.stato || " - ",  
                        
                  app_actions:{
                    redirectOccupazioni: {
                      kind: "function", 
                          function: redirect,
                          parameters:
                          {
                            id: element.id,
                            macroambito: null,
                            entita: 'occupazioni'
                          },
                          label: "Dettaglio Occupazione",
                          
                          
      
                    },  
                  }
                }
                i++
              });
            }
      }
       
      setListOccupazioni(listOccupazioni); 
      //setLoading(false);
      } 
      catch (error) {
        alertify.error("Errore durante il recupero dei dati.");
        console.log("errore: ", error);
      }
  } 

  const fetchDataDenuncePozzo = async() => {
    try {
      const urlApiDenuncePozzo = "denunce_pozzo/persona_id/" + params.id
      const responseDenuncePozzo = await fetch(
        process.env.REACT_APP_API_URL + urlApiDenuncePozzo, {
          method: 'GET',
          headers: { accept:'application/json' }, 
          mode: 'cors',  
        }
      )
      const jsonDenuncePozzo = await responseDenuncePozzo.json()
      
      let listDenuncePozzo = [];
      let i = 1;
    
      if(jsonDenuncePozzo.risposta.denunce_pozzo){
      
        if(Array.isArray(jsonDenuncePozzo.risposta.denunce_pozzo)){
         
          jsonDenuncePozzo.risposta.denunce_pozzo.map(element => {
            element = formatter.cleanObject(element)
            
            listDenuncePozzo[i] = {
              id: element.id,
              codice_sicondir: element.codice_sicondir || " - ",
              codice_sistema_precedente: element.codice_sistema_precedente || " - ",
              tipo_ambito: element.tipo_ambito || " - ",
              provincia: element.provincia || " - ",
              stato: element.stato || " - ",  
              app_actions:{
                redirectDenuncePozzo: {
                  kind: "function", 
                  function: redirect,
                  parameters:
                  { id: element.id,
                    macroambito: null,
                    entita: "denunce-pozzo" 
                  },
                  label: "Dettaglio Denuncia Pozzo",
                },  
              }
            }
            i++
          });
        }
  }
   
    setListDenuncePozzo(listDenuncePozzo); 
    //setLoading(false);
  } 
  catch (error) {
    alertify.error("Errore durante il recupero dei dati.");
    console.log("errore: ", error);
  }

  }

  useEffect(() => {
    setLoading(true)
    fetchData();
    fetchDataConcessioni()
    fetchDataRichiesteConcessioni()
    fetchDataOccupazioni()
    fetchDataDenuncePozzo()
  },[]);
  
  return (
    <main>
    <h3>Modifica persona</h3>
    <div className="card">
      <div className="card-body">
      {!loading?
        <>
        <AnagraficaFormEdit 
          persona={persona} 
          ruoli={ruoli} 
          contesto="anagrafica"
        />
        <h3> Elenco Delle Concessioni Associate Alla Persona</h3>
        <SicondirTable
          headers={["Codice sicondir","codice sistema precedente","tipo ambito","provincia","stato",""]} 
          data={listConcessioni}
        />
        <h3> Elenco Delle Domande Associate Alla Persona</h3>
        <SicondirTable
          headers={["Codice sicondir","codice sistema precedente","tipo ambito","provincia","stato",""]} 
          data={listRichiesteConcessioni}
        />
        <h3> Elenco Delle Denunce Pozzo Associate Alla Persona</h3>
        <SicondirTable
          headers={["Codice sicondir","codice sistema precedente","tipo ambito","provincia","stato",""]} 
          data={listDenuncePozzo}
        />
        <h3> Elenco Delle Occupazioni Associate Alla Persona</h3>
        <SicondirTable
          headers={["Codice sicondir","codice sistema precedente","tipo ambito","provincia","stato",""]} 
          data={listOccupazioni}
        />
        </>
      :
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>
      }
      </div>
    </div>
    </main>
  )
}

export default AnagraficaEdit