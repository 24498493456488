import UsoForm from "../../../common/forms/UsoForm";
import { useEffect, useState } from 'react';
import alertify from "alertifyjs";
import SicondirTable from "../../../common/components/SicondirTable";
import formatter from "../../../common/utils/formatters/_";
import Modal from 'react-bootstrap/Modal'
import UsoFormEdit from "../../../common/forms/UsoFormEdit";


const OccupazioniBeniUsi = (props) => {
    const [loading, setLoading] = useState(true);
    const [usi, setUsi] = useState(false);
    const [list, setList] = useState([])
    const [occupazioneCatastoId, setOccupazioneCatastoId] = useState(props.occupazioneCatastoId);
    const [usoInserito, setUsoInserito] = useState(false);
    const [usoCancellato, setUsoCancellato] = useState(false);
    // const [tipiUsoSelezionati, setTipiUsoSelezionati] = useState([]);
    // per la modifica.
    const[editShow, setEditShow] = useState(false);
    const[loadingEdit, setLoadingEdit] = useState(true);
    const[usoEdit, setUsoEdit] = useState(null)
    const[usoModificato, setUsoModificato] = useState(false);

    const handleModalEdit = async (idUso) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL +
                'occupazioni_catasto_uso/id/' + idUso,
                {
                    method: 'GET',
                    headers: { accept: 'application/json' },
                    mode: 'cors',
                })
            const json = await response.json()
            let  uso=" "
            if (json.risposta.occupazioni_catasto_uso) {
              uso = json.risposta.occupazioni_catasto_uso   
            }
            setUsoEdit(formatter.cleanObject(uso))
            setLoadingEdit(false)
        }
        catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
        }
        setEditShow(true);
    }
  
    const handleCloseEdit = () => {
        setEditShow(false);
    }

    let headersBene = ["Foglio", "Particella", "Subalterno", "Comune", "Località", "Descrizione catasto", "Stato"];
    let beneDemaniale = [
        {   foglio: props.beneDemaniale.catasto_foglio || "-", 
            particella: props.beneDemaniale.catasto_particella || "-",
            subalterno: props.beneDemaniale.catasto_sub || "-",
            comune: props.beneDemaniale.comune_nome ? props.beneDemaniale.comune_nome + "(" + props.beneDemaniale.provincia_sigla + ")" : "-", 
            localita: props.beneDemaniale.localita || "-",
            descrizioneCatasto: props.beneDemaniale.descrizione_tipo_catasto || "-",
            stato: props.beneDemaniale.codice_stato || "-"
        }

    ]
    let headers = ["Codice", "Descrizione categoria", "Descrizione sottocategoria", "Unità misura", "Valore", "Durata", "Descrizione uso", ""];

    const handleDelete = async (idCatastoUso) => {
        setLoading(true);
        const payload = {
            nome_tabella: "occupazioni_catasto_uso",
            id: idCatastoUso,
            utente_modifica_id: localStorage.id
        }
        
        try{
            const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
                method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
                headers: { 'Content-Type': 'application/json',
                            'Accept': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:payload})
            })
            const data = await response.json();
            alertify.success("Associazione uso/bene demaniale eliminata con successo");
            
            setLoading(false)
            setUsoCancellato(true)
        } catch(error) {
            alertify.error("Errore durante la cancellazione dell'associazione uso/bene demaniale");
            setLoading(false)
        }
    }

    const buildActions = (idCatastoUso) => {
        let result = {};
        result = { elimina: {
                        kind: "function", 
                        function: handleDelete,
                        parameters:
                        {
                            idCatastoUso: idCatastoUso
                        },
                        buttonClass: "btn-danger",
                        label: "Elimina uso",
                        confirmLabel: "Procedere con l'eliminazione dell'uso per il bene demaniale dell'occupazione?",
                        buttonClass: "btn-danger",
                        linkClass: "text-danger",
                    },
                    modificaUso: {
                        kind: "function", 
                        function: handleModalEdit,
                        parameters:
                        {
                            idUso: idCatastoUso
                        },
                        label: "Modifica uso",
                    }
        }
        return result;        
    }

    
    useEffect(() => {
        const url = process.env.REACT_APP_API_URL + "occupazioni_catasto_uso/occupazione_catasto_id/"+occupazioneCatastoId;
    
        const fetchData = async () => {
          try {
            const response = await fetch(url, {
              method: 'GET',
              headers: { accept: "application/json" },      
              mode: 'cors'
             });
  
            let beniCollegati = []; 
            let usiSelezionati = [];
            const json = await response.json();
            if(json.risposta.occupazioni_catasto_uso){
                let list = [];
                let i = 1;
                let response = formatter.cleanObject(json.risposta.occupazioni_catasto_uso)
                if(Array.isArray(json.risposta.occupazioni_catasto_uso)){
                    json.risposta.occupazioni_catasto_uso.map(element => {
                        element = formatter.cleanObject(element)
                        let actions = buildActions(element.id)
                        usiSelezionati.push(element.tipo_uso_id);
                        list[i] = {
                            id: element.id,
                            codice: element.codice || " - ",
                            descrizione_categoria: element.descrizione_categoria || " - ",
                            descrizione_sottocategoria: element.descrizione_sottocategoria || " - ",
                            unita_misura: element.unita_misura || " - ",
                            valore: element.valore || " - ",
                            durata: element.durata || " - ",
                            popover: element.descrizione_uso || " - ",
                            app_actions: actions
                        }
                        i++
                      });
                } 
                setList(list); 
                let k = 0
                list.map(element => {
                    beniCollegati[k] = element.id
                    k++;
                })
                setUsi(true); // Se c'è un oggetto lo setto come bene demaniale.
            } else{
                setUsi(null)
            }
            // setTipiUsoSelezionati(usiSelezionati)
            setUsoInserito(false)
            setUsoCancellato(false)
            setUsoModificato(false)
            setLoading(false);
          } catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
          }
        };
        
        fetchData();
    }, [usoInserito, usoCancellato, usoModificato]);


    return(
        <>
            <SicondirTable 
                headers={headersBene} 
                data={beneDemaniale}
                compact={true}
            />

            <div className=" align-items-center justify-content-center">
                <UsoForm 
                        setUsoInserito={setUsoInserito}
                        contesto="occupazioni"
                        idAssociazione={props.occupazioneCatastoId}
                        // tipiUsoSelezionati = {tipiUsoSelezionati}
                        tipiUsoSelezionati = {[]}
                        idAmbito={props.beneDemaniale.ambito_id}
                />
            </div>
            { !loading
              ?
                <>
                    
                    { !usi
                        ?
                        <div className="row">
                            <div className="col-12 mt-5 row justify-content-center">
                                Nessun uso è associato a questo bene demaniale.
                            </div>
                        </div>
                        :
                        <>
                        <br />
                        <div>
                            <h5>
                                Usi del bene demaniale per l'occupazione
                            </h5>
                            <br />
                            <SicondirTable 
                                headers={headers} 
                                data={list}
                                compact={true}
                                dropdown={true}
                            />
                        </div>
                        <Modal
                        size="xl"
                        show={editShow}
                        onHide={handleCloseEdit}
                        >
                        <Modal.Header closeButton>
                            <Modal.Title >
                                Modifica Uso 
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {!loadingEdit ?
                                <>
                                <UsoFormEdit
                                uso={usoEdit}
                                handleClose={handleCloseEdit} 
                                setModifica={setUsoModificato}
                                tipiUsoSelezionati = {[]}
                                idAmbito={props.beneDemaniale.ambito_id}
                                contesto={"occupazioni"}
                                />
                                <br></br>
                                </>
                                :
                                <div className="h-100 d-flex align-items-center justify-content-center">
                                    <div className="progress-spinner progress-spinner-active">
                                        <span className="sr-only">Caricamento...</span>
                                    </div>
                                </div>
                            }
                        </Modal.Body>
                        </Modal>
                        </>
                    }
                </>
                :
                <div className="h-50 d-flex align-items-center justify-content-center">
                    <div className="progress-spinner progress-spinner-active">
                        <span className="sr-only">Caricamento...</span>
                    </div>
                </div>
            }
        </>
        
    )
}

export default OccupazioniBeniUsi;