import { useNavigate } from "react-router-dom";

export default function SicondirUpdateButton(props) {

    let navigate = useNavigate();
    const buttonLabel = props.label ? props.label : "Gestisci";

    const redirect = () =>{
        navigate(props.url);
    }

    return (
        <button className="btn btn-primary" onClick={redirect}>{buttonLabel}</button>
    )
}