import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirSelect from "../../components/SicondirSelect";
import tipiAmbito from '../../../_data/tipi_ambito.json'
import SicondirInput from "../../components/SicondirInput";
import tipiDurata from '../../../_data/tipi_durata.json';
import tipiRichiesta from '../../../_data/tipi_richiesta.json';
import formatter from '../../utils/formatters/_';
import ConcessioniRicerca from '../../../modules/concessioni/components/ConcessioniRicerca';
import Modal from 'react-bootstrap/Modal'
import SicondirTable from "../../components/SicondirTable";
import provinceLazio from "../../../_data/province_lazio.json"
import SicondirTextarea from "../../components/SicondirTextarea";
import tipiStatoConcessione from "../../../_data/tipi_stato_concessioni.json";
import tipiStatoRichiesta from "../../../_data/tipi_stato_richiesta_concessioni.json";
import OccupazioniRicerca from "../../../modules/concessioni/components/OccupazioniRicerca";
import DenuncePozzoRicerca from "../../../modules/concessioni/components/DenuncePozzoRicerca";

const RichiestaForm = (props) => {
    // Get ID from URL
    const params = useParams();
    const richiestaId = params.id;
    let navigate = useNavigate();
    const [oldValue, setOldValue] = useState(props.data);
    const [loading, setLoading] = useState(props.loading);
    const [richiesta, setRichiesta] = useState(props.richiesta);
    const [tipiAmbitoFiltrati, setTipiAmbitoFiltrati] = useState([])
    const [valueUploaded, setValueUploaded] = useState(false);
    const [associazione, setAssociazione] = useState(false)
    const [show, setShow] = useState(false);
    const [cancellaAssociazione, setCancellaAssociazione] = useState(false);
    const [associazioneId, setAssociazioneId] = useState(null)
    const [concessione, setConcessione] = useState(null)
    const [list, setList] = useState([])
    const [provinciaSelezionata,setProvinciaSelezionata] = useState(null)
    const [stringaMacroambito, setStringaMacroambito] = useState(props.macroambito == "F" ? "fluviali-lacuali" : "derivazioni")
    
    // Dati occupazione
    const [occupazione, setOccupazione] = useState(null)
    const [showOccupazione, setShowOccupazione] = useState(false);
    const [associazioneOccupazioneId, setAssociazioneOccupazioneId] = useState(null)
    const [cancellaAssociazioneOccupazione, setCancellaAssociazioneOccupazione] = useState(false);
    const [listOccupazioni, setListOccupazioni] = useState([])
    const [associazioneOccupazione, setAssociazioneOccupazione] = useState(false)

    // Dati denuncia pozzo
    const [denunciaPozzo, setDenunciaPozzo] = useState(null)
    const [showDenunciaPozzo, setShowDenunciaPozzo] = useState(false);
    const [associazioneDenunciaPozzoId, setAssociazioneDenunciaPozzoId] = useState(null)
    const [cancellaAssociazioneDenunciaPozzo, setCancellaAssociazioneDenunciaPozzo] = useState(false);
    const [listDenuncePozzo, setListDenuncePozzo] = useState([])
    const [associazioneDenunciaPozzo, setAssociazioneDenunciaPozzo] = useState(false)

    const opzioniStatoImport = [
      { value: 'BOZZA', label: 'Bozza' },
      { value: 'NUOVA', label: 'Nuova/Importata' },
      { value: 'CONSOLIDATA', label: 'Consolidata' }
    ]
    const [readOnly, setReadOnly] = useState(props.readOnly)
    const [statoImport, setStatoImport] = useState(opzioniStatoImport.filter(c => c.value == props.richiesta.stato_import))

    const collegamento=async(id) => {
      navigate('/concessioni/' + stringaMacroambito + '/inserisci/' + id);
    }

    const collegamentoOccupazione=async(id) => {
      navigate('/occupazioni/inserisci/' + id);
    }

    const collegamentoDenunciaPozzo=async(id) => {
      navigate('/denunce-pozzo/inserisci/' + id);
    }
    
    const handleModal = () => {
      setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }
   
    const handleDelete = async (associazioneId) => {
      setLoading(true);
      const payload = {
          nome_tabella: "concessioni_richieste_concessione",
          id: associazioneId,
          utente_modifica_id: localStorage.id
      }
    
      try{
          const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
              method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
              headers: { 'Content-Type': 'application/json',
                          'Accept': 'application/json' }, 
              mode: 'cors', 
              body: JSON.stringify({Entry:payload})
          })
          const data = await response.json();
          alertify.success("Associazione domanda/concessione eliminata con successo");
          setLoading(false)
          setCancellaAssociazione(true)
          setList([]);
      } catch(error) {
          alertify.error("Errore durante la cancellazione dell'associazione bene domanda/concessione");
          setLoading(false)
      }
  }

  /* HANDLE OCCUPAZIONI */
  const handleModalOccupazione = () => {
    setShowOccupazione(true);
  }

  const handleCloseOccupazione = () => {
      setShowOccupazione(false);
  }

  const handleDeleteOccupazione = async (associazioneId) => {
    setLoading(true);
    const payload = {
        nome_tabella: "occupazioni_richieste_concessione",
        id: associazioneId,
        utente_modifica_id: localStorage.id
    }
  
    try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
            method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
            headers: { 'Content-Type': 'application/json',
                        'Accept': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
        })
        const data = await response.json();
        alertify.success("Associazione domanda/occupazione eliminata con successo");
        setLoading(false)
        setCancellaAssociazioneOccupazione(true)
        setListOccupazioni([]);
    } catch(error) {
        alertify.error("Errore durante la cancellazione dell'associazione bene domanda/occupazione");
        setLoading(false)
    }
  }
  /* */


  /* HANDLE DENUNCE POZZO  */
  const handleModalDenunciaPozzo = () => {
    setShowDenunciaPozzo(true);
  }

  const handleCloseDenunciaPozzo = () => {
      setShowDenunciaPozzo(false);
  }

  const handleDeleteDenunciaPozzo = async (associazioneId) => {
    setLoading(true);
    const payload = {
        nome_tabella: "denunce_pozzo_richieste_concessione",
        id: associazioneId,
        utente_modifica_id: localStorage.id
    }
  
    try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
            method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
            headers: { 'Content-Type': 'application/json',
                        'Accept': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
        })
        const data = await response.json();
        alertify.success("Associazione domanda/denuncia pozzo eliminata con successo");
        setLoading(false)
        setCancellaAssociazioneDenunciaPozzo(true)
        setListDenuncePozzo([]);
    } catch(error) {
        alertify.error("Errore durante la cancellazione dell'associazione bene domanda/denuncia pozzo");
        setLoading(false)
    }
  }
  /* */

  useEffect(() => {
    if(oldValue && !valueUploaded){
      setValueUploaded(true)
      if(oldValue.tipo_provincia_id){
    const provinciaSelezionataRecuperata = provinceLazio.filter(c => c.label == oldValue.tipo_provincia_id).map(c => {
      return {
        value: c.value,
        label: c.label,
      }
    })
    setProvinciaSelezionata(provinciaSelezionataRecuperata[0])
  }}} ,[oldValue])

  useEffect(() => { 
    const fetchData = async () => {
        // Per la tendina degli ambiti:
        const res = tipiAmbito.filter(c => c.macroarea_ambito == props.richiesta.macroarea_ambito)
        .map(    
        c => { return { 
            value: c.value,
            label: c.label,
        };}
        )
        setTipiAmbitoFiltrati(res)

        // Recupero l'associazione con la concessione se presente: 
        try {
          const url = process.env.REACT_APP_API_URL + 'concessioni_richieste_concessione/richiesta_id/' + richiestaId
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          if(json.risposta.concessione){
              let element = formatter.cleanObject(json.risposta.concessione)
              setConcessione(element); 
              setAssociazioneId(element.associazione_id);
                 // gestione intestatario
                 let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
                 let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
                 let ragioneSociale =  datiIntestatario[2]
                 let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
                 let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
                 let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]

                 let statoArray = tipiStatoConcessione.filter(s => (s.value == element.stato))
                 let stato
                 if(statoArray[0]) stato = statoArray[0].label
                 let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
                 let tipoAmbito
                 if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice

              let listConcessioni = []
              listConcessioni[0] = {
                id: element.associazione_id,
                tipo_ambito: tipoAmbito || "-",
                codice_sicondir: element.codice_sicondir  || "-",
                codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                intestatario: intestatario ,
                codice_fiscale: codiceFiscale,
                piva: piva,
                stato: stato || "-",
                app_actions:{
                  elimina: {
                    kind: "function", 
                    function: handleDelete,
                    label: "Elimina associazione",
                    parameters: {
                        associazioneId: element.associazione_id
                    },
                    confirmLabel: "Procedere con l'eliminazione dell'associazione alla concessione?",
                    buttonClass:"btn-danger " + props.readOnly ? "disabled" : "",
                    linkClass: "text-danger " + props.readOnly ? "disabled" : "",
                  },
                  collegamento: { 
                  kind:"function",
                  function: collegamento,
                  parameters:
                  {
                    id: element.id
                  },
                  label: "Dettaglio Concessione"       
                  
                },

           
              }
              }
              setList(listConcessioni) // c'è una lista, ma dovremmo avere sempre e solo una concessione

          } else{
              setConcessione(null);
          }

          setLoading(false);
          setAssociazione(false)
          setCancellaAssociazione(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }

        setLoading(false);
    };


    fetchData();

  }, [associazione, cancellaAssociazione]);
  
  /* USE EFFECT OCCUPAZIONE */
  useEffect(() => { 
    const fetchData = async () => {
        // Per la tendina degli ambiti:
        const res = tipiAmbito.filter(c => c.macroarea_ambito == props.richiesta.macroarea_ambito)
        .map(    
        c => { return { 
            value: c.value,
            label: c.label,
        };}
        )
        setTipiAmbitoFiltrati(res)

        // Recupero l'associazione con la occupazione se presente: 
        try {
          const url = process.env.REACT_APP_API_URL + 'occupazioni_richieste_concessione/richiesta_id/' + richiestaId
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          if(json.risposta.occupazione){
              let element = formatter.cleanObject(json.risposta.occupazione)
              setOccupazione(element); 
              setAssociazioneOccupazioneId(element.associazione_id);
                 // gestione intestatario
                 let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
                 let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
                 let ragioneSociale =  datiIntestatario[2]
                 let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
                 let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
                 let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]

                 let statoArray = tipiStatoConcessione.filter(s => (s.value == element.stato))
                 let stato
                 if(statoArray[0]) stato = statoArray[0].label
                 let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
                 let tipoAmbito
                 if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice

              let listOccupazioni = []
              listOccupazioni[0] = {
                id: element.associazione_id,
                tipo_ambito: tipoAmbito || "-",
                codice_sicondir: element.codice_sicondir  || "-",
                codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                intestatario: intestatario ,
                codice_fiscale: codiceFiscale,
                piva: piva,
                stato: stato || "-",
                app_actions:{
                  elimina: {
                    kind: "function", 
                    function: handleDeleteOccupazione,
                    label: "Elimina associazione",
                    parameters: {
                        associazioneId: element.associazione_id
                    },
                    confirmLabel: "Procedere con l'eliminazione dell'associazione all'occupazione?",
                    buttonClass:"btn-danger " + props.readOnly ? "disabled" : "",
                    linkClass: "text-danger " + props.readOnly ? "disabled" : "",
                  },
                  collegamento: { 
                  kind:"function",
                  function: collegamentoOccupazione,
                  parameters:
                  {
                    id: element.id
                  },
                  label: "Dettaglio Occupazione"       
                },
              }
              }
              setListOccupazioni(listOccupazioni) // c'è una lista, ma dovremmo avere sempre e solo una occupazione

          } else{
              setOccupazione(null);
          }

          setLoading(false);
          setAssociazioneOccupazione(false)
          setCancellaAssociazioneOccupazione(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }

        setLoading(false);
    };
    if(props.macroambito == "F") fetchData();
  }, [associazioneOccupazione, cancellaAssociazioneOccupazione]);
  /* */

  /* USE EFFECT DENUNCIA POZZO */
  useEffect(() => { 
    const fetchData = async () => {
        // Per la tendina degli ambiti:
        const res = tipiAmbito.filter(c => c.macroarea_ambito == props.richiesta.macroarea_ambito)
        .map(    
        c => { return { 
            value: c.value,
            label: c.label,
        };}
        )
        setTipiAmbitoFiltrati(res)

        // Recupero l'associazione con la denuncia pozzo se presente: 
        try {
          const url = process.env.REACT_APP_API_URL + 'denunce_pozzo_richieste_concessione/richiesta_id/' + richiestaId
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          if(json.risposta.denuncia_pozzo){
              let element = formatter.cleanObject(json.risposta.denuncia_pozzo)
              setDenunciaPozzo(element); 
              setAssociazioneDenunciaPozzoId(element.associazione_id);
                 // gestione intestatario
                 let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
                 let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
                 let ragioneSociale =  datiIntestatario[2]
                 let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
                 let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
                 let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]

                 let statoArray = tipiStatoConcessione.filter(s => (s.value == element.stato))
                 let stato
                 if(statoArray[0]) stato = statoArray[0].label
                 let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
                 let tipoAmbito
                 if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice

              let listDenuncePozzo = []
              listDenuncePozzo[0] = {
                id: element.associazione_id,
                tipo_ambito: tipoAmbito || "-",
                codice_sicondir: element.codice_sicondir  || "-",
                codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                intestatario: intestatario ,
                codice_fiscale: codiceFiscale,
                piva: piva,
                stato: stato || "-",
                app_actions:{
                  elimina: {
                    kind: "function", 
                    function: handleDeleteDenunciaPozzo,
                    label: "Elimina associazione",
                    parameters: {
                        associazioneId: element.associazione_id
                    },
                    confirmLabel: "Procedere con l'eliminazione dell'associazione alla denuncia pozzo?",
                    buttonClass:"btn-danger " + props.readOnly ? "disabled" : "",
                    linkClass: "text-danger " + props.readOnly ? "disabled" : "",
                  },
                  collegamento: { 
                  kind:"function",
                  function: collegamentoDenunciaPozzo,
                  parameters:
                  {
                    id: element.id
                  },
                  label: "Dettaglio Denuncia Pozzo"       
                  
                },
              }
              }
              setListDenuncePozzo(listDenuncePozzo) // c'è una lista, ma dovremmo avere sempre e solo una denuncia pozzo

          } else{
              setDenunciaPozzo(null);
          }

          setLoading(false);
          setAssociazioneDenunciaPozzo(false)
          setCancellaAssociazioneDenunciaPozzo(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }

        setLoading(false);
    };

    if(props.macroambito == "D") fetchData(); 

  }, [associazioneDenunciaPozzo, cancellaAssociazioneDenunciaPozzo]);
  /* */

  useEffect(() => {
    setOldValue(props.data);
  });

  useEffect(() => {
    if(oldValue && !valueUploaded){
      setValueUploaded(true)
      if(oldValue.tipo_ambito_id){ const tipoAmbitoSelezionato = tipiAmbito.filter( // ...lo popolo
          (c) => c.value == oldValue.tipo_ambito_id
      ).map((c) => {
          return {
            value: c.value,
            label: c.label,
          };
        });
        setTipiAmbitoFiltrati(tipoAmbitoSelezionato[0])
      }
      if(oldValue.tipo_provincia_id){
    const provinciaSelezionataRecuperata = provinceLazio.filter(c => c.label == oldValue.tipo_provincia_id).map(c => {
      return {
        value: c.value,
        label: c.label,
      }
    })
    setProvinciaSelezionata(provinciaSelezionataRecuperata[0])
  }}} ,[oldValue])

  useEffect(() => {
    setLoading(false);
  });

  const handleStatoImport = (event) => {
    setStatoImport(event);
  }

  const formikRef = useRef(null)

  const handleSubmitForm = () =>{
    if(statoImport.value == 'CONSOLIDATA'){
      alertify.confirm("Si sta per consolidare la domanda. Dopo tale operazione non saranno possibili ulteriori modifiche. Procedere alla consolidazione?", 
      async function(){
        formikRef.current.submitForm()
      })
      .set('labels', {
          cancel: "ANNULLA",
          ok: "OK",
      });
    } else{
      formikRef.current.submitForm()
    }
  }

  const handleGeneraConcessione = () =>{
    alertify.confirm("Procedere con la generazione della concessione a partire da questa domanda?", 
      async function(){
        try{
          setLoading(true)
          const payload = {
            richiesta_id: richiesta.id,
            utente_modifica_id: localStorage.id
          }

          const response = await fetch(process.env.REACT_APP_API_URL + 'concessioni/richiesta_concessione_id', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
          })

          console.log("response", response) 
          if(response.status == 500) throw new Error("Errore inserimento")
          alertify.success("Nuova concessione creata con successo.");

        } catch(error) {
          console.log("ERRORE: ", error);
          alertify.error("Errore durante la creazione della concessione");
          
          setLoading(false)
        }
        setAssociazione(true)
      })
      .set('labels', {
          cancel: "ANNULLA",
          ok: "OK",
      });
  } 

    return (
        <>
        {!loading ?
        <>
        <Formik
        innerRef={formikRef}
        id='richiesta' 
        className="p-4"
        
        initialValues={{
            archivio_provenienza: richiesta.archivio_provenienza || '', //concessione.archivio_provenienza,
            archivio_codice: richiesta.archivio_codice || '',
            archivio_posizione: richiesta.archivio_posizione || '',
            codice_sistema_precedente: richiesta.codice_sistema_precedente || '',
            codice_sicondir: richiesta.codice_sicondir || '', // è "nuovo codice univoco concessione"
            tipo_ambito_id: richiesta.tipo_ambito_id || '',
            tipo_richiesta_id: richiesta.tipo_richiesta_id || '', // nel documento si chiama "Tipologia Domanda"
            tipo_durata_id: richiesta.tipo_durata_id || '', 
            descrizione_uso: richiesta.descrizione_uso || '',
            durata_anni: richiesta.durata_anni || '',
            durata_mesi: richiesta.durata_mesi || '',
            durata_giorni: richiesta.durata_giorni || '',
            data_richiesta: richiesta.data_richiesta ? richiesta.data_richiesta.substring(0, 10) : '',
            note: richiesta.note || '',
            tipo_provincia_id: richiesta.tipo_provincia_id || '',
            tipo_stato_richiesta_id: richiesta.tipo_stato_richiesta_id || '',
            data_consolidazione: richiesta.data_consolidazione ? richiesta.data_consolidazione.substring(0, 10) : '',
          }}
          validationSchema={Yup.object({
            durata_anni: Yup.string().matches(/^((\d+?))$/,'inserire un valore numerico corretto'),
            durata_mesi: Yup.string().matches(/^((\d+?))$/,'inserire un valore numerico corretto'),
            durata_giorni: Yup.string().matches(/^((\d+?))$/,'inserire un valore numerico corretto')
          })}  


          onSubmit = { async (values, { setSubmitting, resetForm }) => {
              let campi = ""
                let consolidata = false;

                Object.entries(values).map(([key,value],i) => {
                  if(value != ''&& value != null){
                    if(key == 'stato_import' && value == 'CONSOLIDATA'){
                      consolidata = true
                      var today = new Date();
                      var date = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');
                      campi+="data_consolidazione='"+date +"', "
                    }
                    campi += key + "='" + formatter.escapeSql(value) + "', ";
                  } else {
                    campi += key + "=NULL , ";
                  }
                  if(key == 'tipo_provincia_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_stato_richiesta_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_ambito_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'archivio_provenienza' && value == null) { campi += key + "=null, "; }
                  if(key == 'archivio_codice' && value == null) { campi += key + "=null, "; }
                  if(key == 'archivio_posizione' && value == null) { campi += key + "=null, "; }
                  if(key == 'codice_sistema_precedente' && value == null) { campi += key + "=null, "; }
                  if(key == 'codice_sicondir' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_richiesta_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_durata_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'descrizione_uso' && value == null) { campi += key + "=null, "; }
                  if(key == 'durata_richiesta' && value == null) { campi += key + "=null, "; }
                  if(key == 'data_richiesta' && value == null) { campi += key + "=null, "; }
                  if(key == 'note' && value == null) { campi += key + "=null, "; }
                 
                })
                
                if(campi == ""){
                  alertify.warning("Inserire almeno un campo");
                  return;
                }
              
                try{
                  campi += "utente_modifica_id=" + localStorage.id;
                  const payload = {
                    nome_tabella: "richieste_concessione",
                    lista_campi_valore: campi,
                    lista_clausole_where: "id="+ richiestaId + " and flag_deleted=0"
                  }
                  
                  
                  const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:payload})
                  })
                  const data_response = await response.json();
                  if(data_response.hasOwnProperty('Fault')){
                    if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + data_response.Fault.faultcode)
                      throw new Error(data_response.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }
                  // navigate('/beni-demaniali');
                  props.setRichiestaConsolidata(consolidata);
                  alertify.success("Dati domanda di concessione salvati con successo.");
                  resetForm({values})
                } catch(error) {
                  alertify.error("Errore durante l'inserimento dei dati: " + error);
                  console.log("errore: ", error);
                }
              
                
                
            }}

        >
        {
        ({
                values, 
                errors, 
                handleChange, 
                handleSubmit,
                isSubmitting,
                isValid,
                touched,
                dirty
              }) => (
                  
        <Form>
              <fieldset disabled={readOnly}>
                <br /><br />
                <div className="row">
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Archivio Provenienza'
                        id='archivio_provenienza'
                        type='text'
                        name='archivio_provenienza'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Archivio Provenienza'   
                        maxLength = '255'
                      />
                    </div>
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Codice Archivio Provenienza'
                        id='archivio_codice'
                        type='text'
                        name='archivio_codice'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Codice Archivio Provenienza'   
                        maxLength = '255'
                      />
                    </div>
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Posizione Archivio'
                        id='archivio_posizione'
                        type='text'
                        name='archivio_posizione'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Posizione Archivio'   
                        maxLength = '255'
                      />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Codice Univoco Del Sistema Di Provenienza'
                      id='codice_sistema_precedente'
                      type='text'
                      name='codice_sistema_precedente'
                      disabled={true}
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = '-'   
                      maxLength = '255'
                      />
                  </div>
                  <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Nuovo Codice Sicondir'
                        id='codice_sicondir'
                        type='text'
                        name='codice_sicondir'
                        className={`form-control mb-2`}
                        size="large" 
                        disabled={true}
                        placeholder = 'Inserisci Nuovo Codice Sicondir'   
                        maxLength = '255'
                      />
                  </div>
                  </div>
                  <div className="row">
                    <div className="col-4 md-12 sm-12">
                      <SicondirSelect
                        label='Stato Pratica'
                        id='tipo_stato_richiesta_id'                
                        name='tipo_stato_richiesta_id'              
                        options={tipiStatoRichiesta} 
                        isClearable={true}
                        defaultValue={  tipiStatoRichiesta.filter(c => c.value == richiesta.tipo_stato_richiesta_id) }  
                        placeholder = 'Stato'
                        maxLength = '2'
                        isDisabled={readOnly}
                      />           
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 md-12 sm-12">
                      <SicondirSelect
                        label='Provincia'
                        id='tipo_provincia_id'                
                        name='tipo_provincia_id'              
                        isDisabled={true}
                        options={provinceLazio} 
                        isClearable={true}
                        defaultValue={  provinceLazio.filter(c => c.value == richiesta.tipo_provincia_id) } 
                        placeholder = 'Provincia '
                        maxLength = '2'
                      />           
                    </div>
                  </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12 ">
                    <SicondirSelect 
                      label="Tipo Ambito" 
                      id="tipo_ambito_id" 
                      name="tipo_ambito_id" 
                      placeholder="Seleziona Tipo Ambito"
                      options={tipiAmbitoFiltrati} 
                      isDisabled={true}
                      isClearable={true}
                      defaultValue={ tipiAmbito.filter(c => c.value == richiesta.tipo_ambito_id) }
                    />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect 
                      label="Tipo Domanda" 
                      id="tipo_richiesta_id" 
                      name="tipo_richiesta_id" 
                      placeholder="Seleziona Tipo Domanda"
                      options={tipiRichiesta} 
                      isClearable={true}
                      defaultValue={ tipiRichiesta.filter(c => c.value == richiesta.tipo_richiesta_id) } 
                      isDisabled={readOnly}
                    />    
                  </div>
                  
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect 
                      label="Tipo Durata" 
                      id="tipo_durata_id" 
                      name="tipo_durata_id" 
                      placeholder="Seleziona Tipo Durata"
                      options={tipiDurata} 
                      isClearable={true}
                      defaultValue={ tipiDurata.filter(c => c.value == richiesta.tipo_durata_id) }
                      isDisabled={readOnly} 
                    />
                  
                </div>
                
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Tipo Utilizzo Concesso'
                      id='descrizione_uso'
                      type='text'
                      name='descrizione_uso'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Tipo Utilizzo Concesso'   
                      maxLength = '255'
                    />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Data Domanda'
                      id='data_richiesta'
                      type='date'
                      name='data_richiesta'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Durata Concessione'   
                      maxLength = '255'
                    />
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput
                      label='Durata Anni'
                      id='durata_anni'
                      type='text'
                      name='durata_anni'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Durata Anni'   
                      maxLength = '255'
                    />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    < SicondirInput
                      label='Durata Mesi'
                      id='durata_mesi'
                      type='text'
                      name='durata_mesi'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Durata Mesi'   
                      maxLength = '255'
                    />
                  </div>
                 
                  <div className="col-4 md-12 sm-12">
                    < SicondirInput
                      label='Durata Giorni'
                      id='durata_giorni'
                      type='text'
                      name='durata_giorni'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Durata Giorni'   
                      maxLength = '255'
                    />
                  </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 md-12 sm-12">
                    <SicondirTextarea
                      label='Note'
                      id='note'
                      type='textarea'
                      style={{height: "150px", width: "100%"}}
                      name='note'
                      size="large" 
                    />
                  </div>
                </div>
                <div className="row">
                    <div className="col-4 md-12 sm-12">
                      <SicondirSelect
                        label='Stato Import'
                        id='stato_import'                
                        name='stato_import'              
                        options={opzioniStatoImport} 
                        isClearable={true}
                        defaultValue={statoImport} 
                        placeholder = 'Stato Import'
                        onChange={handleStatoImport}
                        isDisabled={readOnly}
                      />           
                    </div>
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput readOnly
                        label='Consolidato in data'
                        id='data_consolidazione'
                        type='date'
                        name='data_consolidazione'                   
                        size="large" 
                        placeholder = 'Data Consolidazione'                
                      />
                    </div>
                  </div>

            <div className="col-12 mt-5 row justify-content-center">
                <button type="button" className="btn btn-primary col-5" 
                onClick={handleSubmitForm} 
                disabled={!(isValid && dirty && !isSubmitting)}>
                Salva
                </button>
            </div>
            </fieldset>
        </Form>
        )}
        </Formik>
        <>
        <br /> <br />
        <hr></hr>
        <h5>Concessione associata</h5>
        { !concessione
            ?
            <div className="row">
              <div className="col-12 mt-5 row justify-content-center">
                  Nessuna concessione associata alla domanda
              </div>
              <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModal} disabled={readOnly}>
                  Associa Concessione
                </button>
              </div>
              {(richiesta.tipo_ambito_id == 1 || richiesta.tipo_ambito_id == 4) && 
              <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleGeneraConcessione} disabled={!readOnly}>
                  Genera Concessione Da Associare
                </button>
              </div>
              }
              </div>
            : 
            <>
              <SicondirTable 
                headers={[
                  "Tipo ambito",
                  "Codice sicondir",
                  "Codice sistema precedente",
                  "Richiedente/ Intestatario",
                  "Codice fiscale",
                  "Partita IVA", 
                  "Stato",
                  ""
                  ]} 
                data={list}
                dropdown={true}
              />         
            </>
        }
        <hr></hr>
        { props.macroambito == "F" ?
          <>
          <h5>Occupazione associata</h5>
          { !occupazione
              ?
              <div className="row">
                <div className="col-12 mt-5 row justify-content-center">
                    Nessuna occupazione associata alla domanda
                </div>
                <div className="col-12 mt-5 row justify-content-center">
                  <button  className="btn btn-primary col-5" onClick={handleModalOccupazione} disabled={readOnly}>
                    Associa Occupazione
                  </button>
                </div>
                </div>
              : 
              <>
                <SicondirTable 
                  headers={[
                    "Tipo ambito",
                    "Codice sicondir",
                    "Codice sistema precedente",
                    "Richiedente/ Intestatario",
                    "Codice fiscale",
                    "Partita IVA", 
                    "Stato",
                    ""
                    ]} 
                  data={listOccupazioni}
                  dropdown={true}
                />         
              </>
          }
          </>
          :
          <>
          <h5>Denuncia pozzo associata</h5>
          { !denunciaPozzo
              ?
              <div className="row">
                <div className="col-12 mt-5 row justify-content-center">
                    Nessuna denuncia pozzo associata alla domanda
                </div>
                <div className="col-12 mt-5 row justify-content-center">
                  <button  className="btn btn-primary col-5" onClick={handleModalDenunciaPozzo} disabled={readOnly}>
                    Associa Denuncia Pozzo
                  </button>
                </div>
                </div>
              : 
              <>
                <SicondirTable 
                  headers={[
                    "Tipo ambito",
                    "Codice sicondir",
                    "Codice sistema precedente",
                    "Richiedente/ Intestatario",
                    "Codice fiscale",
                    "Partita IVA", 
                    "Stato",
                    ""
                    ]} 
                  data={listDenuncePozzo}
                  dropdown={true}
                />         
              </>
          }
          </>
        }
        </>
        </>
        :
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>
        }

        <Modal 
                dialogClassName={'modal-fullpage'}
                show={show} 
                onHide={handleClose}>
				  <Modal.Header closeButton>
				    <Modal.Title >
              Ricerca Concessione
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <ConcessioniRicerca contesto='richieste' richiestaId={richiestaId}
                handleClose={handleClose}
                setAssociazione={setAssociazione}
                macroambito={props.richiesta.macroarea_ambito}/>
          </Modal.Body>
        </Modal>          

        <Modal 
              dialogClassName={'modal-fullpage'}
              show={showOccupazione} 
              onHide={handleCloseOccupazione}>
				  <Modal.Header closeButton>
				    <Modal.Title >
              Ricerca Occupazione
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <OccupazioniRicerca contesto='richieste' richiestaId={richiestaId}
                handleClose={handleCloseOccupazione}
                setAssociazione={setAssociazioneOccupazione}
                macroambito={props.richiesta.macroarea_ambito}/>
          </Modal.Body>
        </Modal>

        <Modal 
              dialogClassName={'modal-fullpage'}
              show={showDenunciaPozzo} 
              onHide={handleCloseDenunciaPozzo}>
				  <Modal.Header closeButton>
				    <Modal.Title >
              Ricerca Denuncia Pozzo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <DenuncePozzoRicerca contesto='richieste' richiestaId={richiestaId}
                handleClose={handleCloseDenunciaPozzo}
                setAssociazione={setAssociazioneDenunciaPozzo}
                macroambito={props.richiesta.macroarea_ambito}/>
          </Modal.Body>
        </Modal>
      </>
    )
}

export default RichiestaForm;
