import moment from 'moment';
import queryString from 'query-string';

/**
 * @component
 *
 * Formattatori comuni
 */
export default {
  /**
   * Formatta una data
   * Ritorna stringa vuota se non riesce a riconoscere la data di partenza
   * @param  {string} date   Data da formattare
   * @param  {string} format Formato
   * @param  {string} base   Formato data passata
   * @return {string}        Data formattata o stringa vuota
   *
   * @public
   */
  formatDate: (date, format, base = "") => {
    try {
      let start_date = (base) ? moment(date, base) : moment(date);
      return (start_date.isValid()) ? start_date.format(format) : "";
    } catch (e) {
      return ""
    }
  },
  /**
   * Formatta indirizzo immunizzazioni che è diverso da quello del paziente
   *
   * Condizionale per streetlines formattati snake case e camel case a seconda dei casi
   *
   * @param  {object} obj Oggetto con i valori
   * @return {string}     Stringa formattata
   *
   * @public
   */
  formatAddressCommon: (obj) => {
    var street_lines = "";
    if (obj.streetLines) obj.streetLines.forEach(line => {
      street_lines += line + " ";
    })

    if (obj.street_lines) obj.street_lines.forEach(line => {
      street_lines += line + " ";
    })
    return street_lines + " " + obj.city + " (" + obj.state + ") " + obj.zip + " " + obj.country;
  },
  /**
   * Prende jwt e restituisce i valori
   * @param  {string} token Token
   * @return {object}       Oggetto con informazioni utenti
   */
  parseJwt: (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  },
  /**
   * Formatta le date per creare un nuovo oggetto moment
   */
  replaceFormDatesIn: (date_fields = [], values0 = {}, date_expected_format = "YYYY-MM-DD") => {
    var values = Object.assign({}, values0);
    Object.keys(values).forEach(k => {
      if (date_fields.indexOf(k) !== -1) {
        if (moment(values[k], date_expected_format).isValid()) {
          values[k] = moment(values[k], date_expected_format)
        } else {
          delete values[k];
        }
      }
    })
    return values;
  },
  /**
   * formatta le date per creare una stringa formattata da inviare al server
   */
  replaceFormDatesOut: (date_fields = [], values = {}, date_format = "YYYY-MM-DD") => {
    var nvalues = Object.assign({}, values);
    Object.keys(nvalues).forEach(k => {
      if (date_fields.indexOf(k) !== -1) {
        if (moment(values[k]).isValid()) {
          nvalues[k] = moment(values[k]).format(date_format)
        } else {
          nvalues[k] = ""
        }
      }
    })

    return nvalues;
  },
  /**
   * Replace integer with strings
   */
  replaceIntegeresWithString: (int_fields = [], values = null) => {
    if (values) {
      Object.keys(values).forEach(k => {
        if (int_fields.indexOf(k) !== -1) {
          values[k] = (values[k] || parseInt(values[k]) === 0) ? "" + values[k] : null;
        }
      });
    } else { values = {} }
    return values
  },

  /**
     * Replace integer with strings
     */
  replaceStringWithIntegeres: (str_fields = [], values = null) => {
    if (values) {
      Object.keys(values).forEach(k => {
        if (str_fields.indexOf(k) !== -1) {
          values[k] = parseInt(values[k]);
        }
      });
    } else { values = {} }
    return values
  },

  replaceArrayToString: (array_fields = [], values = null, separator = ', ') => {
    if (values) {
      Object.keys(values).forEach(k => {
        if (array_fields.indexOf(k) !== -1) {
          values[k] = (values[k] && values[k].length > 0) ? values[k].join(separator) : "";
        }
      });
    } else { values = {} }
    return values
  },

  /**
   * Replace boolean values
   */
  convertBoolean: (boolean_fields = [], values = null) => {
    if (values) {
      Object.keys(values).forEach(k => {
        if (boolean_fields.indexOf(k) !== -1) {
          // eslint-disable-next-line eqeqeq
          values[k] = values[k] == true  ? String(1) : String(0);
        }
      });
    } else { values = {} }
    return values
  },

   /**
   * Revert boolean values
   */
  revertBoolean: (string_fields = [], values = null) => {
    if (values) {
      Object.keys(values).forEach(k => {
        if (string_fields.indexOf(k) !== -1) {
          // eslint-disable-next-line eqeqeq
          values[k] = values[k] == '1'  ? true : false;
        }
      });
    } else { values = {} }
    return values
  }, 


  convertMultipleInString: (multiple_fields = [], values = null) => {
    if (values) {
      Object.keys(values).forEach(k => {
        if (multiple_fields.indexOf(k) !== -1) {
          if (Array.isArray(values[k])) {
          } else {
            values[k] = (values[k] && values[k] !== '') ? values[k].split("|||") : [];
          }
        }
      });
    } else {
      multiple_fields.forEach(ff => {
        values[ff] = [];
      })
    }
    return values
  },

  convertMultipleOutString: (multiple_fields = [], values = null) => {
    if (values) {
      Object.keys(values).forEach(k => {
        if (multiple_fields.indexOf(k) !== -1) {
          values[k] = (values[k].length > 0) ? values[k].join("|||") : "";
        }
      });
    } else { values = {} }
    return values
  },

  /**
   * Replace boolean single value
   */
  convertSingleBoolean: (value) => {
    var newValue = null;
    if (value) {
      newValue = (value === true) ? String(1) : String(0);
    }
    return newValue
  },

  replaceStub: (value, stub, store) => {
    try {
      const stubResponse = store.stubs[stub].find(el => el.value === parseInt(value));
      if(typeof stubResponse !== 'undefined'){
        return stubResponse.label
      }else{
        return "";
      }
    } catch (e) {
      console.error(e);
      return ""
    }
  },

  getIndirizzo: (indirizzo, store) => {
    let base_str = indirizzo.via + " " + indirizzo.ncivico + ", " + indirizzo.cap;
    if (indirizzo.stato !== 1) {
      base_str += " (" + store.stubs['nazione'].find(el => el.value === parseInt(indirizzo.stato)).label + ")"
    } else {
      try {
        base_str += " (" + indirizzo.locComune.comune + ")";
      } catch (e) {
        console.error(e);
      }
    }

    return base_str;
  },

  formatSortChange: (props, baseUrl, obj) => {
    try {
      let q_s = queryString.parse(props.location.search);

      if (obj.sort && q_s.sort && q_s.sort === obj.sort) {
        q_s.sort = q_s.sort[0] === "" ? q_s.sort.replace("-", "") : "-" + q_s.sort;
      } else {
        q_s = { ...q_s, ...obj }
      }
      props.history.push(baseUrl + '?' + queryString.stringify(q_s));
    } catch (e) {
      //console.log('errore cambio url',e);
    }
  },

  getFilterParam: (props, defaultSortKey = null) => {
    let params = queryString.parse(props);

    let sortKey = defaultSortKey;
    let direction = 'ascend';
    if (params.sort) {
      direction = params.sort[0] === '-' ? 'descend' : 'ascend';
      sortKey = params.sort[0] === '-' ? params.sort.substring(1) : params.sort;
    } else {
      params.sort = defaultSortKey;
    }

    return { params: params, pager: { key: sortKey, order: direction } };
  },

  getResponse: (res) => {
    return {
      data: res.data.data?.list || res.data.data,
      current: res.data.data?._meta?.currentPage || parseInt(res.headers['x-pagination-current-page']),
      total: res.data.data?._meta?.totalCount || parseInt(res.headers['x-pagination-total-count']),
    };
  },


  /*
  Poiché il dataService con i campi nulli ritorna come valore
  un oggetto di tipo 
  {
    "@nil": "true"
  }

  Quando trovo questo tipo di oggetto, setto il campo a null...
  */
  cleanObject: (object) => {
    Object.keys(object).forEach(function(key) {
      if(typeof object[key] === 'object'
        && object[key] != null
        && "@nil" in object[key]
        && object[key]["@nil"] == "true"
      ) { 
        object[key] = null;
      }
    })
    return object;
  },

  makeArray: (object) => {
    if(!Array.isArray(object)) {
      // object è un oggetto
      let arr = []
      arr.push(object)
      return arr
    } else {
      // object è un array
      return object
    }
  },


  // Funzione incompleta, work in progress... Dobbiamo capire come gestire le actions dei pulsanti della tabella.
  prepareForTable: (object, fields, actions) => {

    const cleanObject = (object) => {
      Object.keys(object).forEach(function(key) {
        if(typeof object[key] === 'object'
          && object[key] != null
          && "@nil" in object[key]
          && object[key]["@nil"] == "true"
        ) { 
          object[key] = null;
        }
      })
      return object;
    }

    let list = []; // inizializzo la lista da dare in pasto alla SicondirTable
    let i = 0;
    
    if(Array.isArray(object)){
      object.map(element => {
        element = cleanObject(element) // ripulisco element dai campi @nil:true
        let entry = {}
        fields.forEach(key => entry[key] = null); // inizializzo l'oggetto da mettere nella lista fatto dai campi passati come parametro
        
        Object.keys(entry).map(function(key) {
          entry[key] = element[key];
        });

        entry["app_actions"] = actions
        list[i] = entry
        
        i++
      })
    } else{
      let entry = {}
      let element = cleanObject(object)
      fields.forEach(key => entry[key] = null);
      Object.keys(entry).map(function(key) {
        entry[key] = element[key];
      });
      entry["app_actions"] = actions
      list[0] = entry
    }
    return list
  },

  escapeSql: (str) => {
    str=String(str)
    if(str.includes("'") || str.includes('"')){
      return str.replace(/'/g, "\\'").replace(/"/g, '\\"');
    }
    
    return str
  },

  formatNumber: (number, form=false) =>{
    if(number != null){
      let n=parseFloat(number).toLocaleString('it-IT', {minimumFractionDigits:2, maximumFractionDigits:2});
      return n
    } else{
      /* Il parametro flag "form" significa: sto usando questo number per un campo di testo di un form? Se sì allora, non devo visualizzare un "-", 
        ma stringa vuota.
        Questo serve in particolare per i form di modifica i cui oggetti hanno dei valori vuoti/nulli, ad esempio la produzione nominale dei beni demaniali.
      */
      if(form) return ""
      return "-"
    }
  }
}