import React from 'react'
import { useNavigate } from 'react-router-dom';
import utilsAnagrafica from "./utilsAnagrafica";
import { Formik, Field, Form, useFormik } from "formik";
import { useEffect, useState } from 'react'
import alertify from "alertifyjs";
import SicondirInput from "../components/SicondirInput";
import SicondirSelect from "../components/SicondirSelect";
import Comuni from '../../_data/comuni.json'
import Nazioni from '../../_data/nazioni.json'
import tipiRuoli from '../../_data/tipi_ruoli_frontend.json'
import formatter from '../utils/formatters/_' 
import _ from 'lodash'  
import SchedaIndirizzo from "../components/SchedaIndirizzo";
import SchedaContatto from "../components/SchedaContatto";
import TastiIndirizzoContatto from '../components/TastiIndirizzoContatto'
import * as Yup from 'yup'
import tipiFormaGiuridicaOptions from '../../_data/tipi_forme_giuridiche.json';
import Modal from 'react-bootstrap/Modal';
import PersonaGiuridicaRicercaForm from './PersonaGiuridicaRicercaForm';
import SicondirTable from "./../components/SicondirTable";

const AnagraficaFormEdit = (props) => {
  
  let validationSchemaPf= Yup.object().shape({
    nome: Yup.string()
    .max(40, "Il nome non può avere più di 40 caratteri")
    .min(2, "Il nome deve avere almeno 2 caratteri")
    .required("Il nome è obbligatorio")
    .nullable(), 
    cognome: Yup.string()
    .max(40, "Il cognome non può avere più di 40 caratteri")
    .min(2, "Il cognome deve avere almeno 2 caratteri")
    .required("Il cognome è obbligatorio")
    .nullable(),
    codice_fiscale: Yup.string()
    .max(16, "Il codice fiscale non può avere più di 16 caratteri")
    .min(16, "Il codice fiscale non può avere meno di 16 caratteri")
    .required("Il codice fiscale è obbligatorio")
    .nullable(),
    })
    
  let validationSchemaPg = Yup.object().shape({
    ragione_sociale: Yup.string()
    .max(150, "La ragione sociale non può avere più di 150 caratteri")
    .min(2, "La ragione sociale deve avere almeno 2 caratteri")
    .required("La ragione sociale è obbligatoria")
    .nullable(),
    piva: Yup.string()
    .max(11, "La partita iva non può avere più di 11 caratteri")
    .min(11, "La partita iva non può avere meno di 11 caratteri")
    .required("La partita iva è obbligatoria")
    .nullable(),
    })

  const [valueNaturaGiuridica, setValueNaturaGiuridica] = useState(
  props.persona.tipo_persona=='PF'?{
    "value": "PF",
    "label": "Persona Fisica"
  }:
  {
    "value": "PG",
    "label": "Persona Giuridica"
  }
  );

  const [ragione_sociale, setRagione_sociale] = useState(props.persona.ragione_sociale);
  const [oldValue, setOldValue] = useState(props.persona);
  const [codicefiscale, setCodicefiscale] = useState(props.persona.codice_fiscale);
  const [comunenascita, setComunenascita] = useState(oldValue.comune_nascita);
  const [provincianascita, setProvincianascita] = useState();
  const [nazionenascita, setNazionenascita] = useState('');
  const [datanascita, setDatanascita] = useState('');
  const [genere, setGenere] = useState('');
  const [estero,setEstero] = useState('');
  const [inizializzato, setInizializzato] = useState(false)
  const [ruoli, setRuoli] = useState(props.ruoli)
  const [ruoliSelected, setRuoliSelected] = useState()
  const [ruolicheck, setRuolicheck] = useState(true)
  const [indirizzi, setIndirizzi] = useState({data: []})
  const [loading, setLoading] = useState(false)
  const [contatti, setContatti] = useState({data: []})
  const [show, setShow] = useState(false);
  const [indirizzoCambiato, setIndirizzoCambiato] = useState(true);
  const [contattoCambiato, setContattoCambiato] = useState(true);

  const [showPersoneGiuridiche, setShowPersoneGiuridiche] = useState(false);
  
  const tipoPersonaOptions = [ {
    "value": "PF",
    "label": "Persona Fisica"
    }, 
    {
      "value": "PG",
      "label": "Persona giuridica"
    }]

    const [showCampiPF, setShowCampiPF] = useState(
      props.persona.tipo_persona==='PG'?'d-none':'d-inline-block'
    )
    const [showCampiPG, setShowCampiPG] = useState(
      props.persona.tipo_persona==='PF'?'d-none':'d-inline-block'
    )
    const [labelRagioneSociale, setLabelRagioneSociale] = useState(
      props.persona.tipo_persona==='PG'?'Ragione sociale':''
    )
    const [labelCognome, setLabelCognome] = useState(
      props.persona.tipo_persona==='PF'?'Cognome':''
    )
    const [labelNome, setLabelNome] = useState(
      props.persona.tipo_persona==='PF'?'Nome':''
    )
    const [labelComuneNascita, setLabelComuneNascita] = useState(
      props.persona.tipo_persona==='PF'?'Comune nascita':''
    )
    const [labelProvinciaNascita, setLabelProvinciaNascita] = useState(
      props.persona.tipo_persona==='PF'?'Provincia nascita':''
    )
    const [labelDataNascita, setLabelDataNascita] = useState(
      props.persona.tipo_persona==='PF'?'Data nascita':''
    )
    const [labelNazioneNascita, setLabelNazioneNascita] = useState(
      props.persona.tipo_persona==='PF'?'Nazione nascita':''
    )
    const [labelGenere, setLabelGenere] = useState(
      props.persona.tipo_persona==='PF'?'Genere':''
    ) 
    const [labelPartitaIva, setLabelPartitaIva] = useState(
      props.persona.tipo_persona==='PG'?'Partita Iva':''
    )
  
    const [valueRagioneSociale, setValueRagioneSociale] = useState(
      props.persona.tipo_persona==='PF'?'':props.persona.ragione_sociale
    )
    const [valueCognome, setValueCognome] = useState(
      props.persona.tipo_persona==='PF'?props.persona.cognome:''
    )
    const [valueNome, setValueNome] = useState(
      props.persona.tipo_persona==='PF'?props.persona.nome:''
    )
    
    const [labelNaturaGiuridica, setLabelNaturaGiuridica] = useState(
      props.persona.tipo_persona==='PG'?'Natura giuridica':''
    )

    const [validationSchema, setValidationSchema] = useState(
      (props.persona.tipo_persona == 'PF')
        ?
          validationSchemaPf
        :
          validationSchemaPg
    )
    const [cambioTipoPersona, setCambioTipoPersona] = useState(false)
    
    const showRicercaPersoneGiuridiche = () =>{
      setShowPersoneGiuridiche(true)
    }
  
    const addRuolo =() => {
      setLoadingRuoli(true)
      let list = listRuoliSelezionati;
      let actions = buildActionsRuoli(ruoloSelezionato);
      let element = {
        id: ruoloSelezionato.value,
        label: ruoloSelezionato.label,
        pgAssociata: ruoloSelezionato.pgAssociata,
        app_actions: actions
      }
      list.push(element);
      setListRuoliSelezionati(list);
      let selezionabili = tipiRuoliSelezionabili.filter((r) => r.value != ruoloSelezionato.value);
      setTipiRuoliSelezionabili(selezionabili);
      setRuoloSelezionato(null)
      setRuoliModificati(true)
      setLoadingRuoli(false)
  }

  const removeRuolo = (ruolo) => {
    setRuoloEliminato(ruolo)
    setRuoliModificati(true)
  }


    const buildActionsRuoli = (ruoloSelezionato) => {
      let result = {};
      result = { elimina: {
              kind: "function", 
              function: removeRuolo,
              parameters: {ruolo: ruoloSelezionato},
              buttonClass: "btn-danger",
              linkClass: "text-danger",
              label: "Rimuovi ruolo",
              confirmLabel: "Procedere con la rimozione del ruolo per la persona?"}
      }

      if(ruoloSelezionato.codice == "RAPLEG"){
        result["aggiungiPersonaGiuridica"] = {
              kind: "function", 
              function: showRicercaPersoneGiuridiche,
              parameters: {ruolo: ruoloSelezionato},
              label: "Seleziona persona giuridica"
              }
        }
      return result;
  }

    let ruoliSelezionabili = tipiRuoli;
    let listaRuoliPresenti = []
    let ruoloLr = null
    let pgPresenteOggetto = null
    if(props.ruoli.length > 0){
      props.ruoli.map(ruolo => {
        ruoliSelezionabili = ruoliSelezionabili.filter(r => r.value != ruolo.id) // tolgo i ruoli già presenti
        if(ruolo.id == 4 || ruolo.id == 5 || ruolo.id == 6 || ruolo.id == 7){
          let ruoloEliminabileArray = tipiRuoli.filter(k => k.value == ruolo.id)
          let actions = buildActionsRuoli(ruoloEliminabileArray[0])

          let pgPresente = null
          if(ruolo.id == 7){
            pgPresente =  (ruolo.persona_giuridica_ragione_sociale || "-") + " ("+ (ruolo.piva || "-") + " / " + (ruolo.codice_sicer || "-") + ")";
            pgPresenteOggetto ={
              id: ruolo.persona_giuridica_id,
              ragione_sociale: ruolo.persona_giuridica_ragione_sociale,
              piva: ruolo.piva,
              codice_sicer: ruolo.codice_sicer
            }
          }  

          let element = {
            id: ruolo.id,
            label: ruolo.descrizione,
            pgAssociata: pgPresente,
            app_actions: actions
          }
          listaRuoliPresenti.push(element)
        }
      })
    }
    if(props.persona.tipo_persona == "PG"){
      ruoliSelezionabili = ruoliSelezionabili.filter(r => r.value != 7) // tolgo legale rappresentante se sto gestendo una PG
    }
    const [tipiRuoliSelezionabili, setTipiRuoliSelezionabili] = useState(ruoliSelezionabili)
    const [ruoloSelezionato, setRuoloSelezionato] = useState(null);

    const [listRuoliSelezionati, setListRuoliSelezionati] = useState(listaRuoliPresenti);
    const [ruoloEliminato, setRuoloEliminato] = useState(null);
    const [loadingRuoli, setLoadingRuoli] = useState(false)
    const [ruoliModificati, setRuoliModificati] = useState(false)
    // TODO: al momento consideriamo solo una persona giuridica per un LR... Questi campi dovranno diventare array.

    const [pgAssociata, setPgAssociata] = useState(pgPresenteOggetto);
    const [idPgAssociata, setIdPgAssociata] = useState(ruoloLr && ruoloLr.persona_giuridica_id)

    useEffect(()=>{
      if(pgAssociata != null){
        setLoading(true)
        let list = listRuoliSelezionati;
        setIdPgAssociata(pgAssociata.id);
        let listAggiornata = []
        list.map((ruolo) => {
          if(ruolo.label == 'Rappresentante Legale'){
            ruolo["pgAssociata"] =  (pgAssociata.ragione_sociale || "-") + " ("+ (pgAssociata.piva || "-") + " / " + (pgAssociata.codice_sicer || "-") + ")";
          }  
          listAggiornata.push(ruolo)
        })
        setListRuoliSelezionati(listAggiornata)
        setShowPersoneGiuridiche(false)
        setLoading(false)
      }
    }, [pgAssociata])

  useEffect(()=>{
    if(ruoloEliminato != null){
      setLoadingRuoli(true)
      setRuoliModificati(true);
      if(ruoloEliminato.label == 'Rappresentante Legale') setIdPgAssociata(null);
      let list = listRuoliSelezionati.filter((r) =>  r.id !== ruoloEliminato.value)
      setListRuoliSelezionati(list)
      let selezionabili = tipiRuoliSelezionabili;
      selezionabili.push(ruoloEliminato);
      setTipiRuoliSelezionabili(selezionabili);
      setRuoloEliminato(null)
      setLoadingRuoli(false)
    }
  }, [ruoloEliminato])

  const associaPersonaGiuridica = (personaGiuridica) => {
    setPgAssociata(personaGiuridica)
    setRuoliModificati(true)
  }
    const valoriIniziali = {
      tipo_persona: valueNaturaGiuridica.value,
      ragione_sociale: valueRagioneSociale,
      codice_sicer: oldValue.codice_sicer,
      codice_fiscale: codicefiscale,
      forma_giuridica_id: {},
      piva: oldValue.piva,
      cognome: valueCognome,
      nome: valueNome,
      comune_nascita: comunenascita,
      provincia_nascita: provincianascita,
      nazione_nascita: nazionenascita,
      data_nascita: datanascita,
      genere: genere,
      ruoli: ruoliSelected,
    }

  const handleModal = () => {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
  } 
  const fetchDataIndirizzi = async () => {
    try {
      setLoading(true)
      
      // INDIRIZZI
      // const whereIndirizzi = "AND persona_id="+props.persona.id
      let urlApiIndirizzi = `persone/indirizzi/${encodeURI(props.persona.id)}`
      const responseIndirizzi = await fetch(process.env.REACT_APP_API_URL + urlApiIndirizzi, 
      {
        method: 'GET',
        headers: { accept:'application/json' }, 
        mode: 'cors',  
      })
      const jsonindirizzi = await responseIndirizzi.json()
      if(jsonindirizzi.risposta) {
        setIndirizzi(
          formatter.cleanObject(
            formatter.makeArray(jsonindirizzi.risposta.persone_indirizzi)
          )
        )
      } else
        setIndirizzi([])
    } catch (error) {
      alertify.error("Errore durante il recupero degli indirizzi.");
      console.log("errore: ", error);
    }
    setLoading(false)
    setIndirizzoCambiato(false)
  }
  const fetchDataContatti = async () => {
    try {
      setLoading(true)
      
      // CONTATTI
      // const whereContatti = "AND persona_id="+props.persona.id
      let urlApiContatti = `persone/contatti/${props.persona.id}`
      const responseContatti = await fetch(process.env.REACT_APP_API_URL + urlApiContatti, 
      {
        method: 'GET',
        headers: { accept:'application/json' }, 
        mode: 'cors',  
      })
      const jsoncontatti = await responseContatti.json()
      if(jsoncontatti.risposta) {
        setContatti(
          formatter.cleanObject(
            formatter.makeArray(jsoncontatti.risposta.persone_contatti)
          )
        )
      } else
        setContatti([]);
    } catch (error) {
      alertify.error("Errore durante il recupero dei contatti.");
      console.log("errore: ", error);
    }
    setLoading(false)
    setContattoCambiato(false)
  }
  const comuneNascitaCf = (codiceFiscale) => { 
    if(codiceFiscale.length==16) {
      // let result={comune:undefined, nazione:undefined};
      const codCatastale = codiceFiscale.substring(11,15).toUpperCase()
      if(codiceFiscale.charAt(11).toUpperCase() !== 'Z') {
        setLabelComuneNascita('Comune nascita');
        const comunijson = JSON.stringify(Comuni, null, 4);
        const comuniobj = JSON.parse(comunijson);
        for(let key in comuniobj) {
          if(comuniobj[key].codiceCatastale==codCatastale) {
            setComunenascita(comuniobj[key].nome)
            setProvincianascita(comuniobj[key].sigla)
            setNazionenascita('Italia')
            break;
          }
        }
      }
      else {
        setEstero('Z')
        setLabelComuneNascita('Città di nascita');
        const nazionijson = JSON.stringify(Nazioni, null, 4);
        const nazioniobj = JSON.parse(nazionijson);
        for(let key in nazioniobj) {
          if(nazioniobj[key].italian_country_code==codCatastale) {
            setNazionenascita(nazioniobj[key].italian_country_name_1)
            break;
          }
        }
      }
      setDatanascita(dataNascitaCf(codiceFiscale))
      setGenere(sessoCf(codiceFiscale))
    }
  }
  const dataNascitaCf = (codiceFiscale) => {
    let [ anno, giorno ] = [ codiceFiscale.substring(6,8), codiceFiscale.substring(9,11) ];
    if (giorno>40) {
      giorno -= 40
      if(giorno<10){
        giorno = "0" + giorno
      }
    }
    return giorno +  "-" + utilsAnagrafica.Mesi[codiceFiscale.charAt(8).toUpperCase()] + "-" + (anno < 20 ? "20" : "19" ) + anno;
  }
  const sessoCf = (codiceFiscale) => { 
    return codiceFiscale.substring(9,11) > 40 ? "F" : "M"; 
  }
  
  const onChangeCF = (codfiscale) => {
    if(codfiscale){
      setCodicefiscale(codfiscale.toUpperCase())
      if(codfiscale.length===16) {
        comuneNascitaCf(codfiscale)
        setGenere(sessoCf(codfiscale))
      }
    }
  }

  const navigate = useNavigate();

  const tornaRicerca = () => {
    navigate('/anagrafica');
  };
  
  const ruoli_inDb = (r) => {
    let arr_ruoli_inDb = []
    const entries = Object.values(r);
    entries.map((e) => {
      arr_ruoli_inDb.push(e)
    })
    const res = tipiRuoli
      .filter(r => inArray(r.label, arr_ruoli_inDb))
      .map(ruolo => {
        return {
          label: ruolo.label,
          value: ruolo.value
        };
      })
    return res;
  }
  
  /*
  useEffect(() => {
    if(!inizializzato) {
      setOldValue(props.persona);
      if (props.persona.tipo_persona == 'PF') {
        onChangeCF(props.persona.codice_fiscale)
        setInizializzato(true)
      } else {
        setRagione_sociale(props.persona.ragione_sociale);
      }
    }
  });

  
  
 useEffect(() => {
  setRuoliSelected(ruoli_inDb(props.ruoli))
  setRuolicheck(false)
 },[])
 */

 useEffect(() => {
  if(indirizzoCambiato) {
    setLoading(true)
    setIndirizzoCambiato(false)
    fetchDataIndirizzi();
  }
  if(contattoCambiato) {
    setLoading(true)
    setContattoCambiato(false)
    fetchDataContatti();
  }
},[indirizzoCambiato, contattoCambiato, fetchDataIndirizzi]);

 useEffect(() => {  
  if(valueNaturaGiuridica.value=='PF'){
    onChangeCF(codicefiscale)
  }
  if(!inizializzato) {
    setOldValue(props.persona);
    if (props.persona.tipo_persona == 'PF') {
      onChangeCF(props.persona.codice_fiscale)
      setInizializzato(true)
    } else {
      setRagione_sociale(props.persona.ragione_sociale);
    }
  }
  const ruoli_inDb = () => {
    let arr_ruoli_inDb = []
    const entries = Object.values(props.ruoli);
    entries.map((e) => {
      arr_ruoli_inDb.push(e)
  })

  const res = tipiRuoli
    .filter(r => inArray(r.label, arr_ruoli_inDb))
    .map(ruolo => {
      return {
        label: ruolo.label,
        value: ruolo.value
      };
    })
    return res;
  }

  if (props.ruoli.length > 0 && Object.entries(props.ruoli)[0][0] != 'data' && ruolicheck) {
    const ruoliselezionati = ruoli_inDb()
    setRuoliSelected(ruoliselezionati)
    setRuolicheck(false)
  }
 });

  function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
      if(typeof haystack[i] == 'object') {
        if(haystack[i].descrizione==needle) return true;
      } else {
        if (haystack[i] == needle) return true;
      }
    }
    return false;
  }
  /*
  const handleChangeRuoli = (e) => {
    setRuoliSelected(e)
  }
  */

  const handleChangeRuoli =(event) => {
    setRuoloSelezionato(event);
  }

  
  const handleCloseRuoli = () => {
    setShowPersoneGiuridiche(false)
  }

  const handleChangeTipoPersona = (e) => {
    setCambioTipoPersona(true)
    
    setValueNaturaGiuridica(e)
    if(e.value == 'PF'){
      setValidationSchema(validationSchemaPf)
    } else{
      setValidationSchema(validationSchemaPg)
    }
    setLabelRagioneSociale(e.value=='PF'?'':'Ragione sociale')
    setLabelCognome(e.value=='PF'?'Cognome':'')
    setLabelNome(e.value=='PF'?'Nome':'')
    setLabelComuneNascita(e.value=='PF'?'Comune nascita':'')
    setLabelProvinciaNascita(e.value=='PF'?'Provincia nascita':'')
    setLabelDataNascita(e.value=='PF'?'Data nascita':'')
    setLabelNazioneNascita(e.value=='PF'?'Nazione nascita':'')
    setLabelGenere(e.value=='PF'?'Genere':'')
    setLabelPartitaIva(e.value=='PF'?'':'Partita Iva')
    setShowCampiPG(e.value=='PF'?'d-none':'d-inline-block')
    setShowCampiPF(e.value=='PG'?'d-none':'d-inline-block')
    
    setValueNome(props.persona.nome)
    setValueNaturaGiuridica(e)
    
  }

  useEffect(() => {
    setLoading(true)
    if(cambioTipoPersona) {
      if(valueNaturaGiuridica.value=='PG') {
        setValueRagioneSociale(
          (props.persona.tipo_persona == 'PG')
          ?props.persona.ragione_sociale
          :(props.persona.nome+' '+props.persona.cognome)
        )

        setValueCognome(
          (props.persona.tipo_persona == 'PF')
          ?props.persona.cognome
          :props.persona.ragione_sociale
        )
      }
      if(valueNaturaGiuridica.value=='PF'){
        setValueRagioneSociale(props.persona.ragione_sociale)
        setValueCognome(
          (props.persona.tipo_persona == 'PG')
          ?props.persona.cognome
          :props.persona.ragione_sociale
        )
      }
      setCambioTipoPersona(false)
    }
    setLoading(false)
   }, [cambioTipoPersona])

  return (
    <>
    {loading?
      <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
      </>
    :
      <>   
        <Formik 
          id='anagrafica' 
          className="p-4"
          enableReinitialize={true}
          initialValues={valoriIniziali}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {

            let pgInserita = true;
            listRuoliSelezionati.map((r) =>{
              if(r.label == 'Rappresentante Legale' && r.pgAssociata == null){
                pgInserita = false
              }
            })

            if(!pgInserita) {
              alertify.warning("Specificare la persona giuridica per il ruolo di rappresentante legale");
              return 
            } 

            setSubmitting(true);
            if (values["tipo_persona"] == 'PF') {
              delete values["piva"];
              delete values["ragione_sociale"];
              delete values["forma_giuridica_id"]
            } else {
              delete values["nome"];
              delete values["cognome"];
              delete values["comune_nascita"];
              delete values["data_nascita"];
              delete values["genere"];
              delete values["nazione_nascita"];
              delete values["provincia_nascita"];
            }
            delete values["created_at"];
            delete values["data_consolidazione"];
            delete values["flag_deleted"];
            delete values["updated_at"];
            delete values["flag_utenza_disabilitata"];
            delete values["note"];
            delete values["stato_import"];
            delete values["username"];
            delete values["utente_modifica_id"];
            let campi = "";
            let listaCampi = "";
            let arr_listaValori = ""
            let campiIndirizzo = new Array()
            let valoriIndirizzo = new Array()
            let campiContatto = new Array()
            let valoriContatto = new Array()
            let i = 1

            Object.entries(values).map(([key, value], i) => {
              if (key == "ruoli") {
                listaCampi += "persona_id, tipo_ruolo_id";
                arr_listaValori = value;
              } else if(key == 'forma_giuridica_id'){
                let formaGiuridica = tipiFormaGiuridicaOptions.filter(c => c.value == value)
                // Calcolo il macroambito_demaniale che deve valere D o F
                if(typeof formaGiuridica[0] !== 'undefined'){
                  let idFormaGiuridica = formaGiuridica[0].value;
                  campi += "forma_giuridica_id="+idFormaGiuridica+", "
                }
              } else {
                if (value != "" && value != null) {
                  campi += key + "='" + formatter.escapeSql(value) + "', ";
                } else {
                  campi += key + "=NULL , ";
                }
              }
            });
            try {
              campi += "utente_modifica_id=" + localStorage.id;
              const payload = {
                nome_tabella: "persone",
                lista_campi_valore: campi,
                lista_clausole_where: "id=" + oldValue.id + " and flag_deleted=0"
              }
              const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
                body: JSON.stringify({ Entry: payload })
              })
              if(ruoliModificati){ // Se ho modificato i ruoli... 
                const payloadDelRuoli = {
                  persona_id: oldValue.id
                }

                /*
                const responseDelRuoli = await fetch(process.env.REACT_APP_API_URL + 'persone_ruoli/cancella', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  mode: 'cors',
                  body: JSON.stringify({ Entry: payloadDelRuoli })
                })
                */

                if(props.ruoli.length){
                  props.ruoli.map(async (el) => {
                    // preparo il payload
                    const payload = {
                      nome_tabella: "persone_ruoli",
                      lista_campi_valore: "flag_deleted=1",
                      lista_clausole_where: "id="+ el.persone_ruoli_id + " AND flag_deleted=0"
                    }
                    let found = listRuoliSelezionati.filter(r => r.id == el.id)
                    // Il ruolo 7 lo cancello sempre, tanto non posso mettere un altro ruolo 7 con la stessa persona giuridica, se ho messo un altro 7, ho per forza una nuova PG(al momento...)
                    if(found[0] === undefined || found[0].id == 7){ // Se NON trovo il vecchio ruolo anche nei nuovi, allora procedo a cancellarlo...
                      // Non dovrei usare MODIFICA, ma CANCELLA, però non lo posso usare al momento perché persone_ruoli non ha il campo utente_modifica_id.
                      const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                        method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
                        headers: { 'Content-Type': 'application/json',
                                    'Accept': 'application/json' }, 
                        mode: 'cors', 
                        body: JSON.stringify({Entry:payload})
                      })
                      const data = await response.json();

                      if(el.id == 7){ // Se è Rappresentante Legale togliamo anche la delega alla persona giuridica
                        let payloadDelega = {
                          nome_tabella: 'deleghe',
                          id: el.delega_id,
                          utente_modifica_id: localStorage.id
                        }
                        const responseRimuoviDelega = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' }, 
                          mode: 'cors', 
                          body: JSON.stringify({Entry:payloadDelega})
                        })
                      }
                    }
                  })
                }


                if(listRuoliSelezionati.length){
                  listRuoliSelezionati.map(async (el) => {
                    let found = props.ruoli.filter(r => r.id == el.id)
                    if(!found[0] || found[0].id == 7){
                      let payloadInsRuoli = {
                        nome_tabella: "persone_ruoli",
                        lista_campi: listaCampi,
                        lista_valori: oldValue.id + ", " + el.id
                      }
                      const responseInsRuoli = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' }, 
                        mode: 'cors', 
                        body: JSON.stringify({Entry:payloadInsRuoli})
                      })

                      const data2 = await responseInsRuoli.json();
                      const id_intestatario_inserito = data2.risposta.elementi_aggiunti.id 
    
                      if(el.id == 7){ // Se è Rappresentante Legale aggiungiamo anche l'associazione con l'altra persona...
                        let payloadDelega = {
                          nome_tabella: 'deleghe',
                          lista_campi: 'utente_modifica_id, persona_profilo_id, persona_giuridica_id',
                          lista_valori: localStorage.id + ',' + id_intestatario_inserito + ", " + idPgAssociata
                        }
                        const responseAssociaDelega = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' }, 
                          mode: 'cors', 
                          body: JSON.stringify({Entry:payloadDelega})
                        })
                      }
                    }
                  })

                  

                }
              }
              alertify.success("Dati persona aggiornati con successo.");
              // Per Claudio, aggiungere questo if: se provieni dalle richieste o dalle concessioni gestisci la chiusura del modal
              if (props.contesto != "anagrafica") {
                props.setModificaIntestatario(true)
                props.handleClose()
              }
            }
            catch (error) {
              alertify.error("Errore durante la modifica dei dati: " + error);
              console.log("errore: ", error);
            }
          }}
        >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
            touched,
            dirty,
        }) => (
          <Form>
            <SicondirSelect 
              label="Tipo persona"
              name="tipo_persona"
              id="tipo_persona"
              key='tipopersona'
              onChange={(e) => handleChangeTipoPersona(e)}
              options={tipoPersonaOptions} 
              value={valueNaturaGiuridica}
              className={'mb-3'}
              placeholder="Tipo persona"
            />
            <SicondirInput 
              label={labelNome}
              id='nome'
              type='text'
              name='nome'
              key='nome'
              value={values.nome || ''}
              mandatory= 'true'
              onChange={handleChange}
              className={`form-control mb-2 text-capitalize ${showCampiPF}`}
              size="large" 
              placeholder = 'Nome'   
              maxLength = '40'
            />
            
            <SicondirInput 
              label={labelCognome}
              id='cognome'
              type='text'
              name='cognome'
              key='cognome'
              value={values.cognome || ''}
              mandatory= 'true'
              onChange={handleChange}
              className={`form-control mb-2 text-capitalize ${showCampiPF}`}
              size="large" 
              placeholder = 'Cognome' 
              maxLength = '40'
            />
            
            <SicondirInput
              label={labelRagioneSociale}
              id='ragione_sociale'
              type='text'
              name='ragione_sociale'
              key='ragione_sociale'
              value={values.ragione_sociale || ''}
              mandatory= 'true'
              onChange={handleChange}
              className={`form-control mb-2 ${showCampiPG}`}
              size="large" 
              placeholder = 'Ragione sociale'
              maxLength = '150'
            />

            <SicondirSelect 
                label={labelNaturaGiuridica}
                name="forma_giuridica_id"
                id="forma_giuridica_id"
                key='formagiuridicaid'
                options={tipiFormaGiuridicaOptions}
                className={`mb-3 ${showCampiPG}`}
                defaultValue={tipiFormaGiuridicaOptions.filter(
                  (c) => c.value == props.persona.forma_giuridica_id
                )}
                placeholder="Natura giuridica"
            />
            
            <SicondirInput
              label='Codice Sicer'
              id='codice_sicer'
              type='text'
              name='codice_sicer'
              key='codice_sicer'
              value={values.codice_sicer || ''}
              onChange={handleChange}
              className='form-control mb-2'
              size="large" 
              placeholder = 'Codice Sicer'
              maxLength = '20'
            />
            
            <SicondirInput
              label='Codice fiscale'
              id='codice_fiscale'
              type='text'
              name='codice_fiscale'
              key='codice_fiscale'
              value={values.codice_fiscale || ''}
              onChange={handleChange}
              mandatory= {valueNaturaGiuridica.value==='PF' ? 'true' : 'false'}
              onBlur={()=> {onChangeCF(values.codice_fiscale)}}
              className='form-control mb-2 text-uppercase'
              size="large" 
              placeholder = 'Codice fiscale'
              maxLength = '16'
            />
            
            <SicondirInput
              label={labelComuneNascita}
              id='comune_nascita'
              type='text'
              name='comune_nascita'
              key='comune_nascita'
              value={comunenascita || ''}
              onChange={handleChange}
              className={`form-control mb-2 ${showCampiPF}`}
              size="large" 
              placeholder = {labelComuneNascita}
              maxLength = '40'
            />
            <SicondirInput
              label={labelProvinciaNascita}
              id='provincia_nascita'
              type='text'
              name='provincia_nascita'
              key='provincia_nascita'
              value={provincianascita || ''}
              className={`form-control mb-2 ${showCampiPF}`}
              size="large" 
              placeholder = 'Provincia nascita'
              maxLength = '2'
            />           
            <SicondirInput
              label={labelNazioneNascita}
              id='nazione_nascita'
              type='text'
              name='nazione_nascita'
              key='nazione_nascita'
              value={nazionenascita || ''}
              className={`form-control mb-2 ${showCampiPF}`}
              size="large" 
              placeholder = 'Nazione nascita'
              maxLength = '40'
            />
            <SicondirInput
              label={labelDataNascita}
              id='data_nascita'
              type='text'
              name='data_nascita'
              key='data_nascita'
              value={datanascita || ''}
              className={`form-control mb-2 ${showCampiPF}`}
              size="large" 
              placeholder = 'Data nascita'  
              maxLength = '10'
            />
            <SicondirInput
              label={labelGenere}
              id='genere'
              type='text'
              name='genere'
              key='genere'
              value={genere || ''}
              className={`form-control mb-2 ${showCampiPF}`}
              size="large" 
              placeholder = 'Sesso'
              maxLength = '1'
            />
            <SicondirInput
              label={labelPartitaIva}
              id='piva'
              type='text'
              name='piva'
              key='piva'
              value={values.piva || ''}
              mandatory= 'true'
              onChange={handleChange}
              className={`form-control mb-2 ${showCampiPG}`}
              size="large" 
              placeholder = 'Partita IVA' 
              maxLength = '11'
            />
            
            <div className="row">
                  <div className="col-6">
                    <SicondirSelect 
                      label="Ruoli"
                      name="ruoli"
                      id="ruoli"
                      options={tipiRuoliSelezionabili} 
                      placeholder="Seleziona ruolo"
                      isClearable={true}
                      onChange={handleChangeRuoli}
                      value={ruoloSelezionato} 
                    />
                    </div>
                    <div className="col-6">
                      <br />
                      <button onClick={() => addRuolo()} className="btn btn-primary justify-content-left" type='button'>
                          Aggiungi Ruolo
                      </button>
                    </div>
                </div>

                {!loadingRuoli
                  ?
                  listRuoliSelezionati.length > 0 && 

                  <SicondirTable 
                  headers={[
                    "Ruolo selezionato",
                    "Persona giuridica associata",
                    ""]} 
                  data={listRuoliSelezionati} 
                  dropdown={true}
                  />
                  
                  :
                  <>
                  <br />
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <div className="progress-spinner progress-spinner-active">
                      <span className="sr-only">Caricamento...</span>
                    </div>
                  </div>
                  </>
                }

            <div className="col-12 mt-5 mb-5 row justify-content-center">
              <button type="button" className="btn btn-primary col-5 mr-5 float-left"
                onClick={() => tornaRicerca(props.persona_trovata, props.setRicerca)}>
                  Torna alla ricerca
              </button>
              <button type="submit" className="btn btn-primary col-5">
                Salva modifiche
              </button>
            </div>
            {
            indirizzi.length
            ?
              <fieldset className="mt-3 p-3">
                <legend style={{'fontSize':'16px',  'fontWeight':'600'}}>
                  Indirizzi
                </legend>
               
                {
                  indirizzi.map((element, index) => (
                    <div className="form-inline border mt-2 p-2" key={index}>
                    {!loading?
                      <SchedaIndirizzo 
                        valueNaturaGiuridica={ props.persona.tipo_persona } 
                        indirizzo={ formatter.cleanObject(element) }  
                        handleModal={ handleModal }
                        setIndirizzoCambiato = { setIndirizzoCambiato }
                        id_persona = { props.persona.id }
                        setLoading = {setLoading}
                      />
                      : 
                      <>
                      <br />
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <div className="progress-spinner progress-spinner-active">
                          <span className="sr-only">Caricamento...</span>
                        </div>
                      </div>
                      </>
                      }
                    </div>
                  ))
                }
                
              </fieldset>
            :''
            }
       
            {
            contatti.length
            ?
              <fieldset className="mt-3 p-3">
                <legend style={{'fontSize':'16px',  'fontWeight':'600'}}>
                  Contatti
                </legend> 
                {contatti.map((element, index) => (
                  <div className="form-inline border mt-2 p-2" key={index}>
                    <SchedaContatto 
                      valueNaturaGiuridica = { props.persona.tipo_persona } 
                      contatto = { formatter.cleanObject(element) }  
                      handleModal = { handleModal }
                      setContattoCambiato = { setContattoCambiato }
                      id_persona = { props.persona.id }
                    />
                  </div>
                ))}
              </fieldset>
            :''
            }   
            <TastiIndirizzoContatto
              persona_id = { props.persona.id }
              valueNaturaGiuridica = { props.persona.tipo_persona }
              setIndirizzoCambiato = { setIndirizzoCambiato }
              setContattoCambiato = { setContattoCambiato }
            />
          
            
          </Form>
        )}
        </Formik>
          <Modal 
            size="xl" 
            show={showPersoneGiuridiche} 
            onHide={handleCloseRuoli}>
            <Modal.Header closeButton>
              <Modal.Title >
                Ricerca persona giuridica 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PersonaGiuridicaRicercaForm associaPersonaGiuridica={associaPersonaGiuridica} />
            </Modal.Body>
          </Modal> 
      </>
    }
    </>
  )
}
export default AnagraficaFormEdit
