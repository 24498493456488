import { useEffect, useState } from 'react'
import { Formik, Form} from 'formik'
import SicondirInput from '../components/SicondirInput';
import alertify from "alertifyjs"; 

const RiferimentoPagamentoRicercaForm = (props) => {
    
    return(
        <>
          
           <Formik 
           id="filtroDebiti"
           initialValues={{
            identificativo_debito: '',
            codice_sicondir: '',
            codice_sistema_precedente: ''
            }}
         
          onSubmit = { async (values, { setSubmitting }) => {
            let campi = ""
            Object.entries(values).map(([key,value],i) => {
              campi += value
            })
            if(campi == ""){
              alertify.warning("Specificare almeno un filtro di ricerca");
              return;
            }
            props.setSent(true);  
            props.setFilter(values);
          }} 
          
          onReset ={() => {
            props.setFilter({})
          }}

          >
            {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
            }) => (
            <Form>
            <div className="">
                <div className="row">
                    <div className="col-12">
                        <SicondirInput 
                          label = 'Identificativo Debito'   
                          id='identificativo_debito'
                          type='text'
                          name='identificativo_debito'
                          className={`form-control mb-2`}
                          size="large" 
                          placeholder = 'Inserisci Identificativo Debito'   
                          maxLength = '255'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SicondirInput 
                          label = 'Nuovo Codice Univoco(Codice SICONDIR)'   
                          id='codice_sicondir'
                          type='text'
                          name='codice_sicondir'
                          className={`form-control mb-2`}
                          size="large" 
                          placeholder = 'Inserisci Codice SICONDIR'   
                          maxLength = '255'
                        />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                        <SicondirInput
                          label = 'Codice Univoco Del Sistema Di Provenienza'   
                          id='codice_sistema_precedente'
                          type='text'
                          name='codice_sistema_precedente'
                          className={`form-control mb-2`}
                          size="large" 
                          placeholder = 'Inserisci Codice Univoco Del Sistema Di Provenienza'   
                          maxLength = '255'
                        />
                    </div>
                </div>
                     
               <div className="col-12 mt-5 row justify-content-end">
                  <button type="reset" className="btn btn-primary col-2" disabled={props.disabled}>Pulisci Campi</button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary col-2" disabled={props.disabled}>
                    Ricerca
                  </button>
                </div>
            </div>
            
            </Form>
            ) }
           </Formik>
        </>
    )
} 

export default RiferimentoPagamentoRicercaForm;
