import RichiesteConcessioneIntestatario from "../components/RichiesteConcessioneIntestatario";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import DeleteButton from "../../../common/components/DeleteButton";
import { useParams, useLocation } from "react-router";
import { useEffect, useState } from 'react'
import alertify from "alertifyjs";  
import formatter from "../../../common/utils/formatters/_";
import OperaForm from "../../../common/forms/OperaForm";
import BeniDemanialiRichiesteForm from "../../../common/forms/richiesteConcessioni/BeniDemanialiRichiesteForm";
import RichiestaForm from "../../../common/forms/richiesteConcessioni/RichiestaForm";
import DocumentoRichiestaForm from "../../../common/forms/richiesteConcessioni/DocumentoRichiestaForm";
import OperaRichiestaForm from "../../../common/forms/richiesteConcessioni/OperaRichiestaForm";

export default function RichiesteConcessioni(props) {
    // Get ID from URL
    const params = useParams();
    const richiestaId = params.id;
    // Per recuperare l'url da cui provengo.
    const location = useLocation()
    // const {fromUrl} = location.state.fromUrl;
    const [fromUrl, setFromUrl] = useState('');
    const [richiesta, setRichiesta] = useState({});
    const [loading, setLoading] = useState(true);
    const [macroambito, setMacroambito] = useState('');
    const [richiestaConsolidata, setRichiestaConsolidata] = useState(true);
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
      const url = process.env.REACT_APP_API_URL + 'richieste_concessione/id/'+richiestaId;
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          
          setRichiesta(formatter.cleanObject(json.risposta.richieste_concessione));
          json.risposta.richieste_concessione.macroarea_ambito == 'D' ? setFromUrl("/richieste-concessioni/derivazioni") : setFromUrl("/richieste-concessioni/fluviali-lacuali")
          setMacroambito(json.risposta.richieste_concessione.macroarea_ambito)
          setReadOnly(json.risposta.richieste_concessione.stato_import == 'CONSOLIDATA')
          setLoading(false);

        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
      };
      if (richiestaConsolidata){
        setLoading(true)
        fetchData();
        setRichiestaConsolidata(false)
      } 
      
    }, [richiestaConsolidata]); 

    return (
      <main>
        <h3>Gestione domanda</h3>
        { !loading?
          <>
          <div className="card">
          <div className="card-body">
            
            <Tabs defaultActiveKey="tab1">
              <Tab eventKey="tab1" title="Dati Domanda">
                <RichiestaForm loading={loading} richiesta={richiesta} macroambito={macroambito} readOnly={readOnly} setRichiestaConsolidata={setRichiestaConsolidata}></RichiestaForm>
              </Tab>
              <Tab eventKey="tab2" title="Intestatario">
                <RichiesteConcessioneIntestatario readOnly={readOnly}></RichiesteConcessioneIntestatario>
              </Tab>
              <Tab eventKey="tab3" title="Bene Demaniale/Derivazione">
                <BeniDemanialiRichiesteForm macroambito={macroambito} readOnly={readOnly}></BeniDemanialiRichiesteForm>
              </Tab>
              <Tab eventKey="tab4" title="Opere">
                <OperaForm macroambito={macroambito}></OperaForm>
             
              </Tab>
              <Tab eventKey="tab5" title="Documenti">
                <DocumentoRichiestaForm readOnly={readOnly}></DocumentoRichiestaForm>
              </Tab>
              <Tab eventKey="tab6" title="Dati Contabili" disabled>
                Dati Contabili
              </Tab>
            </Tabs>
            
          </div>
        </div>
        <br />
        <div className="card">
              <div className="card-body">
                <div className="float-right">
                  <DeleteButton 
                    table="richieste_concessione" 
                    resourceId={params.id} 
                    redirectPath={fromUrl}
                    buttonLabel="Elimina richiesta di concessione"
                    confirmLabel="Procedere con l'eliminazione della richiesta di concessione?"
                    successMessage="Richiesta di concessione eliminata con successo"
                    errorMessage="Errore durante la cancellazione della richiesta di concessione">  
                  </DeleteButton>
                </div>
              </div>
            </div>
            </>
          :
          
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>

        }
        
      </main>
    );
  }