import { useNavigate, useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirRadio from "../components/SicondirRadio";
import SicondirInput from "../components/SicondirInput";
import tipiDocumento from '../../_data/tipi_documento.json';
import formatter from '../utils/formatters/_';
import SicondirTable from "../components/SicondirTable";
import SicondirSelect from "../components/SicondirSelect";
import SicondirFile from "../components/SicondirFile";
import UploadButton from "../components/UploadButton";
import DocumentoFormEdit from "./DocumentoFormEdit";


const DocumentoForm = (props) => {
    // Get ID from URL
    const params = useParams();
    const concessioneId = params.id;
    const [loading, setLoading] = useState(true);
    const [documentiArray, setDocumentiArray] = useState([])
    const [list, setList] = useState([])
    const [fileObject, setFileObject] = useState(undefined)
    const [tipoDocumento, setTipoDocumento] = useState(null)
    const [pulisciFile, setPulisciFile] = useState(false)

    // per la modifica.
    const[editShow, setEditShow] = useState(false);
    const[loadingEdit, setLoadingEdit] = useState(true);
    const[documentoEdit, setDocumentoEdit] = useState(null)
    const[modificaDocumento, setModificaDocumento] = useState(false);

    const handleModalEdit = async (idDocumento) => {
      try {
          const response = await fetch(process.env.REACT_APP_API_URL +
              'concessioni_documenti/id/' + idDocumento,
              {
                  method: 'GET',
                  headers: { accept: 'application/json' },
                  mode: 'cors',
              })

          const json = await response.json()
          let  documento=" "
          if (json.risposta.concessioni_documenti) {
            documento = json.risposta.concessioni_documenti   
          }
      
          setDocumentoEdit(formatter.cleanObject(documento))
          setLoadingEdit(false)
        
      }
      catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
      }
      
      setEditShow(true);
  }

  const handleCloseEdit = () => {
      setEditShow(false);
  }


  const buildActions = (idDocumento) => {
    let result = {};
    result = { elimina: {
            kind: "function", 
            function: handleDelete,
            parameters:
            {
              idDocumento: idDocumento
            },
            buttonClass: "btn-danger",
            linkClass: "text-danger",
            label: "Elimina associazione",
            confirmLabel: "Procedere con l'eliminazione del documento?"},
            
            modificaDocumento: {
                kind: "function", 
                function: handleModalEdit,
                parameters:
                {
                    idDocumento: idDocumento
                },
                label: "Modifica documento caricato",
            }
    }
    return result;        
  }

  const manageTipoDocumento = (event) =>{
    if(event){
      setTipoDocumento({
          label: event.label,
          value: event.value
      })
  } else{
      setTipoDocumento(null);
  }
  }


  const handleDelete = async(documentoId) => {
    setLoading(true);
    const payload = {
        nome_tabella: "concessioni_documenti",
        id: documentoId,
        utente_modifica_id: localStorage.id
    }
  
    try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
            method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
            headers: { 'Content-Type': 'application/json',
                        'Accept': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
        })
        const data = await response.json();
        alertify.success("Documento eliminato con successo");
        setLoading(false)
        setDocumentiArray([]);
    } catch(error) {
        alertify.error("Errore durante la cancellazione del documento");
        setLoading(false)
    }
  }
  
    useEffect(() => {
        const getTipoDocumento = (idTipoDocumento) => {
            const res = tipiDocumento.filter(c => c.value == idTipoDocumento)
            .map(c => { return c.label })
            return res;
        }

        const fetchData = async() => {
          setLoading(true)
          try{
            const url = process.env.REACT_APP_API_URL + 'concessioni_documenti/concessione_id/' + concessioneId;
            const response = await fetch(url, {
              method: 'GET',
              headers: { 
                        accept: "application/json"
                      },      
              mode: 'cors'
             });
             const json = await response.json();
             // Da rivedere questa parte: dovremmo avere una api ad hoc che ritorni solo i dati che ci servono, non dobbiamo scremarli qua...
            let list = [];
            let i = 1;
            if(json.risposta.concessioni_documenti){
              if(Array.isArray(json.risposta.concessioni_documenti)){
                json.risposta.concessioni_documenti.map(element => {
                  element = formatter.cleanObject(element)
                  let actions = buildActions(element.id)
                  list[i] = {
                    id: element.id,
                    tipo_documento: getTipoDocumento(element.tipo_documento_id)|| " - ",
                    descrizione: element.descrizione || " - ",
                    numero_riferimento_atto: element.numero_riferimento_atto,
                    link: { 
                      label: element.filename,
                      link: element.file_path           
                    },
                    app_actions:actions
                    }
                  
                  i++
                })
              }
            } 
            
            setList(list); 
            setLoading(false);
          
          } catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
          }
          setPulisciFile(false)
          setModificaDocumento(false)
          setLoading(false);
      };
      fetchData();
    }, [documentiArray, modificaDocumento]);
  

    return (
        <>
        <Formik
        id='documento' className="p-4"
        initialValues={{
            tipo_documento_id: '',
            numero_riferimento_atto: '', 
            descrizione: '',
            file: null 
        }}
        validationSchema={Yup.object({
          tipo_documento_id: Yup.string().required("Il campo è obbligatorio"),
        })}
        /*
        validationSchema={Yup.object().shape({
            file: Yup.mixed().required(),
        })}
        */

          onSubmit = { async (values, { setSubmitting, setFieldValue,resetForm }) => {
            setLoading(true)
            let associazioneConcessioneDocumento = true;
            if(!fileObject){
              alertify.warning("Caricare un file");
              setLoading(false)
              return;
            }

            let campi = ""
            let valori = ""

                 
            Object.entries(values).map(([key,value],i) => {
              if(key=='file'){
              } else if(value != ''){
                campi += key + ", "
                valori += "'" + formatter.escapeSql(value) + "', "
              } 
            })

            if(valori == ""){
              alertify.warning("Inserire almeno un campo");
              setLoading(false)
              return;
            }

            // Prima carico il doc: se va a buon fine posso inserire a DB.
            const formData = new FormData();
            let filePath = process.env.REACT_APP_FILE_FOLDER
            let fileName = ""
            formData.append('uploadedFile', fileObject);
            formData.append('contesto', "concessione");
            formData.append('id', concessioneId);
            await fetch(
              process.env.REACT_APP_API_FILE_UPLOAD,
              {
                method: 'POST',
                body: formData,
              }
            ).then((response) => response.json())
              .then((result) => {
                filePath += result.risposta.path;
                fileName += result.risposta.filename;
              })
              .catch((error) => {
                alertify.error("Errore durante il caricamento del file");
                console.error('Error:', error);
                associazioneConcessioneDocumento = false;
              });
            
            if(associazioneConcessioneDocumento){
              try{
                campi += "concessione_id,file_path,filename,utente_modifica_id";
                valori += concessioneId+",'"+formatter.escapeSql(filePath)+"','"+formatter.escapeSql(fileName)+"',"+localStorage.id
                const opera = {
                  nome_tabella: "concessioni_documenti",
                  lista_campi: campi,
                  lista_valori: valori
                }
  
                const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' }, 
                  mode: 'cors', 
                  body: JSON.stringify({Entry:opera})
                })
  
                const data_response = await response.json();
                if(data_response.hasOwnProperty('Fault')){
                  if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                    console.log("ErrorCode: " + data_response.Fault.faultcode)
                    throw new Error(data_response.Fault.faultstring)
                  } else{
                    throw new Error("errore durante il salvataggio.")
                  }
                }
                setDocumentiArray(documentiArray => [documentiArray, data_response.risposta.elementi_aggiunti.id] );
                alertify.success("Documento associato alla concessione con successo.");
                 resetForm();
                 setPulisciFile(true)
                 setTipoDocumento(null)
              } catch(error) {
                alertify.error("Errore durante l'associazione del documento");
                console.log("errore: ", error);
              }
            }

            setLoading(false)
          }}
          
        >
        <Form>
                <br /><br />
                    <SicondirSelect
                        label="Tipo Documento" 
                        id="tipo_documento_id" 
                        name="tipo_documento_id"
                        mandatory= 'true'
                        isClearable={true}
                        value={tipoDocumento}
                        onChange={manageTipoDocumento}
                        options={tipiDocumento} 
                        placeholder="Seleziona Tipo Documento"
                    />
                    

                    <SicondirInput 
                        label='Descrizione'
                        id='descrizione'
                        type='text'
                        name='descrizione'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Descrizione Documento'   
                        maxLength = '255'
                    />

                    <SicondirInput 
                        label='Numero Riferimento Atto'
                        id='numero_riferimento_atto'
                        type='text'
                        name='numero_riferimento_atto'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Numero Riferimento Atto'   
                        maxLength = '255'
                    />

                    <UploadButton setFileObject={setFileObject} cleanFile={pulisciFile}/>

                <div className="col-12 mt-5 row justify-content-center">
                    <button type="submit" className="btn btn-primary col-5" disabled={loading}>
                    Salva
                    </button>
                </div>
            
        </Form>
        </Formik>
        <br /><br />
        {!loading ?
          <SicondirTable 
          headers={[
            "Tipo documento",
            "Descrizione", 
            "Numero riferimento atto",
            "Scarica",
            ""]} 
          data={list}
          dropdown={true} 
          />

        :
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>
        }

        <Modal
          //dialogClassName={'modal-fullpage'}
          size="xl"
          show={editShow}
          onHide={handleCloseEdit}
          >
          <Modal.Header closeButton>
              <Modal.Title >
                  Modifica Documento Caricato
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {!loadingEdit ?

                  <DocumentoFormEdit 
                    documento={documentoEdit}
                    handleClose={handleCloseEdit} 
                    setModifica={setModificaDocumento} />
                  :
                  <div className="h-100 d-flex align-items-center justify-content-center">
                      <div className="progress-spinner progress-spinner-active">
                          <span className="sr-only">Caricamento...</span>
                      </div>
                  </div>
              }
          </Modal.Body>
        </Modal>
      </>
    )
}

export default DocumentoForm;