import { useEffect, useState } from 'react'
import { Formik, Form, prepareDataForValidation} from 'formik'
import SicondirInput from '../components/SicondirInput';
import formatter from '../utils/formatters/_'
import alertify from "alertifyjs";
import SicondirTable from '../components/SicondirTable';
import * as Yup from 'yup'

const RiferimentoPagamentoImportoEditForm = (props) => {
  
  const[importoUtilizzabile, setImportoUtilizzabile] = useState(0)
  const[loading, setLoading] = useState(true);

  useEffect(()=>{
    const fetchData = async () => {
      setLoading(true);  
      let urlImporto = process.env.REACT_APP_API_URL + 'pagamenti/importo_utilizzato/'+props.pagamento.id;
      try {
        const response = await fetch(urlImporto, {
          method: 'GET',
          headers: { accept: "application/json" },      
          mode: 'cors'
         });

        const json = await response.json();
        let resp = formatter.cleanObject(json.risposta.pagamento)
        let importoTotale = props.pagamento.importo || 0
        let importoUtilizzatoDb = resp.importo_utilizzato
        let importoUtilizzabile = importoTotale - importoUtilizzatoDb + parseFloat(props.debito.pagamento_importo_pagato)
        setImportoUtilizzabile(importoUtilizzabile);
        setLoading(false);

      } catch (error) {
        alertify.error("Errore durante il recupero dei dati.");
        console.log("errore: ", error);
      }
    };
    fetchData();
  }, [props.pagamento.importo])

    return(
        <>
        { !loading ?
          <>
          <SicondirTable
            headers={[
              "Identificativo Pagamento",
              "Importo Residuo Disponibile Sul Pagamento(€)"
            ]}
            data={[{
              id: props.pagamento.id,
              identificativoPagamento: props.pagamento.identificativo_pagamento,
              importoResiduo: formatter.formatNumber(importoUtilizzabile)
            }]}
          />
          <SicondirTable
            headers={[
              "Codice Concessione/Occupazione",
              "Identificativo Debito",
              "Importo Debito Residuo(€)"
            ]}
            data={[{
              id: props.debito.id_debito,
              identificativoRiferimento: (props.debito.riferimento_codice_sicondir || "-") + ("(" + (props.debito.riferimento_codice_sistema_precedente || "-") + ")"),
              identificativoDebito: props.debito.canone_identificativo_debito || " - ",
              /* Nota: qua sono in modifica quindi l'importo "pagabile" è quello effettivamente non pagato 
              con più l'importo che avrei pagato con questo "pagamento_oggetto"
              */
              importoPagabile: formatter.formatNumber(parseFloat(props.debito.canone_importo_totale)-parseFloat(props.debito.canone_importo_pagato)+parseFloat(props.debito.pagamento_importo_pagato))
            }]}
          />
          
           <Formik 
           id="riferimentoImporto"
           initialValues={{
            importo: formatter.formatNumber(props.debito.pagamento_importo_pagato).replace(".", ""),
           }}
         
          onSubmit = { async (values, { setSubmitting }) => {
            
            try{
              let campi = ""
              campi += "importo="+parseFloat(values.importo.replace(",", "."))+","
              campi += "utente_modifica_id="+localStorage.id;

              const payload = {
                nome_tabella: "pagamenti_oggetto",
                lista_campi_valore: campi,
                lista_clausole_where: "id="+ props.debito.id_pagamento_oggetto + " and flag_deleted=0"
              }
                  
              const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:payload})
              })
              const data_response = await response.json();
              if(data_response.hasOwnProperty('Fault')){
                if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                  console.log("ErrorCode: " + data_response.Fault.faultcode)
                  throw new Error(data_response.Fault.faultstring)
                } else{
                  throw new Error("errore durante il salvataggio.")
                }
              }
              if(data_response.hasOwnProperty('code') && data_response.code == '500'){
                throw new Error("errore durante il salvataggio.")
              }

              props.setAssociazione(true)
              props.handleClose() 
              alertify.success("Importo pagamento/debito aggiornato con successo.");
            } catch(error) {
                alertify.error("Errore durante l'inserimento: " + error);
                console.log("errore: ", error);
            }
            
            
          }} 
          
          validationSchema={Yup.object({
            importo: Yup.string()
            .required("Il campo è obbligatorio")
            .matches(/^((\d+(\,\d{0,2})?))$/, 'Inserire un valore numerico, i decimali(massimo 2) devono essere separati da ","')
            .test("checkImportoResiduo", "L'importo non può essere superiore all'importo residuo del pagamento",
              function(value) {
                let i = parseFloat(value.replace(",", "."));
                if(i > importoUtilizzabile) return false
                return true;
              }
            )
            .test("checkImportoPagabile", "L'importo non può essere superiore all'importo da pagare del debito",
              function(value) {
                let i = parseFloat(value.replace(",", "."));
                let importoMassimoPagabile = parseFloat(props.debito.canone_importo_totale)-parseFloat(props.debito.canone_importo_pagato)+parseFloat(props.debito.pagamento_importo_pagato)
                if(i > importoMassimoPagabile) return false
                return true;
              }
            )
            .test("checkImportoMaggioreZero", "Specificare un importo superiore a 0",
              function(value) {
                let i = parseFloat(value.replace(",", "."));
                if(i == 0) return false
                return true;
              }
            ),
        })}  

          >
            {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
            }) => (
            <Form>
            <div className="">
                <div className="row">
                    <div className="col-12">
                        <SicondirInput 
                          label = 'Importo Da Riconciliare'   
                          id='importo'
                          type='text'
                          name='importo'
                          className={`form-control mb-2`}
                          size="large" 
                          placeholder = 'Inserisci importo del pagamento da riconciliare'   
                          maxLength = '10'
                        />
                    </div>
                </div>
                   
               <div className="col-12 mt-5 row justify-content-end">
                  <button type="submit" className="btn btn-primary col-2" disabled={!(isValid && dirty)}>
                    Aggiorna
                  </button>
                </div>
            </div>
            </Form>   
            ) }
           </Formik>
           <br /><br />
           </>
           :
           <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="progress-spinner progress-spinner-active">
                    <span className="sr-only">Caricamento...</span>
                </div>
            </div>
          }
        </>
    )
} 

export default RiferimentoPagamentoImportoEditForm;
