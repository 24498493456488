import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BeniDemanialiRicerca from "../components/BeniDemanialiRicerca";

export default function BeniDemanialiList(props) {
  let navigate = useNavigate();
    
  const [currentMacroambito, setCurrentMacroambito] = useState(props.macroambito);

  let titolo = "Ricerca beni demaniali "
    let fromUrl = "/concessioni/"
    if(props.macroambito == 'F'){
      //titolo += " fluviali/lacuali"
      fromUrl += "fluviali-lacuali"
    } else if(props.macroambito == 'D'){
      //titolo += " derivazioni"
      fromUrl += "derivazioni"
    }

    useEffect(()=>{
      setCurrentMacroambito(props.macroambito)
    }, [props.macroambito])
    useEffect(() => {
      if((localStorage.sezione=='Derivazioni' && props.macroambito == 'F') 
      || (localStorage.sezione=='Fluviali' && props.macroambito == 'D')) {
        navigate('/');
      }
    },[])
    return (
      <main>
        <h3>{titolo}</h3>

        <div className="card ">
          <div className="card-body">
            <br></br>
            <BeniDemanialiRicerca insert={true} contesto='anagrafica' macroambito={currentMacroambito}/>
          </div>
        </div>
      </main>
    );
  }