import {Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import SicondirDropDownButtons from './SicondirDropDownButtons';
import formatter from '../utils/formatters/_';
import alertify from "alertifyjs";  
import SicondirRigaUsi from './SicondirRigaUsi';
import TriggerPopover from './TriggerPopover';
import SicondirRigaPagamenti from './SicondirRigaPagamenti';

export default function SicondirDebitiTable(props) {

    const [loading, setLoading] = useState(true)

    let numeroCampi = props.headers.length;
    let noDataLabel = props.noDataLabel ? props.noDataLabel : "Nessun dato disponibile";
    let showId = props.showId ? props.showId : false
    let headersPagamenti = ["Codice categoria", "Descrizione categoria", "Codice sottocategoria", "Descrizione sottocategoria", "Unità misura", "Valore", "Durata", "Descrizione uso"];
    let tableClass = "table table-hover"
    props.compact ? tableClass += " table-sm " : tableClass += ""
    let i = 0;

    useEffect(()=>{
        setLoading(false)
    }, [])
    
    return(
        <>
        {
            !props.data || props.data.length < 1 ? 
            <Table className={tableClass}>
                <thead className="text-capitalize">
                    <tr>
                    { props.headers.map((col) => {
                        return ( <th key={col}> {col} </th> )
                    })
                    }
                    </tr>
                </thead>
                <tbody>
                    <tr key="noData">
                        <td colSpan={numeroCampi} className="text-center">{noDataLabel}</td>
                    </tr>
                </tbody>
            </Table>
            :
            <>
            {!loading ?
                <Table className={tableClass}>
                    {props.data.map((el) => {
                        i++;
                        return(
                            <React.Fragment key={el.id}>
                            <thead key={"head-" + el.id}>
                            <tr>
                                { props.headers.map((col) => {
                                    return ( <th key={col} colSpan={(col=='Note'?'2':'1')}> {col} </th> )
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody>
                            <tr key={el.id}>
                                { Object.keys(el).map(function(keyValue) {
                                    if(keyValue == "app_actions"){
                                        return (
                                            <td key={keyValue}>
                                                <SicondirDropDownButtons mainLabel="Azioni" data={el[keyValue]} />
                                            </td>
                                        )
                                    } else if(keyValue == "link"){
                                        return (<td key={keyValue}> 
                                                { <a target="_blank" href={el[keyValue].link}>{el[keyValue].label}</a> }
                                                </td>)
                                    } else{
                                        
                                        if(keyValue == 'id'){
                                            return (
                                                showId && <td key={keyValue}> {el[keyValue]} </td>
                                            )
                                        } else if(keyValue == 'popover') {
                                            return (
                                                <td key={keyValue} colSpan='2'>
                                                <TriggerPopover text={el[keyValue]} />
                                                </td>
                                            )       
                                        } else{
                                            if(keyValue != 'usi' 
                                                && keyValue != 'id_concessione_catasto' 
                                                && keyValue != 'id_richiesta_catasto'
                                                && keyValue != 'id_occupazione_catasto'
                                                && keyValue != 'id_denuncia_pozzo_catasto') 
                                            return (
                                                <td key={keyValue}> {el[keyValue]} </td>
                                            )
                                        }
                                    } 
                                })
                                }
                            </tr>
                           
                            <SicondirRigaPagamenti
                                idAssociazione={el.id}
                                contesto={props.contesto}
                            />
                            
                            { i < props.data.length  && 
                                <tr key={"filler-"+i}>
                                    <td    
                                        style={
                                            {pointerEvents: 'none'}
                                        }
                                        colSpan="10">&nbsp;</td>
                                </tr>
                            }
                            </tbody>
                            
                            </React.Fragment>
                        )
                    })
                    
                    }
                </Table>
                :
                                
                <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="progress-spinner progress-spinner-active">
                    <span className="sr-only">Caricamento...</span>
                </div>
                </div>
            }
            </>
        }
        </>
    )
}
