
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Formik, Form} from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirSelect from "../components/SicondirSelect";
import tipiAmbito from '../../_data/tipi_ambito.json'
import provinceLazio from '../../_data/province_lazio.json'
import comuni from '../../_data/comuni.json'
import SicondirInput from "../components/SicondirInput";

const BeniDemanialiRicercaForm = (props) => {
    
    const [tipiAmbitoFiltrati, setTipiAmbitoFiltrati] = useState([])
    const [ambitoSelezionato, setAmbitoSelezionato] = useState(props.macroambito);
    const [provinciaSelezionata, setProvinciaSelezionata] = useState(null);
    
    const [comuniDisabled, setComuniDisabled] = useState(true);
    const [comuniFiltrati, setComuniFiltrati] = useState([]);
    const [comuneSelezionato, setComuneSelezionato] = useState(null);
    
    // "TENDINA AMBITI"
    useEffect(() => {
        const res = tipiAmbito.filter(c => c.macroarea_ambito == props.macroambito)
            .map(c => { return { 
                value: c.value,
                label: c.label,
            };}
            )
        setTipiAmbitoFiltrati(res)
    }, [props.macroambito]) 

      const handleAmbito = (e) => {
        if(e){
            setAmbitoSelezionato({
                label: e.label,
                value: e.value
            })
        } else{
            setAmbitoSelezionato(null);
        }
    }

    const enableComuni = (e) => {
        if(e){
            setProvinciaSelezionata({
                label: e.label,
                value: e.value
            })
            const res = comuni
            .filter(c => c.provincia.nome == e.label)
            .map(c => { return { 
                value: c.id,
                label: c.nome,
            };})
            setComuniDisabled(false);
            setComuniFiltrati(res);
            setComuneSelezionato(null);
        } else {
            
            setProvinciaSelezionata(null);
            setComuniDisabled(true);
            setComuneSelezionato(null);
        }

    }  

    const handleComune = (e) => {
        if(e){
            setComuneSelezionato({
                label: e.label,
                value: e.value
                })
        } else {
            setComuneSelezionato(null);
        }
        
    }  

    return(
        <>
           <Formik 
           id="filtroBeniDemaniali"
           initialValues={{
            tipo_ambito_id: '',
            provincia: '',
            tipo_comune_id: '',
            indirizzo: '',
            localita: '',
            catasto_sezione: '',
            catasto_foglio: '',
            catasto_particella: '',
            catasto_sub: '',
            
          }}

          onSubmit = { async (values, { setSubmitting }) => {

              if(values.provincia==null){
                  values.tipo_comune_id=null
              }
              
            props.setSent(true);  
            props.setFilter(values); 
            props.setCurrentPage(1)     
          }} 
          
          onReset ={() => {
            setAmbitoSelezionato(null);
            setProvinciaSelezionata(null);
            setComuniDisabled(true);
            setComuneSelezionato(null);
            props.setFilter({})
            props.setCurrentPage(1)
          }}
          >
            <Form>
            <div className="">
                <div className="row">
                    <div className="col-3">
                        <SicondirSelect 
                        label="Tipo Ambito" 
                        id="tipo_ambito_id" 
                        name="tipo_ambito_id" 
                        options={tipiAmbitoFiltrati}
                        value={ambitoSelezionato} 
                        isClearable={true}
                        placeholder="Seleziona Tipo Ambito"
                        onChange={handleAmbito}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <SicondirSelect 
                        label="Provincia" 
                        id="provincia" 
                        name="provincia" 
                        options={provinceLazio} 
                        placeholder="Seleziona Provincia"
                        value={provinciaSelezionata} 
                        isClearable={true}
                        onChange={enableComuni}/>
                    </div>
              
                    <div className="col-3">
                        <SicondirSelect 
                        label="Comune" 
                        id="tipo_comune_id" 
                        options={comuniFiltrati} 
                        name="tipo_comune_id" 
                        isDisabled={comuniDisabled} 
                        value={comuneSelezionato} 
                        placeholder="Seleziona Comune"
                        isClearable={true}
                        onChange={handleComune}/> 
                    </div>
                    <div className="col-3">
                        <SicondirInput 
                        label='Indirizzo'
                        id='indirizzo'
                        type='text'
                        name='indirizzo'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Indirizzo'   
                        maxLength = '255'
                        />
                    </div>
                    <div className="col-3">
                        <SicondirInput 
                        label="Località"
                        id='localita'
                        type='text'
                        name='localita'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Località'   
                        maxLength = '255'
                        />
                    </div>
                </div>
                <div className="row">
                  {/*  <div className="col-3 md-12 sm-12">
                        <SicondirInput 
                        label = 'Sezione'   
                        id='catasto_sezione'
                        type='text'
                        name='catasto_sezione'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Sezione'   
                        maxLength = '255'
                        />
                    </div> */}
                    <div className="col-3 md-12 sm-12">
                        <SicondirInput 
                        label = 'Foglio'
                        id='catasto_foglio'
                        type='text'
                        name='catasto_foglio'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Foglio'   
                        maxLength = '255'
                        />
                    </div>
                    <div className="col-3 md-12 sm-12">
                        <SicondirInput 
                        label = 'Particella'
                        id='catasto_particella'
                        type='text'
                        name='catasto_particella'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Particella'   
                        maxLength = '255'
                        />
                    </div>
                    {/* <div className="col-3 md-12 sm-12">
                        <SicondirInput 
                        label = 'Subalterno'
                        id='catasto_sub'
                        type='text'
                        name='catasto_sub'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Subalterno'   
                        maxLength = '255'
                        />
                    </div>*/}
                </div>
               <div className="col-12 mt-5 row justify-content-end">
                  <button type="reset" className="btn btn-primary col-2" disabled={props.disabled}>Pulisci Campi</button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary col-2" disabled={props.disabled}>
                    Ricerca
                  </button>
                </div>
            </div>
            </Form>

           </Formik>
        </>
    )
} 

export default BeniDemanialiRicercaForm;