import React from 'react';
import { Container } from 'react-bootstrap';
import Navbar from './Navbar';

export default function Header(props) {


  return (
  <>
    <div className="col-12 it-header-slim-wrapper pr-5" style={{height:'55px', color:'#fff', textAlign:'right'}}>
        Benvenuto <span style={{fontWeight: 'bold'}}>{localStorage.nome} {localStorage.cognome}</span>
        <br/>{(props.sezione)?`Ambito "${(props.sezione=='Derivazioni'?'Derivazioni idriche':(props.sezione=='Fluviali')?'Fluviali/Lacuali':'')}"`:''}
    </div>
    <div className="it-header-center-wrapper col-12" style={{height:'180px'}}>
      {/* Scommentare per centrare l'header <div className="col-2"></div> */}
      <div>
      <div className="row">
        <div className="col-12">
          <div className="it-header-center-content-wrapper">
            <div className="it-brand-wrapper">
              <a href="#">
                <div className="it-brand-text">
                  <div className="it-brand-title">
                    Sicondir {(props.sezione)? ` - ${(props.sezione=='Fluviali')?'Fluviali/Lacuali':(props.sezione=='Derivazioni')?'Derivazioni idriche':''}`:''}
                  </div>
                  <div className="it-brand-tagline d-none d-md-block"><small>Concessioni idriche Regione Lazio</small></div>
                </div>
              </a>
            </div>
            <div className="it-right-zone">
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12"><Navbar sezione={ props.sezione } /></div>
      </div>
      </div>
    </div> 
  </>
    );
}
