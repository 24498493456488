import React from 'react'
import { useEffect, useState } from 'react'
import { Formik, Field, Form, useFormik } from "formik";
import SicondirSelect from "./SicondirSelect";
import SicondirInput from "./SicondirInput";
import tipiContatti from '../../_data/tipi_contatti.json'
import formatter from '../utils/formatters/_' 
import alertify from "alertifyjs";
import * as Yup from 'yup'

const ContattoIns = (props) => {
  const [loading, setLoading] = useState(false);
  const[persona, setPersona] = useState({data: []})

  const initialValue = {
    tipo_contatto_id: "",
    valore: "",
  }
  const validationSchema = Yup.object().shape({
    tipo_contatto_id: Yup.string()
    .required("Il tipo contatto è obbligatorio"),
  });
  const fetchData = async (listaCampi, listaValori) => {
    
    let payloadIns = {
      nome_tabella: "persone_contatti",
      lista_campi: listaCampi,
      lista_valori: listaValori
    }
    const responseIns = 
    await fetch(
      process.env.REACT_APP_API_URL + 'inserisci_elemento', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }, 
      mode: 'cors', 
      body: JSON.stringify({Entry:payloadIns})
      }
    )
  }
  const stampa_persona = async () => {
    let urlApi = `persone/id/${props.persona_id}`
    const response = await fetch(process.env.REACT_APP_API_URL + urlApi, 
    {
      method: 'GET',
      headers: { accept:'application/json' }, 
      mode: 'cors',  
    })
    const json = await response.json()
    setPersona(formatter.cleanObject(json.risposta.persone))
  }
  useEffect(() => {
    stampa_persona();
  },[]);

    return (
      
      <>
        <h3 className="text-capitalize">
          { (props.valueNaturaGiuridica=='PF') 
            ? 
               persona.nome + ' ' + persona.cognome 
            :
               persona.ragione_sociale 
          }
        </h3>
      {!loading ?
        <>
       
        <Formik 
          initialValues={initialValue} 
          validationSchema={validationSchema}
          id='contatti' 
          className="p-4"
          enableReinitialize={true}
          onSubmit = { async (values, { setSubmitting }) => {
            setSubmitting(true);
                          // FACCIO L'INSERIMENTO DEL CONTATTO
            let campiContatto = "utente_modifica_id, persona_id,"
            let valoriContatto = localStorage.id+','+props.persona_id+','
            const lengthV = Object.entries(values).length-1
            let i = 1;
            Object.entries(values).map(([key, value], i) => {
              campiContatto += key + (i<lengthV?',':'')
              valoriContatto += "'" + formatter.escapeSql(value) + "'" + (i<lengthV?',':'')
              i++;
            });
            try {
              fetchData(campiContatto, valoriContatto);
              alertify.success("Contatto inserito con successo.");
              props.setContattoCambiato(true);
              props.handleClose()
            } 
            catch(error) {
              alertify.error("Errore durante la modifica dei dati: " + error);
              console.log("errore: ", error);
            }
          }}
        >
          {({
            values, 
            errors, 
            handleChange, 
            handleSubmit,
            isSubmitting,
            isValid,
            touched,
            dirty,
          }) => (
            
            <form onSubmit={handleSubmit} id='ricercaPersona' className="p-4">    
              <div className="col-4 md-12 sm-12 sicondir-label">
              
                <SicondirSelect 
                  label="Tipo Contatto"
                  id='tipo_contatto_id'
                  name='tipo_contatto_id'
                  mandatary='true'
                //  value={props.tipo_contatto_id || ''}
                  options={tipiContatti}
                 // onChange={handleChange}
                  key="tipoCointatti"
                  placeholder = 'Tipo Contatto'
                />  
              </div>
              <div className="col-4 md-12 sm-12">
                <SicondirInput
                  label="Valore" 
                  id='valore'
                  name='valore'
                  type='text'
                  onChange={handleChange}
                  key="Valore"
                  className='form-control mb-2'
                  size="large" 
                  placeholder = 'Valore'
                  maxLength = '70'
                />
              </div>
              <button type="button" onClick={props.handleClose} className="btn btn-primary col-2 mr-2">
                Chiudi
              </button>
              <button type="submit" className="btn btn-primary col-2">
                Inserisci
              </button>
            </form>
          )}
        </Formik>
        </>
         :
         <>
         <br />
         <div className="h-100 d-flex align-items-center justify-content-center">
           <div className="progress-spinner progress-spinner-active">
             <span className="sr-only">Caricamento...</span>
           </div>
         </div>
         </>
        } 
      </>     
    )
}
export default ContattoIns