import { useEffect, useState } from 'react'
import { Formik, Form} from 'formik'
import SicondirSelect from "../components/SicondirSelect";
import SicondirInput from "../components/SicondirInput";
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import formatter from '../utils/formatters/_';
import tipiPagamento from "../../_data/tipi_pagamento.json";
import _ from 'lodash'
import { useNavigate } from "react-router-dom";

const PagamentoInserimentoForm = (props) => {
  let navigate = useNavigate();
    let fromUrl = "/pagamenti/"

    const checkIdDuplicato = async (valore) => {
        
        const url = process.env.REACT_APP_API_URL + 'pagamenti/codice/'+encodeURI(valore);
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });
          const json = await response.json()
          return !_.isEmpty(json.risposta.pagamenti)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
          return true
        }
      };

    return(
        <>
          <br />
          
           <Formik 
           id="pagamento"
           
           disabled={true}
           initialValues={{
                tipologia_pagamento: '',
                identificativo_pagamento: '',
                data_pagamento: '',
                importo: '',
            }}
        
            validationSchema={Yup.object({
                tipologia_pagamento: Yup.string().required("Il campo è obbligatorio"),
                identificativo_pagamento: Yup.string().required("Il campo è obbligatorio").test('checkIdDuplicato', 
                'Identificativo già presente a sistema', 
                async function(value) {
                    if (value != null && value.trim()!='') {
                      const idDuplicato = await checkIdDuplicato(value);
                      return !idDuplicato;
                    }
                    return true;
                }
                ),
                data_pagamento: Yup.string().required("Il campo è obbligatorio"),
                importo: Yup.string().required("Il campo è obbligatorio")
                .matches(/^((\d+(\,\d{0,2})?))$/, 'Inserire un valore numerico, i decimali(massimo 2) devono essere separati da ","')
                .test("checkImporto", "Il valore dell'importo non può essere inferiore a " + formatter.formatNumber(props.importoUtilizzato) + "€",
                function(value){
                  if(value != null && value.trim()!=''){
                    let i = parseFloat(value.replace(",", "."));
                    if(i < props.importoUtilizzato) return false
                  }
                  return true
                }
                ),
            })}  

          onSubmit = { async (values, { setSubmitting, resetForm }) => {
            let campi = "tipologia_pagamento, identificativo_pagamento, data_pagamento, importo, utente_modifica_id"
            let valori = ""
            Object.entries(values).map(([key,value],i) => {
                if(key == 'importo'){
                    if(value != ""){
                      value = value.replace(",", ".");
                    }
                } 
                valori += "'" + formatter.escapeSql(value) + "',";
            })
              
            if(campi == ""){
              alertify.warning("Inserire almeno un campo");
              return;
            }

            try{
                valori += localStorage.id;
                
                let payload = {
                  nome_tabella: "pagamenti",
                  lista_campi: campi,
                  lista_valori: valori
                }

                const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' }, 
                  mode: 'cors', 
                  body: JSON.stringify({Entry:payload})
                })
                const data_response = await response.json();
                if(data_response.hasOwnProperty('Fault')){
                  if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                    console.log("ErrorCode: " + data_response.Fault.faultcode)
                    throw new Error(data_response.Fault.faultstring)
                  } else{
                    throw new Error("errore durante il salvataggio.")
                  }
                }
                alertify.success("Dati pagamento salvati con successo.");
                navigate('/pagamenti/inserisci/' + data_response.risposta.elementi_aggiunti.id, 
                        {state: {
                          fromUrl: {fromUrl}
                          }
                        }) ; // ???

              } catch(error) {
                alertify.error("Errore durante l'inserimento dei dati: " + error);
                console.log("errore: ", error);
              }


          }} 
          
          >
            {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
            }) => (
            <Form>
                <fieldset disabled={props.importoUtilizzato > 0}>
                <br /><br />
                <div className="row">
                    <div className="col-12">
                        <SicondirSelect
                        label='Tipologia Pagamento'
                        id='tipologia_pagamento'                
                        name='tipologia_pagamento'
                        mandatory='true'    
                        options={tipiPagamento} 
                        isClearable={false}
                        placeholder = 'Tipologia Pagamento'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SicondirInput 
                            label='Identificativo Pagamento'
                            id='identificativo_pagamento'
                            type='text'
                            mandatory='true'
                            name='identificativo_pagamento'
                            className={`form-control`}
                            size="large" 
                            placeholder = 'Inserisci Identificativo Pagamento'   
                            maxLength = '100'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    <SicondirInput 
                      label='Data Pagamento'
                      id='data_pagamento'
                      type='date'
                      mandatory='true'
                      name='data_pagamento'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Data Pagamento'   
                    />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SicondirInput 
                        label='Importo Bollettino Pagamento(€)'
                        id='importo'
                        type='text'
                        mandatory='true'
                        name='importo'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Importo Pagamento'
                        maxLength = '10'  
                        />
                    </div>
                </div>
                <div className="col-12 mt-5 row justify-content-center">
                    <button type="submit" className="btn btn-primary col-5" disabled={!(isValid && dirty && !isSubmitting)}>
                        Salva
                    </button>
                </div>
                <br />
                </fieldset>
            </Form>
            ) }
           </Formik>
        </>
    )
} 

export default PagamentoInserimentoForm;