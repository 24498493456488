import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useUser() {
    function getUser() {
        const token = localStorage.getItem('token');
        return token
    }

    const [user, setUser] = useState(getUser());

    const saveUser = userData => {
        localStorage.setItem('token', userData.token);
        localStorage.setItem('nome', userData.nome);
        localStorage.setItem('cognome', userData.cognome);
        localStorage.setItem('codice_fiscale', userData.codice_fiscale);
        localStorage.setItem('id', userData.id);
        localStorage.setItem('sezione', '');
        setUser(userData.token);
    };

    return {
        setUser: saveUser,
        user
    }
}
