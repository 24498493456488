import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import alertify from "alertifyjs";  
import formatter from '../../../common/utils/formatters/_';
import Modal from 'react-bootstrap/Modal'
import SicondirTable from "../../../common/components/SicondirTable";
import RiferimentoPagamentoRicerca from './RiferimentoPagamentoRicerca';
import RiferimentoPagamentoImportoEditForm from '../../../common/forms/RiferimentoPagamentoImportoEditForm';

function PagamentoRiferimento(props) {
    const [list, setList] = useState([])
    const params = useParams();
    const [pagamentoRiferimento, setPagamentoRiferimento] = useState(null)
    const [show, setShow] = useState(false);
    const [associazione, setAssociazione] = useState(false);
    const [cancellaAssociazione, setCancellaAssociazione] = useState(false);
    const [loading, setLoading] = useState(true);
    const [importoMassimoPagabile, setImportoMassimoPagabile] = useState(0)

    const [pagamentoId, setPagamentoId] = useState(params.id);

    // variabili per la modifica
    const [showEdit, setShowEdit] = useState(false);
    const [debitoEdit, setDebitoEdit] = useState({});
    const [modificaAssociazione, setModificaAssociazione] = useState(false);
    
    const handleModal = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }
    
    const handleDelete = async (idAssociazione) => {
        setLoading(true);
        const payload = {
            nome_tabella: "pagamenti_oggetto",
            id: idAssociazione,
            utente_modifica_id: localStorage.id
        }
        
        try{
            const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
                method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
                headers: { 'Content-Type': 'application/json',
                            'Accept': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:payload})
            })
            const data = await response.json();
            alertify.success("Associazione pagamento/debito eliminata con successo");
            
            setLoading(false)
            setCancellaAssociazione(true)
        } catch(error) {
            alertify.error("Errore durante la cancellazione dell'associazione bene pagamento/debito");
            setLoading(false)
        }
    }
    
    const handleModalEdit = (debito) => {
        setDebitoEdit(debito)
        setShowEdit(true);
    }
    const handleCloseEdit = () => {
        setShowEdit(false);
    }

    const fetchData = async (pagamentoId) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `pagamenti_oggetto/pagamento_id/${pagamentoId}`, 
            {
                method: 'GET',
                headers: { accept:'application/json' }, 
                mode: 'cors',  
            })
            const json = await response.json()
            if(json.risposta.pagamenti_oggetto){
                let response = json.risposta.pagamenti_oggetto
                setPagamentoRiferimento(response)
                const lista = []
                if(Array.isArray(response)) {
                    const entries = Object.entries(response);
                    let i = 0;
                    entries.map(([key,value]) => {
                        formatter.cleanObject(value)
                        let debito = {}
                        debito["id_pagamento_oggetto"] = value.id
                        debito["id_debito"] = value.canone_id
                        debito["riferimento_codice_sicondir"] = value.riferimento_codice_sicondir
                        debito["riferimento_codice_sistema_precedente"] = value.riferimento_codice_sistema_precedente
                        debito["canone_identificativo_debito"] = value.canone_identificativo_debito
                        debito["canone_importo_totale"] = value.canone_importo_totale
                        debito["canone_importo_pagato"] = value.canone_importo_pagato
                        debito["pagamento_importo_pagato"] = value.importo_pagato
                        let actions = buildActions(value.id, debito)
                        let codice = (value.riferimento_codice_sicondir || "-") + ("(" + (value.riferimento_codice_sistema_precedente || "-") + ")");
                        let annoContabile = value.canone_anno_contabile || "-"
                        let identificativoDovuto = value.canone_identificativo_debito || "-";
                        let tipoDebito = value.canone_tipo_pagamento == 'CA' ? "Canone" : (value.canone_tipo_pagamento == 'CO' ? "Conguaglio" : "-") ;
                        let importoDebito = formatter.formatNumber(value.canone_importo_totale);
                        let importoPagato = formatter.formatNumber(value.importo_pagato);

                        lista[i]=
                        {
                          id: value.id,
                          codice: codice,
                          anno_contabile: annoContabile,
                          identificativo_dovuto: identificativoDovuto,
                          tipo_debito: tipoDebito,
                          importo_debito: importoDebito,
                          importo_pagato: importoPagato,
                          app_actions: actions
                        }
                        i++
                    })
                } 
                setList(lista)

            } else{
                setPagamentoRiferimento(null)
            }
            setAssociazione(false)
            setCancellaAssociazione(false)
            setModificaAssociazione(false)
            setLoading(false);

        } catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
        }
    }

    const buildActions = (idAssociazione, debito, importoPagato) => {
        let result = {};
        result = { associa: {
                    kind: "function", 
                    function: handleDelete,
                    parameters:
                    {
                        idAssociazione: idAssociazione
                    },
                    label: "Elimina riferimento" ,
                    buttonClass: "btn-danger",
                    linkClass: "text-danger",
                    confirmLabel: "Procedere con l'eliminazione del riferimento pagamento/debito?"
                },
                 modifica: {
                    kind: "function", 
                    function: handleModalEdit,
                    parameters:
                    {
                        debito: debito,
                        importoPagato: importoPagato
                    },
                    label: "Modifica riferimento" 
                    
                }
        }
        return result;        
    }

    useEffect(() => {
        if(associazione || cancellaAssociazione || modificaAssociazione) props.setAggiornamento(true)
        fetchData(pagamentoId)
    }, [associazione, cancellaAssociazione, modificaAssociazione])
    
  return (
    <>
    { !loading
        ?
        <>
        { !pagamentoRiferimento 
            ?
            <div className="row">
                <div className="col-12 mt-5 row justify-content-center">
                    Nessun riferimento è associato a questo pagamento.
                </div>
            </div>
            :
            <div>
            <br />
            <SicondirTable 
                headers={[
                  "Codice Concessione/Occupazione",
                  "Anno Contabile", 
                  "Identificativo Dovuto",  
                  "Tipo Debito",
                  "Importo Debito(€)",
                  "Importo Pagato(€)",
                  ""
                  ]} 
                data={list}
                dropdown={true} />
            </div>
        }
            <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModal} disabled={props.importoUtilizzabile <= 0}>
                    Associa Riferimento
                </button>
            </div>
        </>
        :
        <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="progress-spinner progress-spinner-active">
                <span className="sr-only">Caricamento...</span>
            </div>
        </div>

    }

        <Modal 
            dialogClassName={'modal-fullpage'}
            show={show} 
            onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title >
                    Associa Riferimento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RiferimentoPagamentoRicerca
                    pagamento={props.pagamento} 
                    handleClose={handleClose} 
                    setAssociazione={setAssociazione} />
            </Modal.Body>
        </Modal>

        <Modal 
            size="xl"
            show={showEdit} 
            onHide={handleCloseEdit}>
            <Modal.Header closeButton>
                <Modal.Title >
                    Modifica Importo Da Riconciliare
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RiferimentoPagamentoImportoEditForm
                    pagamento={props.pagamento}
                    debito={debitoEdit}
                    importoMassimoPagabile={importoMassimoPagabile}
                    handleClose={handleCloseEdit} 
                    setAssociazione={setModificaAssociazione} 
                />
            </Modal.Body>
        </Modal>
    </>
  )
}

export default PagamentoRiferimento