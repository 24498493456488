import React from "react";
import { useState, useEffect } from "react";
import sprite from 'bootstrap-italia/dist/svg/sprite.svg';

export default function SicondirPaginator(props){

    const[currentPage, setCurrentPage] = useState(props.currentPage);
    const[lastPage, setLastPage] = useState(Math.ceil(props.totalElements/process.env.REACT_APP_PAGINATION));

    const handleClick = (e, index) => {
        props.setCurrentPage(index)
    }

    const handleBack = (e) => {
        e.preventDefault();
        if(currentPage > 1 ) props.setCurrentPage(currentPage-1)
    }

    const handleForward = (e) => {
        e.preventDefault();
        if(currentPage < lastPage) props.setCurrentPage(currentPage+1)
    }

    // funzione alla quale passiamo indice di pagina e pagina corrente per capire se stampare il link o il riquadro della pagina corrente.
    const printCurrentPage = (index, currentPage, ellipsisBefore, ellipsisAfter) => {
        if(currentPage){
            return (
                    <React.Fragment key={index} >
                        {ellipsisBefore && 
                            <li  className="page-item"><span className="page-link">...</span></li>
                        }
                        <li className="page-item active">
                            <a className="page-link" href="#" aria-current="page">
                                <span className="d-inline-block d-sm-none">Pagina </span>{index}
                            </a>
                        </li>
                        {ellipsisAfter && 
                            <li className="page-item"><span className="page-link">...</span></li>
                        }
                    </React.Fragment>
                    )
        } else {
            return (
                    <React.Fragment key={index} >
                        {ellipsisBefore && 
                            <li className="page-item"><span className="page-link">...</span></li>
                        }   
                        <li className="page-item">
                            <a className="page-link" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{index}</a>
                        </li>
                        {ellipsisAfter && 
                            <li className="page-item"><span className="page-link">...</span></li>
                        }
                    </React.Fragment>
            )
        }
    }

    const printPagination = (currentPage, lastPage) => {
        let pages = [] 

        
        
        // Se ho al massimo 5 pagine: stampo tutto!
        if(lastPage <= 5){
            for (let i = 1; i <= lastPage; i++) {
                pages.push(printCurrentPage(i, i == currentPage), false, false)
            } 
        } else { // Se ho più di 5 elementi...
            for (let i = 1; i <= lastPage; i++) {
                // la prima pagina e l'ultima le stampo sempre
                if(i == 1 || i == lastPage){ 
                    if(currentPage == lastPage && i == lastPage) {
                        pages.push(printCurrentPage(i-1, false, true, false))
                        pages.push(printCurrentPage(i-2, false, false, false))
                    }
                    pages.push(printCurrentPage(i, i == currentPage, false, false))
                    if(currentPage == 1 && i == 1){
                        pages.push(printCurrentPage(i+1, false, false, false))
                        pages.push(printCurrentPage(i+2, false, false, true))
                    }
                } else if(i == currentPage){ // stampo solo la current page con la precedente e la successiva
                    if(i-1 != 1) pages.push(printCurrentPage(i-1, i-1 == currentPage, i>=4 , false))
                    pages.push(printCurrentPage(i, i == currentPage, false, false))
                    if(i+1 != lastPage) pages.push(printCurrentPage(i+1, i+1 == currentPage, false, i<=lastPage-3))
                }
            } 
        }
        return pages;
    }

    return(
        <>
        {lastPage > 1 && 
            <nav className="pagination-wrapper pagination-total">
                    <ul className="pagination">
                        <li className="page-item ">
                        <a className="page-link" href="#" tabIndex="-1" aria-hidden="true" onClick={(e) => handleBack(e)} >
                            <svg className="icon icon-primary"><use href={sprite + "#it-chevron-left"}></use></svg>
                            <span className="sr-only">Pagina precedente</span>
                        </a>
                        </li>
                        {
                            printPagination(currentPage, lastPage)
                        }
                        <li className="page-item">
                        <a className="page-link " href="#" tabIndex="-1" aria-hidden="true" onClick={(e) => handleForward(e)}>
                            <span className="sr-only">Pagina successiva</span>
                            <svg className="icon icon-primary"><use href={sprite + "#it-chevron-right"}></use></svg>
                        </a>
                        </li>
                    </ul>
                    <p><span className="sr-only">Pagina</span>{currentPage} di {lastPage}</p>
                    <p>Totale {props.totalElements} elementi </p>
            </nav>
        }
        </>
        
    )

}