import React from 'react'
import { useEffect, useState } from 'react'
import tipiIndirizzi from '../../_data/tipi_indirizzi.json'
//import Comuni from '../../_data/comuni.json'
//import Province from '../../_data/province.json'
import Nazioni from '../../_data/nazioni.json'
import alertify from "alertifyjs";
import DeleteIndirizzoContatto from "./DeleteIndirizzoContatto";
import Modal from 'react-bootstrap/Modal';
import IndirizzoEdit from "../components/IndirizzoEdit";


const SchedaIndirizzo = (props) => {
 // console.log(props)
  const[show, setShow] = useState(false);

  const handleModal = () => {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
  }
  const settaTipoIndirizzo = () => {
    if(props.indirizzo.tipo_indirizzo_id != undefined) {
      let ObjTipoIndirizzo = {}
      ObjTipoIndirizzo = tipiIndirizzi.find(t => t.value == props.indirizzo.tipo_indirizzo_id)
      return JSON.stringify(ObjTipoIndirizzo.label).replaceAll('"','')
     }
  }

  // const settaProv = () => {
  //   console.log('propsind',props.indirizzo.provincia)
  //   if(props.indirizzo.provincia != undefined) {
  //     const ObjComune = Comuni.find(
  //       c => c.sigla === props.indirizzo.provincia
  //     )
  //     console.log(ObjComune)
  //      return (ObjComune)?ObjComune.sigla:''
  //   }
  // }
  
  // const settaComune = () => {
  //   if(props.indirizzo.provincia != undefined) {
  //     const ObjComune = Comuni.find(comune => comune.nome === props.indirizzo.comune)
  //     return (ObjComune)?ObjComune.nome:''
  //   }
  // }
 
  const scriviNazione = () => {
    let res = Nazioni.filter((n) => n.minint == props.indirizzo.nazione)
    
    if(res.length)
      return res[0].italian_country_name_1
  } 
 
  return (
    <>
      <div className="col-3 md-12 sm-12 sicondir-label">
      Tipo indirizzo<br/><span style={{color:'#000'}}>{settaTipoIndirizzo()}</span>
      </div>
      <div className="col-3 md-12 sm-12 sicondir-label">
      Nazione<br/>
      <span style={{color:'#000'}}>
        { scriviNazione() }
      </span>
      </div>
      <div className="col-3 md-12 sm-12 sicondir-label">
      Provincia<br/><span style={{color:'#000'}}>{props.indirizzo.provincia}</span>
      </div>
      <div className="col-3 md-12 sm-12 sicondir-label">
      Comune<br/><span style={{color:'#000'}}>{props.indirizzo.comune}</span>
      </div>
      <div className="col-3 md-12 sm-12 sicondir-label">
      CAP<br/><span style={{color:'#000'}}>{props.indirizzo.cap}</span>
      </div>
      <div className="col-3 md-12 sm-12 sicondir-label">
      Localit&agrave;<br/><span style={{color:'#000'}}>{props.indirizzo.localita}</span>
      </div>
      <div className="col-3 md-12 sm-12 sicondir-label">
      Indirizzo<br/><span style={{color:'#000'}}>{props.indirizzo.indirizzo}</span>
      </div>
      <div className="col-3 md-12 sm-12 sicondir-label">
      Numero civico<br/><span style={{color:'#000'}}>{props.indirizzo.civico}</span>
      </div>
      <div className="col-12 text-right">
        <Modal 
        //  dialogClassName={'modal-fullpage'}
        size="xl"  
        show={show} 
          onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title >
              Modifica indirizzo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IndirizzoEdit
              handleClose = {handleClose} 
              valueNaturaGiuridica = { props.valueNaturaGiuridica }
              indirizzo = { props.indirizzo } 
              setIndirizzoCambiato = { props.setIndirizzoCambiato }
            />
          </Modal.Body>
        </Modal> 
        <button className="btn btn-primary col-2 mr-2" type="button" onClick={handleModal}>
          Modifica indirizzo
        </button>
        <DeleteIndirizzoContatto 
          id={props.indirizzo.id} 
          contesto='indirizzo' 
          setIndirizzoCambiato={props.setIndirizzoCambiato}
          setLoading = {props.setLoading}
        />
      </div>
    </>
  )
}
export default SchedaIndirizzo
