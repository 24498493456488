import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import SicondirTable from "../../../common/components/SicondirTable";
import alertify from "alertifyjs";
import BeniDemanialiRicercaForm from "../../../common/forms/BeniDemanialiRicercaForm";
import formatter from "../../../common/utils/formatters/_";
import BeniDemanialiForm from "../../../common/forms/BeniDemanialiForm";
import _ from 'lodash'
import SicondirPaginator from "../../../common/components/SicondirPaginator";

export default function BeniDemanialiRicerca(props) {

    let navigate = useNavigate();
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [sent, setSent] = useState(false);
    const [concessioneId, setConcessioneId] = useState(props.concessioneId);
    const [richiestaId, setRichiestaId] = useState(props.richiestaId);
    const [occupazioneId, setOccupazioneId] = useState(props.occupazioneId);
    const [denunciaPozzoId, setDenunciaPozzoId] = useState(props.denunciaPozzoId);
    const [enableForm, setEnableForm] = useState(props.inserisciBene ? props.inserisciBene : false);
    const [beneDemanialeInserito, setBeneDemanialeInserito] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [currentMacroambito, setCurrentMacroambito] = useState(props.macroambito);

    let contesto = props.contesto ? props.contesto : 'anagrafica';

    let macroambitoUrl = '';
    if(props.macroambito == 'F'){
      macroambitoUrl = 'fluviali-lacuali'
    } else {
      macroambitoUrl = 'derivazioni'
    }


    // Qua ho RICERCATO i beni demaniali, ne ho scelto uno e lo associo alla concessione.
    const associaConcessioneBene = async (id, concessioneId) => {
      let campi = "concessione_id,catasto_demaniale_derivazione_id,utente_modifica_id";
      let valori = concessioneId + "," + id + "," + localStorage.id
      const concessioneBene = {
        nome_tabella: "concessioni_catasto",
        lista_campi: campi,
        lista_valori: valori
      }
      try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }, 
          mode: 'cors', 
          body: JSON.stringify({Entry:concessioneBene})
        })
        const data_response = await response.json();
        if(data_response.hasOwnProperty('Fault')){
          if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
            console.log("ErrorCode: " + data_response.Fault.faultcode)
            throw new Error(data_response.Fault.faultstring)
          } else{
            throw new Error("errore durante il salvataggio.")
          }
        }
        alertify.success("Bene demaniale/derivazione associato alla concessione con successo.");
        props.setAssociazione(true)
        props.handleClose()
      } catch(error) {
        alertify.error("Errore durante l'associazione.");
        console.log("errore: ", error);
      }
    }
    useEffect(()=>{
      setCurrentMacroambito(props.macroambito)
    }, [props.macroambito])
    // Qua ho INSERITO un bene demaniale e lo associo alla concessione.
    useEffect(() => {
      if(!_.isEmpty(beneDemanialeInserito)){
        contesto == 'concessioni' && associaConcessioneBene(beneDemanialeInserito, concessioneId)
        contesto == 'richieste' && associaRichiestaBene(beneDemanialeInserito, richiestaId)
        contesto == 'occupazione' && associaOccupazioneBene(beneDemanialeInserito, occupazioneId)
        contesto == 'denuncia_pozzo' && associaDenunciaPozzoBene(beneDemanialeInserito, denunciaPozzoId)
      } 
    }, [beneDemanialeInserito])
  
    // Qua ho RICERCATO i beni demaniali, ne ho scelto uno e lo associo alla domanda.
    const associaRichiestaBene = async (id, richiestaId) => {
      let campi = "richiesta_id,catasto_demaniale_derivazione_id,utente_modifica_id";
      let valori = richiestaId + "," + id + "," + localStorage.id
      const richiestaBene = {
        nome_tabella: "richieste_concessione_catasto",
        lista_campi: campi,
        lista_valori: valori
      }
      try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }, 
          mode: 'cors', 
          body: JSON.stringify({Entry:richiestaBene})
        })
        const data_response = await response.json();
        if(data_response.hasOwnProperty('Fault')){
          if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
            console.log("ErrorCode: " + data_response.Fault.faultcode)
            throw new Error(data_response.Fault.faultstring)
          } else{
            throw new Error("errore durante il salvataggio.")
          }
        }
        alertify.success("Bene demaniale/derivazione associato alla domanda con successo.");
        props.setAssociazione(true)
        props.handleClose()
      } catch(error) {
        alertify.error("Errore durante l'associazione.");
        console.log("errore: ", error);
      }
    }

    const associaOccupazioneBene = async (id, occupazioneId) => {
      let campi = "occupazione_id,catasto_demaniale_derivazione_id,utente_modifica_id";
      let valori = occupazioneId + "," + id + "," + localStorage.id
      const occupazioneBene = {
        nome_tabella: "occupazioni_catasto",
        lista_campi: campi,
        lista_valori: valori
      }
      try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }, 
          mode: 'cors', 
          body: JSON.stringify({Entry:occupazioneBene})
        })
        const data_response = await response.json();
        if(data_response.hasOwnProperty('Fault')){
          if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
            console.log("ErrorCode: " + data_response.Fault.faultcode)
            throw new Error(data_response.Fault.faultstring)
          } else{
            throw new Error("errore durante il salvataggio.")
          }
        }
        alertify.success("Bene demaniale/derivazione associato all'occupazione con successo.");
        props.setAssociazione(true)
        props.handleClose()
      } catch(error) {
        alertify.error("Errore durante l'associazione.");
        console.log("errore: ", error);
      }
    }

    const associaDenunciaPozzoBene = async (id, denunciaPozzoId) => {
      let campi = "denuncia_pozzo_id,catasto_demaniale_derivazione_id,utente_modifica_id";
      let valori = denunciaPozzoId + "," + id + "," + localStorage.id
      const denunciaPozzoBene = {
        nome_tabella: "denunce_pozzo_catasto",
        lista_campi: campi,
        lista_valori: valori
      }
      try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }, 
          mode: 'cors', 
          body: JSON.stringify({Entry:denunciaPozzoBene})
        })
        const data_response = await response.json();
        if(data_response.hasOwnProperty('Fault')){
          if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
            console.log("ErrorCode: " + data_response.Fault.faultcode)
            throw new Error(data_response.Fault.faultstring)
          } else{
            throw new Error("errore durante il salvataggio.")
          }
        }
        alertify.success("Bene demaniale/derivazione associato alla denuncia pozzo con successo.");
        props.setAssociazione(true)
        props.handleClose()
      } catch(error) {
        alertify.error("Errore durante l'associazione.");
        console.log("errore: ", error);
      }
    }

    // Qua ho INSERITO un bene demaniale e lo associo alla domanda.
    useEffect(() => {
      if(!_.isEmpty(beneDemanialeInserito)){
        associaRichiestaBene(beneDemanialeInserito, richiestaId)
      } 
    }, [beneDemanialeInserito])

    const showForm = () => {
      setEnableForm(true)
    }

    const hideForm = () => {
      setEnableForm(false)
    }


    const buildActions = (contesto, id) => {
        let result = {};
        switch(contesto){
          case 'anagrafica':
            result = { update: "/beni-demaniali/" + macroambitoUrl + "/modifica/" + id,
                      delete: {
                          table: "catasto_demaniale_derivazioni",
                          id: id
                      },
                    }
          break;
          case 'concessioni':
            result = { associa: {
                          kind: "function", 
                          function: associaConcessioneBene,
                          parameters:
                          {
                            idBene: id,
                            idConcessione: concessioneId
                          },
                          label: "Associa a concessione" }
                    }
          break;
          case 'richieste':
            result = { associa: {
                          kind: "function", 
                          function: associaRichiestaBene,
                          parameters:
                          {
                            idBene: id,
                            idRichiesta: richiestaId
                          },
                          label: "Associa a domanda" }
                    }
          break;
          case 'occupazione':
            result = { associa: {
                          kind: "function", 
                          function: associaOccupazioneBene,
                          parameters:
                          {
                            idBene: id,
                            idOccupazione: occupazioneId
                          },
                          label: "Associa a occupazione" }
                    }
          break;
          case 'denuncia_pozzo':
            result = { associa: {
                          kind: "function", 
                          function: associaDenunciaPozzoBene,
                          parameters:
                          {
                            idBene: id,
                            idDenunciaPozzo: denunciaPozzoId
                          },
                          label: "Associa a denuncia pozzo" }
                    }
          break;
          default: break;
        }
        return result;        
    }
    let headers = [ "Comune", "Località", "Foglio", "Particella", /*"Subalterno", "Sezione",*/ "Desc. catasto", "Note", "Stato", ""];
    useEffect(() => {
      const fetchData = async (filter) => {
        setLoading(true);

        let inCollegati = " ";
        if(props.beniCollegati && props.beniCollegati.length > 0) {
          let array = props.beniCollegati;
          array.map(element => {
            inCollegati += element + ","
          })
          inCollegati = inCollegati.slice(0, -1)
        }

        let pagination = (currentPage - 1)*process.env.REACT_APP_PAGINATION+","+process.env.REACT_APP_PAGINATION

        // let url = process.env.REACT_APP_API_URL + 'catasto_demaniale_derivazioni/ricerca/'+whereCondition+"/"+pagination;
        let propsMacroambito = props.macroambito ? encodeURI(props.macroambito) : " ";
        let tipoAmbitoId = filter.tipo_ambito_id ? encodeURI(filter.tipo_ambito_id) : " "
        let provincia = filter.provincia ? encodeURI(filter.provincia) : " " 
        let tipoComuneId = filter.tipo_comune_id ? encodeURI(filter.tipo_comune_id) : " "
        let indirizzo = filter.indirizzo ? encodeURI(filter.indirizzo) : " "
        let localita = filter.localita ? encodeURI(filter.localita) : " "
        let catastoSezione = filter.catasto_sezione ? encodeURI(filter.catasto_sezione) : " "
        let catastoFoglio = filter.catasto_foglio ? encodeURI(filter.catasto_foglio) : " "
        let catastoParticella = filter.catasto_particella ? encodeURI(filter.catasto_particella) : " "
        let catastoSub = filter.catasto_sub ? encodeURI(filter.catasto_sub) : " "
        let url = process.env.REACT_APP_API_URL + 'catasto_demaniale_derivazioni/ricerca/'
                + propsMacroambito + "/"
                + tipoAmbitoId + "/"
                + provincia + "/"
                + tipoComuneId + "/"
                + indirizzo + "/"
                + localita + "/"
                + catastoSezione + "/"
                + catastoFoglio + "/"
                + catastoParticella + "/"
                + catastoSub + "/"
                + inCollegati + "/"
                + pagination;

        let response = await fetch(url, 
            {
                method: 'GET',
                headers: { accept:'application/json' }, 
                mode: 'cors',  
            })
            let json = await response.json()

          let list = [];
          let i = 1;
          setTotalElements(0);
          if(json.risposta.catasto_demaniale_derivazioni){
            if(Array.isArray(json.risposta.catasto_demaniale_derivazioni)){
              setTotalElements(json.risposta.catasto_demaniale_derivazioni[0].conteggio);
              json.risposta.catasto_demaniale_derivazioni.map(element => {
                element = formatter.cleanObject(element)
                let actions = buildActions(contesto, element.id)
                list[i] = {
                  id: element.id,
                  comune_nome: (element.comune_nome || " - ") + "(" + (element.provincia_sigla || " - ") + ")" ,
                  localita: element.localita || " - ",
               //  pozzo: element.identificativo_pozzo || " - ",
                  catasto_foglio: element.catasto_foglio || " - ",
                  catasto_particella: element.catasto_particella || " - ",
                 // catasto_sub: element.catasto_sub || " - ",
                //  catasto_sezione: element.catasto_sezione || " - ",

                  descrizione_tipo_catasto: element.descrizione_tipo_catasto || " - ",
                  popover: element.note,
                  codice_stato: element.codice_stato || " - ",
                  app_actions: actions
                 
                }
                if(props.macroambito=='F'){ 
                  delete list[i]["pozzo"]
                }
                i++
               
              });
            }
            
          }
          
          setList(list); 
          setLoading(false);
      }

      if(sent){
        fetchData(filter);
      }

    }, [filter, sent, currentPage]) 

    useEffect(() => {
      setSent(false)
    }, [currentMacroambito]);

    return (
       
      <>
            { enableForm
              ?
              <>
                <button className="btn btn-primary" onClick={hideForm}>
                  Vai alla ricerca
                </button>
                <br />
                <br />
                <BeniDemanialiForm fromAssociazione={true} macroambito={props.macroambito} setBeneDemanialeInserito={setBeneDemanialeInserito}></BeniDemanialiForm>
              </>
              :
              <>
                <BeniDemanialiRicercaForm setFilter={setFilter} setSent={setSent} setCurrentPage={setCurrentPage} disabled={loading} macroambito={props.macroambito} />
              
                <br /><br />

                {!loading ?

                  <>
                  { sent 
                    ?
                    <>
                    <SicondirTable 
                      headers={headers} 
                      data={list}
                      compact={true}
                      />
                      
                      <SicondirPaginator 
                        totalElements={totalElements}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    
                    </>
                    :
                    <></>
                  }
                
                    {contesto == 'anagrafica' &&
                      <>
                      <br />
                      <div className="float-right">
                        <button className="btn btn-primary" onClick={() => navigate('/beni-demaniali/' + macroambitoUrl + '/inserisci')}>
                          Inserisci nuovo bene demaniale / derivazione
                        </button>
                      </div>
                      </>
                    }

                    { sent && (contesto == 'concessioni' || contesto == 'richieste' || contesto == 'occupazione' || contesto == 'denuncia_pozzo') && 
                      <>
                      <div className="float-right">
                        <button className="btn btn-primary" onClick={showForm}>
                          Inserisci nuovo bene demaniale / derivazione
                        </button>
                      </div>
                      <br/><br/>
                      </>
                    }

                  </>
              
                    :
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <div className="progress-spinner progress-spinner-active">
                        <span className="sr-only">Caricamento...</span>
                      </div>
                    </div>
                
                  }
              </>

            }
            
          
      </>

    );
        }
       
      
  
