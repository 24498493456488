import { useEffect, useState } from 'react'
import formatter from '../utils/formatters/_';
import alertify from "alertifyjs";  
import TriggerPopover from './TriggerPopover';

export default function SicondirRigaUsi(props) {

    const [loading, setLoading] = useState(true)
    const [usi, setUsi] = useState([])

    let headersUsi = ["Codice", "Descrizione categoria", "Descrizione sottocategoria", "Unità misura", "Valore", "Durata", "Descrizione uso"];
    
    let idAssociazione = props.idAssociazione;
    
    useEffect(()=>{
        const fetchData = async (idAssociazione, contesto)  => {
            try{

                let urlUsi = process.env.REACT_APP_API_URL + 'concessioni_catasto_uso/concessione_catasto_id/'+idAssociazione;
                if(contesto == "richiesta"){
                    urlUsi = process.env.REACT_APP_API_URL + 'richieste_concessione_catasto_uso/richiesta_concessione_catasto_id/'+idAssociazione;
                }
                if(contesto == "occupazione"){
                    urlUsi = process.env.REACT_APP_API_URL + 'occupazioni_catasto_uso/occupazione_catasto_id/'+idAssociazione;
                }
                if(contesto == "denuncia_pozzo"){
                    urlUsi = process.env.REACT_APP_API_URL + 'denunce_pozzo_catasto_uso/denuncia_pozzo_catasto_id/'+idAssociazione;
                }
                let usiResponse = await fetch(urlUsi, {
                    method: 'GET',
                    headers: { accept: "application/json" },      
                    mode: 'cors'
                });
                let json = await usiResponse.json();
                let arrayUsi = [];
                
                if(json.risposta.concessioni_catasto_uso){
                    let response = formatter.cleanObject(json.risposta.concessioni_catasto_uso)
                    if(Array.isArray(response)){
                        json.risposta.concessioni_catasto_uso.map(element =>{
                            element = formatter.cleanObject(element)
                            arrayUsi.push(element);
                        })
                    }
                } else if(json.risposta.richieste_concessione_catasto_uso){ // se sono in richieste
                    let response = formatter.cleanObject(json.risposta.richieste_concessione_catasto_uso)
                    if(Array.isArray(response)){
                        json.risposta.richieste_concessione_catasto_uso.map(element =>{
                            element = formatter.cleanObject(element)
                            arrayUsi.push(element);
                        })
                    }
                } else if(json.risposta.occupazioni_catasto_uso){ // se sono in occupazioni
                    let response = formatter.cleanObject(json.risposta.occupazioni_catasto_uso)
                    if(Array.isArray(response)){
                        json.risposta.occupazioni_catasto_uso.map(element =>{
                            element = formatter.cleanObject(element)
                            arrayUsi.push(element);
                        })
                    }
                } else if(json.risposta. denunce_pozzo_catasto_uso){ // se sono in denunce_pozzo
                    let response = formatter.cleanObject(json.risposta. denunce_pozzo_catasto_uso)
                    if(Array.isArray(response)){
                        json.risposta. denunce_pozzo_catasto_uso.map(element =>{
                            element = formatter.cleanObject(element)
                            arrayUsi.push(element);
                        })
                    }
                }
                setUsi(arrayUsi)
                setLoading(false)
            }
            catch (error) {
                alertify.error("Errore durante il recupero dei dati.");
                console.log("errore: ", error);
                
            } 
        };    
        
        fetchData(props.idAssociazione, props.contesto)
    }, [])


    return(
        <>
        {!loading ?
            <>
                {usi.length ? 
                    <>
                    <tr key={idAssociazione} >
                        <th scope="row" rowSpan={usi.length +1 } colSpan='1' style={{verticalAlign: 'middle'}}>Usi</th>
                        { headersUsi.map((col) => {
                            // descrizione sottocategoria lo metto più lungo...
                            let colSpan = (col == "Descrizione sottocategoria") ? '2' : '1'
                            return ( <th className="table-light" colSpan={colSpan} key={col}> {col} </th> )
                            })
                        }
                    </tr>
                    
                    {usi.map((uso) => {
                        return (
                            <tr className="table-light" id={idAssociazione + "-" +uso.id} key={idAssociazione + "-" +uso.id}>
                                <td> {uso.codice || " - "} </td>
                                <td> {uso.descrizione_categoria || " - "} </td>
                                <td colSpan='2'> {uso.descrizione_sottocategoria || " - "} </td>
                                <td> {uso.unita_misura || " - "} </td>
                                <td> {uso.valore || " - "} </td>
                                <td> {uso.durata || " - "} </td>
                                <td> 
                                    { uso.descrizione_uso
                                        ?  <TriggerPopover placement="bottom" text={uso.descrizione_uso} />
                                        :  " - "
                                    }
                                    
                                </td>
                            </tr>
                        )   
                        })
                    } 
                    </>
                    :
                    <></>
                }
                
            </>
            :
            <tr>
                <td colSpan="9">
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        <div className="progress-spinner progress-spinner-active">
                        <span className="sr-only">Caricamento...</span>
                        </div>
                    </div>
                </td>
            </tr>
            
        }
        </>
    )
}
