import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import SicondirTable from "../../../common/components/SicondirTable";
import alertify from "alertifyjs";
import PagamentiRicercaForm from "../../../common/forms/PagamentiRicercaForm";
import formatter from "../../../common/utils/formatters/_";

export default function PagamentoImporti(props) {

    const [loading, setLoading] = useState(true);
    const [importoTotale, setImportoTotale] = useState(props.pagamento.importo);
    const [importoUtilizzato, setImportoUtilizzato] = useState(0);
    const [importoUtilizzabile, setImportoUtilizzabile] = useState(0);

    useEffect(()=>{
      const fetchData = async () => {
        setLoading(true);  
        let urlImporto = process.env.REACT_APP_API_URL + 'pagamenti/importo_utilizzato/'+props.pagamento.id;
        try {
          const response = await fetch(urlImporto, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          
          const json = await response.json();
          let resp = formatter.cleanObject(json.risposta.pagamento)

          let importoTotale = props.pagamento.importo || 0
          let importoUtilizzatoDb = resp.importo_utilizzato
          let importoUtilizzabile = importoTotale - importoUtilizzatoDb

          // Il pagamento può essere utilizzato per la cifra del proprio importo meno la cifra già utilizzata(calcolata sulla somma degli importi dei pagamenti_oggetto)
          setImportoTotale(importoTotale)
          setImportoUtilizzato(importoUtilizzatoDb)
          setImportoUtilizzabile(importoUtilizzabile)

          props.setImportoUtilizzato(importoUtilizzatoDb)
          props.setImportoUtilizzabile(importoUtilizzabile)
          setLoading(false);

        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
      };
      fetchData();
    }, [props.pagamento.importo, props.aggiornamento])

    return(
        <>
        { !loading?
            <>
            <div>
                Importo totale del pagamento: <span style={{ fontWeight: 'bold' }}>{formatter.formatNumber(importoTotale)}€</span><br />
                Importo utilizzato: <span style={{ fontWeight: 'bold' }}>{formatter.formatNumber(importoUtilizzato)}€</span> <br />
                Importo residuo del pagamento: <span style={{ fontWeight: 'bold' }}>{formatter.formatNumber(importoUtilizzabile)}€</span><br /><br />
            </div>
            </>
            :
            <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="progress-spinner progress-spinner-active">
                    <span className="sr-only">Caricamento...</span>
                </div>
            </div>
        }
        </>
        
    )
}