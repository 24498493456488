import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
export default function Dashboard(props) {
  let navigate = useNavigate();
  const gotoSezione = (navigate) => {
    
    let url='';
    if(localStorage.sezione=='Fluviali') {
      url = "/concessioni/fluviali-lacuali"
    } else if(localStorage.sezione=='Derivazioni'){
      url = "/concessioni/derivazioni"
    }
    navigate(url)
  } 
  useEffect(() => {
    gotoSezione(navigate)

  },[props.sezione])
  return (
      <></>
    );
  }