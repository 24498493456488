import { useState } from 'react'
import { Formik, Form} from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirSelect from "../../components/SicondirSelect";
import anniCanone from '../../../_data/anni_canone.json'
import SicondirInput from "../../components/SicondirInput";
import formatter from "../../utils/formatters/_"
import SicondirSingleCheckbox from "../../components/SicondirSingleCheckbox";

const CanoniDenunciaPozzoForm = (props) => {

    const tipiPagamento =[
      {},
      {}
    ]

    const [loading, setLoading] = useState(true);
    const [tipoDebitoSelezionato, setTipoDebitoSelezionato] = useState(null);
    
    const [importo, setImporto] = useState(0);
    const [sanzione, setSanzione] = useState(0);
    const [interesse, setInteresse] = useState(0);
    const [importoRivalutazione, setImportoRivalutazione] = useState(0);
    const [importoTotale, setImportoTotale] = useState(0);
    const [importoSanzioniInteressiTotale, setImportoSanzioniInteressiTotale] = useState(0)
    const [importoNominaleTotale, setImportoNominaleTotale] = useState(0)

    const handleImporti = (e, tipologia) => {
      let value = parseFloat(e.target.value.replace(",", "."));
      let it = parseFloat(importoTotale)
      if(/^((\d+(\,\d{0,2})?))$/.test(e.target.value)){
        switch(tipologia){
          case "importo": 
            it -= importo;
            setImporto(value);
            break;
          case "sanzione":
            it -= sanzione;
            setSanzione(value);
            break;
          case "interesse":
            it -= interesse;
            setInteresse(value);
            break;
          case "importoRivalutazione":
            it -= importoRivalutazione;
            setImportoRivalutazione(value);
            break;
        }
        it += value
        setImportoTotale(it);
      } else{
        switch(tipologia){
          case "importo": 
            it -= importo;
            setImporto(0);
            break;
          case "sanzione":
            it -= sanzione;
            setSanzione(0);
            break;
          case "interesse":
            it -= interesse;
            setInteresse(0);
            break;
          case "importoRivalutazione":
            it -= importoRivalutazione;
            setImportoRivalutazione(0);
            break;
        }
        setImportoTotale(it);
      }
    }

    const handleImportiNominali = (e, tipologia) => {
      let value = parseFloat(e.target.value.replace(",", "."));
      let it = parseFloat(importoNominaleTotale)
      if(/^((\d+(\,\d{0,2})?))$/.test(e.target.value)){
        switch(tipologia){
          case "importo": 
            it -= importo;
            setImporto(value);
            break;
          case "importoRivalutazione":
            it -= importoRivalutazione;
            setImportoRivalutazione(value);
            break;
        }
        it += value
        setImportoNominaleTotale(it);
      } else{
        switch(tipologia){
          case "importo": 
            it -= importo;
            setImporto(0);
            break;
          case "importoRivalutazione":
            it -= importoRivalutazione;
            setImportoRivalutazione(0);
            break;
        }
        setImportoNominaleTotale(it);
      }
    }

    const handleImportiSanzioneInteressi = (e, tipologia) => {
      let value = parseFloat(e.target.value.replace(",", "."));
      let it = parseFloat(importoSanzioniInteressiTotale)
      if(/^((\d+(\,\d{0,2})?))$/.test(e.target.value)){
        switch(tipologia){
          case "sanzione":
            it -= sanzione;
            setSanzione(value);
            break;
          case "interesse":
            it -= interesse;
            setInteresse(value);
            break;
        }
        it += value
        setImportoSanzioniInteressiTotale(it);
      } else{
        switch(tipologia){
          case "sanzione":
            it -= sanzione;
            setSanzione(0);
            break;
          case "interesse":
            it -= interesse;
            setInteresse(0);
            break;
        }
        setImportoSanzioniInteressiTotale(it);
      }
    }

    return(
        <>
            <Formik 
              id='canone' className="p-4"

              initialValues={{
                tipo_pagamento: ''
              }}
              
              validationSchema={Yup.object({
                tipo_pagamento: Yup.string().required("Il campo è obbligatorio"),
                anno_contabile: Yup.string().required("Il campo è obbligatorio"),
                identificativo_debito: Yup.string().required("Il campo è obbligatorio"),
                importo: Yup.string().required("Il campo è obbligatorio").matches(/^((\d+(\,\d{0,2})?))$/, 'Inserire un valore numerico, i decimali(massimo 2) devono essere separati da ","'),
                sanzione: Yup.string().matches(/^((\d+(\,\d{0,2})?))$/, 'Inserire un valore numerico, i decimali(massimo 2) devono essere separati da ","'),
                interesse: Yup.string().matches(/^((\d+(\,\d{0,2})?))$/, 'Inserire un valore numerico, i decimali(massimo 2) devono essere separati da ","'),
                importo_rivalutazione: Yup.string().matches(/^((\d+(\,\d{0,2})?))$/, 'Inserire un valore numerico, i decimali(massimo 2) devono essere separati da ","')
              })}

              onSubmit = { async (values, { setSubmitting }) => {
                setSubmitting(true);
                let campi = ""
                let valori = ""
                Object.entries(values).map(([key,value],i) => {
                  
                  if(key == 'importo' || key == 'sanzione' || key == 'interesse' || key == 'importo_rivalutazione'){
                    if(value != ""){
                      value = value.replace(",", ".");
                    }
                  } 
                  // gestione della checkbox...
                  if(key == 'flag_iscrizione_ruolo'){
                    console.log("calue", value)
                    if(value){
                      value = '1'
                    } else{
                      value = '0'
                    }
                  }

                  if(value != '' && key != 'importo_nominale_totale' && key != 'importo_sanzioni_interessi_totale'){
                    campi += key + ", "
                    valori += "'" + formatter.escapeSql(value) + "', "
                  } 
                })
                
                if(valori == ""){
                  alertify.warning("Inserire almeno un campo");
                  return;
                }
              
                try{
                  campi += "denuncia_pozzo_id,"
                  valori += props.denunciaPozzoId+","

                  campi += "utente_modifica_id";
                  valori += localStorage.id
                  const denunce_pozzo_canone = {
                    nome_tabella: "denunce_pozzo_canoni",
                    lista_campi: campi,
                    lista_valori: valori
                  }
                  
                  const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:denunce_pozzo_canone})
                  })
                  const data_response = await response.json();
                  if(data_response.hasOwnProperty('Fault')){
                    if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + data_response.Fault.faultcode)
                      throw new Error(data_response.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }
                  // data_response.risposta.elementi_aggiunti.id
                  props.setAssociazione(true);
                  props.handleClose();
                  alertify.success("Importo dovuto creato con successo.");

                } catch(error) {
                  alertify.error("Errore durante l'inserimento: " + error);
                  console.log("errore: ", error);
                }
                
            }}
            >
            {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
              setTouched,
              setFieldTouched
            }) => (
            <Form>
                <br /><br />
                <SicondirSelect 
                  label="Tipo debito" 
                  id="tipo_pagamento" 
                  name="tipo_pagamento"
                  mandatory="true"
                  options={
                    [{ value: "CO",
                      label: "Conguaglio" 
                    },
                    { value: "CA",
                      label: "Canone" 
                    },
                    { value: "AC",
                      label: "Accertamento" 
                    }
                    ]
                  } 
                  placeholder="Seleziona Tipo Debito"
                />
                <br />
                <SicondirSelect 
                  label="Anno contabile" 
                  id="anno_contabile" 
                  name="anno_contabile" 
                  mandatory="true"
                  placeholder="Seleziona Anno Contabile"
                  options={anniCanone}
                />
                <br />
                <SicondirInput
                  label="Periodo di riferimento - inizio" 
                  id="data_periodo_riferimento_inizio" 
                  name="data_periodo_riferimento_inizio" 
                  type="date"
                />
                <br />
                <SicondirInput
                  label="Periodo di riferimento - fine" 
                  id="data_periodo_riferimento_fine" 
                  name="data_periodo_riferimento_fine" 
                  type="date"
                />
                <br />
                <SicondirInput 
                  label='Identificativo dovuto'
                  id='identificativo_debito'
                  type='text'
                  name='identificativo_debito'
                  size="large" 
                  mandatory="true"
                  placeholder = 'Inserisci identificativo importo dovuto'
                  maxLength = '100'
                />
                <br />
                <SicondirInput 
                  label='Protocollo comunicazione posizione debitoria'
                  id='protocollo_avviso_pagamento'
                  type='text'
                  name='protocollo_avviso_pagamento'
                  size="large" 
                  placeholder = 'Inserisci protocollo posizione debitoria'   
                  maxLength = '100'
                />
                <SicondirInput 
                  label='Data comunicazione posizione debitoria'
                  id='data_avviso_pagamento'
                  type='date'
                  name='data_avviso_pagamento'
                />
                <SicondirInput 
                  label='Data scadenza pagamento'
                  id='data_scadenza_pagamento'
                  type='date'
                  name='data_scadenza_pagamento'
                />
                <SicondirSingleCheckbox
                  label="Iscrizione ruolo" 
                  id='flag_iscrizione_ruolo'
                  name='flag_iscrizione_ruolo'
                />
                <h5>Importo nominale</h5>
                <SicondirInput
                  label='A - Importo base'
                  id='importo'
                  name='importo'
                  mandatory="true"
                  type='text'
                  onBlur={(e) => {
                    setFieldTouched('importo');
                    handleImporti(e, "importo")
                    handleImportiNominali(e, "importo")
                  }}
                  placeholder = 'Inserisci importo base'
                />
                <SicondirInput
                  label='B - Quota rivalutazione ISTAT'
                  id='importo_rivalutazione'
                  name='importo_rivalutazione'
                  type='text'
                  onBlur={(e) => {
                    setFieldTouched('importo_rivalutazione');
                    handleImporti(e, "importoRivalutazione")
                    handleImportiNominali(e, "importoRivalutazione")
                  }}
                  placeholder = 'Inserisci quota rivalutazione ISTAT'
                />
                <SicondirInput
                  label='C - Importo nominale totale(A+B)'
                  id='importo_nominale_totale'
                  name='importo_nominale_totale'
                  type='text'
                  value={formatter.formatNumber(importoNominaleTotale)}
                  disabled={true}
                />
                
                <h5>Importi per sanzioni e interessi</h5>
                <SicondirInput
                  label='D - Sanzione'
                  id='sanzione'
                  name='sanzione'
                  type='text'
                  onBlur={(e) => {
                    setFieldTouched('sanzione');
                    handleImporti(e, "sanzione")
                    handleImportiSanzioneInteressi(e, "sanzione")
                  }}
                  placeholder = 'Inserisci sanzione'
                />
                <SicondirInput
                  label='E - Interesse'
                  name='interesse'
                  type='text'
                  onBlur={(e) => {
                    setFieldTouched('interesse');
                    handleImporti(e, "interesse")
                    handleImportiSanzioneInteressi(e, "interesse")
                  }}
                  placeholder = 'Inserisci interesse'
                />
                <SicondirInput
                  label="Data calcolo interessi" 
                  id="data_calcolo_interessi" 
                  name="data_calcolo_interessi" 
                  type="date"
                />
                <SicondirInput
                  label='F - Importo sanzioni e interessi totale(D+E)'
                  name='importo_sanzioni_interessi_totale'
                  type='text'
                  value={formatter.formatNumber(importoSanzioniInteressiTotale)}
                  disabled={true}
                />
                
                <br /><br />
                <div>
                <b>IMPORTO TOTALE(C+F):</b> {formatter.formatNumber(importoTotale)} €
                </div>
                <div className="col-12 mt-5 row justify-content-center">
                  <button type="submit" className="btn btn-primary col-5" disabled={!isValid}>
                    Salva
                  </button>
                </div>
               <br/><br/>
            </Form>
            )}
            </Formik>
            
            
        </>
        
    )
   
}
export default CanoniDenunciaPozzoForm;