import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Formik, Field, Form, useFormik } from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirInput from "../components/SicondirInput";
import SicondirTextarea from "../components/SicondirTextarea";
import formatter from "../utils/formatters/_"
import tipiRuoli from '../../_data/tipi_ruoli_frontend.json'
import SicondirTable from "../components/SicondirTable";

const PersonaGiuridicaRicercaForm = (props) => {
    
  const [sent, setSent] = useState(false)
  const [filter, setFilter] = useState({})
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  
  const associaPersonaGiuridica = (personaGiuridica) => {
    props.associaPersonaGiuridica(personaGiuridica)
  } 

  const buildActions = (personaGiuridica) => {
    let result = {};

    result = { associa: {
        kind: "function", 
        function: associaPersonaGiuridica,
        parameters:
        {
          personaGiuridica: personaGiuridica,
        },
        label: "Associa" }
    }
      
    return result;        
  }
  

  useEffect(() => {
    const fetchData = async (filter, ids) => {
      setLoading(true)
      let ragioneSociale = filter.ragione_sociale ? filter.ragione_sociale.trim() : " ";
      let piva = filter.piva ? filter.piva.trim() : " ";
      let sicer = filter.codice_sicer ? filter.codice_sicer.trim() : " ";
      let idSelezionato = ids || "-";

      const url = process.env.REACT_APP_API_URL + 'persone/legale_rappresentante/' + ragioneSociale + '/' + piva + '/' + sicer + "/" + idSelezionato;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: { 
                    accept: "application/json"
                  },      
          mode: 'cors'
         });

         const json = await response.json();

         let list = [];
          let i = 1;
          if(json.risposta.persone){
            if(Array.isArray(json.risposta.persone)){
              let i = 0;
              json.risposta.persone.map(element => {
                element = formatter.cleanObject(element)
                let actions = buildActions(element)
                list[i] = {
                  id: element.id,                 
                  ragione_sociale: element.ragione_sociale || "-",
                  piva: element.ragione_sociale || "-",
                  codice_fiscale: element.codice_fiscale || "-",
                  codice_sicer: element.codice_sicer || "-",
                  app_actions: actions
                }
                i++
              });
            }
          }
          setList(list)
          setLoading(false)
      } catch (error) {
        alertify.error("Errore durante il recupero dei dati.");
        console.log("errore: ", error);
      }
    }

    if(Object.keys(filter).length !== 0 ){
      fetchData(filter, props.ids);
    }
  }, [sent, filter])

    return(
        <>
            <Formik 
              id='ruolo' className="p-4"

              initialValues={{
              }}
              
              validationSchema={Yup.object({
                ragione_sociale: Yup.string().min(3, "Specificare almeno 3 caratteri"),
                piva: Yup.string().min(11, "La partita Iva non può avere meno di 11 caratteri"),
                codice_sicer: Yup.string().min(2, "Specificare almeno 2 caratteri")
              })}
              
              onSubmit = { async (values, { setSubmitting }) => {
                let valoriInseriti = "";
                Object.keys(values).map(function(key) {
                    valoriInseriti += values[key]
                });
                if (valoriInseriti.trim() == "") {
                    alertify.warning("Specificare almeno un campo per la ricerca");
                    return;
                }
                setFilter(values);
                setSent(true)
              }}
            >
            <Form>
                <br /><br />
                <SicondirInput
                  label="Ragione sociale"
                  name="ragione_sociale"
                  id="ragione_sociale"
                  type='text'
                  placeholder="Ragione sociale"
                />
                <SicondirInput
                  label="Partita IVA"
                  name="piva"
                  id="piva"
                  type='text'
                  placeholder="Partita IVA"
                  maxLength = '16'
                />
                <SicondirInput
                  label="Codice Sicer"
                  name="codice_sicer"
                  id="codice_sicer"
                  type='text'
                  placeholder="Codice Sicer"
                />
                <div className="col-12 mt-5 row justify-content-center">
                  <button type="submit" className="btn btn-primary col-5">
                    Ricerca persona giuridica
                  </button>
                </div>
            </Form>
            </Formik>
            <br />
            {!loading ? 
              <>
              { sent ?
                <>
                <SicondirTable 
                  headers={[
                    "Ragione sociale ",
                    "Partita Iva",
                    "Codice fiscale",
                    "Codice Sicer",
                    ""]} 
                  data={list} 
                  />
                </>
              :
              <>
              </>
              }
            </>
            :
              <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="progress-spinner progress-spinner-active">
                  <span className="sr-only">Caricamento...</span>
                </div>
              </div>
            }
            
        </>
        
    )
   
}
export default PersonaGiuridicaRicercaForm;