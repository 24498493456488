import React from 'react';
import Navbar from './Navbar';

export default function Footer() {
    const scaricaPdf = (file) => {
        const filetext = file;
        // using Java Script method to get PDF file
        fetch(filetext).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = file;
                alink.click();
            })
        })
    }
    return (
      
<footer className="it-footer">
    <div className="it-footer-main">
        <div className="container">
        <div className="row">
            <div className="col-12 my-4 font-weight-bold">
            Sicondir - ver 1.0.2
            </div>
            <div className="col-12 my-3">
                <span className="d-inline-block">&copy; Copyright Sicondir Regione Lazio&nbsp;</span>
                <span className="d-inline-block"> - tutti i diritti riservati&nbsp;</span><br />
                <span className="d-inline-block"> - Via Rosa Raimondi Garibaldi, 7 &nbsp;</span>
                <span className="d-inline-block"> - 00147 Roma</span>
            </div>
        </div>
        <div className="d-flex flex-row">
            <button onClick={() => scaricaPdf('privacySicondir.pdf')}
            className="btn btn-link"
            style={{color:'#fff', marginLeft:'-25px'}}
            >Privacy
            </button>
            <button onClick={() => scaricaPdf('cookieSicondir.pdf')}
            className="btn btn-link col-1"
            style={{color:'#fff', whiteSpace: 'nowrap'}}
            >Cookie policy
            </button>
        </div>
    </div>
    </div>
</footer>
       
    );
}
