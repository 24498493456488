import { useState, useEffect} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirSelect from "../components/SicondirSelect";
import tipiUso from '../../_data/tipi_uso.json'
import tipiDurata from '../../_data/tipi_durata.json'
import SicondirInput from "../components/SicondirInput";
import formatter from "../utils/formatters/_"

const UsoForm = (props) => {
    const [loading, setLoading] = useState(true);
    const [tipiUsoFiltrati, setTipiUsoFiltrati] = useState(tipiUso.filter(c => (props.idAmbito == c.ambito)));
    const [tipoUsoSelezionato, setTipoUsoSelezionato] = useState(null);
    const [labelValore, setLabelValore] = useState("Valore");
    const [nascondiDurata, setNascondiDurata] = useState(true);

    useEffect(() => {
      let result = tipiUso.filter(c => (props.idAmbito == c.ambito)).filter(c => (props.tipiUsoSelezionati.indexOf(c.value.toString()) == -1))
      setTipiUsoFiltrati(result)
    },[props.tipiUsoSelezionati])

    const manageTipoUso = (event) =>{
      if(event){
        setTipoUsoSelezionato({
            label: event.label,
            value: event.value
        })
        let usoSelezionato = tipiUso.filter(c => (event.value == c.value))[0]
        let unitaMisura = usoSelezionato.unita_misura
        setLabelValore("Valore("+unitaMisura+")")
        let durata = usoSelezionato.durata
        if(tipiDurata.filter(c => (durata == c.value))[0].label == "Breve"){
          setNascondiDurata(false)
        } else {
          setNascondiDurata(true)
        }
      } else{
        setTipoUsoSelezionato(null);
        setLabelValore("Valore")
        setNascondiDurata(true)
      }
    }

    return(
        <>
            <Formik 
              id='uso' className="p-4"
              initialValues={{
                tipo_uso_id: '',
                valore: '',
                durata: '',
                descrizione_uso: '',
              }}
              validationSchema={Yup.object({
                tipo_uso_id: Yup.string().required("Il campo è obbligatorio"),
              })}
            
              onSubmit = { async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                let campi = ""
                let valori = ""

                Object.entries(values).map(([key,value],i) => {
                  // Se la durata non la devo inserire, svuoto il valore 
                  if(nascondiDurata && key == 'durata') value = ''
                  if(value != ''){
                    campi += key + ", "
                    valori += "'" + formatter.escapeSql(value) + "', "
                  } 
                })
                
                if(valori == ""){
                  alertify.warning("Inserire almeno un campo");
                  return;
                }
              
                try{
                  let nomeTabella = "";
                  let campoChiave = "";
                  if(props.contesto == "concessioni"){
                    nomeTabella = "concessioni_catasto_uso";
                    campoChiave = "concessione_catasto_id";
                  } else if(props.contesto == "richieste"){
                    nomeTabella = "richieste_concessione_catasto_uso";
                    campoChiave = "richiesta_concessione_catasto_id";
                  } else if(props.contesto == "occupazioni"){
                    nomeTabella = "occupazioni_catasto_uso";
                    campoChiave = "occupazione_catasto_id";
                  } else if(props.contesto == "denunce_pozzo"){
                    nomeTabella = "denunce_pozzo_catasto_uso";
                    campoChiave = "denuncia_pozzo_catasto_id";
                  }

                  campi += campoChiave + ", ";
                  valori += props.idAssociazione + ", ";
                  campi += "utente_modifica_id";
                  valori += localStorage.id
                  const uso = {
                    nome_tabella: nomeTabella,
                    lista_campi: campi,
                    lista_valori: valori
                  }
                  
                  const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:uso})
                  })
                  const data_response = await response.json();
                  if(data_response.hasOwnProperty('Fault')){
                    if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + data_response.Fault.faultcode)
                      throw new Error(data_response.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }
                  
                  alertify.success("Uso associato alla concessione con successo.");
                  resetForm();
                  setTipoUsoSelezionato(null);
                  setLabelValore("Valore")
                  setNascondiDurata(true)
                  props.setUsoInserito(true);
                } catch(error) {
                  alertify.error("Errore durante l'inserimento: " + error);
                  console.log("errore: ", error);
                }
                
            }}
            >
            <Form>
                <SicondirSelect 
                  label="Tipo Uso(Codice - Categoria - Sottocategoria - Uso Specifico - Unità Di Misura)" 
                  id="tipo_uso_id" 
                  name="tipo_uso_id" 
                  options={tipiUsoFiltrati} 
                  mandatory={true}
                  value={tipoUsoSelezionato}
                  onChange={manageTipoUso}
                  placeholder="Seleziona Tipo Uso"
                />
                <SicondirInput
                  label={labelValore}
                  id='valore'
                  type='text'
                  name='valore'
                  className={`form-control mb-2`}
                  size="large" 
                  placeholder = 'Inserisci Valore Concesso'   
                  maxLength = '255'
                />
                <SicondirInput
                  hidden={nascondiDurata}
                  label='Durata'
                  id='durata'
                  type='text'
                  name='durata'
                  className={`form-control mb-2`}
                  size="large" 
                  placeholder = 'Inserisci Durata Concessa'   
                  maxLength = '255'
                />
              <SicondirInput 
                  label='Descrizione Uso'
                  id='descrizione_uso'
                  type='text'
                  name='descrizione_uso'
                  className={`form-control mb-2`}
                  size="large" 
                  placeholder = 'Inserisci Descrizione Uso'   
                  maxLength = '255'
                />
                  <div className="col-12 mt-5 row justify-content-center">
                    <button type="submit" className="btn btn-primary col-5">
                    Associa uso
                    </button>
                  </div>
            </Form>
            </Formik>
        </>
        
    )
}

export default UsoForm;