import { useField } from "formik";

export default function SicondirTextarea(props){
    
    const [field, meta] = useField(props);
    let currentClass = props.className ? props.className :  "";
    // la textarea ha una classe tutta sua e non va d'accordo con il form-control(ma perché!?!?!). Potremmo migliorare...
    props.type == "textarea" ? currentClass += currentClass + " sicondir-textarea " : currentClass += " form-control "
    const mandatory = props.mandatory ? "*" : ""
   return (
    <>
    {
    props.label ?
       (<label className="sicondir-label" htmlFor={props.id}>{props.label}<sup>{mandatory}</sup></label>)
    : ''
    }
    <textarea {...field} {...props} className={currentClass}/>
    
    {
        meta.touched && meta.error ? 
        (<small className="sicondir-error">{meta.error}</small>) 
        : (<div style={{height: "8px"}}></div>)
    }
    </>
   )
}
