import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import OccupazioniRicerca from "../components/OccupazioniRicerca";

export default function OccupazioniList(props) {

    const [currentMacroambito, setCurrentMacroambito] = useState(props.macroambito);
    let navigate = useNavigate();
    let titolo = "Ricerca occupazioni "
    let fromUrl = "/occupazioni/"

    // "TENDINA AMBITI": necessario questo useEffect per quando si cambia routing da concessioni fluviali e derivazioni(e viceversa), per aggiornare il menu a tendina(vedere anche ConcessioniRicercaForm)
    useEffect(()=>{
      setCurrentMacroambito(props.macroambito)
    }, [props.macroambito])

    useEffect(() => {
      if((localStorage.sezione=='Derivazioni' && props.macroambito == 'F') 
      || (localStorage.sezione=='Fluviali' && props.macroambito == 'D')) {
        navigate('/');
      }
    },[])
    return (
      <main>
        <h3>{titolo}</h3>
        <div className="card ">
          <div className="card-body">
            <br></br>
            <OccupazioniRicerca insert={true} contesto='anagrafica' macroambito={currentMacroambito}/>
          </div>
        </div>
      </main>
    );
  }