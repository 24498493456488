import React, { useState } from 'react';
import '../App.css';
import PropTypes from 'prop-types';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import SicondirInput from './components/SicondirInput'
import alertify from 'alertifyjs';
// import bcrypt from 'bcryptjs'
import md5 from 'md5';
import format from 'date-fns/format'
import Cookiebar from './components/Cookiebar'

async function loginUser(persona) {
    try{
      // Eseguo il salvataggio nel log 
      let nowDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
      const payload = {
        nome_tabella: "persone_ruoli_access_log",
        lista_campi: "persona_profilo_id,data_accesso",
        lista_valori: persona.persona_profilo_id + ",'" + nowDate + "'"
      }
      const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, 
        mode: 'cors', 
        body: JSON.stringify({Entry:payload})
      })

      if(response.status != 200){
        throw new Error("ERRORE INSERIMENTO LOG")
      }
  
      return {
        nome: persona.nome,
        cognome: persona.cognome,
        codice_fiscale: persona.codice_fiscale,
        id: persona.id,
        token: "YbY62IbepGq9LaMmho5guZBO1DImS3s7",
      }
    } catch(error){
      throw new Error(error);
    }
}

export default function LoginPageForm({ setUser }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const [isSubmitted, setIsSubmitted] = useState(false); 
  const [errorMessages, setErrorMessages] = useState({});
  const renderErrorMessage = (name) =>
  name === errorMessages.name && (
    <div className="error">{errorMessages.message}</div>
  );

  const renderForm = ( 

    <Formik  id='beneDemaniale' className="p-4"
      initialValues={{
                username: '',
                password: ''
              }}
      validationSchema={Yup.object({
          username: Yup.string().required("Il campo è obbligatorio"),
          password: Yup.string().required("Il campo è obbligatorio"),
      })}

      onSubmit = { async (values, { setSubmitting }) => {
        var msg = alertify.message("Autenticazione in corso...");
        msg.delay(2).setContent("Autenticazione in corso...");
        const hashedPassword = md5(values.password)
        const url = process.env.REACT_APP_API_URL + 'login/user_name/'+values.username+'/user_pass/' + hashedPassword
        const response = await fetch(url, {
          method: 'GET',
          headers: { accept: "application/json" },      
          mode: 'cors'
        });
        const json = await response.json();
        
        // Se la response torna qualcosa, allora recupero la persona...
        try{
          if(json.risposta == "") throw new Error("errore durante la fase di login")
          const user = await loginUser(json.risposta.user);
          setUser(user);
        } catch(error){
          console.log(error)
          alertify.error("Non è stato possibile procedere con l'autenticazione. Controllare le credenziali inserite e riprovare ")
        }

      }}

      >
      <Form>
      <div className='mb-5'>
          <SicondirInput 
            label="Username"
            id='username'
            type='text'
            name='username'
            className={`form-control mb-2`}
            size="large" 
            placeholder = 'Inserisci Username' 
            mandatory='true'
            maxLength = '16'
          />
          <SicondirInput 
            label="Password"
            id='password'
            type='password'
            name='password'
            className={`form-control mb-2`}
            size="large" 
            placeholder = 'Inserisci Password'
            mandatory='true'   
            maxLength = '255'
          />
      </div>
      <div className='row justify-content-end me-4 mb-4'>
          <button type="submit" className='btn btn-primary col-4 col-sm-4'>Invia</button>
      </div>
    </Form>
    </Formik>
    );

  return (
    <section className="main-content">
    <Cookiebar/>
    <div style={{height: '100vh'}}>
    <div className="col-12 it-header-slim-wrapper pr-5" 
    style={{color:'#fff', textAlign:'right'}}>
       
    </div>
    <div className="it-header-center-wrapper col-12" style={{height:'180px'}}>
      {/* Scommentare per centrare l'header <div className="col-2"></div> */}
      <div className="align-top" style={{marginTop:'-90px'}}>
      <div className="row">
        <div className="col-12">
          <div className="it-header-center-content-wrapper">
            <div className="it-brand-wrapper">
              <a href="#">
                <div className="it-brand-text">
                  <div className="it-brand-title">Sicondir</div>
                  <div className="it-brand-tagline d-none d-md-block"><small>Concessioni idriche Regione Lazio</small></div>
                </div>
              </a>
            </div>
            <div className="it-right-zone">
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
      <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 container bg-white shadow rounded p-4"
        style={{height:'56%'}}
      >
        {isSubmitted ? <div>Sei loggato</div> : renderForm}
        </div>
      <Footer />
    </div>
    </section>
  );
}

LoginPageForm.propTypes = {
    setUser: PropTypes.func.isRequired
};
