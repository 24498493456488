import React from "react";
import { useState, useEffect } from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    HashRouter,
    useNavigate,
  } from "react-router-dom";
import Wrapper from "./common/Wrapper";
import Dashboard from "./modules/dashboard/routes/Dashboard";
import Concessioni from "./modules/concessioni/routes/Concessioni";
import ConcessioniList from "./modules/concessioni/routes/ConcessioniList";
import RichiesteConcessioni from "./modules/concessioni/routes/RichiesteConcessioni";
import RichiesteConcessioniList from "./modules/concessioni/routes/RichiesteConcessioniList";
import Anagrafica from "./modules/anagrafica/routes/Anagrafica";
import AnagraficaEdit from "./modules/anagrafica/routes/AnagraficaEdit";
import BeniDemanialiList from "./modules/anagrafica/routes/BeniDemanialiList";
import BeniDemaniali from "./modules/anagrafica/routes/BeniDemaniali";
import BeniDemanialiEdit from "./modules/anagrafica/routes/BeniDemanialiEdit";
import LoginPage from "./common/LoginPage";
import LoginPageForm from "./common/LoginPageForm";
import AmbitoPage from "./common/AmbitoPage";
import useUser from "./common/components/useUser";
import OccupazioniList from "./modules/concessioni/routes/OccupazioniList";
import Occupazioni from "./modules/concessioni/routes/Occupazioni";
import PagamentiList from "./modules/pagamenti/routes/PagamentiList";
import Pagamenti from "./modules/pagamenti/routes/Pagamenti";
import DenuncePozzoList from "./modules/concessioni/routes/DenuncePozzoList";
import DenuncePozzo from "./modules/concessioni/routes/DenuncePozzo";

function AppRoutes() {

    const { user, setUser } = useUser();
    const [sezione, setSezione] = useState(localStorage.sezione)
   // const navigate = useNavigate()
   /* if(!localStorage.sezione) {
       // navigate('/')
    }*/
    const writeSezione = () => {
        (sezione=='Fluviali')
        ?
            setSezione('Fluviali')
        : (sezione=='Derivazioni')
            ?
                setSezione('Derivazioni')
            : 
                setSezione('')
    }
    useEffect(() => {
        writeSezione()
    },[sezione])
    if(!user) {
        if(process.env.REACT_APP_LOGIN_IAM == 'true'){
            return <LoginPage setUser={setUser} />
        } else{
            return <LoginPageForm setUser={setUser} />
        } 
    } else if(!sezione){
        return <AmbitoPage setSezione={setSezione} />
    }


   return (
    <HashRouter>
        <Routes>
            <Route path="/" element={<Wrapper sezione={sezione} />}>
                <Route index element={<Dashboard sezione={sezione} setSezione={setSezione} />} />
                {/* CONCESSIONI */}
                <Route path="concessioni/fluviali-lacuali" element={<ConcessioniList key="list-concessioni-fluviali" macroambito="F" />} />
                <Route path="concessioni/derivazioni" element={<ConcessioniList key="list-concessioni-derivazioni" macroambito="D" />} />
                <Route path="concessioni/fluviali-lacuali/inserisci/:id" element={<Concessioni key="inserisci-concessioni-fluviali"  macroambito="F" />} />
                <Route path="concessioni/derivazioni/inserisci/:id" element={<Concessioni key="inserisci-concessioni-derivazioni" macroambito="D" />} />
                {/* RICHIESTE-CONCESSIONI */}
                <Route path="richieste-concessioni/fluviali-lacuali" element={<RichiesteConcessioniList macroambito="F" />} />
                <Route path="richieste-concessioni/derivazioni" element={<RichiesteConcessioniList macroambito="D" />} />
                <Route path="richieste-concessioni/fluviali-lacuali/inserisci/:id" element={<RichiesteConcessioni macroambito="F" />} />
                <Route path="richieste-concessioni/derivazioni/inserisci/:id" element={<RichiesteConcessioni macroambito="D" />} />
                {/* OCCUPAZIONI */}
                <Route path="occupazioni" element={<OccupazioniList macroambito="F" />} />
                <Route path="occupazioni/inserisci/:id" element={<Occupazioni macroambito="F" />} />
                {/* PAGAMENTI */} 
                <Route path="pagamenti" element={<PagamentiList/>} />
                <Route path="pagamenti/inserisci/:id" element={<Pagamenti/>} />
                {/* DENUNCE POZZO */}
                <Route path="denunce-pozzo" element={<DenuncePozzoList macroambito="D" />} />
                <Route path="denunce-pozzo/inserisci/:id" element={<DenuncePozzo macroambito="D" />} />

                <Route path="anagrafica" element={<Anagrafica />} />
                <Route path="anagrafica/modifica/:id" element={<AnagraficaEdit />} />
                <Route path="beni-demaniali/fluviali-lacuali" element={<BeniDemanialiList macroambito="F" />} />
                <Route path="beni-demaniali/derivazioni" element={<BeniDemanialiList macroambito="D" />} />
                <Route path="beni-demaniali/fluviali-lacuali/inserisci" element={<BeniDemaniali macroambito="F"/>} />
                <Route path="beni-demaniali/derivazioni/inserisci" element={<BeniDemaniali macroambito="D"/>} />
                
                <Route path="beni-demaniali/fluviali-lacuali/modifica/:id" element={<BeniDemanialiEdit macroambito="F"/>} />
                <Route path="beni-demaniali/derivazioni/modifica/:id" element={<BeniDemanialiEdit macroambito="D"/>} />
                
                <Route path="beni_demaniali_fluviali_lacuali/inserisci/:id" element={<BeniDemanialiEdit macroambito="F"/>} />
                
                
                <Route
                    path="*"
                    element={
                        <main style={{ padding: "1rem" }}>
                        <p>Pagina non trovata...</p>
                        </main>
                    }
                />
            </Route>
        </Routes>
    </HashRouter>
   )
}

export default AppRoutes;