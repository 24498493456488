
import BeniDemanialiForm from "../../../common/forms/BeniDemanialiForm";
import {useLocation} from 'react-router-dom';
import _ from "lodash";

export default function BeniDemaniali(props) {
    const location = useLocation();
    const macroambito = _.get(location, 'state.macroambito', null)
    let titolo = 'Inserisci Bene Demaniale - '
    if (props.macroambito=='D'){
      titolo+='Derivazione'
    }
    if (props.macroambito=='F'){
      titolo+='Fluviali/Lacuali'
    }
    return (
      <main>
        <h3>{titolo}</h3>
          <div className="card ">
            <div className="card-body">
            <br></br>
            <BeniDemanialiForm macroambito={props.macroambito}></BeniDemanialiForm>
            </div>
          </div>
      </main>
    );

  }