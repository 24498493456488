import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import Comuni from '../../_data/comuni.json'
import Nazioni from '../../_data/nazioni.json'
import { Formik, Field, Form, useFormik } from 'formik'

import formatter from '../utils/formatters/_'
import _ from 'lodash'
import SicondirTable from "./../components/SicondirTable";
import SicondirSelect from "./../components/SicondirSelect";
import associaAllaConcessione from '../../modules/concessioni/components/AssociaAllaConcessione'
import alertify from "alertifyjs"; 
import tipiRuoli from '../../_data/tipi_ruoli_frontend.json'
import * as Yup from 'yup'
import SicondirInput from "../components/SicondirInput";
import associaAllaRichiesta from '../../modules/concessioni/components/AssociaAllaRichiesta';
import Contatto from "../components/Contatto";
import TastiIndirizzoContatto from '../components/TastiIndirizzoContatto'
import SchedaIndirizzo from "../components/SchedaIndirizzo";
import SchedaContatto from "../components/SchedaContatto";
import tipiFormaGiuridicaOptions from '../../_data/tipi_forme_giuridiche.json';
import Modal from 'react-bootstrap/Modal';
import PersonaGiuridicaRicercaForm from './PersonaGiuridicaRicercaForm';
import associaAllaOccupazione from '../../modules/concessioni/components/AssociaAllaOccupazione';
import associaAllaDenunciaPozzo from '../../modules/concessioni/components/AssociaAllaDenunciaPozzo';

const IntestatarioForm = (props) => {

  let navigate = useNavigate();
  const [list, setList] = useState([])
  const params = useParams()
  const concessioneId = params.id
  const [persona, setPersona] = useState({});
  const [comunenascita, setComunenascita] = useState();
  const [provincianascita, setProvincianascita] = useState();
  const [nazionenascita, setNazionenascita] = useState('');
  const [codicefiscale, setCodicefiscale] = useState(props.cod_fisc);
  const [codiceSicer, setCodiceSicer] = useState(props.codiceSicer);
  const [cognome, setCognome] = useState(props.cognome);
  const [datanascita, setDatanascita] = useState('');
  const [genere, setGenere] = useState('');
  const [estero,setEstero] = useState('');
  const [piva,setPiva] = useState(props.partita_iva);
  const [ragione_sociale,setRagione_sociale] = useState(props.ragione_sociale);
  const [valueNaturaGiuridica, setValueNaturaGiuridica] = useState(props.natura_giuridica);
  const [labelComuneNascita, setLabelComuneNascita] = useState('Comune nascita');
  const [ruoliDeafult, setRuoliDeafult] = useState([{
    "value": "4",
    "label": "Intestatario",
    "codice": "INTESTATARIO"
  }])
  const [formIndirizzoValues, setFormIndirizzoValues] = useState([{ indirizzo: ""}])
  const [formContattoValues, setFormContattoValues] = useState([])
  const [indirizzoCambiato, setIndirizzoCambiato] = useState(false);
  const [contattoCambiato, setContattoCambiato] = useState(false);
  const [persona_id, setPersona_id] = useState(0);
  const [showTastiIndirContat, setShowTastiIndirContat] = useState(false);  
  const [showPersoneGiuridiche, setShowPersoneGiuridiche] = useState(false);  
  
  let ruoliSelezionabili = tipiRuoli;
  if(props.natura_giuridica == "PG"){
    ruoliSelezionabili = ruoliSelezionabili.filter(r => r.value != 7) // tolgo legale rappresentante se sto inserendo una PG
  }
  const [tipiRuoliSelezionabili, setTipiRuoliSelezionabili] = useState(ruoliSelezionabili)
  const [ruoloSelezionato, setRuoloSelezionato] = useState(null);
  const [listRuoliSelezionati, setListRuoliSelezionati] = useState([]);
  const [ruoloEliminato, setRuoloEliminato] = useState(null);
  // TODO: al momento consideriamo solo una persona giuridica per un LR... Questi campi dovranno diventare array.
  const [pgAssociata, setPgAssociata] = useState(null);
  const [idPgAssociata, setIdPgAssociata] = useState(null)
  const [loading, setLoading] = useState(false);
  

  const Mesi = { A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' }

  const validationSchemaPf = Yup.object({
    nome: Yup.string()
    .max(40, "Il nome non può avere più di 40 caratteri")
    .min(2, "Il nome deve avere almeno 2 caratteri"),
    // .required("Il nome è obbligatorio"), 
    cognome: Yup.string()
    .max(40, "Il cognome non può avere più di 40 caratteri")
    .min(2, "Il cognome deve avere almeno 2 caratteri")
    .required("Il cognome è obbligatorio"),
    // codice_sicer: Yup.string().required("Il codice Sicer è obbligatorio"),
    codice_fiscale: Yup.string()
    .max(16, "Il codice fiscale non può avere più di 16 caratteri")
    .min(16, "Il codice fiscale non può avere meno di 16 caratteri")
    .required("Il codice fiscale è obbligatorio"),
  })
  const validationSchemaPg = Yup.object({
    //   codice_sicer: Yup.string().required("Il codice Sicer è obbligatorio"),
      ragione_sociale: Yup.string()
      .max(150, "La ragione sociale non può avere più di 150 caratteri")
      .min(2, "La ragione sociale deve avere almeno 2 caratteri")
      .required("La ragione sociale è obbligatoria"),
      piva: Yup.string()
      .max(11, "La partita iva non può avere più di 11 caratteri")
      .min(11, "La partita iva non può avere meno di 11 caratteri")
      .required("La partita iva è obbligatoria"),
      })
      
    const initialValue = {
          tipo_persona: valueNaturaGiuridica,
          nome: "", 
          cognome: cognome,
          codice_sicer: codiceSicer,
          codice_fiscale: codicefiscale,
          ragione_sociale: ragione_sociale,
          comune_nascita: "",
          nazione_nascita: "",
          data_nascita: "",
          genere: "",
          piva: piva,
          datainizioincarico: "",
          note: "",
          username: "",
          ruoli: ruoliDeafult
      }
    
    const handleChangeRuoli =(event) => {
      setRuoloSelezionato(event);
    }

    const showRicercaPersoneGiuridiche = () =>{
      setShowPersoneGiuridiche(true)
    }

    const buildActionsRuoli = (ruoloSelezionato) => {
      let result = {};
      result = { elimina: {
              kind: "function", 
              function: removeRuolo,
              parameters: {ruolo: ruoloSelezionato},
              buttonClass: "btn-danger",
              linkClass: "text-danger",
              label: "Rimuovi ruolo",
              confirmLabel: "Procedere con la rimozione del ruolo per la persona?"}
      }

      if(ruoloSelezionato.codice == "RAPLEG"){
        result["aggiungiPersonaGiuridica"] = {
              kind: "function", 
              function: showRicercaPersoneGiuridiche,
              parameters: {ruolo: ruoloSelezionato},
              label: "Seleziona persona giuridica"
              }
        }
      return result;        
  }

  const associaPersonaGiuridica = (personaGiuridica) => {
    setPgAssociata(personaGiuridica)
  }

  useEffect(()=>{
    if(pgAssociata != null){
      setLoading(true)
      let list = listRuoliSelezionati;
      setIdPgAssociata(pgAssociata.id);
      let listAggiornata = []
      list.map((ruolo) => {
        if(ruolo.label == 'Rappresentante Legale'){
          ruolo["pgAssociata"] =  (pgAssociata.ragione_sociale || "-") + " ("+ (pgAssociata.piva || "-") + " / " + (pgAssociata.codice_sicer || "-") + ")";
        }  
        listAggiornata.push(ruolo)
      })
      setListRuoliSelezionati(listAggiornata)
      setShowPersoneGiuridiche(false)
      setLoading(false)
    }
  }, [pgAssociata])

    const removeRuolo = (ruolo) => {
      setRuoloEliminato(ruolo)
    }

    useEffect(()=>{
      if(ruoloEliminato != null){
        setLoading(true)
        if(ruoloEliminato.label == 'Rappresentante Legale') setIdPgAssociata(null);
        let list = listRuoliSelezionati.filter((r) =>  r.id !== ruoloEliminato.value)
        setListRuoliSelezionati(list)
        let selezionabili = tipiRuoliSelezionabili;
        selezionabili.push(ruoloEliminato);
        setTipiRuoliSelezionabili(selezionabili);
        setRuoloEliminato(null)
        setLoading(false)
      }
    }, [ruoloEliminato])

      const addRuolo =() => {
        setLoading(true)
        let list = listRuoliSelezionati;
        let actions = buildActionsRuoli(ruoloSelezionato);
        let element = {
          id: ruoloSelezionato.value,
          label: ruoloSelezionato.label,
          pgAssociata: ruoloSelezionato.pgAssociata,
          app_actions: actions
        }
        list.push(element);
        setListRuoliSelezionati(list);
        let selezionabili = tipiRuoliSelezionabili.filter((r) => r.value != ruoloSelezionato.value);
        setTipiRuoliSelezionabili(selezionabili);
        setRuoloSelezionato(null)
        setLoading(false)
    }

    const handleCloseRuoli = () => {
      setShowPersoneGiuridiche(false)
    }

    const capitalizeFirstLetter = (string)=>  {
      if(string != null){
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      return "";
    }

    const tornaRicerca = (persona_trovata, setRicerca) => {
      for (const key in persona_trovata) {
        delete persona_trovata[key];
      }
      setRicerca(true)
    }
    const nazionijson = JSON.stringify(Nazioni, null, 4);
    const nazioniobj = JSON.parse(nazionijson);
    const comuneNascitaCf = (codiceFiscale) => { 
      if(codiceFiscale.length==16) {
        // let result={comune:undefined, nazione:undefined};
        const codCatastale = codiceFiscale.substring(11,15).toUpperCase()
        if(codiceFiscale.charAt(11).toUpperCase() !== 'Z') {
          setLabelComuneNascita('Comune nascita');
          const comunijson = JSON.stringify(Comuni, null, 4);
          const comuniobj = JSON.parse(comunijson);
          for(let key in comuniobj) {
            if(comuniobj[key].codiceCatastale==codCatastale) {
              setComunenascita(comuniobj[key].nome)
              setProvincianascita(comuniobj[key].sigla)
              setNazionenascita('Italia')
              break;
            }
          }
        }
        else {
          setEstero('Z')
          setLabelComuneNascita('Città di nascita');

          for(let key in nazioniobj) {
            if(nazioniobj[key].italian_country_code==codCatastale) {
              setNazionenascita(nazioniobj[key].italian_country_name_1)
              break;
            }
          }
        }
        setDatanascita(dataNascitaCf(codiceFiscale))
        setGenere(sessoCf(codiceFiscale))
      }
    }
    const dataNascitaCf = (codiceFiscale) => {
      let [ anno, giorno ] = [ codiceFiscale.substring(6,8), codiceFiscale.substring(9,11) ];
      if (giorno>40) {
        giorno -= 40
        if(giorno<10){
          giorno = "0" + giorno
        }
      }
      return giorno +  "-" + Mesi[codiceFiscale.charAt(8).toUpperCase()] + "-" + (anno < 20 ? "20" : "19" ) + anno;
    }
    const sessoCf = (codiceFiscale) => { 
      return codiceFiscale.substring(9,11) > 40 ? "F" : "M"; 
    }

    const onChange = (e, errors) => {
      for (const key in errors) {
        delete errors[key];
      }
      const { name, value } = e.target;
      if(name==='codice_fiscale') {   
        setCodicefiscale(e.target.value.toUpperCase())
        if(value.length===16 && valueNaturaGiuridica=='PF') {
          comuneNascitaCf(value)
          setGenere(sessoCf(value))
        }
      }
    }

    const vaiModificaPersona = (concessioneId, handleClose, personaId, ruoliId, setAssociazione) => {
      navigate('/anagrafica/modifica/' + personaId) ;
    }

  useEffect(
    () => {
      let confirmLabel= "Procedere con l'eliminazione dell'intestatario?" 
        let lista = [];
        setPersona(formatter.cleanObject(props.persona_trovata));
        let label=""
       if(Array.isArray(props.persona_trovata)) {
        // ho più persone  
        let i = 1;
        const entries = Object.entries(formatter.cleanObject(props.persona_trovata));
        entries.map(([key,value]) => {
          value = formatter.cleanObject(value)
          if(props.contesto=="Concessione") {
            if(props.persona_trovata[i-1].ruoli!=null) {
              label="Associa a concessione"
            } else {
              label="Cambia ruolo"
            }
          } else if(props.contesto=="richiesta") {
            if(props.persona_trovata[i-1].ruoli!=null) {
              label="Associa a Domanda"
            } else {
              label="Cambia ruolo"
            }
          } else if(props.contesto == 'occupazione'){
            if(props.persona_trovata[i-1].ruoli!=null) {
              label="Associa all'Occupazione"
            } else {
              label="Cambia ruolo"
            }
          } else if(props.contesto == 'denuncia_pozzo'){
            if(props.persona_trovata[i-1].ruoli!=null) {
              label="Associa alla denuncia pozzo"
            } else {
              label="Cambia ruolo"
            }
          }
          else {
            label="Modifica"
          }
          
          (value.tipo_persona=='PF') 
          ?
         
            lista[i]=
            {
              id: value.id,
              cognome: capitalizeFirstLetter(value.cognome),
              nome: capitalizeFirstLetter(value.nome),
              codice_fiscale: value.codice_fiscale,
              codice_sicer: value.codice_sicer,
              tipo_persona: 'Persona fisica',
              //ruolo: '',
              app_actions: { associa: {
                kind: "function", 
                function: 
                props.contesto=="Concessione" && props.persona_trovata[i-1].ruoli!=null
                  ?
                    associaAllaConcessione
                  : (
                    props.contesto=="richiesta" && props.persona_trovata[i-1].ruoli!=null
                      ?
                        associaAllaRichiesta
                      :
                        (
                          props.contesto=="occupazione" && props.persona_trovata[i-1].ruoli!=null
                          ?
                            associaAllaOccupazione
                          :
                            (
                              props.contesto=="denuncia_pozzo" && props.persona_trovata[i-1].ruoli!=null
                              ?
                              associaAllaDenunciaPozzo
                              :
                              vaiModificaPersona
                            )
                        )
                    )
                ,                  
                parameters:
                {
                  concessioneId: concessioneId,
                  handleClose: props.handleClose,
                  personaId: value.id,
                  ruoliId: value.ruoli,
                  setAssociazione: props.setAssociazione
                },
                label: label
                
              }}
            } 
          :
            lista[i]=
            {
               id: value.id,
               ragione_sociale: capitalizeFirstLetter(value.ragione_sociale),
               piva: value.piva,
               codice_fiscale: value.codice_fiscale,
               codice_sicer: value.codice_sicer,
               tipo_persona: 'Persona giuridica',
              // ruolo: '',
              app_actions: { 
                associa: {
                kind: "function", 
                function: 
                props.contesto=="Concessione" && props.persona_trovata[i-1].ruoli!=null
                ?
                  associaAllaConcessione
                :
                (
                  props.contesto=="richiesta" && props.persona_trovata[i-1].ruoli!=null
                    ?
                      associaAllaRichiesta
                    :
                      ( 
                        props.contesto=="occupazione" && props.persona_trovata[i-1].ruoli!=null
                        ?
                        associaAllaOccupazione
                        :
                        (
                          props.contesto=="denuncia_pozzo" && props.persona_trovata[i-1].ruoli!=null
                          ?
                          associaAllaDenunciaPozzo
                          :
                          vaiModificaPersona
                        )
                      )
                ) 
                ,
                parameters:
                {
                  concessioneId: concessioneId,
                  handleClose: props.handleClose,
                  personaId: value.id,
                  ruoliId: value.ruoli,
                  setAssociazione: props.setAssociazione
                },
                label: label
               
              }}
            } 
            i++
        })
      }
      setList(lista)
    }, [persona]
  )

  useEffect(() => {
    const fetchDataIndirizzi = async () => {
      try {
        if(persona_id>0 && persona_id!=undefined && indirizzoCambiato){
          //let whereIndirizzi = "AND persona_id="+persona_id
          //whereIndirizzi = encodeURI(whereIndirizzi);
          let urlApiIndirizzi = `persone/indirizzi/${encodeURI(persona_id)}`
          const responseIndirizzi = await fetch(process.env.REACT_APP_API_URL + urlApiIndirizzi, 
          {
                method: 'GET',
                headers: { accept:'application/json' }, 
                mode: 'cors',  
          })
          const jsonindirizzi = await responseIndirizzi.json()
          
          setFormIndirizzoValues(formatter.makeArray(formatter.cleanObject(jsonindirizzi.risposta.persone_indirizzi)))
          if(indirizzoCambiato) {
            
            setIndirizzoCambiato(false);
          }
        }
      } catch (error){
        alertify.error("Errore durante il recupero degli indirizzi.");
        console.log("errore: ", error);
      }
    }
    const fetchDataContatti = async () => {
      try {
        if(persona_id>0 && persona_id!=undefined && contattoCambiato){
          let urlApiContatti = `persone/contatti/` + persona.id
          const responseContatti = await fetch(process.env.REACT_APP_API_URL + urlApiContatti, 
          {
                method: 'GET',
                headers: { accept:'application/json' }, 
                mode: 'cors',  
          })
          const jsoncontatti = await responseContatti.json()
          setFormContattoValues(formatter.makeArray(formatter.cleanObject(jsoncontatti.risposta.persone_contatti)))
          if(contattoCambiato) {
            setContattoCambiato(false);
          }
        }
      } catch (error){
        alertify.error("Errore durante il recupero dei contatti.");
        console.log("errore: ", error);
      }    
    }
    fetchDataIndirizzi();
    fetchDataContatti();
  }, [indirizzoCambiato, contattoCambiato])
  
  return (
    <>
      {
        _.isEmpty(props.persona_trovata)
        ? 
          <Formik id='anagrafica' className="p-4"
            initialValues={initialValue} 
            validationSchema={valueNaturaGiuridica=='PF'?validationSchemaPf:validationSchemaPg} 
            onSubmit = { async (values, { setSubmitting }) => {

              let pgInserita = true;
              listRuoliSelezionati.map((r) =>{
                if(r.label == 'Rappresentante Legale' && idPgAssociata == null){
                  pgInserita = false
                }
              })

              if(!pgInserita) {
                alertify.warning("Specificare la persona giuridica per il ruolo di rappresentante legale");
                return 
              } 
              setSubmitting(true);
              
              if(valueNaturaGiuridica=='PF') {
                values.comune_nascita = formatter.escapeSql(String(comunenascita))
                values.nazione_nascita = nazionenascita
                values.provincia_nascita = provincianascita
                values.data_nascita = datanascita
                values.genere = genere
              }
              try{
                let campi = ""
                let valori = ""
                let campiRuoli = ""
                let valoriRuoli = []
                Object.entries(values).map(([key,value],i) => {
                  if(key == 'natura_giuridica'){
                    key = "tipo_persona"
                  } 
                  if(key=='ruoli'){
                    campiRuoli += key
                    valoriRuoli = value
          
                  } else if(value != ''){
                    campi += key + ", "
                    valori += "'" + formatter.escapeSql(value) + "', "
                  } 
                })
                
                // FACCIO L'INSERIMENTO DELLA PERSONA
                campi += "utente_modifica_id";
                valori += localStorage.id
                const person = {
                  nome_tabella: "persone",
                  lista_campi: campi,
                  lista_valori: valori
                }
                const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' }, 
                  mode: 'cors', 
                  body: JSON.stringify({Entry:person}) 
                })
                const data = await response.json();
                const id_persona_inserita = data.risposta.elementi_aggiunti.id 
                setPersona(values)
        
               // FACCIO L'INSERIMENTO DEI RUOLI DELLA PERSONA IN persone_ruoli
               if(listRuoliSelezionati.length){
                listRuoliSelezionati.map(async el =>{
                  const person_ruol = {
                    nome_tabella: "persone_ruoli",
                    lista_campi: 'persona_id, tipo_ruolo_id',
                    lista_valori: id_persona_inserita + ',' + el.id
                  }
              
                  const responseRuolo = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:person_ruol})
                  })
                  
                  const data2 = await responseRuolo.json();
                  const id_intestatario_inserito = data2.risposta.elementi_aggiunti.id 

                  if(el.id == 7){ // Se è Rappresentante Legale aggiungiamo anche l'associazione con l'altra persona...
                    let payloadDelega = {
                      nome_tabella: 'deleghe',
                      lista_campi: 'utente_modifica_id, persona_profilo_id, persona_giuridica_id',
                      lista_valori: localStorage.id + ',' + id_intestatario_inserito + ", " + idPgAssociata
                    }
                    const responseAssociaDelega = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' }, 
                      mode: 'cors', 
                      body: JSON.stringify({Entry:payloadDelega})
                    })
                  }

                  if(props.contesto !="anagrafica") { 
                    if(el.id==4) { // se è intestatario, recupero
                      //  l'id_persona_ruolo (l'id della tb persona_ruoli)
                      let nome_tabella = '';
                      let ident = '';
                  
                      if(props.contesto=="richiesta") {
                        nome_tabella = 'richieste_concessione_persone'
                        ident = 'richiesta_concessione_id'
                      } else if(props.contesto=="Concessione") {
                        nome_tabella = 'concessioni_persone'
                        ident = 'concessione_id'
                      } else if(props.contesto=="occupazione") {
                        nome_tabella = 'occupazioni_persone'
                        ident = 'occupazione_id'
                      } else if(props.contesto=="denuncia_pozzo") {
                        nome_tabella = 'denunce_pozzo_persone'
                        ident = 'denuncia_pozzo_id'
                      }

                      const associa_concessione_richiesta = {
                        nome_tabella,
                        lista_campi: 'utente_modifica_id, persona_ruolo_id, '+ident,
                        lista_valori: localStorage.id + ',' + id_intestatario_inserito + ',' + concessioneId
                      }
                      const responseAssociaConcessioneRichiesta = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' }, 
                        mode: 'cors', 
                        body: JSON.stringify({Entry:associa_concessione_richiesta})
                      })
                    }
                  }
                  props.setRicerca(true)
                })
               }
               /*
               if(valoriRuoli.length){
                  valoriRuoli.map(async el => {
                    const person_ruol = {
                      nome_tabella: "persone_ruoli",
                      lista_campi: 'persona_id, tipo_ruolo_id',
                      lista_valori: id_persona_inserita + ',' + el.value
                    }
                
                    const responseRuolo = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' }, 
                      mode: 'cors', 
                      body: JSON.stringify({Entry:person_ruol})
                    })
                    if(props.contesto !="anagrafica") { 
                      if(el.value==4) { // se è intestatario, recupero
                        //  l'id_persona_ruolo (l'id della tb persona_ruoli)
                        const data2 = await responseRuolo.json();
                        const id_intestatario_inserito = data2.risposta.elementi_aggiunti.id 
                        let nome_tabella = '';
                        let ident = '';
                    
                        if(props.contesto=="richiesta") {
                          nome_tabella = 'richieste_concessione_persone'
                          ident = 'richiesta_concessione_id'
                        } else if(props.contesto=="Concessione") {
                          nome_tabella = 'concessioni_persone'
                          ident = 'concessione_id'
                        }
                        const associa_concessione_richiesta = {
                          nome_tabella,
                          lista_campi: 'utente_modifica_id, persona_ruolo_id, '+ident,
                          lista_valori: localStorage.id + ',' + id_intestatario_inserito + ',' + concessioneId
                        }
                        const responseAssociaConcessioneRichiesta = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' }, 
                          mode: 'cors', 
                          body: JSON.stringify({Entry:associa_concessione_richiesta})
                        })
                      }
                    }
                    props.setRicerca(true)
                  })
                }
                */
                alertify.success("Nuova persona creata con successo.");
               if(id_persona_inserita) {
                  setPersona_id(id_persona_inserita)
                  setShowTastiIndirContat(true);
                }
              } catch(error) {
                console.log("Errore:", error)
                alertify.error("Errore durante l'inserimento.");
              }
            }}
          >
            {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
            }) => (
              <Form>
                <SicondirInput 
                  label={`${valueNaturaGiuridica==='PF'?'Nome':''}`}
                  id='nome'
                  type='text'
                  name='nome'
                  value={values.nome}
                  mandatory= 'true'
                  onChange={handleChange}
                  className={`form-control mb-2 text-capitalize ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Inserisci nome'   
                  maxLength = '40'
                />
                <SicondirInput 
                  label={`${valueNaturaGiuridica==='PF'?'Cognome':''}`}
                  id='cognome'
                  type='text'
                  name='cognome'
                  value={ values.cognome }
                  mandatory = 'true'
                  onChange={handleChange}
                  className={`form-control mb-2 text-capitalize ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Cognome' 
                  maxLength = '40'
                />
                <SicondirInput
                  label={`${valueNaturaGiuridica==='PG'?'Ragione sociale':''}`}
                  id='ragione_sociale'
                  type='text'
                  name='ragione_sociale'
                  value={capitalizeFirstLetter(values.ragione_sociale) || ''}
                  mandatory= 'true'
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PF'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Ragione sociale'
                  maxLength = '150'
                />
                <SicondirSelect 
                  label={`${valueNaturaGiuridica==='PG'?'Natura giuridica':''}`}
                  name="forma_giuridica_id"
                  id="forma_giuridica_id"
                  key='formagiuridicaid'
                  options={tipiFormaGiuridicaOptions} 
                  className={`mb-3 ${valueNaturaGiuridica==='PF'?'d-none':'d-inline-block'}`}
                  placeholder="Natura giuridica"
                />
                <SicondirInput
                  label="Codice Sicer" 
                  id='codice_sicer'
                  type='text'
                  name='codice_sicer'
                  defaultValue={values.codice_sicer}
                  onChange={handleChange}
                  className='form-control mb-2'
                  size="large" 
                  placeholder = 'Codice Sicer'
                  maxLength = '20'
                />
                <SicondirInput
                  label="Codice fiscale" 
                  id='codice_fiscale'
                  type='text'
                  name='codice_fiscale'
                  value={ values.codicefiscale }
                  onChange={ handleChange }
                  onBlur={onChange}
                  className='form-control mb-2 text-uppercase'
                  size="large" 
                  placeholder = 'Codice fiscale'
                  maxLength = '16'
                 // ref={inputRef}
                />
                 <SicondirInput 
                  label={`${valueNaturaGiuridica==='PG'?'Partita IVA':''}`}
                  id='piva'
                  type='text'
                  name='piva'
                  value={values.piva}
                  mandatory= 'true'
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PF'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Partita IVA' 
                  maxLength = '11'
                />
                <SicondirInput
                  label={`${valueNaturaGiuridica==='PF'?'Comune nascita':''}`}
                  id='comune_nascita'
                  type='text'
                  name='comune_nascita'
                  value={comunenascita}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = {labelComuneNascita}
                  maxLength = '40'
                />
                <SicondirInput
                  label={`${valueNaturaGiuridica==='PF'?'Provincia nascita':''}`}
                  id='provincia_nascita'
                  type='text'
                  name='provincia_nascita'
                  value={provincianascita}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PG'?'d-none':(estero=='Z'?'d-none':'d-inline-block')}`}
                  size="large" 
                  placeholder = 'Provincia nascita'
                  maxLength = '2'
                />           
                <SicondirInput
                  label={`${valueNaturaGiuridica==='PF'?'Nazione nascita':''}`} 
                  id='nazione_nascita'
                  type='text'
                  name='nazione_nascita'
                  key='nazione_nascita'
                  value={nazionenascita || ''}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Nazione nascita'
                  maxLength = '40'
                />
                <SicondirInput
                  label={`${valueNaturaGiuridica==='PF'?'Data nascita':''}`} 
                  id='data_nascita'
                  type='text'
                  name='data_nascita'
                  value={datanascita}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Data nascita'  
                  maxLength = '10'
                />
                <SicondirInput
                  label={`${valueNaturaGiuridica==='PF'?'Genere':''}`}  
                  id='genere'
                  type='text'
                  name='genere'
                  value={genere}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Genere'
                  maxLength = '1'
                />

                {/*}
                <SicondirSelect 
                  label="Ruoli"
                  name="ruoli"
                  id="ruoli"
                  isMulti="true"
                  options={tipiRuoli} 
                  placeholder="Ruoli"
                  defaultValue={ruoliDeafult}
                  //value={ruoliDeafult}
                 // onChange={handleChange}
                />
                */}
                <div className="row">
                  <div className="col-6">
                    <SicondirSelect 
                      label="Ruoli"
                      name="ruoli"
                      id="ruoli"
                      options={tipiRuoliSelezionabili} 
                      placeholder="Seleziona ruolo"
                      isClearable={true}
                      onChange={handleChangeRuoli}
                      value={ruoloSelezionato} 
                    />
                    </div>
                    <div className="col-6">
                      <br />
                      <button onClick={() => addRuolo()} className="btn btn-primary justify-content-left" type='button'>
                          Aggiungi Ruolo
                      </button>
                    </div>
                </div>
                {!loading 
                  ?
                  listRuoliSelezionati.length > 0 && 

                  <SicondirTable 
                  headers={[
                    "Ruolo selezionato",
                    "Persona giuridica associata",
                    ""]} 
                  data={listRuoliSelezionati} 
                  dropdown={true}
                  />
                  
                  :
                  <>
                  <br />
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <div className="progress-spinner progress-spinner-active">
                      <span className="sr-only">Caricamento...</span>
                    </div>
                  </div>
                  </>
                }
                
                <Modal 
                  //dialogClassName={'modal-fullpage'}
                  size="xl" 
                  show={showPersoneGiuridiche} 
                  onHide={handleCloseRuoli}>
                  <Modal.Header closeButton>
                    <Modal.Title >
                      Ricerca persona giuridica 
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <PersonaGiuridicaRicercaForm associaPersonaGiuridica={associaPersonaGiuridica} />
                  </Modal.Body>
                </Modal> 

                  {/* Commentata da Cipriani perché è finita in PROD incompleta... 
                  <button className="button add" type="button" onClick={() => addFormFields()}>Aggiungi indirizzo</button>
                  {formIndirizzoValues.map((element, index) => (
                    <div className="form-inline" key={index}>
                    <SicondirInput
                      label="Indirizzo"
                      id='indirizzo1'
                      type='text'
                      name='indirizzo1'
                      onChange={handleChange}
                      className='form-control mb-2'
                      size="large" 
                      placeholder = 'Inserisci indirizzo'  
                      maxLength = '100'
                    />              
                    {
                      index ? 
                        <button type="button" className="button remove" onClick={() => removeFormFields(index)}>Remove</button> 
                      : null
                    }
                    </div>
                  ))}
... FINO A QUA*/}
                
                {/* <br /><br />
                <div className="mb-2 align-middle">
                  <label id='dataultimavariazionePGlabel' htmlFor='dataultimavariazionePG' className="col-3">Data ultima variazione PG</label>
                  <Space direction="vertical" id='dataultimavariazionePG' className="ms-5" style={{display:'inlineBlock'}}>
                    <DatePicker placeholder='Data ultima variazione PG' onChange={handleChange} />
                  </Space>
                </div>
                <div className="mb-2 align-middle">
                  <label id='datainizioincaricolabel' htmlFor='datainizioincarico' className="col-3">Data inizio incarico ({notaDataIncarico})</label>
                  <Space direction="vertical" id='datainizioincarico' className="ms-5" style={{display:'inlineBlock'}}>
                    <DatePicker placeholder='Data inizio incarico' onChange={handleChange} />
                  </Space>
                </div> */}
                <div className="col-12 mt-5 row justify-content-center">
                
                
                <button type="button" className="btn btn-primary col-5 mr-5 float-right"
                  onClick={() => tornaRicerca(props.persona_trovata, props.setRicerca)}
                >
                  Torna alla ricerca
                </button>
                { !showTastiIndirContat ?
                <button type="submit" className="btn btn-primary col-5">
                  Inserisci
                  </button>

                :'' }
                { showTastiIndirContat 
                  ? 
                    <>
                    {(formIndirizzoValues.length>0)
                      ?
                        <legend 
                          className='mt-5' 
                          style={{'fontSize':'16px',  'fontWeight':'600'}}
                        >
                          Indirizzi
                        </legend>
                      :''
                    }
                    {
                      formIndirizzoValues.map((element, index) => (
                        <div className="form-inline border mt-2 p-2" key={index}>
                          <SchedaIndirizzo 
                            valueNaturaGiuridica={ valueNaturaGiuridica } 
                            indirizzo={ formatter.cleanObject(element) }
                            setIndirizzoCambiato = { setIndirizzoCambiato }
                          />
                        </div>
                      ))
                    }
                    {(formContattoValues.length>0)?
                      <legend className='mt-5' style={{'fontSize':'16px',  'fontWeight':'600'}}>
                        Contatti
                      </legend>
                    :''
                    }
                    {     
                      formContattoValues.map((element, index) => (
                        <div className="form-inline border mt-2 p-2" key={index}>
                          <SchedaContatto
                            valueNaturaGiuridica={ valueNaturaGiuridica } 
                            contatto={ formatter.cleanObject(element) }
                            setContattoCambiato = { setContattoCambiato }
                          />
                        </div>
                      ))
                    }
                    <TastiIndirizzoContatto
                      persona_id = { persona_id }
                      valueNaturaGiuridica = { valueNaturaGiuridica }
                      setIndirizzoCambiato = { setIndirizzoCambiato }
                      setContattoCambiato = { setContattoCambiato }
                    />
                    </>
                  : ''
                }                
              </div>
            </Form>
            )}
          </Formik>
        : 
        <div className="mt-5">
          {(valueNaturaGiuridica=='PF')?
            <SicondirTable 
              headers={[
                "Cognome",
                "Nome", 
                "Codice fiscale",  
                "Codice Sicer",
                "Tipo persona",
                ""
                ]} 
              data={list} />
            :
            <SicondirTable 
              headers={[
                "Ragione sociale",
                "Partita Iva",
                "Codice fiscale", 
                "Codice Sicer",         
                "Tipo persona",
                ""
              ]} 
              data={list} 
            />
          }
          
          <button type="button" className="btn btn-primary float-right"
            onClick={() => tornaRicerca(props.persona_trovata, props.setRicerca)}
          >
              Torna alla ricerca
          </button>
        </div>
      }
    </>
  );
}
export default IntestatarioForm;
