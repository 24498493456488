import alertify from "alertifyjs"; 

const associaAllaOccupazione = async (occupazioneId, handleClose, personaId, ruoliId, setAssociazione) => {
  const inserimentoOccupazioniPersone = async (ruoliId) => {
    if(ruoliId) {
      let campi = "utente_modifica_id, occupazione_id, persona_ruolo_id "
      let valori = localStorage.id + ", " + occupazioneId + ", " + ruoliId
      
      const payload = {
        nome_tabella: "occupazioni_persone",
        lista_campi: campi,
        lista_valori: valori
      }
      const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, 
        mode: 'cors', 
        body: JSON.stringify({Entry:payload})
       })
      alertify.success("Persona associata all'occupazione con successo.")
      handleClose()
      setAssociazione(true)
    }
  }
  try {
    inserimentoOccupazioniPersone(ruoliId)
  } catch(error) {
    alertify.error("Errore durante l'inserimento.");
    console.log("errore: ", error);
  }
  return(
    <dialog></dialog>
  )
}
export default associaAllaOccupazione;