import React from 'react'
import { useEffect, useState } from 'react'
import tipiContatti from '../../_data/tipi_contatti.json'
import alertify from "alertifyjs";
import DeleteButton from "./DeleteButton";
import Modal from 'react-bootstrap/Modal';
import ContattoEdit from "../components/ContattoEdit";
import DeleteIndirizzoContatto from "./DeleteIndirizzoContatto";


const SchedaContatto= (props) => {
  const[show, setShow] = useState(false);

  const handleModal = () => {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
  }
  const settaTipoContatto = () => {
    if(props.contatto.tipo_contatto_id != undefined) {
      let ObjTipoContatto = {}
      ObjTipoContatto = tipiContatti.find(tI => tI.value === props.contatto.tipo_contatto_id)
    
      return JSON.stringify(ObjTipoContatto.label).replaceAll('"','')
    }
  }
  
  return (
    <>
      <div className="col-3 md-12 sm-12 sicondir-label">
      Tipo contatto<br/><span style={{color:'#000'}}>{settaTipoContatto()}</span>
      </div>
      <div className="col-3 md-12 sm-12 sicondir-label">
      Valore<br/><span style={{color:'#000'}}>{props.contatto.valore}</span>
      </div>
      
      <div className="col-12 text-right">
        <Modal 
          dialogClassName={'modal-fullpage'}
          show={show} 
          onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title >
              Modifica Contatto
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ContattoEdit
              handleClose = { handleClose } 
              valueNaturaGiuridica = { props.valueNaturaGiuridica }
              contatto = { props.contatto } 
              setContattoCambiato = { props.setContattoCambiato }
            />
          </Modal.Body>
        </Modal> 
        <button className="btn btn-primary col-2 mr-2" type="button" onClick={handleModal}>
          Modifica Contatto
        </button>
        <DeleteIndirizzoContatto 
          id={props.contatto.id} 
          contesto='contatto' 
          setContattoCambiato={props.setContattoCambiato} 
        />
        
      </div>
    </>
  )
}
export default SchedaContatto