import { useEffect, useState } from 'react'
import formatter from '../utils/formatters/_';
import alertify from "alertifyjs";  

export default function SicondirRigaPagamenti(props) {

    const [loading, setLoading] = useState(true)
    const [pagamenti, setPagamenti] = useState([])

    let headersPagamenti = ["Identificativo pagamento", "Tipologia pagamento", "Data pagamento", "Importo riconciliato(€)"];
    
    let idAssociazione = props.idAssociazione;
    
    useEffect(()=>{
        const fetchData = async (idAssociazione, contesto)  => {
            try{

                let urlPagamenti = "";
                if(contesto == "concessione"){
                    urlPagamenti = process.env.REACT_APP_API_URL + 'pagamenti_oggetto/concessione_canone_id/'+idAssociazione;
                }
                if(contesto == "occupazione"){
                    urlPagamenti = process.env.REACT_APP_API_URL + 'pagamenti_oggetto/occupazione_indennizzo_id/'+idAssociazione;
                }
                if(contesto == "denuncia_pozzo"){
                    urlPagamenti = process.env.REACT_APP_API_URL + 'pagamenti_oggetto/denuncia_pozzo_indennizzo_id/'+idAssociazione;
                }
                let pagamentiResponse = await fetch(urlPagamenti, {
                    method: 'GET',
                    headers: { accept: "application/json" },      
                    mode: 'cors'
                });
                let json = await pagamentiResponse.json();
                let arrayPagamenti = [];
                
                if(json.risposta.pagamenti){
                    let response = formatter.cleanObject(json.risposta.pagamenti)
                    if(Array.isArray(response)){
                        json.risposta.pagamenti.map(element =>{
                            element = formatter.cleanObject(element)
                            arrayPagamenti.push(element);
                        })
                    }
                }

                setPagamenti(arrayPagamenti)
                setLoading(false)
            }
            catch (error) {
                alertify.error("Errore durante il recupero dei dati.");
                console.log("errore: ", error);
                
            } 
        };    
        
        fetchData(props.idAssociazione, props.contesto)
    }, [])


    return(
        <>
        {!loading ?
            <>
                {pagamenti.length ? 
                    <>
                    <tr key={idAssociazione} >
                        <th scope="row" rowSpan={pagamenti.length +1 } colSpan='2' style={{verticalAlign: 'middle'}}>Pagamenti</th>
                        { headersPagamenti.map((col) => {
                            return ( <th className="table-light" colSpan={2} key={col}> {col} </th> )
                            })
                        }
                    </tr>
                    
                    {pagamenti.map((pagamento) => {
                        return (
                            <tr className="table-light" id={idAssociazione + "-" +pagamento.pagamenti_id} key={idAssociazione + "-" +pagamento.pagamenti_id}>
                                <td colSpan={2}> {pagamento.identificativo_pagamento || " - "} </td>
                                <td colSpan={2}> {pagamento.tipologia_pagamento || " - "} </td>
                                <td colSpan={2}> {pagamento.data_pagamento ? pagamento.data_pagamento.substring(0, 10) : ''} </td>
                                <td colSpan={2}> {formatter.formatNumber(pagamento.importo_riconciliato)} </td>
                            </tr>
                        )   
                        })
                    } 
                    </>
                    :
                    <></>
                }
                
            </>
            :
            <tr>
                <td colSpan="9">
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        <div className="progress-spinner progress-spinner-active">
                        <span className="sr-only">Caricamento...</span>
                        </div>
                    </div>
                </td>
            </tr>
            
        }
        </>
    )
}
