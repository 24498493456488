import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import SicondirTable from "../../../common/components/SicondirTable";
import alertify from "alertifyjs";
import ConcessioniRicercaForm from "../../../common/forms/ConcessioniRicercaForm";
import formatter from "../../../common/utils/formatters/_";
import SicondirPaginator from "../../../common/components/SicondirPaginator";
import tipiStatoConcessione from "../../../_data/tipi_stato_concessioni.json";
import tipiAmbito from "../../../_data/tipi_ambito.json";
import Modal from 'react-bootstrap/Modal'
import DenunciaPozzoInserimentoForm from "../../../common/forms/denuncePozzo/DenunciaPozzoInserimentoForm";
import statiDenunciaPozzo from "../../../_data/tipi_stato_concessioni.json";

export default function DenuncePozzoRicerca(props) {

    let navigate = useNavigate();
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [currentMacroambito, setCurrentMacroambito] = useState(props.macroambito);
    const [stringaMacroambito, setStringaMacroambito] = useState(props.macroambito == "F" ? "fluviali-lacuali" : "derivazioni")
    const [richiestaId, setRichiestaId] = useState(props.richiestaId);
    const [concessioneId, setConcessioneId] = useState(props.concessioneId);
    const [sent, setSent] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);

    const [show, setShow] = useState(false);
    const handleModal = () => {
      setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }


    let contesto = props.contesto ? props.contesto : 'anagrafica';

    let fromUrl = "/denunce-pozzo/"

    const associaDenunciaPozzoRichiesta = async (denunciaPozzoId, richiestaId) => {
        let campi = "denuncia_pozzo_id,richiesta_concessione_id,utente_modifica_id";
        let valori = denunciaPozzoId + "," + richiestaId + "," + localStorage.id
        const payload = {
          nome_tabella: "denunce_pozzo_richieste_concessione",
          lista_campi: campi,
          lista_valori: valori
        }
        try{
          const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
          })
          const data_response = await response.json();
          if(data_response.hasOwnProperty('Fault')){
            if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
              console.log("ErrorCode: " + data_response.Fault.faultcode)
              throw new Error(data_response.Fault.faultstring)
            } else{
              throw new Error("errore durante il salvataggio.")
            }
          }
          alertify.success("Denuncia pozzo associata alla domanda con successo.");
          props.setAssociazione(true)
          props.handleClose()
        } catch(error) {
          alertify.error("Errore durante l'associazione.");
          console.log("errore: ", error);
        }
      }

      const associaDenunciaPozzoConcessione = async (denunciaPozzoId, concessioneId) => {
        let campi = "concessione_id,denuncia_pozzo_id,utente_modifica_id";
        let valori = concessioneId + "," + denunciaPozzoId + "," + localStorage.id
        const payload = {
          nome_tabella: "denunce_pozzo_concessioni",
          lista_campi: campi,
          lista_valori: valori
        }
        try{
          const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
          })
          const data_response = await response.json();
          if(data_response.hasOwnProperty('Fault')){
            if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
              console.log("ErrorCode: " + data_response.Fault.faultcode)
              throw new Error(data_response.Fault.faultstring)
            } else{
              throw new Error("errore durante il salvataggio.")
            }
          }
          alertify.success("Concessione associata alla denuncia pozzo con successo.");
          props.setAssociazione(true)
          props.handleClose()
        } catch(error) {
          alertify.error("Errore durante l'associazione.");
          console.log("errore: ", error);
        }
      }
    
    const vaiDettaglio = (idDenunciaPozzo) =>{
        navigate('/denunce-pozzo/inserisci/' + idDenunciaPozzo) ;
    }

    const buildActions = (contesto, id) => {
        let result = {};
        switch(contesto){
          case 'anagrafica':
            result = { dettaglio: {
                kind: "function", 
                function: vaiDettaglio,
                parameters:
                {
                  idConcessione: id,
                },
                label: "Gestisci" }
            }
          break;
          case 'richieste':
            result = { associa: {
                kind: "function", 
                function: associaDenunciaPozzoRichiesta,
                parameters:
                {
                  idDenunciaPozzo: id,
                  idRichiesta: richiestaId
                },
                label: "Associa a domanda" }
            }
          break;
          case 'concessioni':
            result = { associa: {
                kind: "function", 
                function: associaDenunciaPozzoConcessione,
                parameters:
                {
                  idDenunciaPozzo: id,
                  idConcessione: concessioneId
                },
                label: "Associa a concessione" }
            }
          default: break;
        }
        return result;        
    }

    // "TENDINA AMBITI": necessario questo useEffect per quando si cambia routing da concessioni fluviali e derivazioni(e viceversa), per aggiornare il menu a tendina(vedere anche ConcessioniRicercaForm)
    useEffect(()=>{
      setCurrentMacroambito(props.macroambito)
    }, [props.macroambito])
    

    useEffect(() => {
      const fetchData = async (filter) => {
        setLoading(true);
        let pagination = (currentPage - 1)*process.env.REACT_APP_PAGINATION+","+process.env.REACT_APP_PAGINATION
        let codiceFiscalePiva = filter.codice_fiscale_piva ? filter.codice_fiscale_piva.trim() : " ";
        let ragioneSociale = filter.ragione_sociale ? filter.ragione_sociale.trim() : " ";
        let cognome = filter.cognome ? filter.cognome.trim() : " ";
        let codiceSicer = filter.codice_sicer ? filter.codice_sicer.trim() : " ";
        let tipoAmbitoId = filter.tipo_ambito_id ? filter.tipo_ambito_id : " ";
        let tipoProvinciaId = filter.tipo_provincia_id ? filter.tipo_provincia_id : " ";
        let codiceSicondir = filter.codice_sicondir ? filter.codice_sicondir.trim() : " ";
        let codiceSistemaPrecedente = filter.codice_sistema_precedente ? filter.codice_sistema_precedente.trim() : " ";
        let tipoStatoId = filter.tipo_stato_id ? filter.tipo_stato_id.trim() : " ";

        const url = process.env.REACT_APP_API_URL + 'denunce_pozzo/ricerca/' + codiceFiscalePiva + '/' + ragioneSociale + '/' + cognome + '/' + codiceSicer +'/' + tipoAmbitoId + '/' + tipoProvinciaId + '/' + codiceSicondir + '/' + codiceSistemaPrecedente + '/' + tipoStatoId +'/'+ contesto +'/' + pagination;
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: { 
                      // 'Content-Type': 'application/json',
                      accept: "application/json"
                    },      
            mode: 'cors'
           });

          const json = await response.json();

          // Da rivedere questa parte: dovremmo avere una api ad hoc che ritorni solo i dati che ci servono, non dobbiamo scremarli qua...
          let list = [];
          let i = 1;
          setTotalElements(0);
          if(json.risposta.denunce_pozzo){

            if(Array.isArray(json.risposta.denunce_pozzo)){
              setTotalElements(json.risposta.denunce_pozzo[0].conteggio);
              json.risposta.denunce_pozzo.map(element => {
                element = formatter.cleanObject(element)
                // gestione intestatario
                let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
                let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
                let ragioneSociale =  datiIntestatario[2]
                let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
                let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
                let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]

                let statoArray = tipiStatoConcessione.filter(s => (s.value == element.stato))
                let stato
                if(statoArray[0]) stato = statoArray[0].label
                let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
                let tipoAmbito
                if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice

                let actions = buildActions(contesto, element.id)
                list[i] = {
                  id: element.id,                                
                  codice_sicondir: element.codice_sicondir  || "-",
                  codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                  intestatario: intestatario,
                  codice_fiscale: codiceFiscale,
                  piva: piva,
                  data_decorrenza: element.data_decorrenza || "-",
                  data_scadenza: element.data_scadenza || "-",
                  stato: stato || "-",
                  app_actions: actions
                }
                i++
              });
            }
          } 
          
          setList(list); 
          setLoading(false);
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
    }
     
    
    if (sent) { 
      fetchData(filter);
     }
    }, [filter,sent, currentPage]); 
    
    useEffect(() => {
      setSent(false)
    }, [currentMacroambito]);
    const creaDenunciaPozzo = async () => {
      const denunciaPozzo = {
        nome_tabella: "denunce_pozzo",
        lista_campi: "macroarea_ambito,utente_modifica_id",
        // Attenzione qua il macroambito lo prendiamo dal macroambito "in sessione", ma per le denunce pozzo potremmo forzarlo sempre a D.
        lista_valori: "'" + formatter.escapeSql(currentMacroambito) + "'," + localStorage.id
      }
      try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }, 
          mode: 'cors', 
          body: JSON.stringify({Entry:denunciaPozzo})
         })
         const data = await response.json();
         navigate('/denunce-pozzo/inserisci/' + data.risposta.elementi_aggiunti.id, 
                  {state: {
                    fromUrl: {fromUrl}
                    }
                  }) ;
         alertify.success("Nuova denuncia pozzo creata con successo.");
      } catch(error) {
        alertify.error("Errore durante l'inserimento.");
        console.log("errore: ", error);
      }
      
    }

    return (
      <>
            <ConcessioniRicercaForm setFilter={setFilter} setSent={setSent} setCurrentPage={setCurrentPage} disabled={loading} macroambito={currentMacroambito} contesto={'denunce_pozzo'} stati={statiDenunciaPozzo}></ConcessioniRicercaForm>
            <br></br>
            {!loading ?

              <>
              { sent ?
              <>
              <SicondirTable 
                headers={[
                  "Codice sicondir",
                  "Codice sistema precedente",
                  "Richiedente/ Intestatario",
                  "Codice fiscale",
                  "Piva",
                  "Data decorrenza",
                  "Data scadenza",
                  "Stato",
                  ""]} 
                data={list} 
                />
                <SicondirPaginator
                  totalElements={totalElements}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
                </>
                :
                <></>
                }
                {contesto == 'anagrafica' 
                ?
                <div className="float-right">
                  <button className="btn btn-primary" onClick={handleModal}>Inserisci nuova denuncia pozzo</button>
                </div>
                :
                <></>
                }
              </>
                :
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <div className="progress-spinner progress-spinner-active">
                    <span className="sr-only">Caricamento...</span>
                  </div>
                </div>
            }

            <Modal
            size="xl"
            show={show} 
            onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title >
                    Inserimento nuova denuncia pozzo
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DenunciaPozzoInserimentoForm macroambito={currentMacroambito}/>
            </Modal.Body>
          </Modal>  
      </>
    );
  }