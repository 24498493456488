import { useEffect } from 'react'
import { Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Footer from './layout/Footer';
import Cookiebar from './components/Cookiebar'
//import { useNavigate } from "react-router-dom";
export default function AmbitoPage(props) {
 // let navigate = useNavigate();
  const gotoSezione = (sezione) => {
     localStorage.setItem('sezione', sezione);
     props.setSezione(sezione)
  //   navigate(url)
  } 
  useEffect(() => {},[props.sezione])
  return (
    <section className="main-content">
      <Cookiebar/>
    <div style={{height: '100vh'}}>
      <div className="col-12 it-header-slim-wrapper pr-5" style={{height:'55px', color:'#fff', textAlign:'right'}}>
        Benvenuto <span style={{fontWeight: 'bold'}}>{localStorage.nome} {localStorage.cognome}</span>
        <br/>{(props.sezione)?`Ambito "${(props.sezione=='Derivazioni'?'Derivazioni idriche':(props.sezione=='Fluviali')?'Fluviali/Lacuali':'')}"`:''}
      </div>
      <div className="it-header-center-wrapper col-12 align-top" style={{height:'180px'}}>
        {/*<div className="col-2"></div> */}
        <div className="align-top" style={{marginTop:'-90px'}}>
          <div className="row">
            <div className="col-12">
              <div className="it-header-center-content-wrapper">
                <div className="it-brand-wrapper">
                  <a href="#">
                    <div className="it-brand-text">
                      <div className="it-brand-title">
                        Sicondir 
                      </div>
                      <div className="it-brand-tagline d-none d-md-block">
                        <small>Concessioni idriche Regione Lazio</small>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="it-right-zone">
                </div>
                <div className="row mt-5"></div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 container bg-white shadow rounded p-4" style={{
          height:'56%'}}>
          <div className="col-12 mt-5 row justify-content-center" >
            <button 
              type="button" 
              onClick={()=>gotoSezione('Fluviali')} 
              className="btn btn-primary col-3 mt-4 mr-4 pt-4 pb-4">
              DEMANIO FLUVIALE/LACUALE
            </button>
            <button 
              type="button" 
              onClick={()=>gotoSezione('Derivazioni')} 
              className="btn btn-primary col-3 mt-4 pt-4 pb-4">
              DERIVAZIONI IDRICHE
            </button>
          </div>
        </div>
      
      <Footer />
      
    </div>
    </section>

    );
  }