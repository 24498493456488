import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirSelect from "../../components/SicondirSelect";
import tipiAmbito from '../../../_data/tipi_ambito.json'
import SicondirInput from "../../components/SicondirInput";
import tipiDurata from '../../../_data/tipi_durata.json';
import tipiRichiesta from '../../../_data/tipi_richiesta.json';
import formatter from '../../utils/formatters/_';
import { isNull, stubString } from "lodash";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import RichiesteConcessioniRicerca from '../../../modules/concessioni/components/RichiesteConcessioniRicerca';
import Modal from 'react-bootstrap/Modal'
import SicondirTable from "../../components/SicondirTable";
import { BrowserRouter, Route, Link } from "react-router-dom";
// import RichiestaForm from "../forms/richiesteConcessioni/RichiestaForm.jsx"
import provinceLazio from '../../../_data/province_lazio.json'
import tipiStatoRichiesta from "../../../_data/tipi_stato_richiesta_concessioni.json";
import SicondirTextarea from "../../components/SicondirTextarea";
import ConcessioniRicerca from "../../../modules/concessioni/components/ConcessioniRicerca";
import tipiStatoConcessione from "../../../_data/tipi_stato_concessioni.json";
import tipiRegolamento from "../../../_data/tipi_regolamento.json";

const DenunciaPozzoForm = (props) => {
    // Get ID from URL
    const params = useParams();
    const denunciaPozzoId = params.id;
    const [dataConsolidazione, setDataConsolidazione] = useState(null);
    const [dataConsolidazioneDisabled, setDataConsolidazioneDisabled] = useState(true)

    let navigate = useNavigate();
    const [valueUploaded, setValueUploaded] = useState(false);
    const [oldValue, setOldValue] = useState(props.data);
    const [loading, setLoading] = useState(props.loading);
    const [denunciaPozzo, setDenunciaPozzo] = useState(props.denunciaPozzo);
    const [tipiAmbitoFiltrati, setTipiAmbitoFiltrati] = useState(tipiAmbito)
    const [stringaMacroambito, setStringaMacroambito] = useState(props.macroambito == "F" ? "fluviali-lacuali" : "derivazioni")
    const [associazione, setAssociazione] = useState(false)
    const [show, setShow] = useState(false);
    const [cancellaAssociazione, setCancellaAssociazione] = useState(false);
    const [richiesta, setRichiesta] = useState(null)
    const [list, setList] = useState([])

    const [provinciaSelezionata,setProvinciaSelezionata] = useState(null)
    let titolo = "denunce-pozzo"

    // valori per concessione
    const [showConcessione, setShowConcessione] = useState(false);
    const [concessione, setConcessione] = useState(null)
    const [listConcessioni, setListConcessioni] = useState([])
    const [associazioneConcessione, setAssociazioneConcessione] = useState(false)
    const [cancellaAssociazioneConcessione, setCancellaAssociazioneConcessione] = useState(false)

    const [regolamentiDisabled, setRegolamentiDisabled] = useState(true);
    const [regolamentiFiltrati, setRegolamentiFiltrati] = useState([]);
    const [regolamentoSelezionato, setRegolamentoSelezionato] = useState(null);

    const enableRegolamenti = (event) => {
      if(event){
          const res = tipiRegolamento
          .filter((r) => r.tipo_ambito_id == event.value)
          .map((r) => {
            return {
              value: r.value,
              label: r.label,
            };
          });
        setRegolamentoSelezionato(event)
        setRegolamentiDisabled(false);
        setRegolamentiFiltrati(res);
        setRegolamentoSelezionato(null)
      } else{ 
        setRegolamentoSelezionato(null)
        setRegolamentiDisabled(true);
        setRegolamentiFiltrati(null);
        setRegolamentoSelezionato(null)
      }
      
    };

    useEffect(() => {
      if(denunciaPozzo.tipo_ambito_id != null && denunciaPozzo.tipo_regolamento_id != null){
        
        const res = tipiRegolamento
          .filter((r) => r.tipo_ambito_id == denunciaPozzo.tipo_ambito_id)
          .map((r) => {
            return {
              value: r.value,
              label: r.label,
            };
          });
        const regolamentoSelezionato = tipiRegolamento
        .filter((r) => r.value == denunciaPozzo.tipo_regolamento_id)
        .map((r) => {
          return {
            value: r.value,
            label: r.label,
          };
        });
        setRegolamentoSelezionato(regolamentoSelezionato[0])
        setRegolamentiDisabled(false);
        setRegolamentiFiltrati(res);
        //setRegolamentoSelezionato(null)
      } else{ 
        setRegolamentoSelezionato(null)
        setRegolamentiDisabled(true);
        setRegolamentiFiltrati(null);
        setRegolamentoSelezionato(null)
      }
    }, []);

    const handleRegolamento = (event) => {
      if(event){
        setRegolamentoSelezionato(event);
      } else {
        setRegolamentoSelezionato(null)
      }
      
    };

    const handleModal = () => {
      setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleDelete = async (associazioneId) => {
      setLoading(true);
      const payload = {
          nome_tabella: "denunce_pozzo_richieste_concessione",
          id: associazioneId,
          utente_modifica_id: localStorage.id
      }
      try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
            method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
            headers: { 'Content-Type': 'application/json',
                        'Accept': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
        })
        const data = await response.json();
        alertify.success("Associazione richiesta/denuncia pozzo eliminata con successo");
        setLoading(false)
        setCancellaAssociazione(true)
        setList([]);
    } catch(error) {
        alertify.error("Errore durante la cancellazione dell'associazione bene richiesta/denuncia pozzo");
        setLoading(false)
    }
    } 
      

const collegamento=async(IdRichiesta) => {
  navigate('/richieste-concessioni/' + stringaMacroambito + '/inserisci/' + IdRichiesta);
}

const collegamentoConcessione=async(IdConcessione) => {
  navigate('/concessioni/' + stringaMacroambito + '/inserisci/' + IdConcessione);
}

const handleModalConcessione = () => {
  setShowConcessione(true);
}

const handleCloseConcessione = () => {
    setShowConcessione(false);
}

const handleDeleteConcessione = async (associazioneId) => {
  setLoading(true);
  const payload = {
      nome_tabella: "denunce_pozzo_concessioni",
      id: associazioneId,
      utente_modifica_id: localStorage.id
  }
  try{
    const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
        method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
        headers: { 'Content-Type': 'application/json',
                    'Accept': 'application/json' }, 
        mode: 'cors', 
        body: JSON.stringify({Entry:payload})
    })
    const data = await response.json();
    alertify.success("Associazione concessione/denuncia pozzo eliminata con successo");
    setLoading(false)
    setCancellaAssociazioneConcessione(true)
    setListConcessioni([]);
} catch(error) {
    alertify.error("Errore durante la cancellazione dell'associazione concessione/denuncia pozzo");
    setLoading(false)
}
} 

useEffect(() => {
  const fetchData = () => {
    // Per la tendina degli ambiti:
    if (props.macroambito) {
      const res = tipiAmbito
        .filter((c) => c.macroarea_ambito == props.macroambito)
        .map((c) => {
          return {
            value: c.value,
            label: c.label,
          };
        });
      setTipiAmbitoFiltrati(res);
    }
  };
  fetchData();
}, []);

useEffect(() => {
  setOldValue(props.data);
});
/*
  useEffect(() => {
    
    if(oldValue && !valueUploaded){
        setValueUploaded(true)
        if(oldValue.tipo_ambito_id){ const tipoAmbitoSelezionato = tipiAmbito.filter( // ...lo popolo
        (c) => c.value == oldValue.tipo_ambito_id).map((c) => {
          return {
            value: c.value,
            label: c.label,
          };
        });
        setTipiAmbitoFiltrati(tipoAmbitoSelezionato[0])
        
      }
    
      if(oldValue.tipo_provincia_id){
        const provinciaSelezionataRecuperata = provinceLazio.filter(c => c.label == oldValue.tipo_provincia_id).map(c => {
          return {
            value: c.value,
            label: c.label,
          }
        })
        setProvinciaSelezionata(provinciaSelezionataRecuperata[0])
      }

    }
  } ,[oldValue])
*/

useEffect(() => {
  setLoading(false);
}, []);

  useEffect(() => {
    setLoading(false);
  });

  useEffect(() => {
    const fetchData = async () => {
        // Per la tendina degli ambiti:
        const res = tipiAmbito.filter(c => c.macroarea_ambito == 'D')
        .map(    
        c => { return { 
            value: c.value,
            label: c.label,
        };}
        )
        setTipiAmbitoFiltrati(res)
        // Recupero l'associazione con la denuncia_pozzo se presente: 
        try {
          const url = process.env.REACT_APP_API_URL + 'denunce_pozzo_richieste_concessione/denuncia_pozzo_id/' + denunciaPozzoId
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          if(json.risposta.richiesta){
              let element = formatter.cleanObject(json.risposta.richiesta)
              setRichiesta(element); 
              // gestione intestatario
              let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
              let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
              let ragioneSociale =  datiIntestatario[2]
              let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
              let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
              let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]
               
              let listDenuncePozzo = []

              let statoArray = tipiStatoRichiesta.filter(s => (s.value == element.stato))
              let stato
              if(statoArray[0]) stato = statoArray[0].label
              let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
              let tipoAmbito
              if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice

              listDenuncePozzo[0] = {
                id: element.associazione_id,
                tipo_ambito: tipoAmbito || "-",
                codice_sicondir: element.codice_sicondir  || "-",
                codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                intestatario:intestatario ,
                codice_fiscale: codiceFiscale  ,
                piva: piva ,
                stato: stato || "-", 
                app_actions:{
                  elimina: {
                    kind: "function", 
                    function: handleDelete,
                    label: "Elimina associazione",
                    parameters: {
                        associazioneId: element.associazione_id
                    },
                    confirmLabel: "Procedere con l'eliminazione dell'associazione alla domanda?",
                    buttonClass:"btn-danger",
                    linkClass: "text-danger",
                  },
                  collegamento: { 
                    kind:"function",
                    function: collegamento,
                    parameters:
                    {
                      IdRichiesta: element.id
                    
                  
                    },
                    label: "Dettaglio Richiesta"       
                  
                  },
                }
            }
              setList(listDenuncePozzo) // c'è una lista, ma dovremmo avere sempre e solo una concessione

          } else{
              setRichiesta(null);
          }

          setLoading(false);
          setAssociazione(false)
          setCancellaAssociazione(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }

        setLoading(false);
        
    };
    fetchData();
  }, [associazione, cancellaAssociazione]);

  /* USE EFFECT CONCESSIONE */
  useEffect(() => { 
    const fetchData = async () => {
        // Per la tendina degli ambiti:
        const res = tipiAmbito.filter(c => c.macroarea_ambito == "D")
        .map(    
        c => { return { 
            value: c.value,
            label: c.label,
        };}
        )
        setTipiAmbitoFiltrati(res)

        // Recupero l'associazione con la concessione se presente: 
        try {
          const url = process.env.REACT_APP_API_URL + 'denunce_pozzo_concessioni/denuncia_pozzo_id/' + denunciaPozzoId
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          if(json.risposta.concessione){
              let element = formatter.cleanObject(json.risposta.concessione)
              setConcessione(element); 
                 // gestione intestatario
                 let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
                 let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
                 let ragioneSociale =  datiIntestatario[2]
                 let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
                 let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
                 let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]

                 let statoArray = tipiStatoConcessione.filter(s => (s.value == element.stato))
                 let stato
                 if(statoArray[0]) stato = statoArray[0].label
                 let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
                 let tipoAmbito
                 if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice

              let listConcessioni = []
              listConcessioni[0] = {
                id: element.associazione_id,
                tipo_ambito: tipoAmbito || "-",
                codice_sicondir: element.codice_sicondir  || "-",
                codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                intestatario: intestatario ,
                codice_fiscale: codiceFiscale,
                piva: piva,
                stato: stato || "-",
                app_actions:{
                  elimina: {
                    kind: "function", 
                    function: handleDeleteConcessione,
                    label: "Elimina associazione",
                    parameters: {
                        associazioneId: element.associazione_id
                    },
                    confirmLabel: "Procedere con l'eliminazione dell'associazione alla concessione?",
                    buttonClass:"btn-danger",
                    linkClass: "text-danger",
                  },
                  collegamento: { 
                    kind:"function",
                    function: collegamentoConcessione,
                    parameters:
                    {
                      id: element.id
                    },
                    label: "Dettaglio Concessione"     
                  },
              }
              }
              setListConcessioni(listConcessioni) // c'è una lista, ma dovremmo avere sempre e solo una concessione

          } else{
              setConcessione(null);
          }
          setLoading(false);
          setAssociazioneConcessione(false)
          setCancellaAssociazioneConcessione(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }

        setLoading(false);
    };
    fetchData();
  }, [associazioneConcessione, cancellaAssociazioneConcessione]);
 /* FINE USE EFFECT CONCESSIONE */

  const options = [
    { value: 'BOZZA', label: 'Bozza' },
    { value: 'CONSOLIDATA', label: 'Consolidata' }
  ]

  return (
        <>
        {!loading ?
        <>
        <Formik
        id='denuncia_pozzo' className="p-4"
        initialValues={{
            archivio_provenienza: denunciaPozzo.archivio_provenienza || '', //concessione.archivio_provenienza,
            archivio_codice: denunciaPozzo.archivio_codice || '',
            archivio_posizione: denunciaPozzo.archivio_posizione || '',
            codice_sistema_precedente: denunciaPozzo.codice_sistema_precedente || '',
            codice_sicondir: denunciaPozzo.codice_sicondir || '', // è "nuovo codice univoco concessione"
            tipo_ambito_id: denunciaPozzo.tipo_ambito_id || '',
            tipo_regolamento_id: regolamentoSelezionato || '',
            tipo_richiesta_id: denunciaPozzo.tipo_richiesta_id || '', // nel documento si chiama "Tipologia Domanda"
            tipo_durata_id: denunciaPozzo.tipo_durata_id || '', 
            descrizione_uso: denunciaPozzo.descrizione_uso || '',
            data_sopralluogo_consegna: denunciaPozzo.data_sopralluogo_consegna ? denunciaPozzo.data_sopralluogo_consegna.substring(0, 10) : '',
            data_inizio_denuncia_pozzo: denunciaPozzo.data_inizio_denuncia_pozzo ? denunciaPozzo.data_inizio_denuncia_pozzo.substring(0, 10) : '',
            data_fine_denuncia_pozzo: denunciaPozzo.data_fine_denuncia_pozzo ? denunciaPozzo.data_fine_denuncia_pozzo.substring(0, 10) : '',
            durata_anni: denunciaPozzo.durata_anni || '',
            durata_mesi: denunciaPozzo.durata_mesi || '',
            durata_giorni: denunciaPozzo.durata_giorni || '',
            data_terminie_richiesta_rinnovo: denunciaPozzo.data_terminie_richiesta_rinnovo ? denunciaPozzo.data_terminie_richiesta_rinnovo.substring(0, 10) : '',
            note: denunciaPozzo.note || '',
            tipo_provincia_id: denunciaPozzo.tipo_provincia_id || '',
            stato_import: denunciaPozzo.stato_import || 'BOZZA',
           
            data_consolidazione: denunciaPozzo.data_consolidazione ? denunciaPozzo.data_consolidazione.substring(0, 10) : '',
            
            
          }}
          validationSchema={Yup.object({
            durata_anni: Yup.string().matches(/^((\d+?))$/,'inserire un valore numerico corretto'),
            durata_mesi: Yup.string().matches(/^((\d+?))$/,'inserire un valore numerico corretto'),
            durata_giorni: Yup.string().matches(/^((\d+?))$/,'inserire un valore numerico corretto')
          })}  
          onSubmit = { async (values, { setSubmitting }) => {
                let campi = ""

                Object.entries(values).map(([key,value],i) => {
                  if(value != '' && value != null && key!='data_consolidazione'){
                    campi += key + "='" + formatter.escapeSql(value) + "', ";
                    if(key=="stato_import" && value=="CONSOLIDATA"){
                      
                      var today = new Date();
                      var date = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');
                      campi+="data_consolidazione='"+date +"', "
                    }
                  } 
                  if(!(value != '' && value != null)){
                    if(key!='data_consolidazione') // La data consolidazione la devo gestire SEMPRE e SOLO in base allo stato_import.
                      campi += key + "=NULL , ";
                  }
                  if(key == 'tipo_provincia_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_ambito_id' && value == null) { campi += key + "=null, "; }
                  
                 
                  if(key == 'tipo_richiesta_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_durata_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'stato_import' && value == null) { campi += key + "=null, "; }
                })
                
                if(campi == ""){
                  alertify.warning("Inserire almeno un campo");
                  return;
                }
                try{
                  
                  campi += "utente_modifica_id=" + localStorage.id;
                  const payload = {
                    nome_tabella: "denunce_pozzo",
                    lista_campi_valore: campi,
                    lista_clausole_where: "id="+ denunciaPozzoId + " and flag_deleted=0"
                  }
                  
                  const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:payload})
                  })
                  const data_response = await response.json();
                  if(data_response.hasOwnProperty('Fault')){
                    if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + data_response.Fault.faultcode)
                      throw new Error(data_response.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }
                  //navigate('/IntestatarioForm/'+localStorage.id);
                  
                  
                  alertify.success("Dati denuncia pozzo salvati con successo.");
                } catch(error) {
                  alertify.error("Errore durante l'inserimento dei dati: " + error);
                  console.log("errore: ", error);
                }
                
            }} 
        >
        
       <>
       <Form>
                <br /><br />
                <div className="row">
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Archivio Provenienza'
                        id='archivio_provenienza'
                        type='text'
                        name='archivio_provenienza'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Archivio Provenienza'   
                        maxLength = '255'
                      />
                    </div>
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Codice Archivio Provenienza'
                        id='archivio_codice'
                        type='text'
                        name='archivio_codice'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Codice Archivio Provenienza'   
                        maxLength = '255'
                      />
                    </div>
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Posizione Archivio'
                        id='archivio_posizione'
                        type='text'
                        name='archivio_posizione'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Posizione Archivio'   
                        maxLength = '255'
                      />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Codice Univoco Del Sistema Di Provenienza'
                      id='codice_sistema_precedente'
                      type='text'
                      name='codice_sistema_precedente'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = '-'   
                      maxLength = '255'
                      disabled={true}
                      />
                  </div>
                  <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Nuovo Codice Univoco Denuncia Pozzo'
                        id='codice_sicondir'
                        type='text'
                        name='codice_sicondir'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Nuovo Codice Univoco Denuncia Pozzo'   
                        maxLength = '255'
                        disabled={true}
                      />
                  </div>
                </div>
               
                 <div className="row">
                    <div className="col-4 md-12 sm-12">
                    <SicondirSelect
                      label='Provincia'
                      id='tipo_provincia_id'                
                      name='tipo_provincia_id'              
                      options={provinceLazio} 
                      isClearable={true}
                      defaultValue = { provinceLazio.filter(c => c.value == denunciaPozzo.tipo_provincia_id)} 
                      placeholder = 'Provincia '
                      maxLength = '2'
                      isDisabled={true}
                    />           
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-4 md-12 sm-12 ">
                    <SicondirSelect 
                      label="Tipo Ambito" 
                      id="tipo_ambito_id" 
                      name="tipo_ambito_id" 
                      placeholder="Seleziona Tipo Ambito"
                      options={tipiAmbitoFiltrati} 
                      isClearable={true}
                      onChange={enableRegolamenti}
                      defaultValue={ tipiAmbito.filter(c => c.value == denunciaPozzo.tipo_ambito_id) }
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-8 md-12 sm-12 ">
                    <SicondirSelect 
                      label="Tipo Regolamento" 
                      id="tipo_regolamento_id" 
                      name="tipo_regolamento_id" 
                      placeholder="Seleziona Tipo Regolamento"
                      options={regolamentiFiltrati} 
                      isClearable={true}
                      isDisabled={regolamentiDisabled}
                      value={regolamentoSelezionato}
                      onChange={handleRegolamento}
                    />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect 
                      label="Tipo Domanda" 
                      id="tipo_richiesta_id" 
                      name="tipo_richiesta_id" 
                      placeholder="Seleziona Tipo Domanda"
                      options={tipiRichiesta} 
                      isClearable={true}
                      defaultValue={ tipiRichiesta.filter(c => c.value == denunciaPozzo.tipo_richiesta_id) }    
                    />    
                  </div>
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect 
                      label="Tipo Durata" 
                      id="tipo_durata_id" 
                      name="tipo_durata_id" 
                      placeholder="Seleziona Tipo Durata"
                      options={tipiDurata} 
                      isClearable={true}
                      defaultValue={ tipiDurata.filter(c => c.value == denunciaPozzo.tipo_durata_id) } 
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Tipo Utilizzo Concesso'
                      id='descrizione_uso'
                      type='text'
                      name='descrizione_uso'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Tipo Utilizzo Concesso'   
                      maxLength = '255'
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Data Sopralluogo Consegna'
                      id='data_sopralluogo_consegna'
                      type='date'
                      name='data_sopralluogo_consegna'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Data Sopralluogo Consegna'   
                    />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Data Decorrenza'
                      id='data_inizio_denuncia_pozzo'
                      type='date'
                      name='data_inizio_denuncia_pozzo'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Data Decorrenza'   
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Data Scadenza Denuncia Pozzo'
                      id='data_fine_denuncia_pozzo'
                      type='date'
                      name='data_fine_denuncia_pozzo'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Data Scadenza Denuncia Pozzo'   
                    />

<div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Data Termine Domanda Rinnovo'
                      id='data_terminie_richiesta_rinnovo'
                      type='date'
                      name='data_terminie_richiesta_rinnovo'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Data Termine Domanda Rinnovo'   
                    />  
                  </div>





                  </div>
                  <div className="row">
                  <div className="col-4 md-12 sm-12">
                    < SicondirInput
                      label='Durata Anni'
                      id='durata_anni'
                      type='text'
                      name='durata_anni'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Durata Denuncia Anni'   
                      maxLength = '255'
                    />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    < SicondirInput
                      label='Durata Mesi'
                      id='durata_mesi'
                      type='text'
                      name='durata_mesi'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Durata Denuncia Mesi'   
                      maxLength = '255'
                    />
                  </div>
                 
                  <div className="col-4 md-12 sm-12">
                    < SicondirInput
                      label='Durata Giorni'
                      id='durata_giorni'
                      type='text'
                      name='durata_giorni'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Durata Giorni'   
                      maxLength = '255'
                    />
                  </div>
                  </div>
               
                  <div className="col-12 md-12 sm-12">
                    <SicondirTextarea
                      label='Note'
                      id='note'
                      type='textarea'
                      style={{height: "150px", width: "100%"}}
                      name='note'
                      size="large" 
                    />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect
                      label='Stato Import'
                      id='stato_import'
                      type='text'
                      name='stato_import'                   
                      size="large" 
                      placeholder = 'Stato Import' 
                      options={options} 
                      isClearable={false}
                      isNullable={false}
                      defaultValue={ options.filter(c => c.value == denunciaPozzo.stato_import) }                       
                     // onChange={enableDataConsolidazione}

                    />
                  </div>

                  <div className="col-4 md-12 sm-12">
                    <SicondirInput readOnly
                      label='Consolidato in data'
                      id='data_consolidazione'
                      type='date'
                      name='data_consolidazione'                   
                      size="large" 
                      placeholder = 'Data Consolidazione'                
                    />
                  </div>
                </div>
               
              

            <div className="col-12 mt-5 row justify-content-center">
                <button type="submit" className="btn btn-primary col-5">
                Salva
                </button>
            </div>
            
        </Form>
        </>
    
            
        </Formik>
        <>
        <br /> <br />
        <hr></hr>
        <h5>Domanda associata</h5>
        { !richiesta
            ?
            <div className="row">
              <div className="col-12 mt-5 row justify-content-center">
                  Nessuna domanda associata alla concessione
              </div>
              <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModal}>
                  Associa Domanda
                </button>
              </div>
              </div>
            : 
            <>
              <SicondirTable 
                headers={[
                  "Tipo ambito",
                  "Codice sicondir",
                  "Codice sistema precedente",
                  "Richiedente/ Intestatario",
                  "Codice fiscale",
                  "Partita IVA", 
                  "Stato",
                  ""
                  ]} 
                data={list} 
                dropdown={true}
              />         
            </>
        }
        <hr></hr>
        <h5>Concessione associata</h5>
        { !concessione
            ?
            <div className="row">
              <div className="col-12 mt-5 row justify-content-center">
                  Nessuna concessione associata alla denuncia pozzo
              </div>
              <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModalConcessione}>
                  Associa Concessione
                </button>
              </div>
              </div>
            : 
            <>
              <SicondirTable 
                headers={[
                  "Tipo ambito",
                  "Codice sicondir",
                  "Codice sistema precedente",
                  "Richiedente/ Intestatario",
                  "Codice fiscale",
                  "Partita IVA", 
                  "Stato",
                  ""
                  ]} 
                data={listConcessioni} 
                dropdown={true}
              />         
            </>
        }
        </>
        </>
        :
        
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>
        }

        <Modal 
                dialogClassName={'modal-fullpage'}
                show={show} 
                onHide={handleClose}>
				  <Modal.Header closeButton>
            <Modal.Title >
              Ricerca Domanda
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RichiesteConcessioniRicerca contesto='denunce_pozzo' denunciaPozzoId={denunciaPozzoId}
              handleClose={handleClose}
              setAssociazione={setAssociazione}
              macroambito={"D"}/>
          </Modal.Body>
        </Modal>

        <Modal 
                dialogClassName={'modal-fullpage'}
                show={showConcessione} 
                onHide={handleCloseConcessione}>
				  <Modal.Header closeButton>
					  <Modal.Title >
              Ricerca Concessione
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <ConcessioniRicerca contesto='denunce_pozzo' denunciaPozzoId={denunciaPozzoId}
                handleClose={handleCloseConcessione}
                setAssociazione={setAssociazioneConcessione}
                macroambito={"D"}/>
          </Modal.Body>
        </Modal>

      </>
      
    )
}

export default DenunciaPozzoForm;