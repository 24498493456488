import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirInput from "../../components/SicondirInput";
import tipiDocumento from '../../../_data/tipi_documento.json';
import formatter from '../../utils/formatters/_';
import SicondirSelect from "../../components/SicondirSelect";
import UploadButton from "../../components/UploadButton";


const DocumentoDenunciaPozzoFormEdit = (props) => {
    // Get ID from URL
    const params = useParams();
    const denunciaPozzoId = params.id;
    const [loading, setLoading] = useState(true);
    const [fileObject, setFileObject] = useState(null)
    const [tipoDocumento, setTipoDocumento] = useState(null)
    const [pulisciFile, setPulisciFile] = useState(false)
    const [documento, setDocumento] = useState(props.documento)

    const manageTipoDocumento = (event) =>{
      if(event){
        setTipoDocumento({
            label: event.label,
            value: event.value
        })
    } else{
        setTipoDocumento(null);
    }
    }

    /*
    useEffect(() => {
      console.log("fileObject", fileObject)
    }, [fileObject]);
    */

    return (
        <>
        <Formik
        id='documento' className="p-4"
        initialValues={{
            tipo_documento_id: documento.tipo_documento_id,
            numero_riferimento_atto: documento.numero_riferimento_atto || '', 
            descrizione: documento.descrizione || '',
            file: null 
        }}
        validationSchema={Yup.object({
          tipo_documento_id: Yup.string().required("Il campo è obbligatorio"),
        })}
        

        onSubmit = { async (values, { setSubmitting, setFieldValue,resetForm }) => {
            let associazioneDenunciaPozzoDocumento = true;
            
            let campi = ""
                 
            Object.entries(values).map(([key,value],i) => {
              if(key=='file'){
              } else if(value != '' && value != null){
                campi += key + "='" + formatter.escapeSql(value) + "', ";
              } 
            })

            // Prima carico il doc se l'ho impostato: se va a buon fine posso inserire a DB.
            if(fileObject){
              const formData = new FormData();
              let filePath = process.env.REACT_APP_FILE_FOLDER
              let fileName = ""
              formData.append('uploadedFile', fileObject);
              formData.append('contesto', "denuncia_pozzo");
              formData.append('id', denunciaPozzoId);
              await fetch(
                process.env.REACT_APP_API_FILE_UPLOAD,
                {
                  method: 'POST',
                  body: formData,
                }
              ).then((response) => response.json())
                .then((result) => {
                  filePath += result.risposta.path;
                  fileName += result.risposta.filename;
                  campi += "file_path='" + formatter.escapeSql(filePath) + "',filename='" + formatter.escapeSql(fileName) + "',"
                })
                .catch((error) => {
                  alertify.error("Errore durante il caricamento del file");
                  console.error('Error:', error);
                  associazioneDenunciaPozzoDocumento = false;
                });
            } 
            
            if(associazioneDenunciaPozzoDocumento){
              try{
                campi += "utente_modifica_id=" + localStorage.id;
                const documentoModifica = {
                  nome_tabella: "denunce_pozzo_documenti",
                  lista_campi_valore: campi,
                  lista_clausole_where: "id="+ documento.id + " and flag_deleted=0"
                }
  
                const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' }, 
                  mode: 'cors', 
                  body: JSON.stringify({Entry:documentoModifica})
                })
  
                const data_response = await response.json();
                if(data_response.hasOwnProperty('Fault')){
                  if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                    console.log("ErrorCode: " + data_response.Fault.faultcode)
                    throw new Error(data_response.Fault.faultstring)
                  } else{
                    throw new Error("errore durante il salvataggio.")
                  }
                }
                alertify.success("Documento modificato con successo.");
                 resetForm();
                 setPulisciFile(true)
                 setTipoDocumento(null)
                 props.setModifica(true)
                 props.handleClose();
              } catch(error) {
                alertify.error("Errore durante la modifica del documento");
                console.log("errore: ", error);
              }
            }

            setLoading(false)
          }}
          
        >
          {({
          values, 
          errors, 
          handleChange, 
          handleSubmit,
          isSubmitting,
          isValid,
          touched,
          dirty,
          }) => (
              <Form>
                <br /><br />
                    <SicondirSelect
                        label="Tipo Documento" 
                        id="tipo_documento_id" 
                        name="tipo_documento_id"
                        mandatory= 'true'
                        // isClearable={true}
                        onChange={manageTipoDocumento}
                        options={tipiDocumento} 
                        placeholder="Seleziona Tipo Documento"
                        defaultValue={tipiDocumento.filter(
                          (c) => c.value == documento.tipo_documento_id
                        )}
                    />
                    

                    <SicondirInput 
                        label='Descrizione'
                        id='descrizione'
                        type='text'
                        name='descrizione'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Descrizione Documento'   
                        maxLength = '255'
                    />

                    <SicondirInput 
                        label='Numero Riferimento Atto'
                        id='numero_riferimento_atto'
                        type='text'
                        name='numero_riferimento_atto'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Numero Riferimento Atto'   
                        maxLength = '255'
                    />

                    <label className="sicondir-label" >Documento attuale</label>
                    <a target="_blank" href={documento.file_path}>{documento.filename}</a>

                    <UploadButton setFileObject={setFileObject} cleanFile={pulisciFile}/>

                <div className="col-12 mt-5 row justify-content-center">
                    <button type="submit" className="btn btn-primary col-5" disabled={(!(isValid && dirty && !isSubmitting)) && (!fileObject) }>
                    Salva
                    </button>
                </div>
                <br></br>
            
        </Form>
        )}
        </Formik>
      </>
    )
}

export default DocumentoDenunciaPozzoFormEdit;