import React from 'react'
import CookieConsent, { Cookies } from "react-cookie-consent";

export default function Cookiebar() {

    return(
        <CookieConsent
            location="bottom"
            buttonText="Accetto"
            cookieName="sicondirCookieBar"
            ariaAcceptLabel	="Gestione dei cookies"
            buttonClasses="btn cookiebar-btn cookiebar-confirm"
            style={{ background: "#435a70" }}
            buttonStyle={{ color: "#000", background: "#fff", fontSize: "13px" }}
        >Questo sito utilizza esclusivamente cookies tecnici. 
        Questi strumenti di tracciamento sono strettamente 
        necessari per garantire il funzionamento e la fornitura 
        del servizio che hai richiesto e, pertanto, non 
        richiedono il tuo consenso.
        </CookieConsent>
    )
}