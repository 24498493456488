import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import SicondirTable from "../../../common/components/SicondirTable";
import alertify from "alertifyjs";
import PagamentiRicercaForm from "../../../common/forms/PagamentiRicercaForm";
import formatter from "../../../common/utils/formatters/_";
import SicondirPaginator from "../../../common/components/SicondirPaginator";
import Modal from 'react-bootstrap/Modal'
import PagamentoForm from "../../../common/forms/PagamentoForm";
import PagamentoInserimentoForm from "../../../common/forms/PagamentoInserimentoForm";

export default function PagamentiRicerca(props) {

    let navigate = useNavigate();
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [sent, setSent] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);

    const [show, setShow] = useState(false);
    const handleModal = () => {
      setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }

    let fromUrl = "/pagamenti/"

    const vaiDettaglio = (idPagamento) =>{
      navigate('/pagamenti/inserisci/' + idPagamento) ;
    }

    const buildActions = (idPagamento) => {
      let result = {};
      result = { 
            dettaglio: {
              kind: "function", 
              function: vaiDettaglio,
              parameters:
              {
                idPagamento: idPagamento,
              },
              label: "Gestisci" }
      }
      return result;        
    }
    
    useEffect(() => {
      const fetchData = async (filter) => {
        setLoading(true);
        let pagination = (currentPage - 1)*process.env.REACT_APP_PAGINATION+","+process.env.REACT_APP_PAGINATION
        let identificativoPagamento = filter.identificativo_pagamento ? filter.identificativo_pagamento.trim() : " ";
        let identificativoDebito = filter.identificativo_debito ? filter.identificativo_debito.trim() : " ";
        let codice = filter.codice ? filter.codice.trim() : " ";
        const url = process.env.REACT_APP_API_URL + 'pagamenti/ricerca/' + identificativoPagamento + '/' + identificativoDebito + '/' + codice + '/'+ pagination;
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: { 
                      // 'Content-Type': 'application/json',
                      accept: "application/json"
                    },      
            mode: 'cors'
           });

          const json = await response.json();

          let list = [];
          let i = 1;
          setTotalElements(0);
          if(json.risposta.pagamenti){
            if(Array.isArray(json.risposta.pagamenti)){
              setTotalElements(json.risposta.pagamenti[0].conteggio);
              json.risposta.pagamenti.map(element => {
                element = formatter.cleanObject(element)
                let identificativoDovuto = 
                  element.concessioni_canoni_identificativo ? element.concessioni_canoni_identificativo : 
                  (element.occupazioni_canoni_identificativo ? element.concessioni_canoni_identificativo : "-");
                let codice = "-"
                if(element.concessioni_codice_sicondir || element.concessioni_codice_sistema_precedente){
                  let codiceSicondir = element.concessioni_codice_sicondir ? element.concessioni_codice_sicondir : "-"
                  let codiceSistemaPrecedente = element.concessioni_codice_sistema_precedente ? element.concessioni_codice_sistema_precedente : "-"
                  codice = "concessione" + codiceSicondir + "("+ codiceSistemaPrecedente +")"
                }
                if(element.occupazioni_codice_sicondir || element.occupazioni_codice_sistema_precedente){
                  let codiceSicondir = element.occupazioni_codice_sicondir ? element.occupazioni_codice_sicondir : "-"
                  let codiceSistemaPrecedente = element.occupazioni_codice_sistema_precedente ? element.occupazioni_codice_sistema_precedente : "-"
                  codice = "occupazione" + codiceSicondir + "("+ codiceSistemaPrecedente +")"
                }
                let importo = formatter.formatNumber(element.importo);
                let actions = buildActions(element.id_pagamento) 
                list[i] = {
                  id: element.id || (element.identificativo_pagamento + "-" + element.id_pagamento),                 
                  identificativo_pagamento: element.identificativo_pagamento || "-",
                  identificativo_dovuto: identificativoDovuto,
                  codice: codice,
                  tipologia_pagamento: element.tipologia_pagamento || "-",
                  data_pagamento: element.data_pagamento ? element.data_pagamento.substring(0, 10) : '-',
                  importo: importo,
                  app_actions: actions
                }
                i++
              });
            }
          } 
          
          setList(list); 
          setLoading(false);
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
    }
     
    
    if (sent) { 
      fetchData(filter);
     }
    }, [filter,sent, currentPage]); 

    const creaPagamento = async () => {
      const pagamento = {
        nome_tabella: "pagamenti",
        lista_campi: "utente_modifica_id",
        // Attenzione qua il macroambito lo prendiamo dal macroambito "in sessione", ma per le occupazioni potremmo forzarlo sempre a F.
        lista_valori: localStorage.id
      }
      try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }, 
          mode: 'cors', 
          body: JSON.stringify({Entry:pagamento})
         })
         const data = await response.json();
         navigate('/pagamenti/inserisci/' + data.risposta.elementi_aggiunti.id, 
                  {state: {
                    fromUrl: {fromUrl}
                    }
                  }) ;
         alertify.success("Nuovo pagamento creato con successo.");
      } catch(error) {
        alertify.error("Errore durante l'inserimento.");
        console.log("errore: ", error);
      }
      
    }

    return (
      <>
            <PagamentiRicercaForm setFilter={setFilter} setSent={setSent} setCurrentPage={setCurrentPage} disabled={loading}></PagamentiRicercaForm>
            <br></br>
            {!loading ?

              <>
              { sent ?
              <>
              <SicondirTable 
                headers={[
                  "Identificativo pagamento",
                  "Identificativo dovuto",
                  "Codice concessione/occupazione\n(codice sistema provenienza)",
                  "Tipo pagamento",
                  "Data pagamento",
                  "Importo pagamento(€)",
                  ""]} 
                data={list} 
                />
                <SicondirPaginator
                  totalElements={totalElements}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
                </>
                :
                <></>
                }
                <div className="float-right">
                  <button className="btn btn-primary" onClick={handleModal}>Inserisci nuovo pagamento</button>
                </div>
                
              </>
                :
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <div className="progress-spinner progress-spinner-active">
                    <span className="sr-only">Caricamento...</span>
                  </div>
                </div>
            }
            <Modal 
            size="xl"
            show={show} 
            onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title >
                    Inserimento nuovo pagamento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PagamentoInserimentoForm />
            </Modal.Body>
          </Modal>
      </>
    );
  }