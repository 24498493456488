import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirSelect from "../components/SicondirSelect";
import tipiAmbito from '../../_data/tipi_ambito.json'
import SicondirInput from "../components/SicondirInput";
import tipiDurata from '../../_data/tipi_durata.json';
import tipiRichiesta from '../../_data/tipi_richiesta.json';
import formatter from '../utils/formatters/_';
import { isNull, stubString } from "lodash";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import RichiesteConcessioniRicerca from '../../modules/concessioni/components/RichiesteConcessioniRicerca';
import Modal from 'react-bootstrap/Modal'
import SicondirTable from "../components/SicondirTable";
import { BrowserRouter, Route, Link } from "react-router-dom";
import RichiestaForm from "../forms/richiesteConcessioni/RichiestaForm.jsx"
import provinceLazio from '../../_data/province_lazio.json'
import tipiStatoRichiesta from "../../_data/tipi_stato_richiesta_concessioni.json";
import SicondirTextarea from "../components/SicondirTextarea";
import OccupazioniRicerca from "../../modules/concessioni/components/OccupazioniRicerca";
import DenuncePozzoRicerca from "../../modules/concessioni/components/DenuncePozzoRicerca";
import tipiStatoConcessione from "../../_data/tipi_stato_concessioni.json";


import SicondirSingleCheckbox from '../components/SicondirSingleCheckbox';
 
const ConcessioneForm = (props) => {
    // Get ID from URL
    const params = useParams();
    const concessioneId = params.id;
    const [dataConsolidazione, setDataConsolidazione] = useState(null);
    const [dataConsolidazioneDisabled, setDataConsolidazioneDisabled] = useState(true)

    let navigate = useNavigate();
    const [valueUploaded, setValueUploaded] = useState(false);
    const [oldValue, setOldValue] = useState(props.data);
    const [loading, setLoading] = useState(props.loading);
    
    const [concessione, setConcessione] = useState(props.concessione);
    const [tipiAmbitoFiltrati, setTipiAmbitoFiltrati] = useState(tipiAmbito)
    const [stringaMacroambito, setStringaMacroambito] = useState(props.macroambito == "F" ? "fluviali-lacuali" : "derivazioni")
    const [associazione, setAssociazione] = useState(false)
    const [show, setShow] = useState(false);
    const [cancellaAssociazione, setCancellaAssociazione] = useState(false);
    const [richiesta, setRichiesta] = useState(null)
    const [list, setList] = useState([])

    // Variabili occupazione
    const [occupazione, setOccupazione] = useState(null)
    const [listOccupazioni, setListOccupazioni] = useState([])
    const [associazioneOccupazione, setAssociazioneOccupazione] = useState(false)
    const [cancellaAssociazioneOccupazione, setCancellaAssociazioneOccupazione] = useState(false);
    const [showOccupazione, setShowOccupazione] = useState(false);

    // Variabili denuncia pozzo
    const [denunciaPozzo, setDenunciaPozzo] = useState(null)
    const [listDenuncePozzo, setListDenuncePozzo] = useState([])
    const [associazioneDenunciaPozzo, setAssociazioneDenunciaPozzo] = useState(false)
    const [cancellaAssociazioneDenunciaPozzo, setCancellaAssociazioneDenunciaPozzo] = useState(false);
    const [showDenunciaPozzo, setShowDenunciaPozzo] = useState(false);
    

    const [provinciaSelezionata,setProvinciaSelezionata] = useState(null)
    const [provincianascita,setProvincianascita] = useState()
    const [comunenascita, setComunenascita] = useState();
    const [nazionenascita, setNazionenascita] = useState('');

    const [codicefiscale, setCodicefiscale] = useState(props.cod_fisc);
    const [labelComuneNascita, setLabelComuneNascita] = useState('Comune nascita');
    const [ragione_sociale,setRagione_sociale] = useState(props.ragione_sociale);
    const [genere, setGenere] = useState('');
    const [datanascita, setDatanascita] = useState('');
    const [estero,setEstero] = useState('');
    const [valueNaturaGiuridica, setValueNaturaGiuridica] = useState(props.natura_giuridica);
    const Mesi = { A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' }
    let titolo = "domande-concessioni"
   
    if(props.macroambito == 'F'){
      titolo += "fluviali/lacuali"
      
    } else if(props.macroambito == 'D'){
      titolo += "derivazioni"
      
    }

    const handleModal = () => {
      setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleDelete = async (associazioneId) => {
      setLoading(true);
      const payload = {
          nome_tabella: "concessioni_richieste_concessione",
          id: associazioneId,
          utente_modifica_id: localStorage.id
      }
      try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
            method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
            headers: { 'Content-Type': 'application/json',
                        'Accept': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
        })
        const data = await response.json();
        alertify.success("Associazione domanda/concessione eliminata con successo");
        setLoading(false)
        setCancellaAssociazione(true)
        setList([]);
    } catch(error) {
        alertify.error("Errore durante la cancellazione dell'associazione bene domanda/concessione");
        setLoading(false)
    }
  } 

    /* HANDLE OCCUPAZIONE */
    const handleModalOccupazione = () => {
      setShowOccupazione(true);
    }

    const handleCloseOccupazione = () => {
        setShowOccupazione(false);
    }

    const handleDeleteOccupazione = async (associazioneId) => {
      setLoading(true);
      const payload = {
          nome_tabella: "occupazioni_concessione",
          id: associazioneId,
          utente_modifica_id: localStorage.id
      }
      try{
        const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
            method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
            headers: { 'Content-Type': 'application/json',
                        'Accept': 'application/json' }, 
            mode: 'cors', 
            body: JSON.stringify({Entry:payload})
        })
        const data = await response.json();
        alertify.success("Associazione occupazione/concessione eliminata con successo");
        setLoading(false)
        setCancellaAssociazioneOccupazione(true)
        setListOccupazioni([]);
    } catch(error) {
        alertify.error("Errore durante la cancellazione dell'associazione bene occupazione/concessione");
        setLoading(false)
    }
    } 
    /*  */
      
/* HANDLE DENUNCIA POZZO */
const handleModalDenunciaPozzo = () => {
  setShowDenunciaPozzo(true);
}

const handleCloseDenunciaPozzo = () => {
    setShowDenunciaPozzo(false);
}

const handleDeleteDenunciaPozzo = async (associazioneId) => {
  setLoading(true);
  const payload = {
      nome_tabella: "denunce_pozzo_concessioni",
      id: associazioneId,
      utente_modifica_id: localStorage.id
  }
  try{
    const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
        method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
        headers: { 'Content-Type': 'application/json',
                    'Accept': 'application/json' }, 
        mode: 'cors', 
        body: JSON.stringify({Entry:payload})
    })
    const data = await response.json();
    alertify.success("Associazione occupazione/concessione eliminata con successo");
    setLoading(false)
    setCancellaAssociazioneOccupazione(true)
    setListOccupazioni([]);
} catch(error) {
    alertify.error("Errore durante la cancellazione dell'associazione bene occupazione/concessione");
    setLoading(false)
}
} 
/*  */    

const collegamento=async(IdRichiesta) => {
  navigate('/richieste-concessioni/' + stringaMacroambito + '/inserisci/' + IdRichiesta);
}

const collegamentoOccupazione=async(IdOccupazione) => {
  navigate('/occupazioni/inserisci/' + IdOccupazione);
}

const collegamentoDenunciaPozzo=async(IdDenunciaPozzo) => {
  navigate('/denunce-pozzo/inserisci/' + IdDenunciaPozzo);
}

useEffect(() => {
  const fetchData = () => {
    // Per la tendina degli ambiti:
    if (props.macroambito) {
      const res = tipiAmbito
        .filter((c) => c.macroarea_ambito == props.macroambito)
        .map((c) => {
          return {
            value: c.value,
            label: c.label,
          };
        });
      setTipiAmbitoFiltrati(res);
    }
  };
  fetchData();
}, []);

useEffect(() => {
  setOldValue(props.data);
});

  useEffect(() => {
    if(oldValue && !valueUploaded){
      setValueUploaded(true)
      if(oldValue.tipo_ambito_id){ const tipoAmbitoSelezionato = tipiAmbito.filter( // ...lo popolo
      (c) => c.value == oldValue.tipo_ambito_id
    ).map((c) => {
      return {
        value: c.value,
        label: c.label,
      };
    });
    setTipiAmbitoFiltrati(tipoAmbitoSelezionato[0])
  }
      if(oldValue.tipo_provincia_id){
    const provinciaSelezionataRecuperata = provinceLazio.filter(c => c.label == oldValue.tipo_provincia_id).map(c => {
      return {
        value: c.value,
        label: c.label,
      }
    })
    setProvinciaSelezionata(provinciaSelezionataRecuperata[0])
  }}} ,[oldValue])

  useEffect(() => {
    setLoading(false);
  });

  useEffect(() => {
    const fetchData = async () => {
        // Per la tendina degli ambiti:
        const res = tipiAmbito.filter(c => c.macroarea_ambito == props.concessione.macroarea_ambito)
        .map(    
        c => { return { 
            value: c.value,
            label: c.label,
        };}
        )
        setTipiAmbitoFiltrati(res)
        // Recupero l'associazione con la concessione se presente: 
        try {
          const url = process.env.REACT_APP_API_URL + 'concessioni_richieste_concessione/concessione_id/' + concessioneId
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          if(json.risposta.richiesta){
              let element = formatter.cleanObject(json.risposta.richiesta)
              setRichiesta(element); 
              // gestione intestatario
              let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
              let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
              let ragioneSociale =  datiIntestatario[2]
              let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
              let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
              let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]
               
              let listConcessioni = []

              let statoArray = tipiStatoRichiesta.filter(s => (s.value == element.stato))
              let stato
              if(statoArray[0]) stato = statoArray[0].label
              let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
              let tipoAmbito
              if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice

              listConcessioni[0] = {
                id: element.associazione_id,
                tipo_ambito: tipoAmbito || "-",
                codice_sicondir: element.codice_sicondir  || "-",
                codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                intestatario:intestatario ,
                codice_fiscale: codiceFiscale  ,
                piva: piva ,
                stato: stato || "-", 
                
                app_actions:{
                  elimina: {
                    kind: "function", 
                    function: handleDelete,
                    label: "Elimina associazione",
                    parameters: {
                        associazioneId: element.associazione_id
                    },
                    confirmLabel: "Procedere con l'eliminazione dell'associazione alla domanda?",
                    buttonClass:"btn-danger",
                    linkClass: "text-danger",
                  },
                  collegamento: { 
                    kind:"function",
                    function: collegamento,
                    parameters:
                    {
                      IdRichiesta: element.id
                    
                  
                    },
                    label: "Dettaglio Domanda"       
                  
                  },
                }
            }
              setList(listConcessioni) // c'è una lista, ma dovremmo avere sempre e solo una concessione

          } else{
              setRichiesta(null);
          }

          setLoading(false);
          setAssociazione(false)
          setCancellaAssociazione(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }

        setLoading(false);
        
    };
    fetchData();
  }, [associazione, cancellaAssociazione]);
  
  /* USE EFFECT OCCUPAZIONE */
  useEffect(() => {
    const fetchData = async () => {
        // Per la tendina degli ambiti:
        const res = tipiAmbito.filter(c => c.macroarea_ambito == props.concessione.macroarea_ambito)
        .map(    
        c => { return { 
            value: c.value,
            label: c.label,
        };}
        )
        setTipiAmbitoFiltrati(res)
        // Recupero l'associazione con la concessione se presente: 
        try {
          const url = process.env.REACT_APP_API_URL + 'occupazioni_concessione/concessione_id/' + concessioneId
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          if(json.risposta.occupazione){
              let element = formatter.cleanObject(json.risposta.occupazione)
              setOccupazione(element); 
              // gestione intestatario
              let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
              let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
              let ragioneSociale =  datiIntestatario[2]
              let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
              let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
              let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]
              let listOccupazioni = []
              let statoArray = tipiStatoRichiesta.filter(s => (s.value == element.stato))
              let stato
              if(statoArray[0]) stato = statoArray[0].label
              let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
              let tipoAmbito
              if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice
              listOccupazioni[0] = {
                id: element.associazione_id,
                tipo_ambito: tipoAmbito || "-",
                codice_sicondir: element.codice_sicondir  || "-",
                codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                intestatario:intestatario ,
                codice_fiscale: codiceFiscale  ,
                piva: piva ,
                stato: stato || "-", 
                app_actions:{
                  elimina: {
                    kind: "function", 
                    function: handleDeleteOccupazione,
                    label: "Elimina associazione",
                    parameters: {
                        associazioneId: element.associazione_id
                    },
                    confirmLabel: "Procedere con l'eliminazione dell'associazione all'occupazione?",
                    buttonClass:"btn-danger",
                    linkClass: "text-danger",
                  },
                  collegamento: { 
                    kind:"function",
                    function: collegamentoOccupazione,
                    parameters:
                    {
                      IdOccupazione: element.id
                    },
                    label: "Dettaglio Occupazione"
                  },
                } 
            }
              setListOccupazioni(listOccupazioni) // c'è una lista, ma dovremmo avere sempre e solo una occupazione
          } else{
              setOccupazione(null);
          }
          setLoading(false);
          setAssociazioneOccupazione(false)
          setCancellaAssociazioneOccupazione(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }

        setLoading(false);
        
    };
    if(props.macroambito == "F") fetchData();
  }, [associazioneOccupazione, cancellaAssociazioneOccupazione]);
  /* */

  /* USE EFFECT DENUNCIA POZZO */
  useEffect(() => {
    const fetchData = async () => {
        // Per la tendina degli ambiti:
        const res = tipiAmbito.filter(c => c.macroarea_ambito == props.concessione.macroarea_ambito)
        .map(    
        c => { return { 
            value: c.value,
            label: c.label,
        };}
        )
        setTipiAmbitoFiltrati(res)
        // Recupero l'associazione con la concessione se presente: 
        try {
          const url = process.env.REACT_APP_API_URL + 'denunce_pozzo_concessioni/concessione_id/' + concessioneId
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          if(json.risposta.denuncia_pozzo){
              let element = formatter.cleanObject(json.risposta.denuncia_pozzo)
              setDenunciaPozzo(element); 
              // gestione intestatario
              let datiIntestatario = element.intestatario ? element.intestatario.split("*#*") : "*#**#**#**#*".split("*#*");
              let nomeCognome =  datiIntestatario[0] + " " + datiIntestatario[1]
              let ragioneSociale =  datiIntestatario[2]
              let intestatario = (nomeCognome + ragioneSociale) == " " ? "-" : nomeCognome + ragioneSociale
              let codiceFiscale = datiIntestatario[3] == "" ? "-" : datiIntestatario[3]
              let piva = datiIntestatario[4] == "" ? "-" : datiIntestatario[4]
              let listDenuncePozzo = []
              let statoArray = tipiStatoRichiesta.filter(s => (s.value == element.stato))
              let stato
              if(statoArray[0]) stato = statoArray[0].label
              let tipoAmbitoArray = tipiAmbito.filter(s => (s.label == element.ambito_demaniale))
              let tipoAmbito
              if(tipoAmbitoArray[0]) tipoAmbito = tipoAmbitoArray[0].codice
              listDenuncePozzo[0] = {
                id: element.associazione_id,
                tipo_ambito: tipoAmbito || "-",
                codice_sicondir: element.codice_sicondir  || "-",
                codice_sistema_precedente: element.codice_sistema_precedente  || "-",
                intestatario: intestatario ,
                codice_fiscale: codiceFiscale,
                piva: piva,
                stato: stato || "-",
                app_actions:{
                  elimina: {
                    kind: "function", 
                    function: handleDeleteDenunciaPozzo,
                    label: "Elimina associazione",
                    parameters: {
                        associazioneId: element.associazione_id
                    },
                    confirmLabel: "Procedere con l'eliminazione dell'associazione alla denuncia pozzo?",
                    buttonClass:"btn-danger",
                    linkClass: "text-danger",
                  },
                  collegamento: { 
                  kind:"function",
                  function: collegamentoDenunciaPozzo,
                  parameters:
                  {
                    id: element.id
                  },
                  label: "Dettaglio Denuncia Pozzo"       
                },
              }
              }
              setListDenuncePozzo(listDenuncePozzo) // c'è una lista, ma dovremmo avere sempre e solo una denuncia pozzo
          } else{
              setDenunciaPozzo(null);
          }
          setLoading(false);
          setAssociazioneDenunciaPozzo(false)
          setCancellaAssociazioneDenunciaPozzo(false)
        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }

        setLoading(false);
        
    };
    if(props.macroambito == "D") fetchData();
  }, [associazioneDenunciaPozzo, cancellaAssociazioneDenunciaPozzo]);
  /* */

  const enableDataConsolidazione = (event) => {
    if(event){
        const res = concessione.data_consolidazione
       
        .map((c) => {
          return {
            value: c.value,
            label: c.label,
          };
        });
      setDataConsolidazione(event)
      setDataConsolidazioneDisabled(false);
      
    } else{ // se ho cancellato la provincia dalla combobox...
      setDataConsolidazione(null)
      setDataConsolidazioneDisabled(true);
     
    }
    
  };
  const options = [
    { value: 'BOZZA', label: 'Bozza' },
    { value: 'CONSOLIDATA', label: 'Consolidata' }
  ]



    return (
        <>
        {!loading ?
        <>
        <Formik
        id='concessione' className="p-4"
        initialValues={{
            archivio_provenienza: concessione.archivio_provenienza || '', //concessione.archivio_provenienza,
            archivio_codice: concessione.archivio_codice || '',
            archivio_posizione: concessione.archivio_posizione || '',
            codice_sistema_precedente: concessione.codice_sistema_precedente || '',
            codice_sicondir: concessione.codice_sicondir || '', // è "nuovo codice univoco concessione"
            tipo_ambito_id: concessione.tipo_ambito_id || '',
            tipo_richiesta_id: concessione.tipo_richiesta_id || '', // nel documento si chiama "Tipologia Domanda"
            tipo_durata_id: concessione.tipo_durata_id || '', 
            descrizione_uso: concessione.descrizione_uso || '',
            data_sopralluogo_consegna: concessione.data_sopralluogo_consegna ? concessione.data_sopralluogo_consegna.substring(0, 10) : '',
            data_inizio_concessione: concessione.data_inizio_concessione ? concessione.data_inizio_concessione.substring(0, 10) : '',
            data_fine_concessione: concessione.data_fine_concessione ? concessione.data_fine_concessione.substring(0, 10) : '',
            durata_anni: concessione.durata_anni || '',
            durata_mesi: concessione.durata_mesi || '',
            durata_giorni: concessione.durata_giorni || '',
            data_terminie_richiesta_rinnovo: concessione.data_terminie_richiesta_rinnovo ? concessione.data_terminie_richiesta_rinnovo.substring(0, 10) : '',
            note: concessione.note || '',
            tipo_provincia_id: concessione.tipo_provincia_id || '',
            stato_import: concessione.stato_import || 'BOZZA',
            atto_concessione_num:   concessione.atto_concessione_num || '',
            atto_concessione_del:concessione.atto_concessione_del ? concessione.atto_concessione_del.substring(0, 10) : '',
            flag_iscrizione_ruolo:concessione.flag_iscrizione_ruolo =="true" ? true : false,
            data_consolidazione: concessione.data_consolidazione ? concessione.data_consolidazione.substring(0, 10) : '',
            tipo_stato_concessione_id: concessione.tipo_stato_concessione_id || '',
            
          }}
          validationSchema={Yup.object({
          durata_anni: Yup.string().matches(/^((\d+?))$/,'inserire un valore numerico corretto'),
          durata_mesi: Yup.string().matches(/^((\d+?))$/,'inserire un valore numerico corretto'),
          durata_giorni: Yup.string().matches(/^((\d+?))$/,'inserire un valore numerico corretto')
        })}  
          onSubmit = { async (values, { setSubmitting }) => {
                let campi = ""

                Object.entries(values).map(([key,value],i) => {
                  if(key == 'flag_iscrizione_ruolo'){
                  if(value){
                     value = '1'
                  } else{
                     value = '0'
                  }
             
             }
                  if(value != '' && value != null && key!='data_consolidazione'){
                    campi += key + "='" + formatter.escapeSql(value) + "', ";
                    if(key=="stato_import" && value=="CONSOLIDATA"){
                      
                      var today = new Date();
                      var date = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');
                      campi+="data_consolidazione='"+date +"', "
                    }
                  } 
                  if(!(value != '' && value != null)){
                    if(key!='data_consolidazione') // La data consolidazione la devo gestire SEMPRE e SOLO in base allo stato_import.
                      campi += key + "=NULL , ";
                  }
                  if(value != '' && value != null && key!='atto_concessione_del'){
                    campi += key + "='" + formatter.escapeSql(value) + "', ";
                    if(key=="stato_import" && value=="CONSOLIDATA"){
                      
                      var today = new Date();
                      var date = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');
                      campi+="atto_concessione_del='"+date +"', "
                    }
                  } 
                  if(!(value != '' && value != null)){
                    if(key!='atto_concessione_del') // La data consolidazione la devo gestire SEMPRE e SOLO in base allo stato_import.
                      campi += key + "=NULL , ";
                  }
                  if(key == 'tipo_provincia_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_ambito_id' && value == null) { campi += key + "=null, "; }
                  
                 
                  if(key == 'tipo_richiesta_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_durata_id' && value == null) { campi += key + "=null, "; }
                  if(key == 'stato_import' && value == null) { campi += key + "=null, "; }
                  if(key == 'atto_concessione_num' && value == null) { campi += key + "=null, "; }
                  if(key == 'tipo_stato_concessione_id' && value == null) { campi += key + "=null, "; }
                  
                 
                })
                
                if(campi == ""){
                  alertify.warning("Inserire almeno un campo");
                  return;
                }
                try{
                  
                  campi += "utente_modifica_id=" + localStorage.id;
                  const payload = {
                    nome_tabella: "concessioni",
                    lista_campi_valore: campi,
                    lista_clausole_where: "id="+ concessioneId + " and flag_deleted=0"
                  }
                  
                  const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:payload})
                  })
                 
                  const data_response = await response.json();
                  if(data_response.hasOwnProperty('Fault')){
                    if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + data_response.Fault.faultcode)
                      throw new Error(data_response.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }
                  //navigate('/IntestatarioForm/'+localStorage.id);
                  
                  
                  alertify.success("Dati concessione salvati con successo.");
                } catch(error) {
                  alertify.error("Errore durante l'inserimento dei dati: " + error);
                  console.log("errore: ", error);
                }
                
            }}

           
        >
          
          
       
       <>
       
       <Form>
                <br /><br />
                <div className="row">
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Archivio Provenienza'
                        id='archivio_provenienza'
                        type='text'
                        name='archivio_provenienza'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Archivio Provenienza'   
                        maxLength = '255'
                      />
                    </div>
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Codice Archivio Provenienza'
                        id='archivio_codice'
                        type='text'
                        name='archivio_codice'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Codice Archivio Provenienza'   
                        maxLength = '255'
                      />
                    </div>
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Posizione Archivio'
                        id='archivio_posizione'
                        type='text'
                        name='archivio_posizione'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Posizione Archivio'   
                        maxLength = '255'
                      />
                  </div>
                </div>
                 
                <div className="row">
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label={props.macroambito=='D' ? "N°. Atto Concessione": "N°. Determina"}
                        id='atto_concessione_num'
                       
                        type='text'
                        name='atto_concessione_num'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci N°. Atto Concessione'   
                        maxLength = '255'
                      />
                    </div>
                    <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label={props.macroambito=='D' ? "Data Atto Concessione": "Data Determina"}
                        id='atto_concessione_del'
                        type='date'
                        name='atto_concessione_del'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Data Atto Concessione'   
                        maxLength = '255'
                      />
                    </div>
                    <div className="col-4 md-12 sm-12">
                      {props.macroambito=="D" ?
                    <SicondirSingleCheckbox
                    label="Iscrizione A Ruolo"
                    id='flag_iscrizione_ruolo'
                    name='flag_iscrizione_ruolo'
                    defaultChecked={concessione.flag_iscrizione_ruolo == 'true' ? true : false}
                    />
                    :<></>}

                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Codice Univoco Del Sistema Di Provenienza'
                      id='codice_sistema_precedente'
                      type='text'
                      name='codice_sistema_precedente'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = '-'   
                      maxLength = '255'
                      disabled = {true}
                      />
                  </div>
                  <div className="col-4 md-12 sm-12">
                      <SicondirInput 
                        label='Nuovo Codice Univoco Concessione'
                        id='codice_sicondir'
                        type='text'
                        name='codice_sicondir'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Nuovo Codice Univoco Concessione'   
                        maxLength = '255'
                        disabled = {true}
                      />
                  </div>
                </div>
                <div className="row">
                    <div className="col-4 md-12 sm-12">
                    <SicondirSelect
                      label='Stato Pratica'
                      id='tipo_stato_concessione_id'                
                      name='tipo_stato_concessione_id'              
                      options={tipiStatoConcessione} 
                      isClearable={true}
                      defaultValue = {tipiStatoConcessione.filter(c => c.value == concessione.tipo_stato_concessione_id)} 
                      placeholder = 'Stato'
                      maxLength = '2'
                    />           
                    </div>
                </div>

                 <div className="row">
                    <div className="col-4 md-12 sm-12">
                    <SicondirSelect
                      label='Provincia'
                      id='tipo_provincia_id'                
                      name='tipo_provincia_id'              
                      options={provinceLazio} 
                      isClearable={true}
                      defaultValue = { provinceLazio.filter(c => c.value == concessione.tipo_provincia_id)} 
                      placeholder = 'Provincia'
                      maxLength = '2'
                      isDisabled={true}
                    />           
                    </div>
                    </div>
                    <div className="row">
                  <div className="col-4 md-12 sm-12 ">
                    <SicondirSelect 
                      label="Tipo Ambito" 
                      id="tipo_ambito_id" 
                      name="tipo_ambito_id" 
                      placeholder="Seleziona Tipo Ambito"
                      options={tipiAmbitoFiltrati} 
                      isClearable={true}
                      defaultValue={ tipiAmbito.filter(c => c.value == concessione.tipo_ambito_id) }
                      isDisabled={true}
                    />
                  </div>
                
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect 
                      label="Tipo Durata" 
                      id="tipo_durata_id" 
                      name="tipo_durata_id" 
                      placeholder="Seleziona Tipo Durata"
                      options={tipiDurata} 
                      isClearable={true}
                      defaultValue={ tipiDurata.filter(c => c.value == concessione.tipo_durata_id) } 
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Tipo Utilizzo Concesso'
                      id='descrizione_uso'
                      type='text'
                      name='descrizione_uso'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Tipo Utilizzo Concesso'   
                      maxLength = '255'
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Data Sopralluogo Consegna'
                      id='data_sopralluogo_consegna'
                      type='date'
                      name='data_sopralluogo_consegna'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Data Sopralluogo Consegna'   
                    />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Data Decorrenza'
                      id='data_inizio_concessione'
                      type='date'
                      name='data_inizio_concessione'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Data Decorrenza'   
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Data Scadenza Concessione'
                      id='data_fine_concessione'
                      type='date'
                      name='data_fine_concessione'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Data Scadenza Concessione'   
                    />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    <SicondirInput 
                      label='Data Termine Domanda Rinnovo'
                      id='data_terminie_richiesta_rinnovo'
                      type='date'
                      name='data_terminie_richiesta_rinnovo'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Data Termine Richiesta Rinnovo'   
                    />  
                  </div>
                  <div className="row">
                  <div className="col-4 md-12 sm-12">
                    < SicondirInput
                      label='Durata Anni'
                      id='durata_anni'
                      type='text'
                      name='durata_anni'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Durata Anni'   
                      maxLength = '255'
                    />
                  </div>
                  <div className="col-4 md-12 sm-12">
                    < SicondirInput
                      label='Durata Mesi'
                      id='durata_mesi'
                      type='text'
                      name='durata_mesi'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Durata Mesi'   
                      maxLength = '255'
                    />
                  </div>
                 
                  <div className="col-4 md-12 sm-12">
                    < SicondirInput
                      label='Durata Giorni'
                      id='durata_giorni'
                      type='text'
                      name='durata_giorni'
                      className={`form-control mb-2`}
                      size="large" 
                      placeholder = 'Inserisci Durata Giorni'   
                      maxLength = '255'
                    />
                  </div>
                  </div>
                
                
                  <div className="col-12 md-12 sm-12">
                    <SicondirTextarea
                      label='Note'
                      id='note'
                      type='textarea'
                      style={{height: "150px", width: "100%"}}
                      name='note'
                      size="large" 
                    />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-4 md-12 sm-12">
                    <SicondirSelect
                      label='Stato Import'
                      id='stato_import'
                      type='text'
                      name='stato_import'                   
                      size="large" 
                      placeholder = 'Stato Import' 
                      options={options} 
                      isClearable={false}
                      isNullable={false}
                      defaultValue={ options.filter(c => c.value == concessione.stato_import) }                       
                     // onChange={enableDataConsolidazione}

                    />
                  </div>

                  <div className="col-4 md-12 sm-12">
                    <SicondirInput readOnly
                      label='Consolidato in data'
                      id='data_consolidazione'
                      type='date'
                      name='data_consolidazione'                   
                      size="large" 
                      placeholder = 'Data Consolidazione'                
                    />
                  </div>
                </div>
               
              

            <div className="col-12 mt-5 row justify-content-center">
                <button type="submit" className="btn btn-primary col-5">
                Salva
                </button>
            </div>
            

        </Form>

     </>
            
        </Formik>
        <>
        <br /> <br />
        <hr></hr>
        <h5>Domanda associata</h5>
        { !richiesta
            ?
            <div className="row">
              <div className="col-12 mt-5 row justify-content-center">
                  Nessuna domanda associata alla concessione
              </div>
              <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModal}>
                  Associa Domanda
                </button>
              </div>
              </div>
            : 
            <>
              <SicondirTable 
                headers={[
                  "Tipo ambito",
                  "Codice sicondir",
                  "Codice sistema precedente",
                  "Richiedente/ Intestatario",
                  "Codice fiscale",
                  "Partita IVA", 
                  "Stato",
                  ""
                  ]} 
                data={list} 
                dropdown={true}
              />         
            </>
        }
        <hr></hr>
        {props.macroambito == 'F' ?
        <>
        <h5>Occupazione associata</h5>
        { !occupazione
            ?
            <div className="row">
              <div className="col-12 mt-5 row justify-content-center">
                  Nessuna occupazione associata alla concessione
              </div>
              <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModalOccupazione}>
                  Associa Occupazione
                </button>
              </div>
              </div>
            : 
            <>
              <SicondirTable 
                headers={[
                  "Tipo ambito",
                  "Codice sicondir",
                  "Codice sistema precedente",
                  "Richiedente/ Intestatario",
                  "Codice fiscale",
                  "Partita IVA", 
                  "Stato",
                  ""
                  ]} 
                data={listOccupazioni} 
                dropdown={true}
              />         
            </>
        }
        </>
        :
        <>
        <h5>Denuncia pozzo associata</h5>
        { !denunciaPozzo
            ?
            <div className="row">
              <div className="col-12 mt-5 row justify-content-center">
                  Nessuna denuncia pozzo associata alla concessione
              </div>
              <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModalDenunciaPozzo}>
                  Associa Denuncia Pozzo
                </button>
              </div>
              </div>
            : 
            <>
              <SicondirTable 
                headers={[
                  "Tipo ambito",
                  "Codice sicondir",
                  "Codice sistema precedente",
                  "Richiedente/ Intestatario",
                  "Codice fiscale",
                  "Partita IVA", 
                  "Stato",
                  ""
                  ]} 
                data={listDenuncePozzo} 
                dropdown={true}
              />         
            </>
        }
        </>
        }
        </>
        </>
        :
        
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>
        }

        <Modal 
                dialogClassName={'modal-fullpage'}
                show={show} 
                onHide={handleClose}>
				  <Modal.Header closeButton>
            <Modal.Title >
              Ricerca Domanda
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RichiesteConcessioniRicerca contesto='concessioni' concessioneId={concessioneId}
              handleClose={handleClose}
              setAssociazione={setAssociazione}
              macroambito={props.concessione.macroarea_ambito}/>
          </Modal.Body>
        </Modal>

        <Modal 
                dialogClassName={'modal-fullpage'}
                show={showOccupazione} 
                onHide={handleCloseOccupazione}>
				  <Modal.Header closeButton>
            <Modal.Title >
              Ricerca Occupazione
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OccupazioniRicerca contesto='concessioni' concessioneId={concessioneId}
              handleClose={handleCloseOccupazione}
              setAssociazione={setAssociazioneOccupazione}
              macroambito={props.concessione.macroarea_ambito}/>
          </Modal.Body>
        </Modal>

        <Modal 
                dialogClassName={'modal-fullpage'}
                show={showDenunciaPozzo} 
                onHide={handleCloseDenunciaPozzo}>
				  <Modal.Header closeButton>
            <Modal.Title >
              Ricerca Denuncia Pozzo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DenuncePozzoRicerca contesto='concessioni' concessioneId={concessioneId}
              handleClose={handleCloseDenunciaPozzo}
              setAssociazione={setAssociazioneDenunciaPozzo}
              macroambito={props.concessione.macroarea_ambito}/>
          </Modal.Body>
        </Modal>
      </>
    )
}

export default ConcessioneForm;