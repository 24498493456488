import Modal from 'react-bootstrap/Modal'
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import BeniDemanialiRicerca from '../../../modules/anagrafica/components/BeniDemanialiRicerca';
import formatter from '../../utils/formatters/_';
import alertify from "alertifyjs";  
import BeniDemanialiFormEdit from '../BeniDemanialiFormEdit';
import OccupazioniBeniUsi from '../../../modules/concessioni/components/OccupazioniBeniUsi';
import SicondirBeniUsiTable from '../../components/SicondirBeniUsiTable';

const BeniDemanialiOccupazioniForm = (props) => {
    const params = useParams();

    const[show, setShow] = useState(false);
    const[occupazioneId, setOccupazioneId] = useState(params.id)
    const[beneDemaniale, setBeneDemaniale] = useState(null)
    const[associazione, setAssociazione] = useState(false)
    const[cancellaAssociazione, setCancellaAssociazione] = useState(false);
    const[associazioneId, setAssociazioneId] = useState(null)
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([])
    const [arrayBeniCollegati, setArrayBeniCollegati] = useState([])
    const[loadingUso, setLoadingUso] = useState(true);

    const[showUsi, setShowUsi] = useState(false);
    const[idAssociazioneOccupazione, setIdAssociazioneOccupazione] = useState(null);
    const[currentBeneDemaniale, setCurrentBeneDemaniale] = useState(null);

    // per la modifica.
    const [editShow, setEditShow] = useState(false);
    const[loadingEdit, setLoadingEdit] = useState(true);
    const[beneDemanialeEdit, setBeneDemanialeEdit] = useState(null);

    // per l'inserimento diretto di un bene demaniale senza passare dalla ricerca
    const [inserisciBene, setInserisciBene] = useState(false);

    let headers = [ "Comune", "Località", "Foglio", "Particella"/*, "Subalterno", "Sezione"*/, "Desc. catasto", "Note", "Stato", ""];
    
    const handleModal = (inserisci) => {
        setShow(true);
        setInserisciBene(inserisci)
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleModalUsi = () => {
        setShowUsi(true);
    }

    const handleUsi = async (idAssociazione, beneDemaniale) => {
        setIdAssociazioneOccupazione(idAssociazione)
        setCurrentBeneDemaniale(beneDemaniale);
        handleModalUsi();
        setLoadingUso(false);
    }

    const handleModalEdit = async (idBeneDemaniale) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL +
                'catasto_demaniale_derivazioni/id/' + idBeneDemaniale,
                {
                    method: 'GET',
                    headers: { accept: 'application/json' },
                    mode: 'cors',
                })

            const json = await response.json()
            let  beneDemaniale=" "
            if (json.risposta.catasto_demaniale_derivazioni) {
                beneDemaniale = json.risposta.catasto_demaniale_derivazioni   
            }
        
            setBeneDemanialeEdit(formatter.cleanObject(beneDemaniale))
            setLoadingEdit(false)
          
        }
        catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
        }

        setEditShow(true);
        
    }

    const handleCloseUsi = () => {
        setLoading(true)
        setAssociazione(true)
        setShowUsi(false);
    }

    const handleCloseEdit = () => {
        setLoading(true)
        setAssociazione(true)
        setEditShow(false);
    }

    const handleDelete = async (idAssociazione) => {
        setLoading(true);
        const payload = {
            nome_tabella: "occupazioni_catasto",
            id: idAssociazione,
            utente_modifica_id: localStorage.id
        }
        try{
            const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
                method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
                headers: { 'Content-Type': 'application/json',
                            'Accept': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:payload})
            })
            const data = await response.json();
            alertify.success("Associazione bene demaniale/occupazione eliminata con successo");
            
            setLoading(false)
            setCancellaAssociazione(true)
        } catch(error) {
            alertify.error("Errore durante la cancellazione dell'associazione bene demaniale/occupazione");
            setLoading(false)
        }
    }

    const buildActions = (idAssociazione, beneDemaniale) => {
        let result = {};
        result = { associa: {
                kind: "function", 
                function: handleDelete,
                parameters:
                {
                    idAssociazione: idAssociazione
                },
                label: "Elimina associazione" ,
                confirmLabel: "Procedere con l'eliminazione del bene demaniale associato?",
                buttonClass:"btn-danger",
                linkClass: "text-danger",
                },
                
                modificaBene: {
                    kind: "function", 
                    function: handleModalEdit,
                    parameters:
                    {
                        idBeneDemaniale: beneDemaniale.id
                    },
                    label: "Modifica bene demaniale",
                },
                
                gestisciUsi: {
                    kind: "function", 
                    function: handleUsi,
                    parameters:
                    {
                        idAssociazione: idAssociazione,
                        beneDemaniale: beneDemaniale
                    },
                    label: "Gestione usi",
                }
        }
        return result;        
    }

    useEffect(() => {
        const url = process.env.REACT_APP_API_URL + 'catasto_demaniale_derivazioni/occupazione_id/'+occupazioneId;
    
        const fetchData = async () => {
          try {
            const response = await fetch(url, {
              method: 'GET',
              headers: { accept: "application/json" },      
              mode: 'cors'
             });
            let beniCollegati = [];
            const json = await response.json();
            if(json.risposta.catasto_demaniale_derivazioni){
                let list = [];
                let i = 0;
                let response = formatter.cleanObject(json.risposta.catasto_demaniale_derivazioni)
                if(Array.isArray(json.risposta.catasto_demaniale_derivazioni)){
                    json.risposta.catasto_demaniale_derivazioni.map(element => {
                        element = formatter.cleanObject(element)
                        let beneDemaniale = element
                        let actions = buildActions(element.id_occupazione_catasto, beneDemaniale)
                        list[i] = {
                            id: element.id,
                            // pozzo: element.identificativo_pozzo || " - ",
                            comune_nome: (element.comune_nome || " - ") + "(" + (element.provincia_sigla || " - ") + ")" ,
                            localita: element.localita || " - ",
                            catasto_foglio: element.catasto_foglio || " - ",
                            catasto_particella: element.catasto_particella || " - ",
                          /*  catasto_sub: element.catasto_sub || " - ",
                            catasto_sezione: element.catasto_sezione || " - ",*/
                            descrizione_tipo_catasto: element.descrizione_tipo_catasto || " - ",
                            popover: element.note,
                            codice_stato: element.codice_stato || " - ",
                            id_occupazione_catasto: element.id_occupazione_catasto,
                            app_actions: actions
                        }
                        if(props.macroambito=='F'){ 
                            delete list[i]["pozzo"]
                        }
                        i++
                      });
                } 
                setList(list);
                let k = 0
                list.map(element => {
                    beniCollegati[k] = element.id
                    k++;
                })
                setArrayBeniCollegati(beniCollegati)
                setBeneDemaniale(response); // Se c'è un oggetto lo setto come bene demaniale.
            } else{
                setArrayBeniCollegati([])
                setBeneDemaniale(null)
            }

            setLoading(false);
            setAssociazione(false)
            setCancellaAssociazione(false)
          } catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
          }
        };
    
        fetchData();
    }, [associazione, cancellaAssociazione]);

    return(
        <>
            { !loading
                ?
                <>
                { !beneDemaniale
                    ?
                    <div className="row">
                        <div className="col-12 mt-5 row justify-content-center">
                            Nessun bene demaniale/derivazione è associato a questa occupazione.
                        </div>
                    </div>
                    :
                    <div>
                        <br />
                        <SicondirBeniUsiTable 
                            headers={headers}
                            data={list}
                            compact={true}
                            dropdown={true}
                            contesto="occupazione"
                        />
                    </div>
                }
                    <div className="col-12 mt-5 row justify-content-center">
                        <button  className="btn btn-primary col-5" onClick={() => handleModal(false)}>
                            Associa Bene Demaniale/Derivazione
                        </button>
                    </div>
                    <div className="col-12 mt-5 row justify-content-center">
                            <button  className="btn btn-primary col-5" onClick={() => handleModal(true)}>
                                Inserisci Bene Demaniale/Derivazione
                            </button>
                    </div>
                </>
                
                :
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <div className="progress-spinner progress-spinner-active">
                    <span className="sr-only">Caricamento...</span>
                  </div>
                </div>

            }

            <Modal 
                dialogClassName={'modal-fullpage'}
                show={show} 
                onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title >
                        Inserisci Bene Demaniale
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BeniDemanialiRicerca contesto='occupazione' occupazioneId={occupazioneId}
                     beniCollegati={arrayBeniCollegati}
                     handleClose={handleClose}
                     setAssociazione={setAssociazione}
                     macroambito={props.macroambito}
                     inserisciBene={inserisciBene}/>
                </Modal.Body>
            </Modal>     

            <Modal 
                dialogClassName={'modal-fullpage'}
                show={showUsi} 
                onHide={handleCloseUsi}>
				<Modal.Header closeButton>
					<Modal.Title >
                        Gestione usi per l'occupazione del bene demaniale 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OccupazioniBeniUsi 
                        beneDemaniale={currentBeneDemaniale}
                        occupazioneCatastoId= {idAssociazioneOccupazione}
                    />
                </Modal.Body>
            </Modal> 

            <Modal
                dialogClassName={'modal-fullpage'}
                show={editShow}
                onHide={handleCloseEdit}
                >
                <Modal.Header closeButton>
                    <Modal.Title >
                        Modifica Bene Demaniale
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loadingEdit ?

                            <BeniDemanialiFormEdit 
                                macroambito={props.macroambito} 
                                data={beneDemanialeEdit}
                                handleClose={handleCloseEdit} 
                                setBeneAggiornato={setAssociazione}
                                contesto="occupazione"
                            />
                        :
                        <div className="h-100 d-flex align-items-center justify-content-center">
                            <div className="progress-spinner progress-spinner-active">
                                <span className="sr-only">Caricamento...</span>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal> 
            
        </>
    )
} 

export default BeniDemanialiOccupazioniForm;