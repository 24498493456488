import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'

import { BiChevronDown } from 'react-icons/bi';
const Navbar = (props) => {
    const navigate = useNavigate();
    return (
        <>
        <Nav bg="light" defaultActiveKey="/home" >
        
            <Nav.Link 
                onClick={() => {localStorage.setItem('sezione', null); navigate(0)}}
                style={{color:'#fff'}} 
                as={Link} 
                to="/"
            >Ambito</Nav.Link>
            <Nav.Link style={{color:'#fff'}} as={Link} to="/anagrafica">Anagrafica</Nav.Link>
             {(props.sezione)?
             <>
            <Nav.Link 
                style={{color:'#fff'}} 
                as={Link} 
                to={props.sezione=='Fluviali'?
                    "/beni-demaniali/fluviali-lacuali"
                :
                    "/beni-demaniali/derivazioni"
                }
            >Beni Demaniali
            </Nav.Link>
            <Nav.Link 
                style={{color:'#fff'}} 
                as={Link} 
                to={props.sezione=='Fluviali'?
                    "/richieste-concessioni/fluviali-lacuali"
                :
                    "/richieste-concessioni/derivazioni"
                }
            >Domande
            </Nav.Link>
            <Nav.Link 
                style={{color:'#fff'}} 
                as={Link} 
                to={props.sezione=='Fluviali'?
                    "/concessioni/fluviali-lacuali"
                :
                    "/concessioni/derivazioni"
                }
            >Concessioni
            </Nav.Link>
            {/* ATTENZIONE: Il commento è stato messo PER NON PERDERE IL CODICE, utile se ci sarà un utente che deve avere il menù con Fluviali e derivazioni */}
            {/*
            <Dropdown>
                <Dropdown.Toggle className="nav-link" style={{color:'#fff'}} as={Link} 
                    to=
                    {(props.sezione=='Fluviali')? 
                        "/beni-demaniali/fluviali-lacuali"
                    : (props.sezione=='Derivazioni')?
                        "/beni-demaniali/derivazioni"
                    : "#"}>
                    Beni Demaniali <BiChevronDown />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item className="nav-link" as={Link} to="/beni-demaniali/fluviali-lacuali">
                        Fluviali/Lacuali
                    </Dropdown.Item>
                    <Dropdown.Item className="nav-link" as={Link} to="/beni-demaniali/derivazioni">
                        Derivazioni
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            
            <Dropdown>
                <Dropdown.Toggle className="nav-link" style={{color:'#fff'}} as={Link} to="#">
                Richieste Concessioni <BiChevronDown />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/richieste-concessioni/fluviali-lacuali">
                    Fluviali/Lacuali
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/richieste-concessioni/derivazioni">
                    Derivazioni
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
                <Dropdown.Toggle className="nav-link" style={{color:'#fff'}} as={Link} to="#">
                Concessioni <BiChevronDown />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/concessioni/fluviali-lacuali">
                    Fluviali/Lacuali
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/concessioni/derivazioni">
                    Concessioni Derivazioni
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            */}
            {
            /* <Nav.Link className="nav-link" style={{color:'#fff'}} as={Link} to="/">Occupazioni</Nav.Link>
            */}
            {props.sezione=='Fluviali' && <Nav.Link className="nav-link" style={{color:'#fff'}} as={Link} to="/occupazioni">Occupazioni</Nav.Link>}
            {props.sezione=='Derivazioni' && <Nav.Link className="nav-link" style={{color:'#fff'}} as={Link} to="/denunce-pozzo">Denunce Pozzo</Nav.Link>}
            </>  
        :''}
        <Nav.Link 
                style={{color:'#fff'}} 
                as={Link} 
                to={"/pagamenti"}
        >Pagamenti
        </Nav.Link>
        <Nav.Link className="nav-link" style={{color:'#fff'}} 
            onClick={() => {
                localStorage.clear(); // pulisco l'utente nella "sessione" react
                if(process.env.REACT_APP_LOGIN_IAM == 'false'){
                    // SE NON-IAM
                    navigate(0)
                } else{
                    // SE IAM: vado a pulire l'utente nella sessione PHP/REST e poi mi sloggo da IAM
                    window.location.href = process.env.REACT_APP_API_URL + 'logoutIAM'
                }
                }} 
            as={Link} to="/">Logout</Nav.Link>
            
        </Nav>
        </>
    )
}
export default Navbar;