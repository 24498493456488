import { Affix, Breadcrumb, Button, Card, Col, Drawer, Icon, Modal, Progress, Row, Tabs } from "antd";
import RicercaPersona from "../../../common/forms/RicercaPersona";

export default function Anagrafica() {
    return (
      <main>
            <RicercaPersona contesto="anagrafica"></RicercaPersona>
      </main>
    );
  }
