import React, { useEffect, useState, useRef } from "react";
import sprite from 'bootstrap-italia/dist/svg/sprite.svg';

function UploadButton(props) {
  
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [cleanFile, setCleanFile] = useState(false);
  const inputRef = useRef(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleDisplayFileDetails = () => {
    inputRef.current?.files &&
    setUploadedFileName(inputRef.current.files[0].name);
    props.setFileObject(inputRef.current.files[0])
  };

  const handleCleanFile = () =>{
    setCleanFile(true)
  }

  useEffect(() => {
    if(cleanFile) {
      setUploadedFileName(null)
      props.setFileObject(null)
      setCleanFile(false)
    };
  }, [cleanFile]);

  useEffect(() => {
    if(props.cleanFile) {
      setUploadedFileName(null)
      props.setFileObject(null)
    };
  }, [props]);

  return (
    <div className="m-3">
      <input
        ref={inputRef}
        onChange={handleDisplayFileDetails}
        className="upload"
        name="upload1" 
        id="upload1"
        type="file"
      />
      <button
        type="button"
        onClick={handleUpload}
        className="btn btn-primary"
        disabled={props.readOnly}
      >
      Carica documento
      </button>
      <br/>
      <br/>
      {uploadedFileName 
        ? 
        <ul className="upload-file-list">
          <li className="upload-file success">
              <svg className="icon icon-sm" aria-hidden="true"><use href={sprite+"#it-file"}></use></svg>
              <p>
                <span className="sr-only">File caricato:</span>
                {uploadedFileName}
              </p>
              <button onClick={handleCleanFile} type="button">
                <span className="visually-hidden">Elimina documento {uploadedFileName} </span>
                <svg className="icon" aria-hidden="true"><use href={sprite+"#it-close"}></use></svg>
              </button>
          </li>
        </ul>
         
        : 
        <></>}
    </div>
  );
}



export default UploadButton;