import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Formik, Form} from 'formik'
import SicondirSelect from "../../components/SicondirSelect";
import SicondirInput from "../../components/SicondirInput";
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import formatter from '../../utils/formatters/_';
import provinceLazio from "../../../_data/province_lazio.json"
import tipiAmbito from '../../../_data/tipi_ambito.json'
import _ from 'lodash'
import SicondirRadio from '../../components/SicondirRadio';

const OccupazioneInserimentoForm = (props) => {
    let navigate = useNavigate();
    const[nuovoCodice, setNuovoCodice] = useState(true)
    const [currentMacroambito, setCurrentMacroambito] = useState(props.macroambito);
    const [provinciaSelezionata, setProvinciaSelezionata] = useState(null)
    const [siglaProvincia, setSiglaProvincia] = useState("");
    const [lacuale, setLacuale] = useState(false);
    const [ambitoSelezionato, setAmbitoSelezionato] = useState(null)

    let fromUrl = "/occupazioni/"

    // Filtro gli ambiti prima per macroarea, poi tolgo ambito lacuale e piccola derivazione che per il momento non prevedono la OCCUPAZIONE.
    let tipiAmbitoFiltrati = tipiAmbito.filter(c => c.macroarea_ambito == "F")
    
    let regex = /^EQO-[0-9]+$/
    let errorMessage = "Inserire un codice di occupazione del tipo EQO-####"

    const checkCodiceDuplicato = async (valore) => {
      const url = process.env.REACT_APP_API_URL + 'occupazioni/codice_sistema_precedente/'+encodeURI(valore);
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: { accept: "application/json" },      
          mode: 'cors'
         });
        const json = await response.json()
        return !_.isEmpty(json.risposta.occupazioni)
      } catch (error) {
        alertify.error("Errore durante il recupero dei dati.");
        console.log("errore: ", error);
        return true
      }
    };

    const handleCampoCodice = (event) => {
      setNuovoCodice(event.target.value == 'true')
    }

    const handleProvincia = (event) => {
      let sigla = provinceLazio.filter(c=> c.value == event.value)[0].sigla
      setProvinciaSelezionata(event)
      setSiglaProvincia(sigla)
    }

    const handleTipoAmbito = (event) => {
      setAmbitoSelezionato(event)
      setLacuale(event.codice=='LAC')
      setNuovoCodice(true)
    }

    
    return(
        <>
           <Formik 
           id="occupazione"
           
           disabled={true}
           initialValues={{
            tipo_provincia_id: '',
            flag_nuovo_codice: "true", 
            codice_sistema_precedente: ''
            }}
        
            validationSchema={Yup.object({
              tipo_provincia_id: Yup.string().required("Il campo è obbligatorio"),
              flag_nuovo_codice: Yup.boolean(),
              codice_sistema_precedente: Yup.string().test("checkCodice", "Codice già importato", 
              async function(value, testContext){
                if(!nuovoCodice){
                  if(value == null || value.trim() ==''){
                    return testContext.createError({
                      message: `Il campo è obbligatorio`,
                      path: 'codice_sistema_precedente',
                    })
                  }
                  if (value != null && value.trim()!='') {
                    const idDuplicato = await checkCodiceDuplicato(value.trim());
                    return !idDuplicato;
                  }
                }
                return true;
              }
              ).matches(regex, errorMessage)
            })}  

            onSubmit = { async (values, { setSubmitting, setFieldValue, resetForm }) => {

            const occupazione = {
              macroarea_ambito:  formatter.escapeSql(currentMacroambito),
              tipo_provincia_id: values.tipo_provincia_id,
              utente_modifica_id: localStorage.id,
              codice_sistema_precedente: !nuovoCodice && values.codice_sistema_precedente.trim() != '' ? values.codice_sistema_precedente : null
            }  
            
            try{
              const response = await fetch(process.env.REACT_APP_API_URL + 'occupazioni', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:occupazione})
               })
               const data = await response.json();

               navigate('/occupazioni/inserisci/' + data.risposta.elementi_aggiunti.id, 
                        {state: {
                          fromUrl: {fromUrl}
                          }
                        }) ;
               alertify.success("Nuova occupazione creata con successo.");
            } catch(error) {
              alertify.error("Errore durante l'inserimento.");
              console.log("errore: ", error);
            }
            
          }} 
          
          >
            {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
            }) => (
            <Form>
                <fieldset disabled={props.importoUtilizzato > 0}>
                <br /><br />
                <div className="row">
                    <div className="col-12">
                    <SicondirSelect
                        label='Provincia'
                        id='tipo_provincia_id'                
                        name='tipo_provincia_id'              
                        options={provinceLazio} 
                        isClearable={true}
                        placeholder = 'Provincia'
                        onChange = {handleProvincia}
                        value={provinciaSelezionata}
                        maxLength = '2'
                      />
                    </div>
                </div>
                <br /><br />
                { !lacuale &&
                  <>
                  <div className="row">
                      <div className="col-12">
                        <SicondirRadio 
                          label=""
                          name="flag_nuovo_codice"
                          id="flag_nuovo_codice"
                          onChange={handleCampoCodice}
                          options={[
                            {
                              value:true,
                              checked: nuovoCodice, 
                              label: <>Inserimento occupazione con <b>codice NON esistente</b> su sistemi precedenti<b>(NUOVA OCCUPAZIONE)</b></>,
                            },
                            {
                              value:false,
                              checked: !nuovoCodice,
                              label: <>Inserimento occupazione con <b>codice esistente</b> su sistemi precedenti<b>(OCCUPAZIONE ESISTENTE)</b></>,
                            }
                          ]} 
                        />
                        </div>
                  </div>
                  
                  <div className="row">
                      <div className="col-12">
                        <SicondirInput 
                          label="Codice Occupazione Esistente"
                          type='text'
                          name="codice_sistema_precedente"
                          id="codice_sistema_precedente"
                          placeholder = 'Inserisci Codice Occupazione'
                          maxLength = '50'
                          disabled={nuovoCodice}
                        />
                        </div>
                  </div>
                  </>
                }
                <div className="col-12 mt-5 row justify-content-center">
                    <button type="submit" className="btn btn-primary col-5" disabled={!(isValid && dirty && !isSubmitting)}>
                        Inserisci nuova occupazione
                    </button>
                </div>
                <br />
                </fieldset>
            </Form>
            ) }
           </Formik>
        </>
    )
} 

export default OccupazioneInserimentoForm;