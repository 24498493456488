import React, { Component } from 'react';
import PropTypes from 'prop-types';
import alertify from "alertifyjs";
import { useNavigate } from "react-router-dom";

class DeleteButton extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      className: props.className ? props.className : ""
    };
    this.onClick = this.onClick.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onClick(e){
    let confirmLabel = this.props.confirmLabel ? this.props.confirmLabel  : "Procedere con l'eliminazione?";
    e.preventDefault();
    alertify.confirm(confirmLabel, this.onConfirm)
    .set('labels', {
      cancel: "ANNULLA",
      ok: "OK",
    });
  }

  async onConfirm(){

    const {table, resourceId, redirectPath, confirmLabel, successMessage, errorMessage} = this.props;

    this.setState({deleting: true});

    const payload = {
      nome_tabella: table,
      id: resourceId,
      utente_modifica_id: localStorage.id
    }

    try{
      const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
        method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
        headers: { 'Content-Type': 'application/json',
                  'Accept': 'application/json' }, 
        mode: 'cors', 
        body: JSON.stringify({Entry:payload})
       })
       const data = await response.json();
       this.setState({deleting: false});
       alertify.success(successMessage);

       this.props.navigate(redirectPath)
    } catch(error) {
      alertify.error(errorMessage);
      this.setState({deleting: false});
    }
  }

  render() {
    return (
      <button
        className={"btn btn-danger pl-4 pr-4 " + this.state.className}
        onClick={this.onClick}
        disabled={this.state.deleting}
      >
        {this.state.deleting ? <i style={{ display: 'inline-block', marginRight:'4px' }} className="fa fa-circle-o-notch fa-spin" /> : null}        
        {this.props.buttonLabel}
      </button>
    );
  }  
}

DeleteButton.propTypes = {
  table: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string
}

DeleteButton.defaultProps = {
  successMessage: 'Risorsa cancellata con successo',
  errorMessage: 'Si è verificato un errore',
  buttonLabel: 'Elimina risorsa',
  confirmLabel: 'Eliminare la risorsa?'
}

// Wrap che serve per la navigation altrimenti useNavigate non si può usare nei component, ma solo nelle function.
export default function(props) {
  const navigate = useNavigate();
  return <DeleteButton {...props} navigate={navigate} />;
}


