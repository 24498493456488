import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { Formik, Field, Form, useFormik } from "formik";
import * as Yup from "yup";
import alertify from "alertifyjs";
import SicondirSelect from "../components/SicondirSelect";
import tipiAmbito from "../../_data/tipi_ambito.json";
import tipiCatastoDemanialeDerivazioni from "../../_data/tipi_catasto_demaniale_derivazioni.json";
import provinceLazio from "../../_data/province_lazio.json";
import comuni from "../../_data/comuni.json";
import statusAreaDemaniale from "../../_data/status_area_demaniale.json";
import SicondirInput from "../components/SicondirInput";
import SicondirTextarea from "../components/SicondirTextarea";
import SicondirTable from '../../common/components/SicondirTable';
import SicondirNumber from "../components/SicondirNumber";
import tipiModalitaScarico from "../../_data/tipi_modalita_scarico.json";
import formatter from "../utils/formatters/_"
import DeleteButton from "../components/DeleteButton";
// RIVEDERE QUESTO FORM!!!
const BeniDemanialiFormEdit = (props) => {
  
  // const[persona, setPersona] = useState(params)
  const[ruoli, setRuoli] = useState([])
  
  const [concessioni,setConcessioni] = useState([])
  const [listDenuncePozzo,setListDenuncePozzo] = useState([])
  const [listRichiesteConcessioni,setListRichiesteConcessioni] = useState([])
  const [listOccupazioni,setListOccupazioni]  = useState([])
  let navigate = useNavigate();
  const params = useParams();
  const beneDemanialeId = params.id;
  const [loading, setLoading] = useState(true);
  const [oldValue, setOldValue] = useState(
    {...props.data,
    produzione_nominale: formatter.formatNumber(props.data.produzione_nominale, true)});

  const [tipiCatastoDisabled, setTipiCatastoDisabled] = useState(true);
  const [tipiCatastoFiltrati, setTipiCatastoFiltrati] = useState([]);
  const [tipoCatastoSelezionato, setTipoCatastoSelezionato] = useState(null);

  const [provinciaSelezionata, setProvinciaSelezionata] = useState(null);

  const [comuniDisabled, setComuniDisabled] = useState(true);
  const [comuniFiltrati, setComuniFiltrati] = useState([]);
  const [comuneSelezionato, setComuneSelezionato] = useState(null);

  const [capDisabled, setCapDisabled] = useState(true);
  const [capFiltrati, setCapFiltrati] = useState([]);
  const [capSelezionato, setCapSelezionato] = useState(null);
  const [tipiAmbitoFiltrati, setTipiAmbitoFiltrati] = useState(tipiAmbito);

  const [valueUploaded, setValueUploaded] = useState(false);

  const [currentAmbito, setCurrentAmbito] = useState(null)

  const fromUrl = props.macroambito == 'D' ? "/beni-demaniali/derivazioni" : "/beni-demaniali/fluviali-lacuali"
  const fetchData = async () => {
    try {
      let urlApi = `persone/id/${params.id}`
      const response = await fetch(process.env.REACT_APP_API_URL + urlApi, 
      {
        method: 'GET',
        headers: { accept:'application/json' }, 
        mode: 'cors',  
      })
      const json = await response.json()
      // setPersona(formatter.cleanObject(json.risposta.persone))
     // RUOLI
      const urlApiRuoli = 'ruoli/persona_id/' + params.id
      const responseRuoli = await fetch(process.env.REACT_APP_API_URL + urlApiRuoli, 
      {
        method: 'GET',
        headers: { accept:'application/json' }, 
        mode: 'cors',  
      })
      const jsonRuoli = await responseRuoli.json()
      if(jsonRuoli.risposta) {
        let array = []
        if(Array.isArray(jsonRuoli.risposta.ruoli)) {
          jsonRuoli.risposta.ruoli.map(r => {
            array.push(formatter.cleanObject(r))
          })
          setRuoli(array)
        }
      }
    } catch (error) {
      alertify.error("Errore durante il recupero dei dati.");
      console.log("errore: ", error);
    }
    
  }
  const redirect = (id,macroambito) => {
    let macroarea=''
    if(macroambito=='F'){macroarea= 'fluviali-lacuali'}
    else {macroarea = 'derivazioni'}
    let url2= `/concessioni/${macroarea}/inserisci/${id}`
    navigate(url2)
  }
  const redirectDenuncePozzo = (id) => {
    let url3= `/denunce-pozzo/inserisci/${id}`
    navigate(url3)
  }
  const redirectRichiesteConcessioni = (id,macroambito) => {
    let macroarea=''
    if(macroambito=='F'){macroarea = 'fluviali-lacuali'}
    else {macroarea = 'derivazioni'}
    let url4= `/richieste-concessioni/${macroarea}/inserisci/${id}`
    navigate(url4)
  }
 const redirectOccupazioni = (id) => {
  let url5= `/occupazioni/inserisci/${id}`
  navigate(url5)
  }


  const fetchDataDenuncePozzo = async() => {
    try {
      const urlApiDenuncePozzo = "denunce_pozzo/catasto_demaniale_derivazione_id/" + params.id
      const responseDenuncePozzo = await fetch(
        process.env.REACT_APP_API_URL + urlApiDenuncePozzo, {
          method: 'GET',
          headers: { accept:'application/json' }, 
          mode: 'cors',  
        }
      )
      const jsonDenuncePozzo = await responseDenuncePozzo.json()
      
      let listDenuncePozzo = [];
      let i = 1;
    
      if(jsonDenuncePozzo.risposta.denunce_pozzo){
      
        if(Array.isArray(jsonDenuncePozzo.risposta.denunce_pozzo)){
         
          jsonDenuncePozzo.risposta.denunce_pozzo.map(element => {
            element = formatter.cleanObject(element)
            
            listDenuncePozzo[i] = {
              id: element.id,
              codice_sicondir: element.codice_sicondir || " - ",
              codice_sistema_precedente: element.codice_sistema_precedente || " - ",
              tipo_ambito: element.tipo_ambito || " - ",
              provincia: element.provincia || " - ",
              stato: element.stato || " - ",  
              app_actions:{
                redirectDenuncePozzo: {
                  kind: "function", 
                  function: redirectDenuncePozzo,
                  parameters:
                  { id: element.id },
                  label: "Dettaglio Denuncia Pozzo",
                },  
              }
            }
            i++
          });
        }
    }
   
    setListDenuncePozzo(listDenuncePozzo); 
    //setLoading(false);
  } 
  catch (error) {
    alertify.error("Errore durante il recupero dei dati.");
    console.log("errore: ", error);
  }

  } 
  const fetchDataRichiesteConcessioni = async() => {
  try {
    const urlApiRichiesteConcessioni = "richieste_concessione/catasto_demaniale_derivazione_id/" + params.id
    const responseRichiesteConcessioni = await fetch(
      process.env.REACT_APP_API_URL + urlApiRichiesteConcessioni, {
        method: 'GET',
        headers: { accept:'application/json' }, 
        mode: 'cors',  
      }
    )
    const jsonRichiesteConcessioni = await responseRichiesteConcessioni.json()
    
    let listRichiesteConcessioni = [];
    let i = 1;
  
    if(jsonRichiesteConcessioni.risposta.richieste_concessione){
    
      if(Array.isArray(jsonRichiesteConcessioni.risposta.richieste_concessione)){
       
        jsonRichiesteConcessioni.risposta.richieste_concessione.map(element => {
          element = formatter.cleanObject(element)
          
          listRichiesteConcessioni[i] = {
            id: element.id,
            codice_sicondir: element.codice_sicondir || " - ",
            codice_sistema_precedente: element.codice_sistema_precedente || " - ",
            tipo_ambito: element.tipo_ambito || " - ",
            provincia: element.provincia || " - ",
            stato: element.stato || " - ",    
            app_actions:{
              redirectRichiesteConcessioni: {
                kind: "function", 
                    function: redirectRichiesteConcessioni,
                    parameters:
                    {
                      id: element.id,
                    macroambito: element.macroarea_ambito,
                    },
                    label: "Dettaglio Domanda",
              },  
            }
          }
          i++
        });
      }
    }
 
  setListRichiesteConcessioni(listRichiesteConcessioni); 
  //setLoading(false);
} 
catch (error) {
  alertify.error("Errore durante il recupero dei dati.");
  console.log("errore: ", error);
}


  } 
  const fetchDataOccupazioni = async() => {
  try {
    const urlApiOccupazioni = "occupazioni/catasto_demaniale_derivazione_id/" + params.id
    const responseOccupazioni = await fetch(
      process.env.REACT_APP_API_URL + urlApiOccupazioni, {
        method: 'GET',
        headers: { accept:'application/json' }, 
        mode: 'cors',  
      }
    )
    const jsonOccupazioni = await responseOccupazioni.json()
    
    let listOccupazioni = [];
    let i = 1;
  
    if(jsonOccupazioni.risposta.occupazioni){
    
      if(Array.isArray(jsonOccupazioni.risposta.occupazioni)){
       
        jsonOccupazioni.risposta.occupazioni.map(element => {
          element = formatter.cleanObject(element)
          
          listOccupazioni[i] = {
            id: element.id,
            codice_sicondir: element.codice_sicondir || " - ",
            codice_sistema_precedente: element.codice_sistema_precedente || " - ",
            tipo_ambito: element.tipo_ambito || " - ",
            provincia: element.provincia || " - ",
            stato: element.stato || " - ",  
                  
            app_actions:{
              redirectOccupazioni: {
                kind: "function", 
                    function: redirectOccupazioni,
                    parameters:
                    {
                      id: element.id,
                    },
                    label: "Dettaglio Occupazione",
                    
                    

              },  
            }
          }
          i++
        });
      } 
    }
 
  setListOccupazioni(listOccupazioni); 
  //setLoading(false);
} 
catch (error) {
  alertify.error("Errore durante il recupero dei dati.");
  console.log("errore: ", error);
}


  } 
  const fetchDataConcessioni = async() => {
  try {
      const urlApiConcessioni = "concessioni/catasto_demaniale_derivazione_id/" + params.id
      const responseConcessioni = await fetch(
        process.env.REACT_APP_API_URL + urlApiConcessioni, {
          method: 'GET',
          headers: { accept:'application/json' }, 
          mode: 'cors',  
        }
      )
      const jsonConcessioni = await responseConcessioni.json()
      
      let list = [];
      let i = 1;
      
      if(jsonConcessioni.risposta.concessioni){
      
        if(Array.isArray(jsonConcessioni.risposta.concessioni)){
         
          jsonConcessioni.risposta.concessioni.map(element => {
            element = formatter.cleanObject(element)
            
            list[i] = {
              id: element.id,
              codice_sicondir: element.codice_sicondir || " - ",
              codice_sistema_precedente: element.codice_sistema_precedente || " - ",
              tipo_ambito: element.tipo_ambito || " - ",
              provincia: element.provincia || " - ",
              stato: element.stato || " - ",            
              app_actions:{
                redirect: {
                  kind: "function", 
                      function: redirect,
                      parameters:
                      {
                        id: element.id,
                        macroambito: element.macroarea_ambito,
                      },
                      label: "Dettaglio Concessione"
                    

                },  
              }
            }
            i++
          });
        }
    }
    setConcessioni(list); 
    //setLoading(false);
  } 
  catch (error) {
    alertify.error("Errore durante il recupero dei dati.");
    console.log("errore: ", error);
  }
 
  
  }
 
useEffect(() => {
  setLoading(true)
  fetchData()
  fetchDataConcessioni()
  fetchDataRichiesteConcessioni()
  fetchDataDenuncePozzo()
  fetchDataOccupazioni()
  setLoading(false)
},[]);

  useEffect(() => {
    const fetchData = () => {
      // Per la tendina degli ambiti:
      if (props.macroambito) {
        const res = tipiAmbito
          .filter((c) => c.macroarea_ambito == props.macroambito)
          .map((c) => {
            return {
              value: c.value,
              label: c.label,
            };
          });
        setTipiAmbitoFiltrati(res);
      }
    };
    fetchData();
  }, []);

  /*
  useEffect(() => {
    setOldValue({...props.data,
      produzione_nominale: formatter.formatNumber(props.data.produzione_nominale)});
  }, []);
  */ 
  // useEffect che serve per l'init: appena oldValue è caricato, inizializzo le tendine...
  useEffect(()=>{
    if(oldValue && !valueUploaded){
      setValueUploaded(true)
      if(oldValue.tipo_ambito_id){ // Se ho un tipo ambito...
        setCurrentAmbito(oldValue.tipo_ambito_id)
        enableTipiCatasto({value: oldValue.tipo_ambito_id}) // ... sblocco i tipi catasto 
        
        if(oldValue.tipo_catasto_demaniale_derivazione_id){ // se ho un tipo catasto...
          const catastoSelezionato = tipiCatastoDemanialeDerivazioni.filter( // ...lo popolo
            (c) => c.value == oldValue.tipo_catasto_demaniale_derivazione_id
          ).map((c) => {
            return {
              value: c.value,
              label: c.label,
            };
          });
          setTipoCatastoSelezionato(catastoSelezionato[0])
        }
      }
      
      // La provincia da sola non viene salvata, quindi non la posso gestire. Devo sbloccare la tendina dei comuni solo se ho un comune tra i dati.
      if(oldValue.tipo_comune_id){
        const comuneSelezionatoRecuperato = comuni.filter(c => c.id == oldValue.tipo_comune_id).map(c => {
          return {
            value: c.id,
            label: c.nome,
            provincia: c.provincia.nome
          }
        })
        const provinciaSelezionataRecuperata = provinceLazio.filter(c => c.label == comuneSelezionatoRecuperato[0].provincia).map(c => {
          return {
            value: c.value,
            label: c.label,
          }
        })
        setProvinciaSelezionata(provinciaSelezionataRecuperata[0])
        enableComuni(provinciaSelezionataRecuperata[0]) // abilito i comuni
        setComuneSelezionato(comuneSelezionatoRecuperato[0]) // poi setto il comune(nella enable lo metterebbe nullo)
        enableCap(comuneSelezionatoRecuperato[0])

        if(oldValue.codice_cap){
          setCapSelezionato({
            value: oldValue.codice_cap,
            label: oldValue.codice_cap,
          })
        }
      }
    }
    setLoading(false)
  }, [oldValue])

  const enableTipiCatasto = (event) => {
      const ambito = tipiAmbito.filter((c) => c.value == event.value);
      const res = tipiCatastoDemanialeDerivazioni.filter(
        (c) => c.macroarea_ambito == ambito[0].macroarea_ambito
      );
      setTipiCatastoDisabled(false);
      setTipiCatastoFiltrati(res);
      setTipoCatastoSelezionato(null);
  };

  const enableComuni = (event) => {
    if(event){
        const res = comuni
        .filter((c) => c.provincia.nome == event.label)
        .map((c) => {
          return {
            value: c.id,
            label: c.nome,
          };
        });
      setProvinciaSelezionata(event)
      setComuniDisabled(false);
      setComuniFiltrati(res);
      setComuneSelezionato(null);
      setCapSelezionato(null);
    } else{ // se ho cancellato la provincia dalla combobox...
      setProvinciaSelezionata(null)
      setComuniDisabled(true);
      setComuneSelezionato(null);
      setCapDisabled(true);
      setCapSelezionato(null);
    }
    
  };

  const enableCap = (event) => {
    if(event){
      const res = comuni.filter((c) => c.id == event.value);
      const capArray = res[0].cap;
      let caps = [];
      capArray.map((e) => {
        caps.push({
          value: e,
          label: e,
        });
      });
  
      setCapDisabled(false);
      setCapFiltrati(caps);
      setCapSelezionato(null);
      setComuneSelezionato(event);
    } else { // se ho cancellato il comune dalla combobox...
      setComuneSelezionato(null);
      setCapDisabled(true);
      setCapSelezionato(null);
    }
    
  };

  return (
    <>
      {!loading ? (
        <>
        <Formik
          id="beneDemaniale"
          className="p-4"
          enableReinitialize={true}
          initialValues={oldValue}
          validationSchema={Yup.object({
            tipo_ambito_id: Yup.string().required("Il campo è obbligatorio"),
            produzione_nominale: Yup.string().matches(/^((\d+(\,\d{0,2})?))$/, 'Inserire un valore numerico, i decimali(massimo 2) devono essere separati da ","')
          })}
          /* Al momento non ci sono campi obbligatori
              validationSchema={Yup.object({
                tipo_ambito_id: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                tipo_catasto_demaniale_derivazione_id: Yup.string().required("Il campo è obbligatorio"),
                provincia: Yup.string().required("Il campo è obbligatorio"),
                tipo_comune_id: Yup.string().required("Il campo è obbligatorio"),
                indirizzo: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                civico: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                cap: Yup.string().required("Il campo è obbligatorio"),
                codice_area: Yup.string().max(100, "Consentiti al massimo 100 caratteri").required("Il campo è obbligatorio"),
                catasto_sezione: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                catasto_foglio: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                catasto_particella: Yup.string().max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                catasto_sub: Yup.string().required("Il campo è obbligatorio"),
                superficie_totale: Yup.string().min(2, "Il campo è obbligatorio").max(255, "Consentiti al massimo 255 caratteri").required("Il campo è obbligatorio"),
                identificativo_pozzo: Yup.string().max(100, "Consentiti al massimo 100 caratteri").required("Il campo è obbligatorio"),
                unita_misura_nominale: Yup.string().max(100, "Consentiti al massimo 100 caratteri").required("Il campo è obbligatorio"),
                produzione_nominale: Yup.number().required("Il campo è obbligatorio e deve essere un numero"),
                tipo_stato_catasto_demaniale_derivazione_id: Yup.string().required("Il campo è obbligatorio"),
                //opere_richieste: Yup.string().required("Il campo è obbligatorio"),
                //opere_esistenti: Yup.string().required("Il campo è obbligatorio"),
                //stato_patrimoniale: Yup.string().required("Il campo è obbligatorio")
                
              })}
              */
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            delete values["provincia"];
            delete values["flag_deleted"];
            let idBeneDemaniale = values["id"]
            delete values["id"];
            delete values["created_at"];
            delete values["updated_at"];
            delete values["geolocalizzazione"];
            delete values["geolocalizzazione"];
            delete values["stato_patrimoniale"];
            delete values["macroambito_demaniale"];
            delete values["utente_modifica_id"];
            delete values["nome"];
            
            let campi = "";
            let deleteUsi = false;
            values["tipo_comune_id"] = comuneSelezionato ? comuneSelezionato.value : null;
            values["codice_cap"] = capSelezionato ? capSelezionato.value : null;
            Object.entries(values).map(([key, value], i) => {
              if (value != "" && value != null) {
                if(key == 'produzione_nominale' && value != ''){
                  value = parseFloat(value.replace(",", "."));
                }
                if(key == 'tipo_ambito_id' && value != currentAmbito){ // Ho modificato l'ambito => tutti gli usi devono essere cancellati!
                  deleteUsi = true;
                }

                campi += key + "='" + formatter.escapeSql(value) + "', ";
              } else {
                campi += key + "=NULL , ";
              }
              if(key == 'tipo_ambito_id' && value == null) { campi += key + "=null, "; }
              if(key == 'tipo_catasto_demaniale_derivazione_id' && value == null) { campi += key + "=null, "; }
              if(key == 'tipo_comune_id' && value == null) { campi += key + "=null, "; }
              if(key == 'codice_cap' && value == null) { campi += key + "=null, "; }
              if(key == 'tipo_modalita_scarico_id' && value == null) { campi += key + "=null, "; }
              if(key == 'tipo_stato_catasto_demaniale_derivazione_id' && value == null) { campi += key + "=null, "; }
            });
            
            if (campi == "") {
              alertify.warning("Inserire almeno un campo");
              return;
            }

            try{
              campi += "utente_modifica_id=" + localStorage.id;
              const payload = {
                nome_tabella: "catasto_demaniale_derivazioni",
                lista_campi_valore: campi,
                lista_clausole_where: "id="+ idBeneDemaniale + " and flag_deleted=0"
              }
              const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:payload})
              })
              const data_response = await response.json();
              if(data_response.hasOwnProperty('Fault')){
                if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                  console.log("ErrorCode: " + data_response.Fault.faultcode)
                  throw new Error(data_response.Fault.faultstring)
                } else{
                  throw new Error("errore durante il salvataggio.")
                }
              } else{
                if(deleteUsi){
                  // Cancellazione concessione/usi
                  const payloadUsiConcessione = {
                    nome_tabella: "concessioni_catasto_uso",
                    lista_campi_valore: "flag_deleted=1, utente_modifica_id=" + localStorage.id,
                    lista_clausole_where: "id in (select cid from (select ccu.id as cid from concessioni_catasto_uso ccu join concessioni_catasto cc on ccu.concessione_catasto_id = cc.id where cc.catasto_demaniale_derivazione_id = "+idBeneDemaniale+") as T) and flag_deleted=0"
                  }
                  const responseUsiConcessione = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:payloadUsiConcessione})
                  })
                  const dataResponseUsiConcessione = await responseUsiConcessione.json();
                  if(dataResponseUsiConcessione.hasOwnProperty('Fault')){
                    if(dataResponseUsiConcessione.Fault.hasOwnProperty("faultcode") && dataResponseUsiConcessione.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + dataResponseUsiConcessione.Fault.faultcode)
                      throw new Error(dataResponseUsiConcessione.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }

                  // Cancellazione richiesta/usi
                  const payloadUsiRichiesta = {
                    nome_tabella: "richieste_concessione_catasto_uso",
                    lista_campi_valore: "flag_deleted=1, utente_modifica_id=" + localStorage.id,
                    lista_clausole_where: "id in (select cid from (select rcu.id as cid from richieste_concessione_catasto_uso rcu join richieste_concessione_catasto rc on rcu.richiesta_concessione_catasto_id = rc.id where rc.catasto_demaniale_derivazione_id = "+idBeneDemaniale+")as T) and flag_deleted=0"
                  }
                  const responseUsiRichiesta = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:payloadUsiRichiesta})
                  })
                  const dataResponseUsiRichiesta = await responseUsiRichiesta.json();
                  if(dataResponseUsiRichiesta.hasOwnProperty('Fault')){
                    if(dataResponseUsiRichiesta.Fault.hasOwnProperty("faultcode") && dataResponseUsiRichiesta.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + dataResponseUsiRichiesta.Fault.faultcode)
                      throw new Error(dataResponseUsiRichiesta.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }

                  // Cancellazione occupazione/usi
                  const payloadUsiOccupazione = {
                    nome_tabella: "occupazioni_catasto_uso",
                    lista_campi_valore: "flag_deleted=1, utente_modifica_id=" + localStorage.id,
                    lista_clausole_where: "id in (select cid from (select ocu.id as cid from occupazioni_catasto_uso ocu join occupazioni_catasto oc on ocu.occupazione_catasto_id = oc.id where oc.catasto_demaniale_derivazione_id = "+idBeneDemaniale+")as T) and flag_deleted=0"
                  }
                  const responseUsiOccupazione = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:payloadUsiOccupazione})
                  })
                  const dataResponseUsiOccupazione = await responseUsiOccupazione.json();
                  if(dataResponseUsiOccupazione.hasOwnProperty('Fault')){
                    if(dataResponseUsiOccupazione.Fault.hasOwnProperty("faultcode") && dataResponseUsiOccupazione.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + dataResponseUsiOccupazione.Fault.faultcode)
                      throw new Error(dataResponseUsiOccupazione.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }

                  // Cancellazione denuncia pozzo/usi
                  const payloadUsiDenunciaPozzo = {
                    nome_tabella: "denunce_pozzo_catasto_uso",
                    lista_campi_valore: "flag_deleted=1, utente_modifica_id=" + localStorage.id,
                    lista_clausole_where: "id in (select cid from(select dcu.id as cid from denunce_pozzo_catasto_uso dcu join denunce_pozzo_catasto dc on dcu.denuncia_pozzo_catasto_id = dc.id where dc.catasto_demaniale_derivazione_id = "+idBeneDemaniale+")as T) and flag_deleted=0"
                  }
                  const responseUsiDenunciaPozzo = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, 
                    mode: 'cors', 
                    body: JSON.stringify({Entry:payloadUsiDenunciaPozzo})
                  })
                  const dataResponseUsiDenunciaPozzo = await responseUsiDenunciaPozzo.json();
                  if(dataResponseUsiDenunciaPozzo.hasOwnProperty('Fault')){
                    if(dataResponseUsiDenunciaPozzo.Fault.hasOwnProperty("faultcode") && dataResponseUsiDenunciaPozzo.Fault.hasOwnProperty("faultstring")){
                      console.log("ErrorCode: " + dataResponseUsiDenunciaPozzo.Fault.faultcode)
                      throw new Error(dataResponseUsiDenunciaPozzo.Fault.faultstring)
                    } else{
                      throw new Error("errore durante il salvataggio.")
                    }
                  }

                }
              }
              // navigate('/beni-demaniali');
              if(props.contesto == 'concessione' || props.contesto == 'richiesta' || props.contesto == 'occupazione'){
                props.setBeneAggiornato(true)
                props.handleClose()
              }
              resetForm({ values });
              alertify.success("Dati bene demaniale/derivazione salvati con successo.");}
              catch(error) { 
                alertify.error("Errore durante l'inserimento dei dati: " + error);
                console.log("errore: ", error);
              }
                                
          }}
        >
              {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
              }) => (
              <Form>
                <SicondirSelect
                  label="Tipo Ambito"
                  id="tipo_ambito_id"
                  name="tipo_ambito_id"
                  options={tipiAmbitoFiltrati}
                  placeholder="Seleziona Tipo Ambito"
                  onChange={enableTipiCatasto}
                  mandatory={true}
                  defaultValue={tipiAmbitoFiltrati.filter(
                    (c) => c.value == oldValue.tipo_ambito_id
                  )}
                />{" "}
                <br />
                <SicondirSelect
                  label="Tipo Catasto Demaniale/Derivazioni"
                  id="tipo_catasto_demaniale_derivazione_id"
                  name="tipo_catasto_demaniale_derivazione_id"
                  options={tipiCatastoFiltrati}
                  isDisabled={tipiCatastoDisabled}
                  value={tipoCatastoSelezionato}
                  isClearable={true}
                  placeholder="Seleziona Tipo Catasto Demaniale/Derivazioni"
                  onChange={(e) => {
                    if(e){
                      setTipoCatastoSelezionato({
                        label: e.label,
                        value: e.value,
                      });
                    } else {setTipoCatastoSelezionato(null)}
                    
                  }}
                />
                <br />
                <SicondirSelect
                  label="Provincia"
                  id="provincia"
                  name="provincia"
                  options={provinceLazio}
                  isClearable={true}
                  placeholder="Seleziona Provincia"
                  value={provinciaSelezionata}
                  onChange={enableComuni}
                />
                <br />
                <SicondirSelect
                  label="Comune"
                  id="tipo_comune_id"
                  options={comuniFiltrati}
                  name="tipo_comune_id"
                  isDisabled={comuniDisabled}
                  value={comuneSelezionato}
                  isClearable={true}
                  placeholder="Seleziona Comune"
                  onChange={(e) => {
                    enableCap(e);
                  }}
                />
                <br />
                <SicondirSelect
                  label="C.A.P."
                  id="codice_cap"
                  options={capFiltrati}
                  name="codice_cap"
                  isDisabled={capDisabled}
                  isClearable={true}
                  value={capSelezionato}
                  placeholder="Seleziona C.A.P."
                  onChange={(e) => {
                    if(e){
                      setCapSelezionato({
                        label: e.label,
                        value: e.value,
                      });
                    } else {setCapSelezionato(null)}
                  }}
                />
                <SicondirInput
                  label="Indirizzo"
                  id="indirizzo"
                  type="text"
                  name="indirizzo"
                  className={`form-control mb-2`}
                  size="large"
                  placeholder="Inserisci Indirizzo"
                  maxLength="255"
                />
                <SicondirInput
                  label="Numero Civico"
                  id="civico"
                  type="text"
                  name="civico"
                  className={`form-control mb-2`}
                  size="large"
                  placeholder="Inserisci Numero Civico"
                  maxLength="255"
                />
                <SicondirInput
                  label="Località"
                  id="localita"
                  type="text"
                  name="localita"
                  className={`form-control mb-2`}
                  size="large"
                  placeholder="Inserisci Località"
                  maxLength="255"
                />
                <SicondirInput
                  label="Codice Area"
                  id="codice_area"
                  type="text"
                  name="codice_area"
                  className={`form-control mb-2`}
                  size="large"
                  placeholder="Inserisci Codice Area"
                  maxLength="255"
                />
                {/*
                  <SicondirInput
                  label="Sezione"
                  id="catasto_sezione"
                  type="text"
                  name="catasto_sezione"
                  className={`form-control mb-2`}
                  size="large"
                  placeholder="Inserisci Sezione"
                  maxLength="255"
                  />
                */}
                <SicondirInput
                  label="Foglio"
                  id="catasto_foglio"
                  type="text"
                  name="catasto_foglio"
                  className={`form-control mb-2`}
                  size="large"
                  placeholder="Inserisci Foglio"
                  maxLength="255"
                />
                <SicondirInput
                  label="Particella"
                  id="catasto_particella"
                  type="text"
                  name="catasto_particella"
                  className={`form-control mb-2`}
                  size="large"
                  placeholder="Inserisci Particella"
                  maxLength="255"
                />
                <SicondirInput
                  label="Subalterno"
                  id="catasto_sub"
                  type="text"
                  name="catasto_sub"
                  className={`form-control mb-2`}
                  size="large"
                  placeholder="Inserisci Subalterno"
                  maxLength="255"
                />
                <SicondirInput
                  label="Superficie Totale"
                  id="superficie_totale"
                  type="text"
                  name="superficie_totale"
                  className={`form-control mb-2`}
                  size="large"
                  placeholder="Inserisci Superficie Totale"
                  maxLength="255"
                />
                {props.macroambito != 'F' &&
                  <>
                    <SicondirInput
                      label="Identificativo Pozzo"
                      id="identificativo_pozzo"
                      type="text"
                      name="identificativo_pozzo"
                      className={`form-control mb-2`}
                      size="large"
                      placeholder="Inserisci Identificativo Pozzo"
                      maxLength="255"
                    />
                    <SicondirInput
                      label="Unità Misura Nominale"
                      id="unita_misura_nominale"
                      type="text"
                      name="unita_misura_nominale"
                      className={`form-control mb-2`}
                      size="large"
                      placeholder="Inserisci Unità Misura Nominale"
                      maxLength="255"
                    />
                  </>
                }
                <SicondirInput
                  label="Produzione Nominale"
                  id="produzione_nominale"
                  type="text"
                  name="produzione_nominale"
                  className={`form-control mb-2`}
                  size="large"
                  placeholder="Inserisci Produzione Nominale"
                  maxLength="10"
                />
                <br />
                <br />
                <SicondirSelect
                  label="Status Area Demaniale"
                  id="tipo_stato_catasto_demaniale_derivazione_id"
                  name="tipo_stato_catasto_demaniale_derivazione_id"
                  placeholder="Seleziona Status Area Demaniale"
                  options={statusAreaDemaniale}
                  isClearable={true}
                  defaultValue={statusAreaDemaniale.filter(
                    (c) =>
                      c.value ==
                      oldValue.tipo_stato_catasto_demaniale_derivazione_id
                  )}
                />
                <br />
                <br />
                {props.macroambito != 'F' &&
                  <>
                    <SicondirSelect
                      label="Modalità Di Scarico"
                      id="tipo_modalita_scarico_id"
                      name="tipo_modalita_scarico_id"
                      placeholder="Seleziona Status Modalità Di Scarico"
                      options={tipiModalitaScarico}
                      isClearable={true}
                      defaultValue={tipiModalitaScarico.filter(
                        (c) => c.value == oldValue.tipo_modalita_scarico_id
                      )}
                    />
                    <br />
                    <br />
                  </>
                  }
                <SicondirTextarea
                  label='Note'
                  id='note'
                  type='textarea'
                  style={{height: "150px", width: "100%"}}
                  name='note'
                  size="large" 
                />
                <br /><br />
                {/*
              <SicondirRadio 
                label="Opere Richieste"
                name="opere_richieste"
                id="opere_richieste"
                options={[
                  {
                     value:"true",
                     label:"Sì",
                   },
                   {
                     value:"false",
                     label:"No",
                   }
               ]}

              />
              */}
                {/*
              <SicondirRadio 
                label="Opere Esistenti"
                name="opere_esistenti"
                id="opere_esistenti"
                options={[
                  {
                     value:"true",
                     label:"Sì",
                   },
                   {
                     value:"false",
                     label:"No",
                   }
               ]} 
              /> */}
                {/* <input label="Stato del bene demaniale al momento della richiesta" options={tipiStatoCatastoAttuale} /> */}
                {/*
              <SicondirRadio 
                label="Stato Patrimoniale"
                name="stato_patrimoniale"
                id="stato_patrimoniale"
                options={[
                  {
                     value:"DISPONIBILE",
                     label:"Disponibile",
                   },
                   {
                     value:"INDISPONIBILE",
                     label:"Indisponibile",
                   }
               ]} 
              />*/}
                <div className="col-12 mt-5 row justify-content-center">
                  <button type="submit" className="btn btn-primary col-5" disabled={!(isValid && dirty && !isSubmitting)}>
                    Salva Modifiche
                  </button>
                </div>
                {props.contesto && props.contesto == "anagrafica" &&
                  
                  <div className="col-12 mt-5 row justify-content-center">
                      <DeleteButton 
                        table="catasto_demaniale_derivazioni" 
                        resourceId={beneDemanialeId}
                        className="col-5"
                        redirectPath={fromUrl}
                        buttonLabel="Elimina Bene Demaniale"
                        confirmLabel="Procedere con l'eliminazione della bene demaniale?"
                        successMessage="Bene demaniale eliminato con successo"
                        errorMessage="Errore durante la cancellazione del bene demaniale">  
                      </DeleteButton>
      
                  </div>
                  
                  }
              </Form>)}
        </Formik>
        <br /><br />
        <h3> Elenco Delle Concessioni Associate  Al Bene Demaniale</h3>
        <SicondirTable
          headers={["Codice sicondir","codice sistema precedente","tipo ambito","provincia","stato",""]} 
          data={concessioni}
        />
        <h3> Elenco Delle Domande Associate  Al Bene Demaniale</h3>
        <SicondirTable
          headers={["Codice sicondir","codice sistema precedente","tipo ambito","provincia","stato",""]} 
          data={listRichiesteConcessioni}
        />
        <h3> Elenco Delle Denunce Pozzo Associate  Al Bene Demaniale</h3>
        <SicondirTable
          headers={["Codice sicondir","codice sistema precedente","tipo ambito","provincia","stato",""]} 
          data={listDenuncePozzo}
        />
        <h3> Elenco Delle Occupazioni Associate Al Bene Demaniale</h3>
        <SicondirTable
          headers={["Codice sicondir","codice sistema precedente","tipo ambito","provincia","stato",""]} 
          data={listOccupazioni}
        />
        </>
      ) 
      : 
      (
        <>
          <br />
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="progress-spinner progress-spinner-active">
              <span className="sr-only">Caricamento...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default BeniDemanialiFormEdit;
