import { useEffect, useState } from 'react'
import { Formik, Form} from 'formik'
import SicondirSelect from "../components/SicondirSelect";
import tipiAmbito from '../../_data/tipi_ambito.json'
import SicondirInput from "../components/SicondirInput";
import provinceLazio from '../../_data/province_lazio.json'

const ConcessioniRicercaForm = (props) => {
    const [provinciaSelezionata, setProvinciaSelezionata] = useState(null);
    const [statoSelezionato, setStatoSelezionato] = useState(null);
    const [tipiAmbitoFiltrati, setTipiAmbitoFiltrati] = useState([])
    const [ambitoSelezionato, setAmbitoSelezionato] = useState(props.macroambito);
    
    // "TENDINA AMBITI"
    useEffect(() => {
        const res = tipiAmbito.filter(c => (c.macroarea_ambito == props.macroambito && c.value != 6 && c.value != 7))
            .map(c => { return { 
                value: c.value,
                label: c.label,
            };}
            )
        setTipiAmbitoFiltrati(res)
    }, [props.macroambito]) 

    const handleAmbito = (e) => {
        if(e){
            setAmbitoSelezionato({
                label: e.label,
                value: e.value
            })
        } else{
            setAmbitoSelezionato(null);
        }
    }  

    // TODO: gestire fluviali/lacuali vs derivazioni.
    return(
        <>
           <Formik 
           id="filtroConcessioni"
           initialValues={{
            tipo_ambito_id: '',
            codice_sicondir: '',
            codice_sistema_precedente: '',
            codice_fiscale_piva: '',
            tipo_provincia_id: '',
            tipo_stato_id: '',
            ragione_sociale: '',
            cognome: '',
            codice_sicer: ''
          }}
         
          onSubmit = { async (values, { setSubmitting }) => {
            props.setSent(true);  
            props.setFilter(values);
            props.setCurrentPage(1)
          }} 
          
          onReset ={() => {
            setAmbitoSelezionato(null);
            setProvinciaSelezionata(null);
            setStatoSelezionato(null);
            props.setFilter({})
            props.setCurrentPage(1)
          }}

          >
               {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
            }) => (
            <Form>
            <div className="">
                <div className="row">
                  {props.contesto != 'denunce_pozzo' && props.contesto != 'occupazioni' &&
                    <div className="col-3">
                        <SicondirSelect 
                        label="Tipo Ambito" 
                        id="tipo_ambito_id" 
                        name="tipo_ambito_id" 
                        options={tipiAmbitoFiltrati}
                        isClearable={true}
                        placeholder="Seleziona Tipo Ambito"
                        value={ambitoSelezionato} 
                        onChange={handleAmbito}
                        />
                    </div>
                  }
               
                  <div className="col-3">
                    <SicondirSelect
                    label='Provincia'
                    id='tipo_provincia_id'                
                    name='tipo_provincia_id'              
                    value={provinciaSelezionata}
                    options={provinceLazio} 
                    isClearable={true}
                                  
                    placeholder = 'Provincia '
                    maxLength = '2'
                    onChange={(e) => {
                      if(e){
                        setProvinciaSelezionata({
                          label: e.label,
                          value: e.value,
                        });
                      } else {setProvinciaSelezionata(null)}
                    }}
                    /> 
                  </div>
                  <div className="col-3">
                    <SicondirSelect
                      label='Stato'
                      id='tipo_stato_id'                
                      name='tipo_stato_id'              
                      value={statoSelezionato}
                      options={props.stati} 
                      isClearable={true}
                                    
                      placeholder = 'Seleziona Stato '
                      maxLength = '2'
                      onChange={(e) => {
                        if(e){
                          setStatoSelezionato({
                            label: e.label,
                            value: e.value,
                          });
                        } else {setStatoSelezionato(null)}
                      }}
                      />
                  </div> 
                </div>

                <div className="row">
                    <div className="col-6">
                        <SicondirInput 
                        label = 'Nuovo Codice Univoco(Codice SICONDIR)'   
                        id='codice_sicondir'
                        type='text'
                        name='codice_sicondir'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Nuovo Codice Univoco Concessione'   
                        maxLength = '255'
                        />
                    </div>
                    <div className="col-6">
                        <SicondirInput 
                        label = 'Codice Univoco Del Sistema Di Provenienza'   
                        id='codice_sistema_precedente'
                        type='text'
                        name='codice_sistema_precedente'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Codice Univoco Del Sistema Di Provenienza'   
                        maxLength = '255'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SicondirInput 
                        label = 'Codice Fiscale/Partita IVA'   
                        id='codice_fiscale_piva'
                        type='text'
                        name='codice_fiscale_piva'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Codice Fiscale/Partita IVA'   
                        maxLength = '16'
                        />
                    </div>
                    <div className="col-6">
                        <SicondirInput 
                        label = 'Ragione Sociale'   
                        id='ragione_sociale_nome_cognome'
                        type='text'
                        name='ragione_sociale'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Ragione Sociale'   
                        maxLength = '255'
                        />
                    </div>
                </div>
                <div className="row">
                <div className="col-6">
                        <SicondirInput 
                        label = 'Cognome'   
                        id='cognome'
                        type='text'
                        name='cognome'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Cognome'   
                        maxLength = '255'
                        />
                    </div>
                    <div className="col-6">
                        <SicondirInput 
                        label = 'Codice SICER'   
                        id='codice_sicer'
                        type='text'
                        name='codice_sicer'
                        className={`form-control mb-2`}
                        size="large" 
                        placeholder = 'Inserisci Codice SICER'   
                        maxLength = '255'
                        />
                    </div>
                </div>
               

               <div className="col-12 mt-5 row justify-content-end">
                  <button type="reset" className="btn btn-primary col-2" disabled={props.disabled}>Pulisci Campi</button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary col-2" disabled={props.disabled}>
                    Ricerca
                  </button>
                </div>
            </div>
            
            </Form>
            ) }
           </Formik>
        </>
    )
} 

export default ConcessioniRicercaForm;
