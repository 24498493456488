import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import alertify from "alertifyjs";  
import formatter from '../../../common/utils/formatters/_';
import Modal from 'react-bootstrap/Modal'
import CanoniDenunciaPozzoForm from '../../../common/forms/denuncePozzo/CanoniDenunciaPozzoForm';
import tipiImporto from '../../../_data/tipi_importo.json';
import SicondirDebitiTable from '../../../common/components/SicondirDebitiTable';
import CanoniDenuncePozzoEditForm from '../../../common/forms/denuncePozzo/CanoniDenuncePozzoEditForm';

function DenunciaPozzoCanone() {
    const [loading, setLoading] = useState(true);
    const [canone, setCanone] = useState(null);
    const [list, setList] = useState([]);
    const params = useParams();
    const denunciaPozzoId = params.id;
    const[show, setShow] = useState(false);

    const[associazione, setAssociazione] = useState(false) 
    const[cancellaAssociazione, setCancellaAssociazione] = useState(false)
    const[modificaCanone, setModificaCanone] = useState(false)

    const[showEdit, setShowEdit] = useState(false);
    const[loadingEdit, setLoadingEdit] = useState(true);
    const[denunciaPozzoCanoneEdit, setDenunciaPozzoCanoneEdit] = useState({})

    const handleModal = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }

    const handleDelete = async (idCanone) => {
        setLoading(true);
        const payload = {
            nome_tabella: "denunce_pozzo_canoni",
            id: idCanone,
            utente_modifica_id: localStorage.id
        }
        
        try{
            const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
                method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
                headers: { 'Content-Type': 'application/json',
                            'Accept': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:payload})
            })
            const data = await response.json();
            alertify.success("Importo dovuto eliminato con successo");

            setCancellaAssociazione(true)
            setLoading(false)
        } catch(error) {
            alertify.error("Errore durante la cancellazione dell'importo dovuto");
        }
    }

    const handleModalEdit = async (idCanone) => {
        
        try {
            const response = await fetch(process.env.REACT_APP_API_URL +
                'denunce_pozzo_canoni/id/' + idCanone,
                {
                    method: 'GET',
                    headers: { accept: 'application/json' },
                    mode: 'cors',
                })

            const json = await response.json()
            let  denunciaPozzoCanone=" "
            if (json.risposta.denunce_pozzo_canoni) {
                denunciaPozzoCanone = json.risposta.denunce_pozzo_canoni  
            }
            
            setDenunciaPozzoCanoneEdit(formatter.cleanObject(denunciaPozzoCanone))
            setLoadingEdit(false)
        }
        catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
        }
        setShowEdit(true)
    }
    const handleCloseEdit = () => {
        setShowEdit(false);
    }

    const buildActions = (idCanone) => {
        let result = {};
        result = { elimina: {
                kind: "function", 
                function: handleDelete,
                parameters:
                {
                    idCanone: idCanone
                },
                buttonClass: "btn-danger",
                linkClass: "text-danger",
                label: "Elimina importo dovuto",
                confirmLabel: "Procedere con l'eliminazione dell'importo dovuto?"},
                modifica: {
                    kind: "function", 
                    function: handleModalEdit,
                    parameters:
                    {
                        idCanone: idCanone
                    },
                    label: "Modifica importo dovuto",
                }
        }
        return result;        
    }

    const calcolaImportoTotale = (importo, rivalutazione, sanzione, interesse) => {
        return (parseFloat(importo) || 0)+ (parseFloat(rivalutazione) || 0 )+ (parseFloat(sanzione) || 0) + (parseFloat(interesse) || 0)
    }

    const fetchData = async (denunciaPozzoId) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `denunce_pozzo_canoni/denuncia_pozzo_id/${denunciaPozzoId}`, 
            {
                method: 'GET',
                headers: { accept:'application/json' }, 
                mode: 'cors',  
            })
            const json = await response.json()
            if(json.risposta.denunce_pozzo_canoni){
                let response = json.risposta.denunce_pozzo_canoni
                const lista = []
                if(Array.isArray(response)) {
                    let sortedList = response.sort((a,b)=> b.anno_contabile - a.anno_contabile)
                    let entries = Object.entries(sortedList);
                    let i = 0;
                    entries.map(([key,value]) => {
                        formatter.cleanObject(value)
                        let tipo_pagamento = tipiImporto.filter((e) => e.value == value.tipo_pagamento)[0].label || "-"
                        let importo = formatter.formatNumber(value.importo);
                        let importo_rivalutazione = formatter.formatNumber(value.importo_rivalutazione);
                        let sanzione = formatter.formatNumber(value.sanzione);
                        let interesse = formatter.formatNumber(value.interesse);
                        let importo_totale = formatter.formatNumber(calcolaImportoTotale(value.importo, value.importo_rivalutazione, value.sanzione, value.interesse));
                        let iscrizione_ruolo = value.flag_iscrizione_ruolo == 'true' ? "Sì" : "No"
                        let importo_pagato = formatter.formatNumber(value.canone_importo_pagato)
                        let importo_residuo = formatter.formatNumber(calcolaImportoTotale(value.importo, value.importo_rivalutazione, value.sanzione, value.interesse) - value.canone_importo_pagato)
                        let stato_pagamento = importo_pagato == importo_totale ? "COMPLETO" : "INCOMPLETO"

                        let actions = buildActions(value.id)
                        lista[i]=
                        {
                            id: value.id,
                            anno_contabile: value.anno_contabile || "-",
                            tipo_pagamento: tipo_pagamento,
                            identificativo_debito: value.identificativo_debito || "-",
                            importo_totale: importo_totale,
                            iscrizione_ruolo: iscrizione_ruolo,
                            data_scadenza_pagamento: value.data_scadenza_pagamento ? value.data_scadenza_pagamento.substring(0, 10) : '-',
                            stato_pagamento: stato_pagamento,
                            importo_pagato: importo_pagato,
                            importo_residuo: importo_residuo,
                            app_actions: actions
                        }
                        i++
                    })
                }
                setList(lista)
                setCanone(lista.length >0);
                setCancellaAssociazione(false)
                setLoading(false);
            } else{
                setList([])
                setCanone(false);
                setCancellaAssociazione(false)
                setLoading(false);
            }
            setAssociazione(false)
            setModificaCanone(false)
            setLoading(false);

        } catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
        }
    }

    useEffect(() => {
        fetchData(denunciaPozzoId)
    }, [associazione, cancellaAssociazione, modificaCanone])


    return (
        <>
        { !loading
            ?
            <> 
            {!canone
                ?
                <div className="row">
                <div className="col-12 mt-5 row justify-content-center">
                    Nessun importo dovuto è associato a questa denuncia pozzo.
                </div>
                </div>
                :
                <div>
                <br />
                <SicondirDebitiTable
                    headers={[
                    "Anno \ncontabile",
                    "Tipo \npagamento",
                    "Identificativo \ndovuto",
                    "Importo \ntotale\n(€)",
                    "Iscrizione \nruolo",
                    "Data scadenza \npagamento",
                    "Stato \npagamento",
                    "Importo pagato",
                    "Importo residuo",
                    ""
                  ]} 
                    data={list}
                    compact={true}
                    contesto="denuncia_pozzo"
                />
                </div>
            }
            <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModal}>
                    Inserisci importo dovuto
                </button>
            </div>
            </>
            :
            <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="progress-spinner progress-spinner-active">
                <span className="sr-only">Caricamento...</span>
            </div>
            </div>
        }

        <Modal 
            dialogClassName={'modal-fullpage'}
            show={show} 
            onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title >
                    Inserisci importo dovuto
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CanoniDenunciaPozzoForm 
                denunciaPozzoId={denunciaPozzoId} 
                handleClose={handleClose} 
                setAssociazione={setAssociazione} />
            </Modal.Body>
        </Modal>     

        <Modal 
            dialogClassName={'modal-fullpage'}
            show={showEdit} 
            onHide={handleCloseEdit}>
            <Modal.Header closeButton>
                <Modal.Title >
                    Dettaglio importo dovuto
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {!loadingEdit ?
                <CanoniDenuncePozzoEditForm
                    denunciaPozzoId={denunciaPozzoId}
                    denunciaPozzoCanone={denunciaPozzoCanoneEdit} 
                    handleClose={handleCloseEdit} 
                    setAssociazione={setModificaCanone} 
                />
                :
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <div className="progress-spinner progress-spinner-active">
                        <span className="sr-only">Caricamento...</span>
                    </div>
                </div>
                }
            </Modal.Body>
        </Modal>


        </>
    )
}

export default DenunciaPozzoCanone