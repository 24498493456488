import '../App.css';
import { Outlet } from "react-router-dom";
import Header from './layout/Header';
import Footer from './layout/Footer';
import Cookiebar from './components/Cookiebar'

function Wrapper(props) {
  return (
    <>
    <Cookiebar/>
    <section className="main-content">
      <Header sezione={props.sezione} />

          <Outlet />
        
    </section> 
    <Footer />
    </>
  );
}

export default Wrapper;
