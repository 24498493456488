import DenunciaPozzoIntestatario from "../components/DenunciaPozzoIntestatario";
import DenunciaPozzoForm from "../../../common/forms/denuncePozzo/DenunciaPozzoForm";
import BeniDemanialiDenuncePozzoForm from "../../../common/forms/denuncePozzo/BeniDemanialiDenuncePozzoForm";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import DeleteButton from "../../../common/components/DeleteButton";
import { useParams, useLocation } from "react-router";
import { useEffect, useState } from 'react'
import alertify from "alertifyjs";  
import formatter from "../../../common/utils/formatters/_";
import DocumentoDenunciaPozzoForm from "../../../common/forms/denuncePozzo/DocumentoDenunciaPozzoForm";
import DenunciaPozzoCanone from "../components/DenunciaPozzoCanone";
import OperaDenunciaPozzoForm from "../../../common/forms/denuncePozzo/OperaDenunciaPozzoForm";

export default function DenuncePozzo(props) {
    // Get ID from URL
    const params = useParams();
    const denunciaPozzoId = params.id;
    // Per recuperare l'url da cui provengo.
    const location = useLocation()
    // const {fromUrl} = location.state.fromUrl;
    const [fromUrl, setFromUrl] = useState('');
    const [denunciaPozzo, setDenunciaPozzo] = useState({});
    const [loading, setLoading] = useState(true);
    const [macroambito, setMacroambito] = useState('');

    useEffect(() => {
      const url = process.env.REACT_APP_API_URL + 'denunce_pozzo/id/'+denunciaPozzoId;
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: { accept: "application/json" },      
            mode: 'cors'
           });

          const json = await response.json();
          
          setDenunciaPozzo(formatter.cleanObject(json.risposta.denunce_pozzo));
          setFromUrl("/denunce-pozzo")
          setMacroambito(json.risposta.denunce_pozzo.macroarea_ambito)
          setLoading(false);

        } catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
        }
      };
  
      fetchData();
    }, []); 

    return (
      <main>
        <h3>Gestione denuncia pozzo</h3>
        { !loading?
          <>
          <div className="card ">
          <div className="card-body">
            
            <Tabs defaultActiveKey="tab1">
              <Tab eventKey="tab1" title="Dati Denuncia Pozzo">
                <DenunciaPozzoForm loading={loading} denunciaPozzo={denunciaPozzo}></DenunciaPozzoForm>
              </Tab>
              <Tab eventKey="tab2" title="Intestatario">
                <DenunciaPozzoIntestatario></DenunciaPozzoIntestatario>
              </Tab>
              <Tab eventKey="tab3" title="Bene Demaniale/Derivazione">
                <BeniDemanialiDenuncePozzoForm macroambito={macroambito}></BeniDemanialiDenuncePozzoForm>
              </Tab>
              <Tab eventKey="tab4" title="Opere">
                <OperaDenunciaPozzoForm></OperaDenunciaPozzoForm>
              </Tab>
              <Tab eventKey="tab5" title="Documenti">
                <DocumentoDenunciaPozzoForm></DocumentoDenunciaPozzoForm>
              </Tab>
              <Tab eventKey="tab6" title="Dati Contabili">
                <DenunciaPozzoCanone></DenunciaPozzoCanone>
              </Tab>
            </Tabs>
            
          </div>
        </div>
        <br />
        <div className="card ">
              <div className="card-body">
                <div className="float-right">
                  <DeleteButton 
                    table="denunce_pozzo" 
                    resourceId={params.id} 
                    redirectPath={fromUrl}
                    buttonLabel="Elimina denuncia pozzo"
                    confirmLabel="Procedere con l'eliminazione della denuncia pozzo?"
                    successMessage="Denuncia pozzo eliminata con successo"
                    errorMessage="Errore durante la cancellazione della denuncia pozzo">  
                  </DeleteButton>
                </div>
              </div>
            </div>
            </>
          :
          
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>

        }
        
      </main>
    );
  }