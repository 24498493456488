import React from 'react'
import * as Yup from 'yup'


export default {
    Mesi: { A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' },

    validationSchemaPf: Yup.object({
        nome: Yup.string()
        .max(40, "Il nome non può avere più di 40 caratteri")
        .min(2, "Il nome deve avere almeno 2 caratteri")
        .required("Il nome è obbligatorio"), 
        cognome: Yup.string()
        .max(40, "Il cognome non può avere più di 40 caratteri")
        .min(2, "Il cognome deve avere almeno 2 caratteri")
        .required("Il cognome è obbligatorio"),
     //   codice_sicer: Yup.string().required("Il codice Sicer è obbligatorio"),
        codice_fiscale: Yup.string()
        .max(16, "Il codice fiscale non può avere più di 16 caratteri")
        .min(16, "Il codice fiscale non può avere meno di 16 caratteri")
        .required("Il codice fiscale è obbligatorio"),
        datainizioincarico: ""
        }),
    validationSchemaPg: Yup.object({
      //  codice_sicer: Yup.string().required("Il codice Sicer è obbligatorio"),
        ragione_sociale: Yup.string()
        .max(150, "La ragione sociale non può avere più di 150 caratteri")
        .min(2, "La ragione sociale deve avere almeno 2 caratteri")
        .required("La ragione sociale è obbligatoria"),
        piva: Yup.string()
        .max(11, "La partita iva non può avere più di 11 caratteri")
        .min(11, "La partita iva non può avere meno di 11 caratteri")
        .required("La partita iva è obbligatoria"),
        datainizioincarico: "",
        }),
        
    initialValue: {
            natura_giuridica: "",
            nome: "", 
            cognome: "",
            codice_sicer: "",
            codice_fiscale: "",
            ragione_sociale: "",
            comune_nascita: "",
            nazione_nascita: "",
            data_nascita: "",
            genere: "",
            piva: "",
            datainizioincarico: "",
            note: "",
            username: "",
            ruoli: ""
        }
    
}