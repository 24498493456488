import {Table } from 'react-bootstrap';
import SicondirUpdateButton from './SicondirUpdateButton';
import { Link } from "react-router-dom";
import alertify from "alertifyjs";  
import SicondirDropDownButtons from './SicondirDropDownButtons';
import TriggerPopover from './TriggerPopover';

export default function SicondirTable(props) {

    let numeroCampi = props.headers.length;
    let noDataLabel = props.noDataLabel ? props.noDataLabel : "Nessun dato disponibile";
    let showId = props.showId ? props.showId : false
    let tableClass = "table table-hover"
    props.compact ? tableClass += " table-sm " : tableClass += ""
   
    return(
        <Table className={tableClass}> 
            <thead className="text-capitalize">
                <tr>
                { props.headers.map((col) => {
                    return ( <th key={col}> {col} </th> )
                })
                }
                </tr>
            </thead>
            <tbody>
                { !props.data || props.data.length < 1 ? 
                    <tr key="noData">
                    <td colSpan={numeroCampi} className="text-center">{noDataLabel}</td>
                    </tr>
                    :
                    props.data.map((el) => {
                        return(
                            <tr key={el.id}>
                                { Object.keys(el).map(function(keyValue) {
                                    if(keyValue == "app_actions"){
                                        if(props.dropdown){
                                            return (
                                                <td key={keyValue}>
                                                    <SicondirDropDownButtons mainLabel="Azioni" disabled={props.readOnly} data={el[keyValue]} />
                                                </td>
                                            )
                                        } else {
                                            return(
                                            <td key={keyValue}>
                                            { 
                                            Object.entries(el[keyValue]).map(([key,value],i) => {
                                                if(key == 'update'){
                                                    return (<SicondirUpdateButton key={key} disabled={props.readOnly} url={value}></SicondirUpdateButton>)
                                                } else if(key == 'delete'){
                                                    // return (<>&nbsp;<SicondirDeleteButton table={value.table} elementId={value.id}></SicondirDeleteButton></>)
                                                } else{
                                                    let buttonClass="btn"
                                                    if(value.buttonClass){ 
                                                        buttonClass+=" "+ value.buttonClass
                                                    }
                                                    else{
                                                        buttonClass+=" btn-primary"
                                                    }
                                                    const handleClick = (e, params) => {
                                                        // eseguo la funzione che ho passato come parametro con i suoi parametri.
                                                        // console.log('confirmLabel', value.confirmLabel)
                                                        
                                                        if(value.confirmLabel){
                                                            e.preventDefault();
                                                            alertify.confirm(value.confirmLabel, function(){value.function(...Object.values(params))})
                                                            .set('labels', {
                                                                cancel: "ANNULLA",
                                                                ok: "OK",
                                                            });
                                                        } else { 
                                                            value.function(...Object.values(params))
                                                        }
                                                    }
                                                    return (<button key={key} className={buttonClass} onClick={(e) => handleClick(e, value.parameters)} disabled={props.readOnly}>{value.label}</button>)
                                                }

                                            })
                                            }
                                            </td>
                                            )
                                        }
                                    } else if(keyValue == "link"){
                                        return (<td key={keyValue}> 
                                                { <a target="_blank" href={el[keyValue].link}>{el[keyValue].label}</a> }
                                                </td>)
                                    } else if(keyValue == "popover") {
                                        return (
                                            <td key={keyValue}>
                                            <TriggerPopover text={el[keyValue]} />
                                            </td>
                                        )
                                    } else{
                                        // l'id lo faccio vedere solo se specificato
                                        if(keyValue == 'id'){
                                            return (
                                                showId && <td key={keyValue}> {el[keyValue]} </td>
                                            )
                                        } else{
                                            return (<td key={keyValue}> {el[keyValue]} </td>)
                                        }
                                    }
                                })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}
