import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Formik, Form} from 'formik'
import SicondirSelect from "../../components/SicondirSelect";
import SicondirInput from "../../components/SicondirInput";
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import formatter from '../../utils/formatters/_';
import provinceLazio from "../../../_data/province_lazio.json"
import tipiAmbito from '../../../_data/tipi_ambito.json'
import _ from 'lodash'
import SicondirRadio from '../../components/SicondirRadio';

const RichiestaInserimentoForm = (props) => {
    let navigate = useNavigate();
    const[nuovoCodice, setNuovoCodice] = useState(true)
    const [currentMacroambito, setCurrentMacroambito] = useState(props.macroambito);
    const [stringaMacroambito, setStringaMacroambito] = useState(props.macroambito == "F" ? "fluviali-lacuali" : "derivazioni")
    const [provinciaSelezionata, setProvinciaSelezionata] = useState(null)
    const [siglaProvincia, setSiglaProvincia] = useState("");

    let fromUrl = "/richieste-concessioni/"
    if(props.macroambito == 'F'){
      fromUrl += "fluviali-lacuali"
    } else if(props.macroambito == 'D'){
      fromUrl += "derivazioni"
    }

    // Filtro gli ambiti prima per macroarea, poi tolgo ambito lacuale e piccola derivazione che per il momento non prevedono la DOMANDA.
    let tipiAmbitoFiltrati = tipiAmbito.filter(c => c.macroarea_ambito == props.macroambito && !c.concessione_autonoma && c.codice != 'PDE' && c.codice != 'FLS' && c.codice != 'LAC')

    let regex = null;
    let errorMessage = ""
    if(props.macroambito == 'F'){
      regex = /^EQ-[0-9]+$/
      errorMessage = "Inserire un codice di domanda del tipo EQ-####"
    } else{
      if(siglaProvincia){
        let pattern = siglaProvincia + "-####"
        switch(siglaProvincia) {
          case 'VT': 
            regex =  /^VT-[0-9]+$/;
            break;
          case 'RI': 
            regex =  /^RI-[0-9]+$/;
            break;
          case 'RM': 
            regex =  /^RM-[0-9]+$/;
            break;
          case 'LT': 
            regex =  /^LT-[0-9]+$/;
            break;
          case 'FR':
            pattern = "FR-#### / FC-####"
            regex =  /^FR|FC-[0-9]+$/;
            break;
        }
        
        errorMessage = "Inserire un codice di domanda del tipo " + pattern
      } else {
        errorMessage = "Selezionare una provincia"
      }
    }

    const checkCodiceDuplicato = async (valore) => {
      const url = process.env.REACT_APP_API_URL + 'richieste_concessione/codice_sistema_precedente/'+encodeURI(valore);
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: { accept: "application/json" },      
          mode: 'cors'
         });
        const json = await response.json()
        return !_.isEmpty(json.risposta.richieste_concessione)
      } catch (error) {
        alertify.error("Errore durante il recupero dei dati.");
        console.log("errore: ", error);
        return true
      }
    };

    const handleCampoCodice = (event) => {
      setNuovoCodice(event.target.value == 'true')
    }

    const handleProvincia = (event) => {
      let sigla = provinceLazio.filter(c=> c.value == event.value)[0].sigla
      setProvinciaSelezionata(event)
      setSiglaProvincia(sigla)
    }

    
    return(
        <>
           <Formik 
           id="richiesta"
           
           disabled={true}
           initialValues={{
            tipo_provincia_id: '',
            tipo_ambito_id: '',
            flag_nuovo_codice: "true", 
            codice_sistema_precedente: ''
            }}
        
            validationSchema={Yup.object({
              tipo_provincia_id: Yup.string().required("Il campo è obbligatorio"),
              tipo_ambito_id: Yup.string().required("Il campo è obbligatorio"),
              flag_nuovo_codice: Yup.boolean(),
              codice_sistema_precedente: Yup.string().test("checkCodice", "Codice già importato", 
              async function(value, testContext){
                if(!nuovoCodice){
                  if(value == null || value.trim() ==''){
                    return testContext.createError({
                      message: `Il campo è obbligatorio`,
                      path: 'codice_sistema_precedente',
                    })
                  }
                  if (value != null && value.trim()!='') {
                    const idDuplicato = await checkCodiceDuplicato(value.trim());
                    return !idDuplicato;
                  }
                }
                return true;
              }
              ).matches(regex, errorMessage)
            })}  

            onSubmit = { async (values, { setSubmitting, setFieldValue, resetForm }) => {

            const richiesta = {
              macroarea_ambito:  formatter.escapeSql(currentMacroambito),
              tipo_ambito_id: values.tipo_ambito_id,
              tipo_provincia_id: values.tipo_provincia_id,
              utente_modifica_id: localStorage.id,
              codice_sistema_precedente: !nuovoCodice && values.codice_sistema_precedente.trim() != '' ? values.codice_sistema_precedente : null
            }  
            
            try{
              const response = await fetch(process.env.REACT_APP_API_URL + 'richieste_concessione', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:richiesta})
               })
               const data = await response.json();

               navigate('/richieste-concessioni/' +  stringaMacroambito + '/inserisci/' + data.risposta.elementi_aggiunti.id, 
                        {state: {
                          fromUrl: {fromUrl}
                          }
                        }) ;
               alertify.success("Nuova domanda di concessione creata con successo.");
            } catch(error) {
              alertify.error("Errore durante l'inserimento.");
              console.log("errore: ", error);
            }
            
          }} 
          
          >
            {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
            }) => (
            <Form>
                <fieldset disabled={props.importoUtilizzato > 0}>
                <br /><br />
                <div className="row">
                    <div className="col-12">
                    <SicondirSelect
                        label='Provincia'
                        id='tipo_provincia_id'                
                        name='tipo_provincia_id'              
                        options={provinceLazio} 
                        isClearable={true}
                        placeholder = 'Provincia'
                        onChange = {handleProvincia}
                        value={provinciaSelezionata}
                        maxLength = '2'
                      />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                      <SicondirSelect 
                        label="Tipo Ambito" 
                        id="tipo_ambito_id" 
                        name="tipo_ambito_id" 
                        placeholder="Seleziona Tipo Ambito"
                        options={tipiAmbitoFiltrati} 
                        isClearable={true}
                      />
                    </div>
                </div>
                <br /><br />
                <div className="row">
                    <div className="col-12">
                      <SicondirRadio 
                        label=""
                        name="flag_nuovo_codice"
                        id="flag_nuovo_codice"
                        onChange={handleCampoCodice}
                        options={[
                          {
                            value:true,
                            checked: nuovoCodice, 
                            label: <>Inserimento domanda con <b>codice NON esistente</b> su sistemi precedenti<b>(NUOVA DOMANDA)</b></>,
                          },
                          {
                            value:false,
                            checked: !nuovoCodice,
                            label: <>Inserimento domanda con <b>codice esistente</b> su sistemi precedenti<b>(DOMANDA ESISTENTE)</b></>,
                          }
                        ]} 
                      />
                      </div>
                </div>
                
                <div className="row">
                    <div className="col-12">
                      <SicondirInput 
                        label="Codice Domanda Esistente"
                        type='text'
                        name="codice_sistema_precedente"
                        id="codice_sistema_precedente"
                        placeholder = 'Inserisci Codice Domanda'
                        maxLength = '50'
                        disabled={nuovoCodice}
                      />
                      </div>
                </div>

                <div className="col-12 mt-5 row justify-content-center">
                    <button type="submit" className="btn btn-primary col-5" disabled={!(isValid && dirty && !isSubmitting)}>
                        Inserisci nuova domanda
                    </button>
                </div>
                <br />
                </fieldset>
            </Form>
            ) }
           </Formik>
        </>
    )
} 

export default RichiestaInserimentoForm;