import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import alertify from "alertifyjs";  
import SicondirRadio from "../../components/SicondirRadio";
import SicondirInput from "../../components/SicondirInput";
import formatter from '../../utils/formatters/_';
import SicondirTable from "../../components/SicondirTable";


const OperaDenunciaPozzoForm = (props) => {
    // Get ID from URL
    const params = useParams();
    const denunciaPozzoId = params.id;
    const [loading, setLoading] = useState(true);
    const [opereArray, setOpereArray] = useState([])
    const [list, setList] = useState([])
    const [clearRadio, setClearRadio] = useState(false)
    
    const deleteOpera = async(operaId) => {
      setLoading(true);
      const payload = {
          nome_tabella: "denunce_pozzo_opere_accettate",
          id: operaId,
          utente_modifica_id: localStorage.id
      }
    
      try{
          const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
              method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
              headers: { 'Content-Type': 'application/json',
                          'Accept': 'application/json' }, 
              mode: 'cors', 
              body: JSON.stringify({Entry:payload})
          })
          const data = await response.json();
          alertify.success("Opera eliminata con successo");
          
          setLoading(false)
          setOpereArray([]);
      } catch(error) {
          alertify.error("Errore durante la cancellazione dell'opera");
          setLoading(false)
      }
      

    }
  
    useEffect(() => {
      const getFlagStabile = (flag_stabile) => {
        if(flag_stabile == null)return "-"; // non è stato settato
        if(flag_stabile == "0") return "Sì"; // E' stabile? No. Verrà rimosso? Sì
        if(flag_stabile == "1") return "No"; // E' stabile? Sì. Verrà rimosso? No
        
      }
        const fetchData = async() => {
          setLoading(true)
          try{
            const url = process.env.REACT_APP_API_URL + 'denunce_pozzo_opere_accettate/denuncia_pozzo_id/' + denunciaPozzoId;
            const response = await fetch(url, {
              method: 'GET',
              headers: { 
                        accept: "application/json"
                      },      
              mode: 'cors'
             });
             const json = await response.json();
             // Da rivedere questa parte: dovremmo avere una api ad hoc che ritorni solo i dati che ci servono, non dobbiamo scremarli qua...
            let list = [];
            let i = 1;
            if(json.risposta.denunce_pozzo_opere_accettate){
              if(Array.isArray(json.risposta.denunce_pozzo_opere_accettate)){
                json.risposta.denunce_pozzo_opere_accettate.map(element => {
                  element = formatter.cleanObject(element)
                  list[i] = {
                    id: element.id,
                    tipo_opera: element.tipo_opera || " - ",
                    descrizione: element.descrizione || " - ",
                    flag_stabile: getFlagStabile(element.flag_stabile),
                    app_actions:{
                      deleteOpera: {
                        kind: "function", 
                            function: deleteOpera,
                            parameters:
                            {
                              idOpera: element.id
                            },
                            label: "Elimina",
                            buttonClass: "btn-danger",
                            confirmLabel: "Procedere con l'eliminazione dell'opera?"
                      }, 
                    }
                  }
                  i++
                });
              }
            } 
            
            setList(list); 
            setLoading(false);
          
          } catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
          }
          
          setLoading(false);
      };
      fetchData();
      
    }, [opereArray]);
  

    return (
        <>
        <Formik
        id='opera' className="p-4"
        initialValues={{
            tipo_opera: '', 
            descrizione: '',
            flag_stabile: ''
          }}
          onSubmit = { async (values, { setSubmitting,resetForm }) => {
            setLoading(true)
            let campi = ""
            let valori = ""

            Object.entries(values).map(([key,value],i) => {
              if(key == 'flag_stabile' && value != ''){
                campi += key + ", "
                let valoreFlag = value == 'true' ? 1 :0
                valori += ""+ valoreFlag + ", " // stabile_flag non ha bisogno dell'apice perché è un boolean
              } else if(value != ''){
                campi += key + ", "
                valori += "'" + formatter.escapeSql(value) + "', "
              } 
            })

            if(valori == ""){
              alertify.warning("Inserire almeno un campo");
              setLoading(false)
              return;
            }

            try{
              campi += "denuncia_pozzo_id,utente_modifica_id";
              valori += denunciaPozzoId+","+localStorage.id
              const opera = {
                nome_tabella: "denunce_pozzo_opere_accettate",
                lista_campi: campi,
                lista_valori: valori
              }

              const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:opera})
              })
              const data_response = await response.json();
              if(data_response.hasOwnProperty('Fault')){
                if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                  console.log("ErrorCode: " + data_response.Fault.faultcode)
                  throw new Error(data_response.Fault.faultstring)
                } else{
                  throw new Error("errore durante il salvataggio.")
                }
              }
              setOpereArray(opereArray => [opereArray, data_response.risposta.elementi_aggiunti.id] );
              alertify.success("Opera associata alla denuncia pozzo con successo.");
              resetForm()
            } catch(error) {
              alertify.error("Errore durante l'associazione dell'opera.");
              console.log("errore: ", error);
            }
            setLoading(false)
          }}
        >
        <Form>
                <br /><br />
                      <SicondirInput 
                        label='Tipo Opera'
                        id='tipo_opera'
                        type='text'
                        name='tipo_opera'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Tipo Opera'   
                        maxLength = '255'
                      />

                      <SicondirInput 
                        label='Descrizione'
                        id='descrizione'
                        type='text'
                        name='descrizione'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Descrizione Opera'   
                        maxLength = '255'
                      />

                      <SicondirRadio 
                        label="Rimozione al termine della concessione"
                        name="flag_stabile"
                        id="flag_stabile"
                        // clearRadio={!clearRadio}
                        options={[
                          {
                            value:false, // false significa che stabile = false, quindi verrà rimossa alla fine della concessione...
                            label:"Sì",
                          },
                          {
                            value:true, // true significa che stabile = true, quindi NON verrà rimossa alla fine della concessione...
                            label:"No",
                          }
                        ]} 
                      />

            <div className="col-12 mt-5 row justify-content-center">
                <button type="submit" className="btn btn-primary col-5" disabled={loading}>
                Salva
                </button>
            </div>
            
        </Form>
        </Formik>
        <br /><br />
        {!loading ?
          <SicondirTable 
          headers={[
            "Tipo opera",
            "Descrizione", 
            "Rimozione al termine della concessione", 
            ""]} 
          data={list} 
          />
        
        
        :
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>
        }
      </>
    )
}

export default OperaDenunciaPozzoForm;