import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import alertify from "alertifyjs";  
import SicondirInput from "../../components/SicondirInput";
import tipiDocumento from '../../../_data/tipi_documento.json';
import formatter from '../../utils/formatters/_';
import SicondirTable from "../../components/SicondirTable";
import SicondirSelect from "../../components/SicondirSelect";
import UploadButton from "../../components/UploadButton";
import Modal from 'react-bootstrap/Modal'
import DocumentoRichiestaFormEdit from "./DocumentoRichiestaFormEdit";

const DocumentoRichiestaForm = (props) => {
    // Get ID from URL
    const params = useParams();
    const richiestaId = params.id;
    const [loading, setLoading] = useState(true);
    const [documentiArray, setDocumentiArray] = useState([])
    const [list, setList] = useState([])
    const [fileObject, setFileObject] = useState(undefined)
    const [tipoDocumento, setTipoDocumento] = useState(null)
    const [pulisciFile, setPulisciFile] = useState(false)


    // per la modifica.
    const[editShow, setEditShow] = useState(false);
    const[loadingEdit, setLoadingEdit] = useState(true);
    const[documentoEdit, setDocumentoEdit] = useState(null)
    const[modificaDocumento, setModificaDocumento] = useState(false);

    const handleModalEdit = async (idDocumento) => {
      try {
          const response = await fetch(process.env.REACT_APP_API_URL +
              'richieste_concessioni_documenti/id/' + idDocumento,
              {
                  method: 'GET',
                  headers: { accept: 'application/json' },
                  mode: 'cors',
              })

          const json = await response.json()
          let  documento=" "
          if (json.risposta.richieste_concessione_documenti) {
            documento = json.risposta.richieste_concessione_documenti   
          }
      
          setDocumentoEdit(formatter.cleanObject(documento))
          setLoadingEdit(false)
        
      }
      catch (error) {
          alertify.error("Errore durante il recupero dei dati.");
          console.log("errore: ", error);
      }
      
      setEditShow(true);
  }

  const handleCloseEdit = () => {
      setEditShow(false);
  }


  const buildActions = (idDocumento) => {
    let result = {};
    result = { elimina: {
            kind: "function", 
            function: handleDelete,
            parameters:
            {
              idDocumento: idDocumento
            },
            buttonClass: "btn-danger",
            linkClass: "text-danger",
            label: "Elimina associazione",
            confirmLabel: "Procedere con l'eliminazione del documento?"},
            
            modificaDocumento: {
                kind: "function", 
                function: handleModalEdit,
                parameters:
                {
                    idDocumento: idDocumento
                },
                label: "Modifica documento caricato",
            }
    }
    return result;        
  }

    const manageTipoDocumento = (event) =>{
      if(event){
        setTipoDocumento({
            label: event.label,
            value: event.value
        })
    } else{
        setTipoDocumento(null);
    }
    }


    const handleDelete = async(documentoId) => {
      setLoading(true);
      const payload = {
          nome_tabella: "richieste_concessione_documenti",
          id: documentoId,
          utente_modifica_id: localStorage.id
      }
    
      try{
          const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
              method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
              headers: { 'Content-Type': 'application/json',
                          'Accept': 'application/json' }, 
              mode: 'cors', 
              body: JSON.stringify({Entry:payload})
          })
          const data = await response.json();
          alertify.success("Documento eliminato con successo");
          
          setLoading(false)
          setDocumentiArray([]);
      } catch(error) {
          alertify.error("Errore durante la cancellazione del documento");
          setLoading(false)
      }
      

    }
  
    useEffect(() => {
        
        const getTipoDocumento = (idTipoDocumento) => {
            const res = tipiDocumento.filter(c => c.value == idTipoDocumento)
            .map(c => { return c.label })
            return res;
        }

        const fetchData = async() => {
          setLoading(true)
          try{
            const url = process.env.REACT_APP_API_URL + 'richieste_concessioni_documenti/richiesta_id/' + richiestaId;
            const response = await fetch(url, {
              method: 'GET',
              headers: { 
                        accept: "application/json"
                      },      
              mode: 'cors'
             });
             const json = await response.json();
             // Da rivedere questa parte: dovremmo avere una api ad hoc che ritorni solo i dati che ci servono, non dobbiamo scremarli qua...
            let list = [];
            let i = 1;
            if(json.risposta.richieste_concessione_documenti){
              if(Array.isArray(json.risposta.richieste_concessione_documenti)){
                json.risposta.richieste_concessione_documenti.map(element => {
                  element = formatter.cleanObject(element)
                  let actions = buildActions(element.id)
                  list[i] = {
                    id: element.id,
                    tipo_documento: getTipoDocumento(element.tipo_documento_id)|| " - ",
                    descrizione: element.descrizione || " - ",
                    riferimento_documento: element.riferimento_documento,
                    link: { 
                      label: element.filename,
                      link: element.file_path           
                    },
                    app_actions:actions
                  }
                  i++
                });
              }
            } 
            
            setList(list); 
            setLoading(false);
          
          } catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
          }
          
          setPulisciFile(false)
          setModificaDocumento(false)
          setLoading(false);
      };
      fetchData();
      
    }, [documentiArray, modificaDocumento]);
  

    return (
        <>
        <Formik
        id='documento' className="p-4"
        initialValues={{
            tipo_documento_id: '',
            riferimento_documento: '', 
            descrizione: '',
            file: null 
        }}

        /*
        validationSchema={Yup.object().shape({
            file: Yup.mixed().required(),
        })}
        */

          onSubmit = { async (values, { setSubmitting, setFieldValue,resetForm }) => {
            setLoading(true)
            let associazioneConcessioneDocumento = true;
            if(!fileObject){
              alertify.warning("Caricare un file");
              setLoading(false)
              return;
            }

            let campi = ""
            let valori = ""

                 
            Object.entries(values).map(([key,value],i) => {
              if(key=='file'){
              } else if(value != ''){
                campi += key + ", "
                valori += "'" + formatter.escapeSql(value) + "', "
              } 
            })

            if(valori == ""){
              alertify.warning("Inserire almeno un campo");
              setLoading(false)
              return;
            }

            // Prima carico il doc: se va a buon fine posso inserire a DB.
            const formData = new FormData();
            let filePath = process.env.REACT_APP_FILE_FOLDER
            let fileName = ""
            formData.append('uploadedFile', fileObject);
            formData.append('contesto', "richiesta");
            formData.append('id', richiestaId);
            await fetch(
              process.env.REACT_APP_API_FILE_UPLOAD,
              {
                method: 'POST',
                body: formData,
              }
            ).then((response) => response.json())
              .then((result) => {
                filePath += result.risposta.path;
                fileName += result.risposta.filename;
              })
              .catch((error) => {
                alertify.error("Errore durante il caricamento del file");
                console.error('Error:', error);
                associazioneConcessioneDocumento = false;
              });
            
            if(associazioneConcessioneDocumento){
              try{
                campi += "richiesta_concessione_id,file_path,filename,utente_modifica_id";
                valori += richiestaId+",'"+formatter.escapeSql(filePath)+"','"+formatter.escapeSql(fileName)+"',"+localStorage.id
                const documento = {
                  nome_tabella: "richieste_concessione_documenti",
                  lista_campi: campi,
                  lista_valori: valori
                }
  
                const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' }, 
                  mode: 'cors', 
                  body: JSON.stringify({Entry:documento})
                })
  
                const data_response = await response.json();
                if(data_response.hasOwnProperty('Fault')){
                  if(data_response.Fault.hasOwnProperty("faultcode") && data_response.Fault.hasOwnProperty("faultstring")){
                    console.log("ErrorCode: " + data_response.Fault.faultcode)
                    throw new Error(data_response.Fault.faultstring)
                  } else{
                    throw new Error("errore durante il salvataggio.")
                  }
                }
                setDocumentiArray(documentiArray => [documentiArray, data_response.risposta.elementi_aggiunti.id] );
                alertify.success("Documento associato alla domanda con successo.");
                 resetForm();
                 setPulisciFile(true)
                 setTipoDocumento(null)
              } catch(error) {
                alertify.error("Errore durante l'associazione del documento");
                console.log("errore: ", error);
              }
            }

            setLoading(false)
          }}
          
        >
        <Form>
          <fieldset disabled={props.readOnly}>
                <br /><br />
                    <SicondirSelect
                        label="Tipo Documento" 
                        id="tipo_documento_id" 
                        name="tipo_documento_id" 
                        isClearable={true}
                        value={tipoDocumento}
                        onChange={manageTipoDocumento}
                        options={tipiDocumento} 
                        placeholder="Seleziona Tipo Documento"
                    />
                    

                    <SicondirInput 
                        label='Descrizione'
                        id='descrizione'
                        type='text'
                        name='descrizione'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Descrizione Documento'   
                        maxLength = '255'
                    />

                    <SicondirInput 
                        label='Numero Riferimento Documento'
                        id='riferimento_documento'
                        type='text'
                        name='riferimento_documento'
                        className={`form-control`}
                        size="large" 
                        placeholder = 'Inserisci Numero Riferimento Documento'   
                        maxLength = '255'
                    />

                    <UploadButton setFileObject={setFileObject} cleanFile={pulisciFile} readOnly={props.readOnly}/>

                <div className="col-12 mt-5 row justify-content-center">
                    <button type="submit" className="btn btn-primary col-5" disabled={loading || props.readOnly}>
                    Salva
                    </button>
                </div>
            </fieldset>
        </Form>
        </Formik>
        <br /><br />
        {!loading ?
          <SicondirTable 
          headers={[
            "Tipo documento",
            "Descrizione", 
            "Numero riferimento documento",
            "Scarica",
            ""]} 
          data={list} 
          dropdown={true}
          readOnly={props.readOnly}
          />
        
        
        :
        <>
        <br />
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="progress-spinner progress-spinner-active">
            <span className="sr-only">Caricamento...</span>
          </div>
        </div>
        </>
        }

        <Modal
          size="xl"
          show={editShow}
          onHide={handleCloseEdit}
          >
          <Modal.Header closeButton>
              <Modal.Title >
                  Modifica Documento Caricato
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {!loadingEdit ?
                  <DocumentoRichiestaFormEdit 
                    documento={documentoEdit}
                    handleClose={handleCloseEdit} 
                    setModifica={setModificaDocumento} />
                  :
                  <div className="h-100 d-flex align-items-center justify-content-center">
                      <div className="progress-spinner progress-spinner-active">
                          <span className="sr-only">Caricamento...</span>
                      </div>
                  </div>
              }
          </Modal.Body>
        </Modal>
      </>
    )
}

export default DocumentoRichiestaForm;