import Select, { Option } from 'react-select'
import './css/custom-select.css';
import { useField } from "formik";


export default function SicondirSelect(props){
    const [field, meta, helpers] = useField(props.name); // can pass 'props' into useField also, if 'props' contains a name attribute
    const { setValue, setTouched, setError } = helpers;
    const mandatory = props.mandatory ? "*" : ""
    
    const setFieldProps = selectedOption=> {
        if(props.isMulti) {
            setValue(selectedOption ? selectedOption : null) 
        } else{
            setValue(selectedOption ? selectedOption.value : null) 
        }
        setTouched(true)
        setError(undefined)
        if(props.onChange)
            props.onChange(selectedOption)
    }

    return (
        <>
            <label className="sicondir-label" htmlFor={props.id}>{props.label}<sup>{mandatory}</sup></label>
            <div className="select-wrapper">
            <Select 
                {...props} 
                className={"w-100 react-select-container " + (props.className || "")}
                classNamePrefix="react-select"
                placeholder={props.placeholder}
                onChange={selectedOption => setFieldProps(selectedOption)} 
                noOptionsMessage={() => 'Nessuna opzione disponibile'}

                onBlur={setTouched}
            />
            {
                meta.touched && meta.error ? 
                (<small className="sicondir-error">{meta.error}</small>) 
                : (<div style={{height: "8px"}}></div>)
            } 
            </div>
        </>
    );

} 