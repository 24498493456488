import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Formik, isObject, Field } from 'formik'
import {Table } from 'react-bootstrap';
import alertify from "alertifyjs";  
import formatter from '../../../common/utils/formatters/_';
import RicercaPersona from "../../../common/forms/RicercaPersona";
import Modal from 'react-bootstrap/Modal'
import SicondirTable from "../../../common/components/SicondirTable";
import AnagraficaFormEdit from '../../../common/forms/AnagraficaFormEdit';

function ConcessioneIntestatario() {
    const [modificaIntestatario,setModificaIntestatario]= useState(false);
    const [editShow, setEditShow] = React.useState(false);
    const [loadingEdit, setLoadingEdit] = useState(true)
    const [ruoliIntestatario, setRuoliIntestatario] = useState([])
    const [richiestaIntestatario, setRichiestaIntestatario] = useState(null)
    const [list, setList] = useState([])
    const params = useParams();
    const concessioneId = params.id;
    const [concessioneIntestatario, setConcessioneIntestatario] = useState(null)
    const[show, setShow] = useState(false);
    const[associazione, setAssociazione] = useState(false);
    const[cancellaAssociazione, setCancellaAssociazione] = useState(false);
    const [loading, setLoading] = useState(true);
    
    const handleModal = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }
    const handleModalEdit = async (idPersona) => {
      
        try {
            const response = await fetch(process.env.REACT_APP_API_URL +
                'persone/id/' + idPersona

                ,
                {
                    method: 'GET',
                    headers: { accept: 'application/json' },
                    mode: 'cors',
                })

            const json = await response.json()
            let  persona=" "
            let ruoli=[]
            if (json.risposta.persone) {
             persona = json.risposta.persone

                
            }
             const response2 = await fetch(process.env.REACT_APP_API_URL +
                'ruoli/persona_id/'+idPersona
                ,
                {
                    method: 'GET',
                    headers: { accept: 'application/json' },
                    mode: 'cors',
                })

            const json2 = await response2.json()

            if (json2.risposta.ruoli) {
                 ruoli = json2.risposta.ruoli

                
            }
            setRichiestaIntestatario(formatter.cleanObject(persona))

            let array = []
            if(Array.isArray(ruoli)) {
                ruoli.map(r => {
                    array.push(formatter.cleanObject(r))
                })
                setRuoliIntestatario(array)
            }
            setLoadingEdit(false)
          
        }
        catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
        }

        setEditShow(true);
        
    }
    const handleCloseEdit = () => {
        setEditShow(false);
    }
    const handleDelete = async (idAssociazione) => {
        setLoading(true);
        const payload = {
            nome_tabella: "concessioni_persone",
            id: idAssociazione,
            utente_modifica_id: localStorage.id
        }
        
        try{
            const response = await fetch(process.env.REACT_APP_API_URL + 'cancella_elemento', {
                method: 'POST', // Deve essere PUT, ma con la PUT da' errore di cors 405... Misteri di WSO2
                headers: { 'Content-Type': 'application/json',
                            'Accept': 'application/json' }, 
                mode: 'cors', 
                body: JSON.stringify({Entry:payload})
            })
            const data = await response.json();
            alertify.success("Associazione intestatario/concessione eliminata con successo");
            
            setLoading(false)
            setCancellaAssociazione(true)
        } catch(error) {
            alertify.error("Errore durante la cancellazione dell'associazione bene intestatario/concessione");
            setLoading(false)
        }
    }
    const checkNull = (txt) => {
        if(String(txt)=='null') {
            return '-'
        }
        return txt
    }
    const fetchData = async (concessioneId) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `concessioni_persone_ruoli/concessione_id/${concessioneId}`, 
            {
                method: 'GET',
                headers: { accept:'application/json' }, 
                mode: 'cors',  
            })
            const json = await response.json()
            if(json.risposta.concessioni_persone_ruoli){
                let response = json.risposta.concessioni_persone_ruoli
                setConcessioneIntestatario(response)
                
                const lista = []
                if(Array.isArray(response)) {
                    const entries = Object.entries(response);
                    let i = 0;
                    entries.map(([key,value]) => {
                        formatter.cleanObject(value)
                        let actions = buildActions(value.associazione_id,value.id)
                        lista[i]=
                        {
                          id: value.associazione_id,
                          nome: value.nome,
                          cognome: value.cognome,
                          codice_fiscale: value.codice_fiscale,
                          ragione_sociale: value.ragione_sociale,
                          piva: value.piva,
                          tipo_persona: value.tipo_persona,
                          ruolo:'Intestatario',
                          app_actions: actions
                        }
                        i++
                    })
                }
                setList(lista)
                setCancellaAssociazione(false)
                setLoading(false);
            } else{
                setConcessioneIntestatario(null)
            }
            setAssociazione(false)
            setModificaIntestatario(false)
            setLoading(false);

        } catch (error) {
            alertify.error("Errore durante il recupero dei dati.");
            console.log("errore: ", error);
        }
    }
    const buildActions = (idAssociazione, idPersona) => {
        let result = {};
        result = { associa: {
                    kind: "function", 
                    function: handleDelete,
                    parameters:
                    {
                        idAssociazione: idAssociazione
                    },
                    label: "Elimina associazione" ,
                    buttonClass: "btn-danger",
                    linkClass: "text-danger",
                    confirmLabel: "Procedere con l'eliminazione dell'associazione intestatario/concessione?"
                },
                 modifica: {
                    kind: "function", 
                    function: handleModalEdit,
                    parameters:
                    {
                        idPersona: idPersona
                    },
                    label: "Modifica Intestatario" 
                    
                     }
        }
        return result;        
    }
    useEffect(() => {
        fetchData(concessioneId)
    }, [associazione, cancellaAssociazione, modificaIntestatario])
    
  return (
    <>
    { !loading
        ?
        <>
        { !concessioneIntestatario 
            ?
            <div className="row">
                <div className="col-12 mt-5 row justify-content-center">
                    Nessun Intestatario è associato a questa concessione.
                </div>
            </div>
            :
            <div>
            <br />
            <SicondirTable 
                headers={[
                  "Nome",
                  "Cognome", 
                  "Codice fiscale",  
                  "Ragione sociale",
                  "Partita Iva",
                  "Tipo",
                  "Ruolo",
                  ""
                  ]} 
                data={list}
                dropdown={true} />
            </div>
        }
            <div className="col-12 mt-5 row justify-content-center">
                <button  className="btn btn-primary col-5" onClick={handleModal}>
                    Associa Intestatario
                </button>
            </div>
        </>
        :
        <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="progress-spinner progress-spinner-active">
                <span className="sr-only">Caricamento...</span>
            </div>
        </div>

    }

        <Modal 
            dialogClassName={'modal-fullpage'}
            show={show} 
            onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title >
                    Associa Intestatario
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RicercaPersona concessioneId={concessioneId} contesto="Concessione" handleClose={handleClose} concessioneIntestatario={concessioneIntestatario} setAssociazione={setAssociazione} />
            </Modal.Body>
        </Modal>     

            <Modal
                dialogClassName={'modal-fullpage'}
                show={editShow}
                onHide={handleCloseEdit}
                >
                <Modal.Header closeButton>
                    <Modal.Title >
                        Modifica Intestatario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loadingEdit ?

                            <AnagraficaFormEdit 
                            persona={richiestaIntestatario} 
                            ruoli={ruoliIntestatario} 
                            handleClose={handleCloseEdit} 
                            setPersonaAggiornata={setAssociazione}
                            contesto="concessione"
                            setModificaIntestatario={setModificaIntestatario}
                            />
                              
                        :
                        <div className="h-100 d-flex align-items-center justify-content-center">
                            <div className="progress-spinner progress-spinner-active">
                                <span className="sr-only">Caricamento...</span>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>





    </>
  )
}

export default ConcessioneIntestatario