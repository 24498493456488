import { useField } from "formik";

export default function SicondirSingleCheckbox(props){
    
    const [field, meta] = useField(props);
    
   return (
    <>
    <div>
    <div className="form-check">
        <input type='checkbox' {...field} {...props} />
        {props.label ? <label htmlFor={props.id}>{props.label}</label> : ''}
    </div>
    </div>
    {
        meta.touched && meta.error ? 
        (<small className="sicondir-error">{meta.error}</small>) 
        : (<div style={{height: "8px"}}></div>)
    }
    </>
   )
}
