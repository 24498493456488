import {Popover } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function TriggerPopover(props) {
  let placement = props.placement ? props.placement : "right"
  let text = props.text
  const renderTooltip = (props) => (
    <Popover id="button-tooltip" {...props}>
      <div style={{padding:'10px'}}>{text}</div>
    </Popover>
  );

  return (
    <>
     
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <div className="text-truncate" 
        style={{maxWidth:'150px'}}>{text}</div>
        {/* <Button variant="success">Hover me to see</Button>*/}
    </OverlayTrigger>
    </>
  );
}

export default TriggerPopover;