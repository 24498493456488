import React from 'react'
import { useState } from 'react'
import { Formik, Field, Form, useFormik } from "formik";
import SicondirSelect from "./SicondirSelect";
import SicondirInput from "./SicondirInput";
import tipiContatti from '../../_data/tipi_contatti.json'
import formatter from '../utils/formatters/_' 
import alertify from "alertifyjs";

const ContattoEdit = (props) => {

  const [loading, setLoading] = useState(false);

  const initialValue = {
    tipo_contatto_id: props.contatto.tipo_contatto_id,
    valore: props.contatto.valore,
  }

  const fetchData = async (listaCampi) => {
    const payload = {
      nome_tabella: "persone_contatti",
      lista_campi_valore: listaCampi,
      lista_clausole_where: "id="+ props.contatto.id + " and flag_deleted=0"
    }
    const response = await fetch(process.env.REACT_APP_API_URL + 'modifica_elemento', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }, 
      mode: 'cors', 
      body: JSON.stringify({Entry:payload})
    })
  }
  return ( 
    <>
      {!loading ?
        <>
        <Formik 
          initialValues={initialValue} 
          id='modifica_contatto' 
          className="p-4"
          enableReinitialize={true}
          onSubmit = { async (values, { setSubmitting }) => {
            setSubmitting(true);
            // FACCIO LA MODIFICA DEL CONTATTO
            let campiContatto = ""
        
            Object.entries(values).map(([key, value], i) => {
              if (value != "") {
                campiContatto += key + "='" + formatter.escapeSql(value) + "', ";
              }
            });
            try {
              campiContatto += "utente_modifica_id=" + localStorage.id;
              fetchData(campiContatto);
              alertify.success("Contatto modificato con successo.");
              props.setContattoCambiato(true)
              props.handleClose()
            } 
            catch(error) {
              alertify.error("Errore durante la modifica dei dati: " + error);
              console.log("errore: ", error);
            }
          }}
        >
          {({
            values, 
            errors, 
            handleChange, 
            handleSubmit,
            isSubmitting,
            isValid,
            touched,
            dirty,
          }) => (
            
            <form onSubmit={handleSubmit} id='modificaContatto' className="p-4">    
              <div className="col-4 md-12 sm-12 sicondir-label">
              
                <SicondirSelect 
                  label = "Tipo contatto"
                  id = 'tipo_contatto_id'
                  name = 'tipo_contatto_id'
                  options = {tipiContatti}
                  isClearable={true}
                  key = "tipoContatti"
                  placeholder = 'Tipo contatto'
                  defaultValue={ tipiContatti.filter(c => c.value == props.contatto.tipo_contatto_id) }    
                />    
              </div>
              <div className="col-4 md-12 sm-12">
                <SicondirInput
                  label = "Valore" 
                  id = 'valore'
                  name = 'valore'
                  type = 'text'
                  value = { values.valore || '' }
                  onChange = {handleChange}
                  key = "valore"
                  className = 'form-control mb-2'
                  size = "large" 
                  placeholder = 'Valore'
                  maxLength = '70'
                />
              </div>
              <button type="button" onClick={props.handleClose} className="btn btn-primary col-2 mr-2">
                Chiudi
              </button>
              <button type="submit" className="btn btn-primary col-2">
                Modifica
              </button>
            </form>
          )}
        </Formik>
        </>
         :
         <>
         <br />
         <div className="h-100 d-flex align-items-center justify-content-center">
           <div className="progress-spinner progress-spinner-active">
             <span className="sr-only">Caricamento...</span>
           </div>
         </div>
         </>
      } 
    </>     
  )
}
export default ContattoEdit