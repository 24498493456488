import alertify from "alertifyjs"; 

const associaAllaConcessione = async (concessioneId, handleClose, personaId, ruoliId, setAssociazione) => {
  const inserimentoConcessioniPersone = async (ruoliId) => {
    if(ruoliId) {
      let campi = "utente_modifica_id, concessione_id, persona_ruolo_id "
      let valori = localStorage.id + ", " + concessioneId + ", " + ruoliId
      
      const payload = {
        nome_tabella: "concessioni_persone",
        lista_campi: campi,
        lista_valori: valori
      }
      const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, 
        mode: 'cors', 
        body: JSON.stringify({Entry:payload}) 
       })

      alertify.success("Persona associata alla concessione con successo.")
      handleClose()
      setAssociazione(true)
    }
  }
  try {
    inserimentoConcessioniPersone(ruoliId)
  } catch(error) {
    alertify.error("Errore durante l'inserimento.");
    console.log("errore: ", error);
  }
  return(
    <dialog></dialog>
  )
}
export default associaAllaConcessione;