import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import formatter from "../../../common/utils/formatters/_";
import RiferimentoPagamentoRicercaForm from '../../../common/forms/RiferimentoPagamentoRicercaForm';
import SicondirTable from '../../../common/components/SicondirTable';
import Modal from 'react-bootstrap/Modal'
import RiferimentoPagamentoImportoForm from '../../../common/forms/RiferimentoPagamentoImportoForm';


const RiferimentoPagamentoRicerca = (props) => {
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [sent, setSent] = useState(false);
    const [list, setList] = useState([]); 
    
    const[associazione, setAssociazione] = useState(false)

    // variabili per il modale dell'importo
    const params = useParams();
    const [showImporto, setShowImporto] = useState(false)
    const [debito, setDebito] = useState({})

    const setAssociazioneImporto = () => {
      setShowImporto(false)
      props.handleClose()
      props.setAssociazione(true)
    }

    const handleCloseImporto = () => {
      setShowImporto(false);
    }

    const showModalImporto = (debito) => {
      setDebito(debito)
      setShowImporto(true)
    }

    useEffect(() => {
      if(associazione){
        props.handleClose()
      }
    }, [associazione])

    const buildActions = (debito) =>{
      return {
        associa: {
          kind: "function", 
          function: showModalImporto,
          parameters:
          {
            debito: debito
          },
          label: "Associa a pagamento" }
        }
    }
    
    
    useEffect(() => {
      const fetchData = async (filter) => {
        setLoading(true);

        let identificativoDebito = filter.identificativo_debito ? encodeURI(filter.identificativo_debito) : " "
        let codiceSicondir = filter.codice_sicondir ? encodeURI(filter.codice_sicondir) : " "
        let codiceSistemaPrecedente = filter.codice_sistema_precedente ? encodeURI(filter.codice_sistema_precedente) : " "

        let url = process.env.REACT_APP_API_URL + 'debiti/ricerca/'
                + identificativoDebito + "/"
                + codiceSicondir + "/"
                + codiceSistemaPrecedente + "/"
                + props.pagamento.id
                
        let response = await fetch(url, 
            {
                method: 'GET',
                headers: { accept:'application/json' }, 
                mode: 'cors',  
            })
        let json = await response.json()

        let list = [];
        let i = 1;
          if(json.risposta.debiti){
            if(Array.isArray(json.risposta.debiti)){
              json.risposta.debiti.map(element => {
                element = formatter.cleanObject(element)
                let actions = buildActions(element)
                let codice = (element.riferimento_codice_sicondir || "-") + ("(" + (element.riferimento_codice_sistema_precedente || "-") + ")");
                let tipoDebito = element.canone_tipo_pagamento == 'CA' ? "Canone" : (element.canone_tipo_pagamento == 'CO' ? "Conguaglio" : "-") ;
                let importoDebito = formatter.formatNumber(element.canone_importo_totale);
                let importoPagato = formatter.formatNumber(element.canone_importo_pagato);
                list[i] = {
                  id: element.id_debito,
                  codice: codice,
                  anno_contabile: element.canone_anno_contabile || " - ",
                  identificativo_debito: element.canone_identificativo_debito || " - ",
                  tipo_debito: tipoDebito,
                  importo_debito: importoDebito,
                  importo_pagato: importoPagato,
                  app_actions: actions
                }
                i++
              });
            }
          }
          
          setList(list); 
          setLoading(false);
      }

      if(sent){
        fetchData(filter);
      }
    }, [filter, sent]) 
    
    return(
        <>
          <RiferimentoPagamentoRicercaForm 
            setFilter={setFilter} 
            setSent={setSent} 
            disabled={loading}
          />
          <br /><br />
          {!loading ?
          <>
            { sent 
              ?
              <>
              <SicondirTable 
                headers={[
                  "Codice Concessione/Occupazione",
                  "Anno contabile",
                  "Identificativo Dovuto",
                  "Tipo Debito",
                  "Importo Debito(€)",
                  "Importo Pagato(€)",
                  ""
                ]} 
                data={list}
                compact={true}
                />
              </>
              :
              <></>
            }

            </>

            :
            <div className="h-100 d-flex align-items-center justify-content-center">
              <div className="progress-spinner progress-spinner-active">
                <span className="sr-only">Caricamento...</span>
              </div>
            </div>

          }

          <Modal 
            size="xl"
            show={showImporto} 
            onHide={handleCloseImporto}>
            <Modal.Header closeButton>
                <Modal.Title >
                    Specifica Importo Da Riconciliare
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RiferimentoPagamentoImportoForm
                    pagamento={props.pagamento}
                    debito={debito}
                    handleClose={handleCloseImporto} 
                    setAssociazione={setAssociazioneImporto} 
                />
            </Modal.Body>
        </Modal>
        </>
    )
} 

export default RiferimentoPagamentoRicerca;
