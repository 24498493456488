
import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import Comuni from '../../../_data/comuni.json'
import Nazioni from '../../../_data/nazioni.json'
import { Formik, Field } from 'formik'

import formatter from '../../utils/formatters/_'
import _ from 'lodash'
import SicondirTable from "../../components/SicondirTable";
import SicondirSelect from "../../components/SicondirSelect";
import associaAllaRichiesta from '../../../modules/concessioni/components/AssociaAllaRichiesta'
import alertify from "alertifyjs"; 
import tipiRuoli from '../../../_data/tipi_ruoli_frontend.json'
import * as Yup from 'yup'



const IntestatarioRichiestaForm = (props) => {
  let navigate = useNavigate();
  const [list, setList] = useState([])
  const params = useParams()
  const richiestaId = params.id
  const [persona, setPersona] = useState({});
  const [comunenascita, setComunenascita] = useState();
  const [provincianascita, setProvincianascita] = useState();
    const [nazionenascita, setNazionenascita] = useState('');
    const [codicefiscale, setCodicefiscale] = useState(props.cod_fisc);
    const [cognome, setCognome] = useState(props.cognome);
    const [datanascita, setDatanascita] = useState('');
    const [genere, setGenere] = useState('');
    const [estero,setEstero] = useState('');
    const [piva,setPiva] = useState(props.partita_iva);
    const [ragione_sociale,setRagione_sociale] = useState(props.ragione_sociale);
    const [valueNaturaGiuridica, setValueNaturaGiuridica] = useState(props.natura_giuridica);
    const [labelComuneNascita, setLabelComuneNascita] = useState('Comune nascita');
    
    const Mesi = { A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' }

    const validationSchemaPf = Yup.object({
        nome: Yup.string()
        .max(40, "Il nome non può avere più di 40 caratteri")
        .min(2, "Il nome deve avere almeno 2 caratteri")
        .required("Il nome è obbligatorio"), 
        cognome: Yup.string()
        .max(40, "Il cognome non può avere più di 40 caratteri")
        .min(2, "Il cognome deve avere almeno 2 caratteri")
        .required("Il cognome è obbligatorio"),
        //codice_sicer: Yup.string().required("Il codice Sicer è obbligatorio"),
        codice_fiscale: Yup.string()
        .max(16, "Il codice fiscale non può avere più di 16 caratteri")
        .min(16, "Il codice fiscale non può avere meno di 16 caratteri")
        .required("Il codice fiscale è obbligatorio"),
        datainizioincarico: ""
        })
      const validationSchemaPg = Yup.object({
     //   codice_sicer: Yup.string().required("Il codice Sicer è obbligatorio"),
        ragione_sociale: Yup.string()
        .max(150, "La ragione sociale non può avere più di 150 caratteri")
        .min(2, "La ragione sociale deve avere almeno 2 caratteri")
        .required("La ragione sociale è obbligatoria"),
        piva: Yup.string()
        .max(11, "La partita iva non può avere più di 11 caratteri")
        .min(11, "La partita iva non può avere meno di 11 caratteri")
        .required("La partita iva è obbligatoria"),
        datainizioincarico: "",
        })
        
      const initialValue = {
            tipo_persona: valueNaturaGiuridica,
            nome: "", 
            cognome: cognome,
            codice_sicer: "",
            codice_fiscale: codicefiscale,
            ragione_sociale: ragione_sociale,
            comune_nascita: "",
            nazione_nascita: "",
            data_nascita: "",
            genere: "",
            piva: "",
            datainizioincarico: "",
            note: "",
            username: "",
            ruoli: ""
        }

    const capitalizeFirstLetter = (string)=>  {
      if(string != null){
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      return "";
    }

    const tornaRicerca = (persona_trovata, setRicerca) => {
      for (const key in persona_trovata) {
        delete persona_trovata[key];
      }
      setRicerca(true)
    }
    const comuneNascitaCf = (codiceFiscale) => { 
      if(codiceFiscale.length==16) {
        // let result={comune:undefined, nazione:undefined};
        const codCatastale = codiceFiscale.substring(11,15).toUpperCase()
        if(codiceFiscale.charAt(11).toUpperCase() !== 'Z') {
          setLabelComuneNascita('Comune nascita');
          const comunijson = JSON.stringify(Comuni, null, 4);
          const comuniobj = JSON.parse(comunijson);
          for(let key in comuniobj) {
            if(comuniobj[key].codiceCatastale==codCatastale) {
              setComunenascita(comuniobj[key].nome)
              setProvincianascita(comuniobj[key].sigla)
              setNazionenascita('Italia')
              break;
            }
          }
        }
        else {
          setEstero('Z')
          setLabelComuneNascita('Città di nascita');
          const nazionijson = JSON.stringify(Nazioni, null, 4);
          const nazioniobj = JSON.parse(nazionijson);
          for(let key in nazioniobj) {
            if(nazioniobj[key].italian_country_code==codCatastale) {
              setNazionenascita(nazioniobj[key].italian_country_name_1)
              break;
            }
          }
        }
        setDatanascita(dataNascitaCf(codiceFiscale))
        setGenere(sessoCf(codiceFiscale))
      }
    }
    const dataNascitaCf = (codiceFiscale) => {
      let [ anno, giorno ] = [ codiceFiscale.substring(6,8), codiceFiscale.substring(9,11) ];
      if (giorno>40) {
        giorno -= 40
        if(giorno<10){
          giorno = "0" + giorno
        }
      }
      return giorno +  "-" + Mesi[codiceFiscale.charAt(8).toUpperCase()] + "-" + (anno < 20 ? "20" : "19" ) + anno;
    }
    const sessoCf = (codiceFiscale) => { 
      return codiceFiscale.substring(9,11) > 40 ? "F" : "M"; 
    }

    const onChange = (e, errors) => {
      for (const key in errors) {
        delete errors[key];
      }
      const { name, value } = e.target;
      if(name==='codice_fiscale') {   
        setCodicefiscale(e.target.value.toUpperCase())
        if(value.length===16 && valueNaturaGiuridica=='PF') {
          comuneNascitaCf(value)
          setGenere(sessoCf(value))
        }
      }
    }
  const vaiModificaPersona = (concessioneId, handleClose, personaId, ruoliId, setAssociazione) => {
    navigate('/anagrafica/modifica/' + personaId) ;
  }
  useEffect(
    () => {
      let confirmLabel= "Procedere con l'eliminazione dell'intestatario?" 
        let lista = [];
        setPersona(formatter.cleanObject(props.persona_trovata));
        let label=""
        if(Array.isArray(props.persona_trovata)) {

        // ho più persone  
        let i = 1;
        
        const entries = Object.entries(formatter.cleanObject(props.persona_trovata));
        entries.map(([key,value]) => {
          value = formatter.cleanObject(value)
          if(props.contesto=="richiesta") {
            if(props.persona_trovata[i-1].ruoli!=null) {
              label="Associa a domanda"
            } else {
              label="Cambia ruolo"
            }
          } else {
            label="Modifica"
          }
          (value.tipo_persona=='PF') 
          ?
            lista[i]=
            {
              id: value.id,
              cognome: capitalizeFirstLetter(value.cognome),
              nome: capitalizeFirstLetter(value.nome),
              codice_fiscale: value.codice_fiscale,
              codice_sicer: value.codice_sicer,
              tipo_persona: 'Persona fisica',
              //ruolo: '',
              app_actions: { associa: {
                kind: "function", 
                function: 
                props.contesto=="richiesta" && props.persona_trovata[i-1].ruoli!=null
                  ?
                    associaAllaRichiesta
                  :
                    vaiModificaPersona 
                ,                  
                parameters:
                {
                  richiestaId: richiestaId,
                  handleClose: props.handleClose,
                  personaId: value.id,
                  ruoliId: value.ruoli,
                  setAssociazione: props.setAssociazione
                },
                label: label
                
              }}
            } 
          :
            lista[i]=
            {
               id: value.id,
               ragione_sociale: capitalizeFirstLetter(value.ragione_sociale),
               piva: value.piva,
              // codice_fiscale: value.codice_fiscale,
               codice_sicer: value.codice_sicer,
               tipo_persona: 'Persona giuridica',
              // ruolo: '',
              app_actions: { 
                associa: {
                kind: "function", 
                function: 
                props.contesto=="richiesta" && props.persona_trovata[i-1].ruoli!=null
                ?
                  associaAllaRichiesta
                :
                  vaiModificaPersona 
                ,
                parameters:
                {
                  richiestaId: richiestaId,
                  handleClose: props.handleClose,
                  personaId: value.id,
                  ruoliId: value.ruoli,
                  setAssociazione: props.setAssociazione
                },
                label: label
                
              }}
            } 
            i++
        })
      }
      setList(lista)
    }, [persona]
  )
  return (
    <>
      {
        _.isEmpty(props.persona_trovata)
        ? 
        <Formik 
            initialValues={initialValue} 
            validationSchema={valueNaturaGiuridica=='PF'?validationSchemaPf:validationSchemaPg} 
            onSubmit = { async (values, { setSubmitting }) => {
              setSubmitting(true);
              //values.tipo_persona = valueNaturaGiuridica
              if(valueNaturaGiuridica=='PF') {
                values.comune_nascita = formatter.escapeSql(String(comunenascita))
                //values.comune_nascita = comunenascita
                values.nazione_nascita = nazionenascita
                values.provincia_nascita = provincianascita
                values.data_nascita = datanascita
                values.genere = genere
              }
              try{
                let campi = ""
                let valori = ""
                let campiRuoli = ""
                let valoriRuoli = []
               
                Object.entries(values).map(([key,value],i) => {
                  if(key == 'natura_giuridica'){
                    key = "tipo_persona"
                  } 
                  if(key=='ruoli'){
                    campiRuoli += key
                    valoriRuoli = value
                  } else if(value != ''){
                    campi += key + ", "
                    valori += "'" + formatter.escapeSql(value) + "', "
                  } 
                })
                
              // FACCIO L'INSERIMENTO DELLA PERSONA
                campi += "utente_modifica_id";
                valori += localStorage.id
                const person = {
                  nome_tabella: "persone",
                  lista_campi: campi,
                  lista_valori: valori
                }
                
                const response = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' }, 
                  mode: 'cors', 
                  body: JSON.stringify({Entry:person}) 
                })
                const data = await response.json();
                const id_persona_inserita = data.risposta.elementi_aggiunti.id 
                setPersona(values)
        
        
              // FACCIO L'INSERIMENTO DEI RUOLI DELLA PERSONA IN persone_ruoli
              if(valoriRuoli.length){
                valoriRuoli.map(async el => {
                    const person_ruol = {
                      nome_tabella: "persone_ruoli",
                      lista_campi: 'persona_id, tipo_ruolo_id',
                      lista_valori: id_persona_inserita + ',' + el.value
                    }
                
                    const responseRuolo = await fetch(process.env.REACT_APP_API_URL + 'inserisci_elemento', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' }, 
                      mode: 'cors', 
                      body: JSON.stringify({Entry:person_ruol})
                    })
                    //const data = await response.json();
                  })
              }
                alertify.success("Nuova persona creata con successo.");
                tornaRicerca(props.persona_trovata, props.setRicerca)
              } catch(error) {
                console.log("Errore: ", error)
                alertify.error("Errore durante l'inserimento.");
              }
          }}
          >
            {({
              values, 
              errors, 
              handleChange, 
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              dirty,
            }) => (
              <form onSubmit={handleSubmit} id='anagrafica' className="p-4">
                <div className="form-group">
                <input 
                  id='nome'
                  type='text'
                  name='nome'
                  value={values.nome}
                  onChange={handleChange}
                  className={`form-control mb-2 text-capitalize ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Nome'   
                  maxLength = '40'
                />
                {
                  touched.nome && errors.nome 
                  ? 
                  (<small className="sicondir-error">{errors.nome}</small>)
                  : 
                  (<div style={{height: "8px"}}></div>)
                }
                <input 
                    id='cognome'
                    type='text'
                    name='cognome'
                    value={ values.cognome }
                    defaultValue={ cognome }
                    onChange={handleChange}
                   // onChange={(e) => handleChangeCognome(e)}
                    className={`form-control mb-2 text-capitalize ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                    size="large" 
                    placeholder = 'Cognome' 
                    maxLength = '40'
                />
                {
                  touched.cognome && errors.cognome 
                  ? 
                  (<small className="sicondir-error">{errors.cognome}</small>)
                  : 
                  (<div style={{height: "8px"}}></div>)
                }
                <input 
                  id='ragione_sociale'
                  type='text'
                  name='ragione_sociale'
                  value={ values.ragione_sociale }
                  defaultValue={ ragione_sociale }
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PF'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Ragione sociale'
                  maxLength = '150'
                />
                {
                  touched.ragione_sociale && errors.ragione_sociale 
                  ? 
                  (<small className="sicondir-error">{errors.ragione_sociale}</small>)
                  : 
                  (<div styel={{height: "8px"}}></div>)
                }
                <input 
                  id='codice_sicer'
                  type='text'
                  name='codice_sicer'
                  defaultValue={values.codice_sicer}
                  onChange={handleChange}
                  className='form-control mb-2'
                  size="large" 
                  placeholder = 'Codice Sicer'
                  maxLength = '20'
                />
                {
                  touched.codice_sicer && errors.codice_sicer 
                  ? 
                  (<small className="sicondir-error">{errors.codice_sicer}</small>)
                  : 
                  (<div style={{height: "8px"}}></div>)
                }
                <input 
                  id='codice_fiscale'
                  type='text'
                  name='codice_fiscale'
                  defaultValue={ codicefiscale }
                  value={ values.codicefiscale }
                  onChange={ handleChange }
                  //onChange={(e) => handleChangeCodFisc(e)}
                  onBlur={onChange}
                  className='form-control mb-2 text-uppercase'
                  size="large" 
                  placeholder = 'Codice fiscale'
                  maxLength = '16'
                />
                {
                  touched.codice_fiscale && errors.codice_fiscale 
                  ? 
                  (<small className="sicondir-error">{errors.codice_fiscale}</small>)
                  : 
                  (<div style={{height: "8px"}}></div>)
                }
                <input 
                  id='comune_nascita'
                  type='text'
                  name='comune_nascita'
                  defaultValue={''}
                // value={values.comune_nascita}
                  value={comunenascita}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = {labelComuneNascita}
                  maxLength = '40'
                />
                <input 
                  id='provincia_nascita'
                  type='text'
                  name='provincia_nascita'
                  defaultValue={''}
                  value={provincianascita}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PG'?'d-none':(estero=='Z'?'d-none':'d-inline-block')}`}
                  size="large" 
                  placeholder = 'Provincia nascita'
                  maxLength = '2'
                />           
                <input 
                  id='nazione_nascita'
                  type='text'
                  name='nazione_nascita'
                  defaultValue={''}
                  value={nazionenascita}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Nazione nascita'
                  maxLength = '40'
                />
                <input 
                  id='data_nascita'
                  type='text'
                  name='data_nascita'
                  value={datanascita}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Data nascita'  
                  maxLength = '10'
                />
                <input  
                  id='genere'
                  type='text'
                  name='genere'
                  value={genere}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PG'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Sesso'
                  maxLength = '1'
                />
                <input 
                  id='piva'
                  type='text'
                  name='piva'
                  value={values.piva}
                  defaultValue={values.piva}
                  onChange={handleChange}
                  className={`form-control mb-2 ${valueNaturaGiuridica==='PF'?'d-none':'d-inline-block'}`}
                  size="large" 
                  placeholder = 'Partita IVA' 
                  maxLength = '11'
                />
                {
                  touched.piva && errors.piva 
                  ? 
                  (<small className="sicondir-error">{errors.piva}</small>)
                  : 
                  (<div style={{height: "8px"}}></div>)
                }
                <SicondirSelect 
                  name="ruoli"
                  id="ruoli"
                  isMulti="true"
                  mandatory="true"
                  options={tipiRuoli} 
                  placeholder="Ruoli"
                 // value={values.ruoli}
                 // onChange={handleChange}
                />
                
              </div>
                {/* <br /><br />
                <div className="mb-2 align-middle">
                  <label id='dataultimavariazionePGlabel' htmlFor='dataultimavariazionePG' className="col-3">Data ultima variazione PG</label>
                  <Space direction="vertical" id='dataultimavariazionePG' className="ms-5" style={{display:'inlineBlock'}}>
                    <DatePicker placeholder='Data ultima variazione PG' onChange={handleChange} />
                  </Space>
                </div>
                <div className="mb-2 align-middle">
                  <label id='datainizioincaricolabel' htmlFor='datainizioincarico' className="col-3">Data inizio incarico ({notaDataIncarico})</label>
                  <Space direction="vertical" id='datainizioincarico' className="ms-5" style={{display:'inlineBlock'}}>
                    <DatePicker placeholder='Data inizio incarico' onChange={handleChange} />
                  </Space>
                </div> */}
                <div className="col-12 mt-5 row justify-content-center">
                <button type="button" className="btn btn-primary col-5 mr-5 float-right"
            onClick={() => tornaRicerca(props.persona_trovata, props.setRicerca)}
            >
              Torna alla ricerca
            </button>
                  <button type="submit" className="btn btn-primary col-5">
                  Inserisci
                  </button>
                </div>
            </form>
            )}
          </Formik>
        : 
          <div className="mt-5">
             {(valueNaturaGiuridica=='PF')?
              <SicondirTable 
                headers={[
                  "Cognome",
                  "Nome", 
                  "Codice fiscale",  
                  "Codice Sicer",
                  "Tipo persona",
                  ""
                  ]} 
                data={list} />
              :
              <SicondirTable 
                headers={[
                  "Ragione sociale",
                  "Partita Iva", 
                  "Codice fiscale",
                  "Codice Sicer",
                  "Tipo persona",
                  ""
                  ]} 
                data={list} />
            }
            <button type="button" className="btn btn-primary float-right"
            onClick={() => tornaRicerca(props.persona_trovata, props.setRicerca)}
            >
              Torna alla ricerca
            </button>
          </div>
        
      }
    </>
  );
}
export default IntestatarioRichiestaForm;