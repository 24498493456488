import React from 'react'
import { useEffect, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import IndirizzoIns from "./IndirizzoIns";
import ContattoIns from "./ContattoIns";

function TastiIndirizzoContatto(props) {
    const [showModal, setShowModal] = useState(false);
    const [contesto, setContesto] = useState();
    const handleModal = (contesto) => {
        setShowModal(true);
        setContesto(contesto)
      }
      const handleClose = () => {
        setShowModal(false);
      }
  return (
    <>
        <div className="col-12 mt-5 mb-5 row justify-content-center">
            <button type="button" onClick={() => handleModal('indir')} className="btn btn-primary col-4 mr-5">Inserisci Indirizzo</button>
            <button type="button" onClick={() => handleModal('contatto')} className="btn btn-primary col-4">Inserisci Contatto</button>
        </div>

        <Modal 
        size="xl"
        show={showModal} 
        onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title >
            Inserisci
              {
              contesto=='indir'
              ?
                ' indirizzo'
              :
              contesto=='contatto' 
                ?
                  ' contatto'
                : ''
              }
            
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            contesto=='indir'
            ?
            <IndirizzoIns
            handleClose = { handleClose }
            persona_id = { props.persona_id }
            valueNaturaGiuridica = { props.valueNaturaGiuridica }
            setIndirizzoCambiato = { props.setIndirizzoCambiato }
            />
            :
            contesto=='contatto' 
              ?
              <ContattoIns
              handleClose = { handleClose }
              persona_id = { props.persona_id }
              valueNaturaGiuridica = { props.valueNaturaGiuridica }
              setContattoCambiato = { props.setContattoCambiato }
              />
              : ''
          }
            
        </Modal.Body>
        </Modal> 
    </>
  )
}

export default TastiIndirizzoContatto